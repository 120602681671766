import * as React from 'react';

import TextBoxWithLabel from '../../input/input.label';
import { isEmpty } from '../../../utils/string.utils';
import { LocaleProps, localizable } from '../../i18n/localizable';
import Button from '../../input/button';
import { Services } from '../../../api/services';
import { UserInfo } from '../../../models/User';
import { ErrorField } from '../../util/error.fields';
import OverlayConfirm from '../../overlay/overlay.confirm';

interface Props {
  isRequest?: boolean;
  onUserChange?: (user: UserInfo) => any;
  showErrors?: boolean;
  showOnlyOnClick?: boolean;
  organizationId?: string;
}
interface State {
  userData: UserInfo;
  success?: boolean;
  error?: string;
  clickedSave?: boolean;
  loading: boolean;
}
class NewUserComponent extends React.Component<Props & LocaleProps, State> {
  constructor(props: Props & LocaleProps) {
    super(props);
    this.state = {
      loading: false,
      userData: { email: '', firstName: '', lastName: '' }
    };
    if (this.props.onUserChange) {
      this.props.onUserChange(this.state.userData);
    }
  }
  changeUserInfo(info: Partial<UserInfo>) {
    this.setState({ userData: Object.assign({}, this.state.userData, info) }, () => {
      if (this.props.onUserChange) {
        this.props.onUserChange(this.state.userData);
      }
    });
  }
  validate = () => {
    this.setState({ clickedSave: true, success: false, error: null });
    return !isEmpty(this.state.userData.email);
  };
  render() {
    const { _, isRequest } = this.props;
    const showError = this.state.clickedSave || this.props.showErrors;
    const showAfterEdit = !this.props.showOnlyOnClick;
    return (
      <div className="admins_page__create">
        <h2>{isRequest ? _('user.admin.details.title') : _('user.admin.create.title')}</h2>
        <TextBoxWithLabel
          label={_('user.admin.fields.email')}
          value={this.state.userData.email}
          onChange={email => this.changeUserInfo({ email })}
          type={'mail'}
          error={showAfterEdit && isEmpty(this.state.userData.email)}
          errorText={'Please enter an email for the new user'}
          showErrorPerDefault={showError}
        />
        {isRequest && (
          <TextBoxWithLabel
            label={_('user.admin.fields.password')}
            value={this.state.userData.password}
            onChange={password => this.changeUserInfo({ password })}
            type={'password'}
          />
        )}
        {isRequest && (
          <TextBoxWithLabel
            label={_('user.admin.fields.confirmation')}
            value={this.state.userData.passConfirmation}
            onChange={passConfirmation => this.changeUserInfo({ passConfirmation })}
            type={'password'}
          />
        )}
        <TextBoxWithLabel
          label={_('user.admin.fields.firstName')}
          value={this.state.userData.firstName}
          onChange={firstName => this.changeUserInfo({ firstName })}
        />
        <TextBoxWithLabel
          label={_('user.admin.fields.lastName')}
          value={this.state.userData.lastName}
          onChange={lastName => this.changeUserInfo({ lastName })}
        />
        {this.state.success && <div className="admins_page__success">{_('user.admin.status.success')}</div>}
        {this.state.error && (
          <OverlayConfirm
            className="admins_page__error_overlay"
            show={true}
            alert={true}
            message={_(`user.admin.status.${this.state.error}`)}
            onYes={() => {
              this.setState({ error: null });
            }}
          />
        )}

        {!isRequest && (
          <div style={{ textAlign: 'right', maxWidth: 330 }}>
            <Button
              text={'Cancel'}
              additionClassName=""
              onClick={() => {
                this.setState({
                  userData: { firstName: '', lastName: '', email: '' },
                  error: null,
                  clickedSave: false
                });
              }}
              link={true}
            />

            <Button
              text={_('user.admin.button.add.user')}
              additionClassName="admins_page__save"
              onClick={() =>
                this.validate() &&
                Services.Users.createUser({
                  email: this.state.userData.email,
                  firstName: this.state.userData.firstName,
                  lastName: this.state.userData.lastName,
                  password: '',
                  organizationId: this.props.organizationId
                })
                  .then(result => {
                    if (result === 'Success') {
                      this.setState({
                        userData: { firstName: '', lastName: '', email: '' },
                        success: true,
                        error: null,
                        clickedSave: false
                      });
                    } else if (result === 'UserExists') {
                      this.setState({ success: false, error: 'user.exists' });
                    } else {
                      this.setState({ success: false, error: 'general' });
                    }
                    this.setState({ clickedSave: false });
                  })
                  .catch(e => {
                    this.setState({ success: false, error: 'general' });
                    this.setState({ clickedSave: false });
                  })
              }
            />
          </div>
        )}
      </div>
    );
  }
}

export const NewUser = localizable(NewUserComponent);
