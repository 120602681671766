import rsf from "./firebase";
import {
  put,
  call,
  take,
  fork,
  takeEvery,
  all,
  select,
} from "redux-saga/effects";
import {
  loginSuccess,
  loginError,
  logoutSuccess,
  logoutError,
  resetPasswordSuccess,
  resetPasswordError,
  syncWithNoPrevUser,
} from "../actions/auth.actions";
import { Actions } from "../state/actions";
import { Action } from "redux-actions";
import { LoginErrorState, AppState } from "../state/state";
import {
  parseUserNode,
  UserDbNode,
  UserNodeSettings,
} from "../api/model/user.node";
import { push } from "connected-react-router";
import { Services } from "../api/services";
import * as moment from "moment-timezone";
import firebase from "../api/firebase";
import { UserCredential } from "firebase/auth";
import { EventChannel } from "redux-saga";
import { User } from "../models/User";
//const authProvider = new firebase.auth.GoogleAuthProvider();
declare const CONTINUE_URL: string;

function* loginSaga(
  action: Action<{ email: string; password: string; toPage?: string }>
) {
  try {
    const { email, password } = action.payload;
    const data: UserCredential = yield call(
      rsf.auth.signInWithEmailAndPassword,
      email,
      password
    );
    yield call(rsf.database.patch, `Users/${data.user.uid}/information`, {
      lastLogin: moment().toISOString(),
    });
    const userData: { information: UserDbNode; settings: UserNodeSettings } =
      yield call(rsf.database.read, `Users/${data.user.uid}`);
    console.log("User fb", data);
    if (userData) {
      userData.information.mainEmail = data.user.email;
    }
    if (
      userData &&
      userData.information.selfSigned &&
      !data.user.emailVerified
    ) {
      firebase.auth().currentUser.sendEmailVerification();
      yield call(rsf.auth.signOut);
      yield put(loginError(LoginErrorState.USER_NOT_EXISTS));
      yield put(push("/login/verify"));
    } else {
      yield put(
        loginSuccess(
          parseUserNode(
            Object.assign(
              { key: data.user.uid as string },
              userData ? userData.information : {}
            ) as UserDbNode,
            (userData ? userData.settings || {} : {}) as UserNodeSettings
          )
        )
      );
      yield put(push(action.payload.toPage || "/"));
    }
  } catch (error) {
    if (error.code === "auth/user-not-found") {
      yield put(loginError(LoginErrorState.USER_NOT_EXISTS));
    } else if (error.code === "auth/wrong-password") {
      yield put(loginError(LoginErrorState.INVALID_PASSWORD));
    } else {
      yield put(loginError(LoginErrorState.GENERAL));
    }
  }
}
function* resetSaga(action: Action<{ email: string }>) {
  try {
    const { email } = action.payload;
    const url = CONTINUE_URL;
    yield call(rsf.auth.sendPasswordResetEmail, email, { url });
    yield put(resetPasswordSuccess(""));
    yield put(push("/login/reset/success"));
  } catch (error) {
    if (error.code === "auth/user-not-found") {
      yield put(resetPasswordError(LoginErrorState.USER_NOT_EXISTS));
    } else {
      console.log(error);
      yield put(resetPasswordError(LoginErrorState.GENERAL));
    }
  }
}

function* logoutSaga() {
  try {
    const data: UserCredential = yield call(rsf.auth.signOut);
    yield put(logoutSuccess(data));
    document.location.href = "/";
  } catch (error) {
    yield put(logoutError(error));
  }
}

function* syncUserSaga() {
  const channel: EventChannel<UserCredential> = yield call(rsf.auth.channel);

  while (true) {
    const { user } = yield take(channel);

    if (user) {
      yield call(rsf.database.patch, `Users/${user.uid}/information`, {
        lastLogin: moment().toISOString(),
      });
      const userData: { information: UserDbNode; settings: any } = yield call(
        rsf.database.read,
        `Users/${user.uid}`
      );

      if (userData && userData.information.selfSigned && !user.emailVerified) {
        firebase.auth().currentUser.sendEmailVerification();
        yield call(rsf.auth.signOut);
        yield put(push("/login/verify"));
        yield put(loginError(LoginErrorState.USER_NOT_EXISTS));
      } else {
        yield put(
          loginSuccess(
            parseUserNode(
              Object.assign(
                { key: user.uid } as Partial<UserDbNode>,
                userData ? userData.information : {}
              ) as UserDbNode,
              userData ? userData.settings : {}
            )
          )
        );
        const path: string = yield select(
          (state: AppState) => state.router.location.pathname
        );
        const toPage: string = yield select((state: AppState) =>
          state.router.location.state && state.router.location.state.from
            ? state.router.location.state.from.pathname
            : null
        );

        if (path === "/login") {
          yield put(push(toPage || "/"));
        }
      }
      //yield put(loginSuccess(user));
    } else yield put(syncWithNoPrevUser(null));
  }
}

function* loginRootSaga() {
  yield fork(syncUserSaga);
  yield all([
    takeEvery(Actions.AUTH.LOGIN_REQUEST, loginSaga),
    takeEvery(Actions.AUTH.LOGOUT_REQUEST, logoutSaga),
    takeEvery(Actions.AUTH.RESET_PASSWORD, resetSaga),
  ]);
}

export default [loginRootSaga];
