export function debounce<F extends (...params: any[]) => void>(fn: F, delay: number) {
  let timeoutID: number = null;
  return function(this: any, ...args: any[]) {
    clearTimeout(timeoutID);
    timeoutID = window.setTimeout(() => fn.apply(this, args), delay);
  } as F;
}

export function onResize(eventHandler: (e: UIEvent) => any) {
  const realCallback = debounce(eventHandler, 250);
  window.addEventListener('resize', realCallback);
  return () => {
    window.removeEventListener('resize', realCallback);
  };
}
