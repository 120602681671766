import Calendar from './calendar';
import Organization from './organization';
import { Moment } from 'moment';

export default class CalendarCategory {
  id: string;
  name: string;
  description: string;
  calendars: { [id: string]: string };
  parent?: Organization;
  order?: number;
  created?: Moment;

  static defaultSort(c1: CalendarCategory, c2: CalendarCategory) {
    if (c1.order && c2.order) {
      return c1.order - c2.order;
    } else if (c1.order) {
      return -1;
    } else if (c2.order) {
      return 1;
    } else {
      return c1.id > c2.id ? 1 : -1;
    }
  }
}
