import * as React from "react";
import * as ReactDOM from "react-dom";

import "./styles/multi-select.less";
import { onResize } from "../../utils/event.utils";
import InputText from "./text";
import { isEmpty } from "../../utils/string.utils";
import calendar from "../pages/calendar";
interface MultiSelectProps<T> {
  text?: string;
  items: T[];
  onChange: (selected: T[]) => any;
  getText: (item: T) => string;
  getKey: (item: T) => string;
  additionalClass?: string;
  selectedItems?: { [name: string]: any };
  showSelectedAsText?: boolean;
  oneOption?: boolean;
  inputMode?: boolean;
  withAdd?: boolean;
  showErrorPerDefault?: boolean;
  error?: boolean;
  errorText?: string;
  filter?: (elem: T, text: string) => boolean;
  maxCharactersPerOption?: number;
  zIndexOpt?: number;
  notNullOnAll?: boolean;
  alwaysFilter?: boolean;
  allButtonText?: string;
  colors?: { [id: string]: string };
  disabled?: boolean;
}
interface MultiSelectState<T> {
  open: boolean;
  textInputVal: string;
}
type OptionsProps<T> = MultiSelectProps<T> & {
  selectBoxChange: (checked: boolean, item: T) => any;
  onClose: () => any;
  mainElemRef: HTMLDivElement;
  zIndex?: number;
};
interface OptionsState<T> {
  top: number;
  left: number;
  right: number;
  open: boolean;
  width: number;
  zIndex: number;
}

class MultiSelectOptions<T = any> extends React.Component<
  OptionsProps<T>,
  OptionsState<T>
> {
  readonly id: number;
  elem: HTMLDivElement;
  optionsElem: HTMLDivElement;
  unsubscribeResize: () => any;
  constructor(props: OptionsProps<T>) {
    super(props);
    this.id = new Date().getTime();
    this.elem = document.createElement("div");
    this.elem.id = `multi_options_${this.id}`;
    this.state = { open: false, ...this.getNewPositionState(props) };
  }
  getNewPositionState = (props: OptionsProps<T>) => {
    const rect = props.mainElemRef.getBoundingClientRect();
    const computedStyle = window.getComputedStyle(props.mainElemRef, null);
    const rightPaddingTxt = computedStyle.getPropertyValue("padding-right");

    const rightPadding = parseInt(rightPaddingTxt || "0", 10);
    const zIndexTxt = computedStyle.getPropertyValue("zIndex");
    return {
      top: rect.bottom + window.scrollY,
      left: rect.left,
      right: window.innerWidth - rect.right + rightPadding,
      width: rect.width,
      zIndex: !isEmpty(zIndexTxt) ? parseInt(zIndexTxt) + 1 : 1
    };
  };
  resize = () => {
    this.setState(this.getNewPositionState(this.props));
  };
  componentDidMount() {
    document.body.appendChild(this.elem);
    setTimeout(() => {
      window.addEventListener("click", this.close);
      this.setState({ open: true });
    });
    this.unsubscribeResize = onResize(this.resize);
  }
  componentWillUnmount() {
    window.removeEventListener("click", this.close);
    if (!this.elem) return;
    this.elem.remove();
    this.elem = null;
    this.unsubscribeResize();
  }

  close = (e?: MouseEvent) => {
    let close = true;
    if (e) {
      let clickedOnOptions = false;
      let target = e.target as HTMLElement;
      while (target && !clickedOnOptions) {
        clickedOnOptions = clickedOnOptions || target == this.optionsElem;
        target = target.parentElement;
      }
      close = !clickedOnOptions;
    }
    if (close) {
      if (this.props.onClose) {
        this.props.onClose();
      }
      window.removeEventListener("click", this.close);
    }
  };
  allSelected() {
    return this.props.selectedItems == null;
  }

  render() {
    const props = this.props;
    this.elem.style.position = "absolute";
    if (window.innerWidth > 600) {
      this.elem.style.right = `${this.state.right}px`;
    }
    this.elem.style.top = `${this.state.top}px`;
    this.elem.style.width = `${this.state.width}px`;
    this.elem.style.zIndex = `${this.props.zIndex || this.state.zIndex}`;
    return ReactDOM.createPortal(
      <div
        ref={optionsElem => (this.optionsElem = optionsElem)}
        className={`multi_select__options ${
          this.state.open
            ? "multi_select__options--opened"
            : "multi_select__options--closed"
        } ${props.oneOption ? "multi_select__options--select" : ""} 
         ${
           props.additionalClass
             ? "multi_select__options--" + props.additionalClass
             : ""
         }
         `}
      >
        <ul>
          {props.allButtonText && (
            <li
              className="multi_select__options__option multi_select__options__option--all"
              key={"all-button"}
              onClick={() => {
                props.onChange(null);
              }}
            >
              {props.allButtonText}
            </li>
          )}
          {props.items.map(i => {
            const isSelected =
              this.allSelected() || !!this.props.selectedItems[props.getKey(i)];
            return (
              <li
                className="multi_select__options__option"
                key={props.getKey(i)}
                onClick={() => {
                  if (props.oneOption) {
                    props.onChange([i]);
                    this.close();
                  }
                }}
              >
                {props.colors && (
                  <span
                    className="multi_select__options__option__color"
                    style={{
                      background: props.colors[props.getKey(i)] || "white"
                    }}
                  />
                )}
                {!props.oneOption && (
                  <input
                    className="multi_select__options__option__chk"
                    type="checkbox"
                    checked={isSelected}
                    onChange={e =>
                      this.props.selectBoxChange(e.target.checked, i)
                    }
                  />
                )}
                <span
                  className="multi_select__options__option__label"
                  onClick={() => {
                    if (!props.oneOption) {
                      this.props.selectBoxChange(!isSelected, i);
                    }
                  }}
                >
                  {props.getText(i)}{" "}
                </span>
              </li>
            );
          })}
        </ul>
      </div>,
      this.elem
    );
  }
}

export default class MultiSelect<T = any> extends React.Component<
  MultiSelectProps<T>,
  MultiSelectState<T>
> {
  selectBox: HTMLDivElement;
  constructor(props: MultiSelectProps<T>) {
    super(props);
    this.state = { open: false, textInputVal: "" };
  }

  open = () => {
    this.setState({ open: true });
  };
  close = () => {
    this.setState({ open: false });
  };
  toggleOpen = () => {
    if (this.state.open) {
      this.close();
    } else if (!this.props.disabled) {
      this.open();
    }
  };
  getSelectedNames(textDefault = false) {
    const text = Object.keys(this.props.selectedItems || {})
      .filter(k => this.props.selectedItems[k] && true)
      .map(k => {
        const text = this.props.getText(
          this.props.items.find(e => this.props.getKey(e) === k)
        );
        if (this.props.maxCharactersPerOption) {
          return text.length > this.props.maxCharactersPerOption
            ? `${text.substr(0, this.props.maxCharactersPerOption)}...`
            : text;
        } else {
          return text;
        }
      })
      .join(", ");
    if (
      textDefault &&
      (isEmpty(text) ||
        !this.props.selectedItems ||
        !Object.keys(this.props.selectedItems))
    ) {
      return this.props.text;
    }
    return text;
  }

  filterRecomendations = (textInputVal: string) => {
    this.setState({ textInputVal });
  };

  render() {
    const props = this.props;
    return (
      <div
        ref={selectBox => (this.selectBox = selectBox)}
        className={`multi_select ${props.additionalClass || ""} ${
          props.disabled ? "multi_select--disabled" : ""
        }`}
      >
        <div
          className={`multi_select__select multi_select__select--${
            props.inputMode ? "input" : "label"
          }`}
        >
          {!props.inputMode && (
            <div
              className={
                this.props.additionalClass.indexOf("multiselect--header") > -1
                  ? "multi_select__select__label  multiselect--header--label"
                  : "multi_select__select__label "
              }
              onClick={() => this.toggleOpen()}
            >
              {props.showSelectedAsText
                ? this.getSelectedNames() != ""
                  ? this.getSelectedNames()
                  : "Category"
                : props.text}
            </div>
          )}
          {props.inputMode && (
            <div
              className="multi_select__select__input"
              onClick={() => this.toggleOpen()}
            >
              <InputText
                onChange={this.filterRecomendations}
                value={this.state.textInputVal}
                onBlur={() => this.setState({ textInputVal: "" })}
                error={props.error}
                placeholder={
                  props.showSelectedAsText
                    ? this.getSelectedNames(true)
                    : props.text
                }
                showErrorPerDefault={props.showErrorPerDefault}
                errorText={props.errorText}
                disabled={props.disabled}
              />
            </div>
          )}

          {(!props.oneOption || (props.items && props.items.length > 1)) && (
            <div
              className={`multi_select__select__collapse_icon ${
                this.state.open
                  ? "multi_select__select__collapse_icon--opened"
                  : "multi_select__select__collapse_icon--closed"
              } `}
              onClick={() => this.toggleOpen()}
            />
          )}
        </div>
        {this.state.open && (
          <MultiSelectOptions
            {...props}
            items={
              props.filter &&
              (props.alwaysFilter || !isEmpty(this.state.textInputVal))
                ? props.items.filter(elem =>
                    props.filter(elem, this.state.textInputVal)
                  )
                : props.items
            }
            selectBoxChange={this.selectBoxChange}
            onClose={() => this.close()}
            mainElemRef={this.selectBox}
            zIndex={this.props.zIndexOpt}
          />
        )}
      </div>
    );
  }
  selectBoxChange = (checked: boolean, item: T) => {
    const allItems: { [name: string]: boolean } = {};
    this.props.items.forEach(i => {
      allItems[this.props.getKey(i)] = !this.props.selectedItems;
    });
    if (this.props.selectedItems != null) {
      Object.keys(this.props.selectedItems).forEach(k => {
        allItems[k] = true;
      });
    }
    allItems[this.props.getKey(item)] = checked;

    const allSelected = Object.values(allItems).every(val => val);
    if (this.props.onChange) {
      this.props.onChange(
        !allSelected || this.props.notNullOnAll
          ? this.props.items.filter(i => allItems[this.props.getKey(i)])
          : null
      );
    }
  };
}
