import { handleActions, Action } from "redux-actions";
import { CurrentUserState, OrganizationState } from "../state/state";
import { Actions } from "../state/actions";
import Organization from "../models/organization";
import OrganizationRequest from "../models/organization.request";

export default handleActions<OrganizationState, any>(
  {
    [Actions.ORGANIZATION.LOADED]: (
      state,
      action: Action<{ ids: string[] }>
    ) => {
      return {
        ...state,
        organizations: action.payload.ids.map(id => {
          const org = new Organization();
          org.id = id;
          return org;
        })
      };
    },
    [Actions.ORGANIZATION.INFORMATION_SET]: (
      state,
      action: Action<{ organization: Organization }>
    ) => {
      const { organization } = action.payload;
      if (!organization) {
        return { ...state, organizationLoaded: true };
      }
      let found = false;
      const newOrganizations = state.organizations.map(org => {
        if (org.id === organization.id) {
          found = true;
          return organization;
        } else {
          return org;
        }
      });
      if (!found) {
        newOrganizations.push(organization);
      }
      return {
        ...state,
        organizations: newOrganizations,
        organizationLoaded: true,
        selectedOrganization: state.selectedOrganization
          ? newOrganizations.find(o => o.id === state.selectedOrganization.id)
          : null
      };
    },
    [Actions.ORGANIZATION.SELECT]: (
      state,
      action: Action<{ organizationId: string }>
    ) => {
      return {
        ...state,
        selectedOrganization: state.organizations.find(
          o => o.id === action.payload.organizationId
        )
      };
    },
    [Actions.AUTH.LOGOUT_SUCCESS]: (
      state,
      action: Action<{ organizationId: string }>
    ) => {
      return {
        organizations: [],
        selectedOrganization: null,
        requests: [],
        organizationLoaded: false
      } as OrganizationState;
    },
    [Actions.ORGANIZATION.UPDATE_REQUESTS]: (
      state,
      action: Action<{ requests: OrganizationRequest[] }>
    ) => {
      return {
        ...state,
        requests: (action.payload.requests || []).filter(
          r => r.organization && r.user
        )
      } as OrganizationState;
    },
    [Actions.ORGANIZATION.DELETE]: (
      state,
      action: Action<{ organization: Organization }>
    ) => {
      return {
        ...state,
        organizations: state.organizations.filter(
          o => o.id !== action.payload.organization.id
        ),
        selectedOrganization:
          state.selectedOrganization &&
          state.selectedOrganization.icon === action.payload.organization.id
            ? null
            : state.selectedOrganization
      } as OrganizationState;
    }
  },
  {
    organizations: [],
    selectedOrganization: null,
    requests: [],
    organizationLoaded: false
  } as OrganizationState
);
