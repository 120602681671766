import { compose } from "recompose";
import { withLocalization, LocaleProps } from "../i18n/localizable";
import * as React from "react";
import numeral from "numeral";
import { Plan } from "../../models/plan";
import "./price.less";

export default compose<{ plan: Plan } & LocaleProps, { plan: Plan }>(
  withLocalization()
)(props => {
  const { _, plan } = props;
  if (plan.pricePerMonth === 0) {
    return null;
  }
  return (
    <div className="plan_price">
      <div className="plan_price__monthly">
        <div className="plan_price__monthly__currency">$</div>
        <div className="plan_price__monthly__rate">
          {numeral(plan.pricePerMonth).format("0.00")}
        </div>
        <div className="plan_price__monthly__message">
          {_("account.plan.per.mont")}
        </div>
      </div>
      <div className="plan_price__yearly">
        {_("account.plan.billed.monthly", {
          amount: numeral(plan.pricePerYear).format("0.00")
        })}
      </div>
    </div>
  );
});
