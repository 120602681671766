import CalendarCategory from "./calendar.category";
import ContactInfo from "./contact.info";
import { BillingInformation } from "./billing.information";

export default class Organization {
  id: string;
  name: string;

  address: string;
  address2: string;
  apartmentSuite: string;
  categories: { [name: string]: string };
  city: string;
  contacts: { [name: string]: string };
  subscriptionStatus?: {
    subscriptionStatus: string;
    billing_cycle_anchor: number;
    periodEnd: number;
    cancel_at_period_end: boolean;
  };
  description: string;
  icon: string;
  links: { [name: string]: string };
  notes: string;
  personalContacts: ContactInfo[];
  photo: string;
  state: string;
  type: string;
  zip: string;
  country: string;
  lat: number;
  lng: number;
  planId: string;
  billingInfo: BillingInformation;
  createdBy: string;
  needsToFinishRegistration?: boolean;
  periodEnd?: number;
  freeUser?: boolean;
  clickedSave: boolean;
  constructor() {
    this.contacts = {};
    this.categories = {};
    this.links = {};
    this.personalContacts = [];
  }

  clone(upd?: (org: Organization) => any) {
    const newOrga = new Organization();
    Object.assign(newOrga, this);
    if (upd) {
      upd(newOrga);
    }
    return newOrga;
  }
}
