import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/storage";
import "firebase/compat/functions";
//import * as firebase from 'firebase/app';
//import 'firebase/auth';
//import 'firebase/database';

declare const STAGING: boolean;
let config = null;
if (STAGING) {
  config = require("./config/timepeace-staging.json");
} else {
  config = require("./config/timepeace-prod.json");
}

export function getEnvName() {
  if (STAGING) {
    return "STAGE";
  } else {
    return "PROD";
  }
}
firebase.initializeApp(config);

export default firebase;
