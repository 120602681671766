import { createAction } from 'redux-actions';
import { Actions } from '../state/actions';
import Category from '../models/calendar.category';
import { CategoryNode, parseCategoryNode } from '../api/model/category.node';
import Organization from '../models/organization';
import CalendarCategory from '../models/calendar.category';
export const syncCategoriesOfCalendar = createAction(
  Actions.CATEGORIES.SYNCH_CALENDAR,
  (calendarId: string, categories: Category[]) => ({
    calendarId,
    categories
  })
);

export const syncCategoriesOfOrganization = createAction(
  Actions.CATEGORIES.SYNCH,
  (organization: Organization, ids: string[]) => ({
    organization,
    ids
  })
);
export const categoryInfoLoaded = createAction(Actions.CATEGORIES.INFO_LOADED, (category: CalendarCategory) => ({
  category
}));

export const newCategory = createAction(Actions.CATEGORIES.NEW_REQUEST, (name: string, description: string) => ({
  name,
  description
}));

export const handleCategoryReorder = createAction(Actions.CATEGORIES.REORDER, (newOrder: string[]) => ({ newOrder }));
