import { CalendarApiImpl } from "./implementation/calendar.api.impl";
import { CategoryApiImpl } from "./implementation/category.api.impl";
import { EventsApiImpl } from "./implementation/event.api.impl";
import { UsersApi } from "./implementation/users.api.impl";
import OrganizationApiImpl from "./implementation/organizations.api.impl";
const calendarApi = new CalendarApiImpl();
const categoryApi = new CategoryApiImpl();
const eventApi = new EventsApiImpl(calendarApi, categoryApi);
calendarApi.setEventService(eventApi);
const usersApi = new UsersApi();
const organizationsApi = new OrganizationApiImpl();

export const Services = {
  Calendar: calendarApi,
  Category: categoryApi,
  Events: eventApi,
  Users: usersApi,
  Organizations: organizationsApi
};
