import * as React from 'react';
import TextField from './text';
import './styles/input-label.less';

interface TextBoxProps {
  label: string;
  value: string;
  onChange?: (val: string) => any;
  enabled?: boolean;
  additionalClass?: string;
  area?: boolean;
  rows?: number;
  placeholder?: string;
  additionalClassName?: string;
  error?: boolean;
  errorText?: string;
  showErrorPerDefault?: boolean;
  type?: string;
  renderInput?: (value: string) => JSX.Element;
  maxLength?: number;
}
interface TextBoxState {
  focus: boolean;
}
export default class TextBoxWithLabel extends React.Component<TextBoxProps, TextBoxState> {
  constructor(props: TextBoxProps) {
    super(props);
    this.state = { focus: false };
  }
  render() {
    const props = this.props;
    const { focus } = this.state;
    let enabled = typeof this.props.enabled === 'undefined' ? true : this.props.enabled;
    return (
      <div className={`input_with_label ${props.additionalClass || ''}`}>
        <label className={`input_with_label__label ${focus ? 'input_with_label__label--focus' : ''}`}>
          {props.label}
        </label>
        {props.renderInput ? (
          props.renderInput(props.value)
        ) : (
          <TextField
            area={props.area}
            rows={props.rows}
            placeholder={props.placeholder}
            additionalClassName={`input_with_label__input ${props.additionalClassName || ''}`}
            value={props.value}
            onChange={props.onChange}
            onFocus={() => this.setState({ focus: true })}
            onBlur={() => this.setState({ focus: false })}
            disabled={!enabled}
            error={props.error}
            errorText={props.errorText}
            showErrorPerDefault={props.showErrorPerDefault}
            type={props.type}
            maxLength={props.maxLength}
          />
        )}
        {props.children}
      </div>
    );
  }
}
