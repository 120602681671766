import Calendar from '../../models/calendar';

export interface CalendarNode {
  id: string;
  name: string;
  details: string;
  order?: number;
}

export function parseCalendarNode(node: CalendarNode): Calendar {
  const cal = new Calendar();
  cal.id = node.id;
  cal.name = node.name;
  cal.description = node.details;
  cal.order = node.order;
  return cal;
}
