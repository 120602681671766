import * as React from "react";
import firebase from "../../../api/firebase";
import queryString from "querystring";
import InputText from "../../input/text";
import { isEmpty } from "../../../utils/string.utils";
import Button from "../../input/button";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { Services } from "../../../api/services";

export interface EmailValidationPageProps {
  location?: any;
  goToLogin?: () => any;
  loginNow?: () => any;
}

class AuthenticationPage extends React.Component<EmailValidationPageProps> {
  state = {
    actionTitle: "",
    actionText: "",
    validationReady: false,
    newPassword: " ",
    isPasswordReset: false,
    isEmailValidation: false,
    disableInput: false,
    currentEmail: "",
    withLogin: false
  };

  componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    let authCode = values.oobCode as string;
    let mode = values["?mode"] as string;

    if (mode == "verifyEmail") {
      this.validateEmailAddress(authCode);
    } else if (mode == "resetPassword") {
      const values = queryString.parse(this.props.location.search);
      let authCode = values.oobCode as string;
      const __this = this;
      firebase
        .auth()
        .verifyPasswordResetCode(authCode)
        .then(function(email) {
          __this.setState({
            isPasswordReset: true,
            actionTitle: "Password Change",
            actionText: "Please enter a new password for ",
            currentEmail: email,
            disableInput: true
          });
        })
        .catch(function(error) {
          __this.setState({
            isPasswordReset: true,
            actionTitle: "Password Change",
            actionText: "Please enter a new password for ",
            disableInput: false
          });
        });
    } else this.props.goToLogin();
  }

  validateEmailAddress(authCode: string) {
    const __this = this;
    firebase
      .auth()
      .verifyPasswordResetCode(authCode)
      .then(email =>
        firebase
          .auth()
          .applyActionCode(authCode)
          .then(function(resp) {
            __this.setState({
              isEmailValidation: true,
              actionTitle: "Thank you for signing up!",
              withLogin: true,
              actionText:
                "Your account is now active. Log in to finish setting up your profile and start sharing events.",
              validationReady: true
            });

            Services.Organizations.sendWelcomeEmail(email);
          })
      )
      .catch(function(error) {
        __this.setState({
          isEmailValidation: true,
          actionTitle: "There was a problem validating your email address!",
          actionText:
            "This email has been already validated. If you think there is a problem, please contact support.",
          validationReady: true,
          withLogin: true
        });
      });
  }

  resetPassword = () => {
    const values = queryString.parse(this.props.location.search);
    let authCode = values.oobCode as string;
    const ___this = this;
    if (this.state.newPassword.length >= 6) {
      firebase
        .auth()
        .confirmPasswordReset(authCode, this.state.newPassword)
        .then(function(resp) {
          ___this.props.goToLogin();
        })
        .catch(function(error) {
          ___this.setState({
            ...this.state,
            actionText: "Please enter a new password for ",
            disableInput: false
          });
        });
    }
  };

  render() {
    return (
      <div>
        <h1 className="login_page__left__get_started">
          {this.state.actionTitle}
        </h1>
        <br />
        <div className="login_page__success__description">
          {this.state.isPasswordReset ? (
            <div>
              {this.state.actionText + " " + this.state.currentEmail}
              <InputText
                type={
                  this.state.newPassword.trim().length === 0
                    ? "text"
                    : "password"
                }
                onFocus={() =>
                  this.setState({
                    ...this.state,
                    newPassword:
                      this.state.newPassword.trim().length > 0
                        ? this.state.newPassword
                        : ""
                  })
                }
                placeholder={"New Password"}
                value={this.state.newPassword}
                error={isEmpty(this.state.newPassword)}
                showErrorPerDefault={true}
                errorText={"Please enter the new password"}
                showErrorAfter={true}
                onChange={newPass => {
                  console.log(newPass.trim().length);
                  this.setState({
                    ...this.state,
                    newPassword: newPass,
                    disableInput: newPass.trim().length < 6 ? true : false
                  });
                }}
                materialPlaceholder={true}
              />
              <div>
                <Button
                  additionClassName="login_page_authentication_password_reset"
                  disabled={this.state.disableInput}
                  text={"Save"}
                  onClick={this.resetPassword}
                />
              </div>
            </div>
          ) : this.state.validationReady ? (
            this.state.actionText
          ) : (
            ""
          )}
          <div style={{ marginTop: 15, textAlign: "center" }}>
            {this.state.withLogin && (
              <Button onClick={this.props.loginNow} text="Log In Now" />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  dispatch => ({
    goToLogin() {
      dispatch(push("/login?passwordReset"));
    },
    loginNow() {
      dispatch(push("/login"));
    }
  })
)(AuthenticationPage);
