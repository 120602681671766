import { Elements } from "react-stripe-elements";
import * as React from "react";
import CheckoutForm from "./checkout.form";
import PaymentsLayout from "./payments.layout";

export default () => {
  return (
    <PaymentsLayout>
      <Elements
        fonts={[
          {
            cssSrc:
              "https://fonts.googleapis.com/css?family=Roboto:300,300i,400,500,600"
          }
        ]}
      >
        <CheckoutForm />
      </Elements>
    </PaymentsLayout>
  );
};
