export function diffList<T, U = T>(a: T[], b: U[], comparator: (a: T, b: U) => boolean) {
  if (!a) {
    return { added: b, deleted: [] };
  } else if (!b) {
    return { added: [], deleted: a };
  }
  const added = b.filter(bElem => !a.find(aElem => comparator(aElem, bElem)));
  const deleted = a.filter(aElem => !b.find(bElem => comparator(aElem, bElem)));

  return { added, deleted };
}

export function isEmptyList(a: any[]) {
  return !a || !a.length;
}

export function listChanged<T, U = T>(a: T[], b: U[], comparator: (a: T, b: U) => boolean): boolean {
  if (a && b) {
    return a.length !== b.length || a.some(aElem => !b.some(bElem => comparator(aElem, bElem)));
  }
  return (isEmptyList(a) && !isEmptyList(b)) || (!isEmptyList(a) && isEmptyList(b));
}
type Key = string | number;
export function indexByText<T>(as: T[], index: (a: T) => string | string[]): { [name: string]: T } {
  const result: { [name: string]: T } = {};
  as.forEach(a => {
    const idx = index(a);
    if (typeof (idx as string).substr !== 'undefined') {
      result[idx as string] = a;
    } else {
      (idx as string[]).forEach(index => {
        result[index] = a;
      });
    }
  });
  return result;
}

export function groupByText<T>(as: T[], index: (a: T) => string | string[]): { [name: string]: T[] } {
  const result: { [name: string]: T[] } = {};
  as.forEach(a => {
    const idx = index(a);
    if (typeof (idx as string).substr !== 'undefined') {
      const index = idx as string;
      if (!result[index]) {
        result[index] = [];
      }
      result[index].push(a);
    } else {
      (idx as string[]).forEach(index => {
        if (!result[index]) {
          result[index] = [];
        }
        result[index].push(a);
      });
    }
  });
  return result;
}
export function indexByTextWithMap<T, U>(
  as: T[],
  index: (a: T) => string,
  map: (a: T, idx?: number) => U
): { [name: string]: U } {
  const result: { [name: string]: U } = {};
  as.forEach((a, idx) => {
    result[index(a)] = map(a, idx);
  });
  return result;
}

export function indexTextList(list: string[]): { [name: string]: string } {
  const indexed: { [name: string]: string } = {};
  if (list) {
    list.forEach(item => (indexed[item] = item));
  }
  return indexed;
}
