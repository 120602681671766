import { Action } from 'redux-actions';

import rsf from '../sagas/firebase';

import firebase from '../api/firebase';
import { put, call, take, fork, takeEvery, all, select, takeLatest } from 'redux-saga/effects';
import { diffList } from './list.utils';
import { Actions } from '../state/actions';
import { eventChannel } from 'redux-saga';

function* onElementAdded<T>(id: string, channelById: { [id: string]: any }, config: RelatedElementConfig<T>) {
  const channel = eventChannel(emitter => {
    if (typeof config.ref(id) === 'string') {
      firebase
        .database()
        .ref(config.ref(id) as string)
        .on('value', s => {
          setTimeout(() => {
            emitter({ value: s.val() });
          });
        });

      return () => {
        firebase
          .database()
          .ref(config.ref(id) as string)
          .off('value');
      };
    }
  });

  //const channel = yield call(rsf.database.channel, config.ref(id));
  channelById[id] = channel;

  yield takeEvery(channel, function*(action: { value: T }) {
    yield config.onChannelUpdated(id, action.value);
  });
}

function* onElementRemoved<T>(id: string, channelById: { [id: string]: any }, config: RelatedElementConfig<T>) {
  const channel = channelById[id];
  if (channel) {
    channel.close();
  }
  delete channelById[id];
  yield put(config.removeAction(id));
}
function onSynchElements<T>(channelById: { [id: string]: any }, config: RelatedElementConfig<T>) {
  return function*(action: Action<{ ids: string[] }>) {
    const currentIds = action.payload.ids;
    const { added, deleted } = diffList(
      Object.keys(channelById),
      currentIds,
      (prevId: string, currentId: string) => prevId === currentId
    );
    added.forEach(id => (channelById[id] = {}));

    yield all(added.map(id => onElementAdded(id, channelById, config)));
    yield all(deleted.map(id => onElementRemoved(id, channelById, config)));
   
  };
}
export interface RelatedElementConfig<T> {
  synchActionName: string;
  ref: (id: string) => firebase.database.Reference | string;
  onChannelUpdated: (id: string, value: T) => any;
  removeAction: (id: string) => Action<any>;
}
let i = 0;
export function relatedElement<T>(config: RelatedElementConfig<T>) {
  const channelById: { [id: string]: any } = {};

  return all([takeEvery(config.synchActionName, onSynchElements(channelById, config))]);
}

export function channelListener<T>(event: (onChange: (v: T) => any) => () => any) {
  return eventChannel(emitter => {
    const close = event((data: T) => {
      setTimeout(() => emitter(data));
    });
    return () => {
      close();
    };
  });
}
