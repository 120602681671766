export function getPageXY(el: HTMLElement) {
  const rect = el.getBoundingClientRect();
  const yStart = rect.top + document.body.scrollTop;
  const xStart = rect.left + document.body.scrollLeft;
  return {
    xStart,
    yStart,
    xEnd: xStart + rect.width,
    yEnd: yStart + rect.height
  };
}
