import { createAction } from 'redux-actions';
import { Actions } from '../state/actions';
import { CalendarNode } from '../api/model/calendar.node';

export const calendarAdded = createAction(Actions.CALENDAR.CALENDAR_ADDED, (calendarId: string) => ({ calendarId }));

export const calendarRemoved = createAction(Actions.CALENDAR.CALENDAR_ADDED, (calendarId: string) => ({ calendarId }));

export const calendarInfoUpdated = createAction(
  Actions.CALENDAR.CALENDAR_INFO_UPDATED,
  (calendarInfo: CalendarNode) => ({ calendarInfo })
);
export const handleCalendarReorder = createAction(Actions.CALENDAR.REORDER, (newOrder: string[]) => ({ newOrder }));
