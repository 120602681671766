import * as React from "react";
import { Component } from "react";
import Organization from "../../models/organization";
import { User } from "../../models/User";
import Button from "../input/button";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { isOrganizationPaidUser } from "../util/organization.utils";
import { toggleMenu } from "../../actions/ui.actions";

export interface OrganizationInfoHolderProps {
  organization: Organization;
  user: User;
  onRedirect?: () => any;
  goToAccount?: () => any;
  toggleMenu?: (showMenu: boolean) => any;
  goToPlans?: () => any;
}

class OrganizationInfoHolder extends React.Component<
  OrganizationInfoHolderProps
> {
  state = {};

  render() {
    return (
      <div className="vertical_nav_bar__app_info_org_cont">
        <div>
          <img
            className="vertical_nav_bar__app_info_org_icon"
            src={this.props.organization ? this.props.organization.icon : ""}
          />
        </div>
        <div className="vertical_nav_bar__app_info_org_organization">
          {this.props.organization ? this.props.organization.name : ""}
        </div>
        <div className="vertical_nav_bar__app_info_org_administrator">
          {this.props.user ? this.props.user.firstName : ""}
        </div>

        {this.props.organization &&
          this.props.user &&
          (this.props.user.admin ||
            this.props.user.id === this.props.organization.createdBy) &&
          !isOrganizationPaidUser(this.props.organization) && (
            <Button
              onClick={(e) => { 
                // this.props.toggleMenu(false);
                // this.props.goToAccount();
                this.props.goToPlans();
             }}
              link={true}
              blueStyle={true}
              boldLinkText={true}
              text="UPGRADE PLAN"
            />
          )}

        <hr className="vertical_nav_bar__app_info_org_sep" />
      </div>
    );
  }
}

export default connect(
  null,
  dispatch => ({
    goToAccount() {
      dispatch(push("/account/profile"));
    },
    goToPlans() {
      dispatch(push("/plans"));
    },
    toggleMenu(showMenu: boolean) {
      dispatch(toggleMenu(showMenu));
    } 
  })
)(OrganizationInfoHolder);
