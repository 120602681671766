import { push } from "connected-react-router";
// import  'moment';
import * as moment from "moment-timezone";
import * as React from "react";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import { connect } from "react-redux";
import { match } from "react-router";
import Spinner from "react-spinner-material";
import { EventCreationInfo, EventDeleteOptions, EventLocationInfo } from "../../../api/model/event.node";
import { AfterType, EventRecursionInfo, RepeatDuration } from "../../../api/model/recursion.info";
import { Services } from "../../../api/services";
import Calendar from "../../../models/calendar";
import { default as CalendarCategory, default as Category } from "../../../models/calendar.category";
import Event from "../../../models/event";
import RecurringEvaluator from "../../../models/recurring.evaluator";
import { AppState, TimeZone } from "../../../state/state";
import { getOrganizationId, getSelectedOrganization, getSortedCalendars } from "../../../state/state_getters";
import { isSameDay, isTodaysDate } from "../../../utils/date.utils";
import { indexByTextWithMap, listChanged } from "../../../utils/list.utils";
import { getBodyOfNotification, getTitleOfNotification } from "../../../utils/notification.text.getter";
import { isEmpty, isMobile } from "../../../utils/string.utils";
import { LocaleProps, localizable } from "../../i18n/localizable";
import AddressInput from "../../input/address.input";
import AutoSelectInput from "../../input/auto.select.input";
import Button from "../../input/button";
import Checkbox from "../../input/checkbox";
import DatePicker from "../../input/date.picker";
import TextBoxWithLabel from "../../input/input.label";
import MultiSelect from "../../input/multi.select";
import TagSelector from "../../input/tag.selector";
import TextInput from "../../input/text";
import TimePicker from "../../input/time.picker";
import OverlayConfirm from "../../overlay/overlay.confirm";
import { isOrganizationPaidUser, isOrganizationPaidUserForPns } from "../../util/organization.utils";
import "./new-event.less";
import RecurringOptions from "./recurring.options";
import Organization from "../../../models/organization";

interface NewEventDialogProps {
  close: () => any;
  allCategories: CalendarCategory[];
  allCalendars: Calendar[];
  goToEventCreatedSuccess: (key: string) => any;
  match?: match<{ key: string; date: string }>;
  organizationId: string;
  timeZones: TimeZone[];
  organizationName: string;
  goIndex: () => any;
  allowPns: boolean;
  selectedOrganization?: Organization;
}
interface NewEventDialogState {
  startDate?: moment.Moment;
  endDate?: moment.Moment;
  startTime?: moment.Duration;
  endTime?: moment.Duration;
  isAllDay: boolean;
  name: string;
  description: string;
  locationInfo: EventLocationInfo;
  isRecurringEvent: boolean;
  address2: string;
  pushNotification: boolean;
  recursionInfo: EventRecursionInfo;
  loading: boolean;
  confirmNewCalendar: boolean;
  addressBoxText: string;
  confirmDiscard: boolean;
  confirmDelete: boolean;
  selectedDeleteOption: EventDeleteOptions;
  originalEvent?: Event;
  timeZone?: TimeZone;
  timeZoneTxt?: string;
  previousTimeZone?: TimeZone;
  previousTimeZoneTxt?: string;
  validationError?: string[];
  clickedOnSave?: boolean;
  changeTz: boolean;
  addressMoreInfoOpen: boolean;
  pnsTitle?: string;
  pnsBody?: string;
  usePnsDefaultText: boolean;
  defaultPnsTitle: string;
  defaultPnsBody: string;
  deletePnsTitle: string;
  deletePnsBody: string;
  confirmRecurringEditable: boolean;
  selectedCalendars: { [name: string]: string };
  selectedCategories: { [name: string]: boolean };
  calendarText: string;
  categoryText: string;
  deletePnsEnabled: boolean;
  isOnline: boolean;
  originalDates: {
    start: moment.Moment;
    end: moment.Moment;
    timeZone: TimeZone;
  };
  validEndTime: boolean;
  validationErrorEndTime: boolean;
}

const DeleteOptions = [
  {
    message: "dialog.events.confirm.delete.recurring.thisevent",
    option: EventDeleteOptions.ThisOcurrence
  },
  {
    message: "dialog.events.confirm.delete.recurring.future",
    option: EventDeleteOptions.Future
  },
  {
    message: "dialog.events.confirm.delete.recurring.all",
    option: EventDeleteOptions.All
  }
];

class NewEventDialog extends React.Component<
  NewEventDialogProps & LocaleProps,
  NewEventDialogState
> {
  constructor(props: NewEventDialogProps & LocaleProps) {
    super(props);
    const startTime = moment
      .duration(moment().hours(), "hours")
      .add(moment().minutes(), "minutes");
    if (startTime.minutes() !== 30) {
      startTime.add(30 - (startTime.minutes() % 30), "minutes");
    }
    let timeZone = null;
    const guessTz = moment.tz.guess();
    if (guessTz) {
      timeZone = this.props.timeZones.find(t => t && t.name === guessTz);
    }
    const endTime = startTime.clone().add(1, "hours");
    const startDate = moment(),
      endDate = moment();
    this.state = {
      startDate,
      endDate,
      startTime,
      endTime,
      isAllDay: false,
      name: "",
      description: "",
      isRecurringEvent: false,
      address2: "",
      locationInfo: null,
      pushNotification: false,
      recursionInfo: NewEventDialog.getDefaultRecursionInfo(),
      loading: !!(this.props.match && this.props.match.params.key),
      confirmNewCalendar: false,
      addressBoxText: this.getAddressText(this.props.selectedOrganization),
      confirmDiscard: false,
      confirmDelete: false,
      selectedDeleteOption: EventDeleteOptions.ThisOcurrence,
      changeTz: false,
      addressMoreInfoOpen: false,
      timeZone,
      timeZoneTxt: this.getTimezoneItemText(timeZone),
      usePnsDefaultText: true,
      ...this.getPNSNotification(true),
      confirmRecurringEditable: false,
      selectedCalendars: {},
      selectedCategories: null,
      calendarText: "",
      categoryText: "",
      deletePnsEnabled: false,
      isOnline: false,
      originalDates: {
        start: startDate
          .clone()
          .startOf("day")
          .add(startTime),
        end: endDate
          .clone()
          .startOf("day")
          .add(endTime),
        timeZone
      },
      validEndTime: true,
      validationErrorEndTime: false
    };
    //console.log(this.getPNSNotification());
  }
  static getDefaultRecursionInfo(): EventRecursionInfo {
    return {
      everyDuration: RepeatDuration.DAY,
      repeatsEvery: 1,
      repeatsOn: [moment().valueOf()],
      endsAfter: 1,
      afterType: AfterType.AFTER,
      removeDays: []
    };
  }
  getEvent = () => {
    Services.Events.getEvent(this.props.match.params.key)
      .then(event => {
        // console.log(event);
        const startDate = moment(event.startDate);
        const endDate = moment(event.endDate);
        const selectedCalendars = indexByTextWithMap(
          event.calendarIds,
          c => c,
          (e, idx) => TagSelector.getColor(idx)
        );
        const selectedCategories = event.calendarIds.reduce(
          (result: { [id: string]: boolean }, calendarId) => {
            const calendar = this.props.allCalendars.find(
              c => c.id === calendarId
            );
            calendar.categories.forEach(c => {
              result[c.id] = true;
            });
            return result;
          },
          {}
        );
        const timeZone = this.props.timeZones.find(
          t => t && t.name === event.timezone
        );
        const category = this.props.allCategories.find(cat =>
          event.calendarIds
            .map(cId => this.props.allCalendars.find(c => c.id === cId))
            .some(
              cal => cal && cal.categories.some(calCat => calCat.id === cat.id)
            )
        );
        this.setState({
          originalEvent: event,
          startDate: startDate,
          startTime: moment
            .duration(startDate.hours(), "hours")
            .add(startDate.minutes(), "minutes"),
          endDate,
          endTime: moment
            .duration(endDate.hours(), "hours")
            .add(endDate.minutes(), "minutes"),
          selectedCalendars,
          selectedCategories,
          description: event.details,
          isAllDay: event.isAllDay,
          isRecurringEvent: event.isRecurring,
          address2: event.address2,
          name: event.name,
          pushNotification: false,
          isOnline: event.isOnline,
          locationInfo: {
            address: event.address,
            address2: event.address2,
            location: event.location,
            apartmentSuite: event.apartmentSuite,
            city: event.city,
            country: event.country,
            state: event.state,
            zip: event.zip,
            lat: event.lat,
            lng: event.lng,
            addressBoxText: event.addressBoxText
          },
          recursionInfo:
            event.recursionInfo || NewEventDialog.getDefaultRecursionInfo(),
          addressBoxText: event.addressBoxText,
          timeZone: timeZone,
          timeZoneTxt: timeZone ? this.getTimezoneItemText(timeZone) : "",
          loading: false,
          ...this.getPNSNotification(),
          categoryText: category ? category.name : ""
        });
      })
      .catch(err => {
        console.log(err);
        this.props.goIndex();
      });
  };
  retrieveEventOnceCalendarsAreIn = (retry = 5) => {
    if (retry < 0) {
      this.props.goIndex();
    }

    if (!this.props.allCategories.length || !this.props.allCalendars.length) {
      setTimeout(() => {
        this.retrieveEventOnceCalendarsAreIn(retry - 1);
      }, 500);
    } else {
      this.getEvent();
    }
  };
  componentDidMount() {
    if (this.props.match && this.props.match.params.key) {
      this.setState({ loading: true });
      this.retrieveEventOnceCalendarsAreIn();
    }
  }
  getSelectedCategories(categoryText?: string): CalendarCategory[] {
    if (!this.state.selectedCategories) {
      return null;
    }
    return this.props.allCategories.filter(
      c => this.state.selectedCategories[c.id]
    );
  }

  getNewEventInfo(): EventCreationInfo {
    return {
      startDate: this.getStartDate().clone(),
      endDate: this.getEndDate().clone(),
      isAllDay: this.state.isAllDay,
      name: this.state.name,
      locationDescription: this.state.address2,
      description: this.state.description,
      calendarIds: Object.keys(this.state.selectedCalendars),
      locationInfo: this.state.locationInfo,
      createPushNotification: this.state.pushNotification,
      recurring: this.state.isRecurringEvent ? this.state.recursionInfo : null,
      organizationId: this.props.organizationId,
      timeZone: this.state.timeZone ? this.state.timeZone.name : null,
      isOnline: this.state.isOnline ? this.state.isOnline : null,
    };
  }
  matchesSelectedCategory(calendar: Calendar) {
    const selected = this.getSelectedCategories();
    if (selected) {
      return calendar.categories.some(c =>
        selected.some(cat => cat.id === c.id)
      );
    }
    return true;
  }
  getStartDate(overrideState?: NewEventDialogState) {
    const state = overrideState || this.state;
    if (state.isAllDay) {
      return state.startDate.clone().startOf("day");
    } else {
      return state.startDate
        .clone()
        .startOf("day")
        .hour(state.startTime.hours())
        .minute(state.startTime.minutes());
    }
  }
  getEndDate(overrideState?: NewEventDialogState) {
    const state = overrideState || this.state;
    if (state.isAllDay) {
      return state.endDate.clone().endOf("day");
    } else {
      return state.endDate
        .clone()
        .startOf("day")
        .hour(state.endTime.hours())
        .minute(state.endTime.minutes());
    }
  }

  getAddressText(org: Organization) {
    return org
      ? `${org.address || ""}, ${org.zip || ""}, ${org.city || ""} ,${org.state || ""}, ${org.country || ""}`
      : "";
  }

  isUpdate(): boolean {
    return this.props.match && this.props.match.params.key && true;
  }

  onSave = () => {
    this.setState({ clickedOnSave: true });
    const validation = this.validate();
    if (validation.length > 0) {
      this.setState({
        validationError: validation
      });
      return;
    }

    setTimeout(() => {
      const evt = this.getNewEventInfo();
      console.log(
        evt.endDate.toString(),
        evt.startDate.toString(),
        evt.endDate.isBefore(evt.startDate)
      );
      if (!this.state.validEndTime || evt.endDate.isBefore(evt.startDate)) {
        this.setState({ validationErrorEndTime: true });
        return;
      } else {
        this.setState({ validationErrorEndTime: false });
      }
      setTimeout(() => {
        this.performSave();
      }, 100);
    }, 100);
  };

  performSave() {
    const addInfo = this.getNewEventInfo();
    let selectedOrganization = this.props.selectedOrganization
    if(addInfo.isOnline && (!addInfo.locationInfo || (addInfo.locationInfo && addInfo.locationInfo.address == ""))) {
      addInfo.locationInfo =  {
          ...this.state.locationInfo,
          address: selectedOrganization.address,
          address2: selectedOrganization.address2,
          country: selectedOrganization.country,
          city: selectedOrganization.city,
          state: selectedOrganization.state,
          zip: selectedOrganization.zip,
          lat: selectedOrganization.lat,
          lng: selectedOrganization.lng,
          addressBoxText: this.getAddressText(selectedOrganization)
        }
    }
    if (this.isUpdate()) {
      Services.Events.updateEvent(
        this.props.match.params.key,
        addInfo,
        this.props.organizationId,
        this.getChanged(),
        this.state.originalEvent,
        {
          title: this.state.pnsTitle,
          body: this.state.pnsBody
        }
      ).then((ref: { key: string }) => {
        this.props.close();
      });
    } else {
      Services.Events.addEvent(addInfo, {
        title: this.state.pnsTitle,
        body: this.state.pnsBody
      }).then((ref: { key: string }) => {
        this.props.close();
      });
    }
  }
  delete() {
    if (
      this.state.originalEvent //&&
      //(this.state.originalEvent.isRecurring || this.props.allowPns)
    ) {
      this.setState({ confirmDelete: true });
    } else {
      this.performDelete();
    }
  }

  performDelete() {
    Services.Events.deleteEvent(
      this.state.originalEvent.id,
      this.props.organizationId,
      this.state.selectedDeleteOption,
      moment(this.props.match.params.date),
      this.state.deletePnsEnabled
        ? {
            title: this.state.deletePnsTitle,
            body: this.state.deletePnsBody
          }
        : null
    )
    this.props.close();
  }
  twoDigits(num: number, withPlus = false) {
    const plusSign = withPlus ? (num >= 0 ? "+" : "-") : "";
    num = Math.floor(num * -1);
    if (num < 0 && num > -10) {
      return plusSign + "0" + Math.abs(num);
    } else if (num >= 0 && num < 10) {
      return plusSign + "0" + num;
    } else if (num > 0) {
      return plusSign + num;
    } else {
      return plusSign + Math.abs(num);
    }
  }
  getDisplayTzName(tzName: string) {
    let splittedName = tzName.split("/");
    let displayName = splittedName[splittedName.length - 1];
    if (displayName === "Central" || displayName === "Mountain") {
      displayName = `${splittedName[0]} ${displayName}`;
    }
    return displayName.replace("_", " ");
  }
  getTimezoneItemText(tz: TimeZone) {
    if (!tz) {
      return "";
    }
    return `(GMT${this.twoDigits(tz.offsetHours, true)}:${this.twoDigits(
      tz.offsetMinutes
    )}) ${this.getDisplayTzName(tz.name)}`;
  }

  addChanged = (changes: string[], field: string, condition: boolean) => {
    if (condition && changes.indexOf(field)) {
      changes.push(field);
      return true;
    }
    return false;
  };
  isTextChanged = (original: string, current: string) => {
    if (isEmpty(original) || isEmpty(current)) {
      return (
        (isEmpty(original) && !isEmpty(current)) ||
        (!isEmpty(original) && isEmpty(current))
      );
    } else {
      return original !== current;
    }
  };
  getChanged = (prevState?: NewEventDialogState) => {
    const state = prevState || this.state;
    if (!state) {
      return [];
    }
    //if (!this.isUpdate() || !state.originalEvent) {
    //return ['all'];
    //}

    const defaultEvent = new Event();
    defaultEvent.startDate = this.state.originalDates.start;
    defaultEvent.endDate = this.state.originalDates.end;
    defaultEvent.timezone = this.state.originalDates.timeZone
      ? this.state.originalDates.timeZone.name
      : "";
    const originalEvent = state.originalEvent || defaultEvent;
    let changes: string[] = [];
    this.addChanged(
      changes,
      "title",
      this.isTextChanged(originalEvent.name, state.name)
    );

    this.addChanged(
      changes,
      "calendar",
      listChanged(
        originalEvent.calendarIds,
        Object.keys(this.state.selectedCalendars),
        (a, b) => a === b
      )
    );

    let dateChanged = false;
    if(this.isUpdate()) {
    dateChanged = this.addChanged(
      changes,
      "start-date",
      !isSameDay(originalEvent.startDate, this.getStartDate(state))
    );

      this.addChanged(
      changes,
      dateChanged ? "start-date" : "end-date",
      !isSameDay(originalEvent.endDate, this.getEndDate(state))
    );

    if (!originalEvent.isAllDay && !dateChanged) {
      const addedTime = this.addChanged(
        changes,
        "start-time",
        Math.abs(
          originalEvent.startDate.diff(this.getStartDate(state), "minutes")
        ) > 2
      );
      this.addChanged(
        changes,
        addedTime ? "start-time" : "end-time",
        Math.abs(
          originalEvent.endDate.diff(this.getEndDate(state), "minutes")
        ) > 2
      );
    }
}

    if (originalEvent.timezone) {
      this.addChanged(
        changes,
        "timezone",
        !state.timeZone ||
          this.isTextChanged(originalEvent.timezone, state.timeZone.name)
      );
    } else {
      this.addChanged(changes, "timezone", !!state.timeZone);
    }
    this.addChanged(
      changes,
      "location",
      (!state.locationInfo && this.isUpdate()) ||
        this.isTextChanged(
          originalEvent.addressBoxText,
          state.locationInfo ? state.locationInfo.addressBoxText : ""
        )
    );
    this.addChanged(
      changes,
      "location",
      this.isTextChanged(originalEvent.address2, state.address2)
    );

    this.addChanged(
      changes,
      "details",
      this.isTextChanged(originalEvent.details, state.description)
    );

    if (originalEvent.recursionInfo && state.recursionInfo) {
      this.addChanged(
        changes,
        "recursion-repeat",
        originalEvent.recursionInfo.repeatsEvery !==
          state.recursionInfo.repeatsEvery
      );
      this.addChanged(
        changes,
        "recursion-after",
        originalEvent.recursionInfo.afterType !== state.recursionInfo.afterType
      );
      this.addChanged(
        changes,
        "every-duration",
        originalEvent.recursionInfo.everyDuration !==
          state.recursionInfo.everyDuration
      );

      if (
        changes.length === 0 &&
        originalEvent.recursionInfo.afterType == AfterType.ON
      ) {
        this.addChanged(
          changes,
          "recursion-on",
          originalEvent.recursionInfo.endsOn &&
            state.recursionInfo.endsOn.getTime() &&
            Math.abs(
              originalEvent.recursionInfo.endsOn.getTime() -
                state.recursionInfo.endsOn.getTime()
            ) >
              60 * 2 * 1000
        );
      }
      if (
        changes.length === 0 &&
        originalEvent.recursionInfo.afterType == AfterType.AFTER
      ) {
        this.addChanged(
          changes,
          "recursion-after",
          originalEvent.recursionInfo.endsAfter !==
            state.recursionInfo.endsAfter
        );
      }
      const originalRepeat = originalEvent.recursionInfo.repeatsOn;
      const newRepeat = state.recursionInfo.repeatsOn;
      if (originalRepeat && newRepeat) {
        this.addChanged(
          changes,
          "recursion-repeat",
          originalRepeat.length !== newRepeat.length ||
            originalRepeat.some(r => newRepeat.indexOf(r) < 0)
        );
      } else {
        this.addChanged(
          changes,
          "recursion-repeat",
          (!!originalRepeat && !newRepeat) || (!originalRepeat && !!newRepeat)
        );
      }
    }
    return changes;
  };
  validate = () => {
    let selectedOrganization = this.props.selectedOrganization;
    const errors = [];

    if (isEmpty(this.state.name)) {
      errors.push("dialog.events.validation.title");
    }

    if (!this.state.isOnline && (!this.state.locationInfo || (this.state.locationInfo && !this.state.locationInfo.addressBoxText && this.state.locationInfo.addressBoxText.trim() == ""))  ) {
      if(!this.state.locationInfo && selectedOrganization && this.state.addressBoxText) {
        this.setState({
          locationInfo : {
            location: this.state.addressBoxText,
            address: selectedOrganization.address,
            city: selectedOrganization.city,
            state: selectedOrganization.state,
            zip: selectedOrganization.zip,
            country: selectedOrganization.country,
            lat:
            selectedOrganization.lat,
            lng:
            selectedOrganization.lng,
            addressBoxText: this.state.addressBoxText
          }
        })
      } else {
        errors.push("dialog.events.validation.address");
      }
    }

    if (this.state.isOnline && this.state.description.trim() == "") {
      errors.push("dialog.events.validation.address");
    }

    if (
      !this.state.selectedCalendars ||
      Object.keys(this.state.selectedCalendars).length === 0
    ) {
      errors.push("dialog.events.validation.calendar");
    }

    //if (isEmpty(this.state.categoryText)) {
    //  errors.push('dialog.events.validation.category');
    //}

    return errors;
  };
  notEmptyText(text: string, replace: string = "<empty>") {
    return isEmpty(text) ? replace : text;
  }
  formatDate(date: moment.Moment) {
    if (isTodaysDate(date)) {
      return "today";
    } else if (isSameDay(date, moment().add(1, "day"))) {
      return "tomorrow";
    }
    return date.format("ddd, MMM DD");
  }
  getMatchDate() {
    return this.props.match &&
      this.props.match.params &&
      this.props.match.params.date
      ? moment(this.props.match.params.date)
      : null;
  }
  getNearestFutureDate() {
    if (!this.state.isRecurringEvent || !this.isUpdate()) {
      return this.getStartDate();
    } else {
      const evaluator = new RecurringEvaluator(
        this.getStartDate(),
        this.getNewEventInfo().recurring
      );
      return (
        evaluator.getNearestFutureDate(
          this.getMatchDate() || this.getStartDate()
        ) || this.getStartDate()
      );
    }
  }
  getReplacements() {
    if (!this.state) {
      return {};
    }
    const locationInfo = this.state.locationInfo;
    const nearestFutureDate = this.getNearestFutureDate();
    const dateText = this.formatDate(nearestFutureDate);
    //Sun, Jul 29

    return {
      organization_name: this.props.organizationName,
      event_name: this.notEmptyText(this.state.name),
      start_date: dateText,
      calendar_name: this.notEmptyText(
        Object.keys(this.state.selectedCalendars)
          .map(calId => this.props.allCalendars.find(c => c.id === calId))
          .join(", ")
      ),
      start_time: nearestFutureDate.format("hh:mm a"),
      full_date: `${dateText}${
        !this.state.isAllDay ? " " + nearestFutureDate.format("hh:mm a") : ""
      }`,
      date: this.getMatchDate() ? this.formatDate(this.getMatchDate()) : "",
      venue: !locationInfo
        ? ""
        : locationInfo.location
        ? `${locationInfo.location} ${this.state.address2 || ""}`
        : `${locationInfo.address}${
            locationInfo.city ? "," + locationInfo.city : ""
          }${locationInfo.zip ? "," + locationInfo.zip : ""}${
            locationInfo.country ? "," + locationInfo.country : ""
          } ${this.state.address2 || ""}`
    };
  }
  getPNSNotification(
    useDefaultParam?: boolean
  ): {
    defaultPnsTitle: string;
    defaultPnsBody: string;
    pnsTitle?: string;
    pnsBody?: string;
    deletePnsTitle: string;
    deletePnsBody: string;
  } {
    const type = this.isUpdate() ? "event_changed" : "event_added";
    const changed = this.getChanged();
    const defaultPnsTitle = getTitleOfNotification(
      type,
      changed,
      this.getReplacements()
    );
    const defaultPnsBody = getBodyOfNotification(
      type,
      changed,
      this.getReplacements()
    );
    const useDefault =
      typeof useDefaultParam !== "undefined"
        ? useDefaultParam
        : this.state.usePnsDefaultText || !this.state;
    const deletePnsTitle = getTitleOfNotification(
      "event_cancelled",
      [],
      this.getReplacements()
    );
    const deletePnsBody = getBodyOfNotification(
      "event_cancelled",
      [],
      this.getReplacements()
    );

    if (useDefault) {
      return {
        defaultPnsTitle,
        defaultPnsBody,
        pnsTitle: defaultPnsTitle,
        pnsBody: defaultPnsBody,
        deletePnsTitle,
        deletePnsBody
      };
    }
    return {
      defaultPnsTitle,
      defaultPnsBody,
      deletePnsTitle,
      deletePnsBody
    };
  }
  componentDidUpdate(
    prevProps: NewEventDialogProps,
    prevState: NewEventDialogState
  ) {
    if (!this.state || !prevState) {
      return;
    }
    const newChanges = this.getChanged();
    const oldChanges = this.getChanged(prevState);
    let changesChanged = newChanges.length !== oldChanges.length;
    if (!changesChanged) {
      changesChanged = newChanges.some(c => oldChanges.indexOf(c) < 0);
    }
    const newNotification = this.getPNSNotification();
    if (
      this.state.usePnsDefaultText &&
      (newNotification.defaultPnsBody !== this.state.defaultPnsBody ||
        newNotification.defaultPnsTitle !== this.state.defaultPnsTitle)
    ) {
      this.setState(this.getPNSNotification());
    }
  }
  getSelectedCalendars() {
    return this.props.allCalendars.filter(
      c => this.state.selectedCalendars && this.state.selectedCalendars[c.id]
    );
  }

  getCalendarText = (c: Calendar) => {
    return `${c.categories[0].name} - ${c.name}`;
  };
  isRecurring() {
    return this.state.originalEvent && this.state.originalEvent.isRecurring;
  }
  isStartOfSeries() {
    return isSameDay(
      this.state.originalEvent.startDate,
      this.getDateFromPath()
    );
  }
  getDateFromPath() {
    return moment(this.props.match.params.date);
  }

  handleEndTimeMin(d: moment.Duration, endTime?: moment.Duration) {
    let isValid =true; this.state.startTime.valueOf() < d.valueOf();
    if (isValid) {
      this.setState({
        endTime: d,
        validEndTime: true,
        validationErrorEndTime: false
      });
    } else {
      this.setState({ endTime: d, validEndTime: false });
    }
  }

  render() {
    const { match, _ } = this.props;

    const recurringRows = [];
    const addressBoxText = this.state.locationInfo
      ? this.state.locationInfo.addressBoxText
      : this.state.addressBoxText;
    const selectedCategory = this.props.allCategories.find(
      c => c.name === this.state.categoryText
    );
    return (
      <Modal isOpen={true}>
        <div className="event_dialog">
          {this.state.loading && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 1,
                background: "white"
              }}
            >
              <div className="page_loader_container">
                <Spinner
                  size={120}
                  spinnerColor={"#497AF2"}
                  spinnerWidth={2}
                  visible={true}
                />
              </div>
            </div>
          )}
          <h2>
            <div
              className="event_dialog__close_btn"
              onClick={e => {
                e.preventDefault();
                if (this.getChanged().length) {
                  this.setState({ confirmDiscard: true });
                } else {
                  this.props.close();
                }
              }}
            />

            {this.isUpdate()
              ? _("dialog.events.update.title")
              : _("dialog.events.new.title")}
          </h2>
          <div className="event_dialog__content">
            <div className="event_form_dialog__row event_form_dialog__row__category_container">
              <AutoSelectInput
                allItems={this.props.allCategories.filter(
                  c => !isEmpty(c.name)
                )}
                getItemKey={(c: Category) => c.id}
                getItemText={(c: Category) => c.name}
                onChange={categoryText => this.setState({ categoryText })}
                placeHolder={_("dialog.events.select.category")}
                value={this.state.categoryText}
                onSelect={categoryText =>
                  this.setState({ categoryText, selectedCalendars: {} })
                }
                className="event_form_dialog__category"
                error={isEmpty(this.state.categoryText)}
                showErrorPerDefault={this.state.clickedOnSave}
                errorText={_("dialog.events.validation.category")}
              />

              <MultiSelect
                additionalClass="event_form_dialog__calendar"
                inputMode={true}
                withAdd={true}
                text={"Select a Subcategory"}
                filter={(calendar, value) =>
                  (isEmpty(value) ||
                    calendar.name.toLowerCase().indexOf(value.toLowerCase()) >=
                      0) &&
                  selectedCategory &&
                  calendar.categories &&
                  calendar.categories.some(
                    cat => cat.id === selectedCategory.id
                  )
                }
                items={this.props.allCalendars.filter(c => !isEmpty(c.name))}
                getKey={(c: Calendar) => c.id}
                getText={(c: Calendar) => c.name}
                onChange={(selectedCalendars: Calendar[]) =>
                  this.setState({
                    selectedCalendars: indexByTextWithMap(
                      selectedCalendars,
                      c => c.id,
                      c => c.id
                    )
                  })
                }
                error={
                  !this.state.selectedCalendars ||
                  !Object.keys(this.state.selectedCalendars).length
                }
                showSelectedAsText={true}
                selectedItems={this.state.selectedCalendars}
                showErrorPerDefault={this.state.clickedOnSave}
                errorText={_("dialog.events.validation.calendar")}
                zIndexOpt={10}
                alwaysFilter={true}
                notNullOnAll={true}
              />
            </div>

            <div className="event_form_dialog__row">
              <TextInput
                value={this.state.name}
                show={true}
                onChange={name => this.setState({ name })}
                placeholder={_("dialog.events.select.title")}
                error={isEmpty(this.state.name)}
                showErrorPerDefault={this.state.clickedOnSave}
                errorText={_("dialog.events.validation.title")}
                area={isMobile()}
              />
            </div>
            <div className="event_form_dialog__time__row">
              <DatePicker
                selected={
                  this.isRecurring() &&
                  !this.isStartOfSeries() &&
                  this.isUpdate()
                    ? this.getDateFromPath()
                    : this.state.startDate
                }
                onChange={newDate => {
                  this.setState({
                    startDate: newDate,
                    endDate: newDate.clone()
                  });
                }}
              />
              <TimePicker
                show={!this.state.isAllDay}
                onChange={d => {
                  this.setState({
                    startTime: d,
                    endTime: d ? d.clone().add(1, "hour") : null
                  });
                }}
                value={this.state.startTime}
              />
              <span className="event_dialog__time__to">{_("to")}</span>
              <DatePicker
                selected={
                  this.isRecurring() &&
                  !this.isStartOfSeries() &&
                  this.isUpdate()
                    ? this.getDateFromPath()
                    : this.state.endDate
                }
                onChange={newDate => {
                  this.setState({ endDate: newDate });
                }}
              />
              <TimePicker
                show={!this.state.isAllDay}
                onChange={d => this.handleEndTimeMin(d)}
                value={this.state.endTime}
              />
              <Checkbox
                containerClass="event_dialog__all_day_check_container"
                checked={this.state.isAllDay}
                onChange={chk => this.setState({ isAllDay: chk })}
                text={_("dialog.events.all.day")}
              />
              <Button
                text={
                  this.state.timeZoneTxt || _("dialog.events.button.timezone")
                }
                onClick={() =>
                  this.setState({
                    changeTz: true,
                    previousTimeZone: this.state.timeZone,
                    previousTimeZoneTxt: this.state.timeZoneTxt
                  })
                }
                additionClassName="event_dialog__timezone_button"
                link={true}
              />
            </div>
            <div className="event_form_dialog__row">
            <Checkbox
                  checked={this.state.isOnline}
                  onChange={isOnline => {
                    this.setState({
                      isOnline
                    });
                    /*if (!this.state.isRecurringEvent || !this.isUpdate()) {
                      this.setState({ isRecurringEvent });
                    } else {
                      this.setState({ confirmRecurringEditable: true });
                    }*/
                  }}
                  text={_("dialog.events.is.online")}
                />
            </div>
            <div className="event_form_dialog__row">
              <div className="">
                <AddressInput
                  isEventEdit={true}
                  useTextArea={true}
                  onChange={locationInfo => {
                    if (locationInfo ) {
                      this.setState({
                        locationInfo,
                        addressBoxText: locationInfo.addressBoxText
                      });
                    } else {
                      this.setState({ locationInfo: null, addressBoxText: "" });
                    }
                  }}
                  text={addressBoxText}
                  placeHolder={_("dialog.events.select.address")}
                  error={!this.state.isOnline && isEmpty(addressBoxText)}
                  showErrorPerDefault={this.state.clickedOnSave}
                  errorText={_("dialog.events.validation.address")}
                  isNewEvent={!this.isUpdate()}
                />
              </div>
              <TextInput
                value={this.state.address2}
                onChange={address2 => {
                  this.setState({ address2 });
                }}
                placeholder={_("dialog.events.select.location.description")}
                area={isMobile()}
              />
            </div>
            <div className="event_form_dialog__row">
              <TextInput
                area={true}
                rows={5}
                value={this.state.description}
                onChange={description => this.setState({ description })}
                error={this.state.isOnline && isEmpty(this.state.description)}
                overrideInternalStateError={true}
                showErrorPerDefault={this.state.clickedOnSave}
                errorText={_("dialog.events.select.online.description")}
                placeholder={this.state.isOnline ? _("dialog.events.select.online.description") : _("dialog.events.select.description")}
              />
            </div>
            <div className="event_form_dialog__row ">
              <div className="event_form_dialog__row__recurring_container">
                <Checkbox
                  checked={this.state.isRecurringEvent}
                  onChange={isRecurringEvent => {
                    if (!this.state.isRecurringEvent || !this.isUpdate()) {
                      this.setState({ isRecurringEvent });
                    } else {
                      this.setState({ confirmRecurringEditable: true });
                    }
                  }}
                  text={_("dialog.events.recurring.title")}
                />

                <RecurringOptions
                  info={this.state.recursionInfo}
                  visible={this.state.isRecurringEvent}
                  updateValue={recursionInfo =>
                    this.setState({ recursionInfo })
                  }
                  startDate={this.getStartDate()}
                  _={_}
                />
              </div>
              {this.props.allowPns && (
                <div className="event_form_dialog__row__pns_container">
                  <Checkbox
                    checked={this.state.pushNotification}
                    onChange={pushNotification => {
                      if (pushNotification) {
                        this.setState({
                          pushNotification,
                          ...this.getPNSNotification(true)
                        });
                      } else {
                        this.setState({ pushNotification });
                      }
                    }}
                    text={_("dialog.events.push")}
                  />
                  <div
                    className={`event_form_button__row__pns_info event_form_button__row__pns_info--${
                      this.state.pushNotification ? "visible" : "invisible"
                    }`}
                  >
                    <TextBoxWithLabel
                      label="Title"
                      value={this.state.pnsTitle}
                      enabled={this.state.pushNotification}
                      onChange={pnsTitle =>
                        this.setState({ pnsTitle, usePnsDefaultText: false })
                      }
                      maxLength={55}
                      area={isMobile()}
                    />
                    <TextBoxWithLabel
                      label="Description"
                      value={this.state.pnsBody}
                      enabled={this.state.pushNotification}
                      onChange={pnsBody =>
                        this.setState({ pnsBody, usePnsDefaultText: false })
                      }
                      maxLength={100}
                      area={isMobile()}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="event_form_button__row">
            {this.state.validationError ? (
              <div className="event_dialog__errors_validation">
                {_("dialog.events.validation.error.title")}
              </div>
            ) : this.state.validationErrorEndTime ? (
              <div className="event_dialog__errors_validation">
                {_("dialog.events.validation.endTime")}
              </div>
            ) : null}

            {this.isUpdate() && (
              <Button
                onClick={() => this.delete()}
                deleteButton={true}
                text={_("delete")}
              />
            )}

            <Button
              onClick={() => this.onSave()}
              text={_("dialog.events.save")}
            />
          </div>
          <OverlayConfirm
            title={_("dialog.events.timezone")}
            show={this.state.changeTz}
            onNo={() =>
              this.setState({
                changeTz: false,
                timeZoneTxt: this.state.previousTimeZoneTxt,
                timeZone: this.state.previousTimeZone
              })
            }
            onYes={() => {
              this.setState({ changeTz: false });
            }}
          >
            <AutoSelectInput
              className="event_dialog__timezone_select"
              allItems={this.props.timeZones}
              getItemKey={timeZone => (timeZone ? timeZone.name : "")}
              getItemText={tz => this.getTimezoneItemText(tz)}
              onChange={timeZoneTxt => {
                const timeZone = this.props.timeZones.find(
                  t =>
                    this.getTimezoneItemText(t).toLowerCase() ===
                    (timeZoneTxt || "").toLowerCase()
                );
                this.setState({ timeZone, timeZoneTxt });
              }}
              placeHolder={_("dialog.events.select.timezone")}
              onBlur={() => {
                this.setState({
                  timeZoneTxt: this.state.timeZone
                    ? this.getTimezoneItemText(this.state.timeZone)
                    : ""
                });
              }}
              value={this.state.timeZoneTxt}
            />
          </OverlayConfirm>

          <OverlayConfirm
            message={_("dialog.events.confirm.calendar")}
            show={this.state.confirmNewCalendar}
            onNo={() => this.setState({ confirmNewCalendar: false })}
            onYes={() => {
              this.setState({ confirmNewCalendar: false });
              this.performSave();
            }}
          />
          <OverlayConfirm
            message={_("dialog.events.confirm.discard")}
            show={this.state.confirmDiscard}
            noText={_("cancel")}
            yesText={_("discard")}
            onNo={() => this.setState({ confirmDiscard: false })}
            onYes={() => {
              this.setState({ confirmDiscard: false });
              this.props.close();
            }}
          />
          <OverlayConfirm
            show={this.state.confirmRecurringEditable}
            message={_("dialog.events.confirm.recurring")}
            noText={_("cancel")}
            yesText={_("continue")}
            onYes={() => {
              this.setState({
                isRecurringEvent: false,
                confirmRecurringEditable: false
              });
            }}
            onNo={() => this.setState({ confirmRecurringEditable: false })}
          />
          <OverlayConfirm
            show={this.state.confirmDelete}
            noText={_("cancel")}
            yesText={_("delete")}
            onNo={() =>
              this.setState({
                confirmDelete: false,
                selectedDeleteOption: EventDeleteOptions.All
              })
            }
            onYes={() => {
              this.setState({
                confirmDelete: false,
                selectedDeleteOption: EventDeleteOptions.All
              });
              this.performDelete();
            }}
            className="event_dialog__delete_overlay"
          >
            {!this.props.allowPns && <div>
            {_("dialog.events.confirm.delete")}
          </div>}
            {this.state.originalEvent && this.state.originalEvent.isRecurring && (
              <ul className="event_dialog__delete_overlay__options">
                {DeleteOptions.map(opt => (
                  <li
                    key={opt.option}
                    className="event_dialog__delete_overlay__options__option"
                  >
                    <Checkbox
                      checked={this.state.selectedDeleteOption === opt.option}
                      text={_(opt.message)}
                      onChange={checked => {
                        if (checked) {
                          this.setState({ selectedDeleteOption: opt.option });
                        }
                      }}
                    />
                  </li>
                ))}
              </ul>
            )}
            {this.props.allowPns && (
              <div className="event_dialog__delete_overlay__push_notifications">
                <Checkbox
                  checked={this.state.deletePnsEnabled}
                  onChange={deletePnsEnabled => {
                    if (deletePnsEnabled) {
                      this.setState({
                        deletePnsEnabled,
                        ...this.getPNSNotification(true)
                      });
                    } else {
                      this.setState({ deletePnsEnabled });
                    }
                  }}
                  text={`${_("dialog.events.push")}?`}
                />
                <div
                  className={`event_dialog__delete_overlay__push_notifications__info event_dialog__delete_overlay__push_notifications__info--${
                    this.state.deletePnsEnabled ? "visible" : "hidden"
                  }`}
                >
                  <TextBoxWithLabel
                    label="Title"
                    value={this.state.deletePnsTitle}
                    enabled={this.state.deletePnsEnabled}
                    onChange={deletePnsTitle =>
                      this.setState({ deletePnsTitle })
                    }
                    maxLength={55}
                    area={isMobile()}
                  />
                  <TextBoxWithLabel
                    label="Description"
                    value={this.state.deletePnsBody}
                    enabled={this.state.deletePnsEnabled}
                    onChange={deletePnsBody => this.setState({ deletePnsBody })}
                    maxLength={100}
                    area={isMobile()}
                    additionalClass={"event_dialog__delete_overlay__push_notifications__info--desc"}
                  />
                </div>
              </div>
            )}
          </OverlayConfirm>
        </div>
      </Modal>
    );
  }
}

export default connect(
  (state: AppState) => ({
    allCategories: state.allCategories,
    allCalendars: getSortedCalendars(state),
    organizationId: getOrganizationId(state),
    timeZones: state.ui.timeZones,
    organizationName: getSelectedOrganization(state)
      ? getSelectedOrganization(state).name
      : "",
      selectedOrganization: getSelectedOrganization(state)
      ? getSelectedOrganization(state)
      : undefined,
    allowPns: isOrganizationPaidUserForPns(getSelectedOrganization(state))
  }),
  dispatch => ({
    goToEventCreatedSuccess: (key: string) => {
      dispatch(push(`/events/created/${key}`));
    },
    close: () => {
      dispatch(push("/"));
    },
    goIndex: () => {
      dispatch(push("/"));
    }
  })
)(localizable(NewEventDialog));
