import rsf from "./firebase";
import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  loginSuccess,
  loginError,
  logoutSuccess,
  logoutError,
  resetPasswordSuccess,
  resetPasswordError,
} from "../actions/auth.actions";
import { Actions } from "../state/actions";
import { Action, createAction } from "redux-actions";
import { LoginErrorState, AppState } from "../state/state";

import { push } from "connected-react-router";
import firebase from "../api/firebase";
import Calendar from "../models/calendar";
import { User } from "../models/User";
import { diffList } from "../utils/list.utils";
import {
  calendarAdded,
  calendarRemoved,
  calendarInfoUpdated,
} from "../actions/calendar.actions";
import { EventNode, parseEventNode } from "../api/model/event.node";
import {
  syncEventsOfCalendar,
  syncRecurringEventsOfCalendar,
} from "../actions/event.action";
import { syncCategoriesOfCalendar } from "../actions/category.actions";
import { CalendarNode } from "../api/model/calendar.node";
import { CategoryNode, parseCategoryNode } from "../api/model/category.node";
import { Services } from "../api/services";
import { channelListener } from "../utils/saga.utils";
import Event from "../models/event";
import { EventChannel, eventChannel } from "../../node_modules/redux-saga";
import { channelFromRefName } from "../utils/firebase.utils";
import { getCategoryOfCalendar } from "../state/state_getters";

const time = new Date().getTime();

function* listenRecurringEventsCal(
  calendarId: string,
  action: { value: { [id: string]: boolean } }
) {
  yield put(
    syncRecurringEventsOfCalendar(
      calendarId,
      action.value ? Object.keys(action.value) : []
    )
  );
  const state: AppState = yield select();
}
function* listenEventsChannel(calendarId: string, action: { value: string[] }) {
  yield put(syncEventsOfCalendar(calendarId, action.value || []));
}
function* listenCalInfoChannelChannel(
  calendarId: string,
  action: { value: CalendarNode }
) {
  yield put(
    calendarInfoUpdated(Object.assign({ id: calendarId }, action.value))
  );
}

function* listenCalendarInfoSaga(action: Action<{ calendarId: string }>) {
  const { calendarId } = action.payload;

  //const channel = yield call(rsf.database.channel, `Calendars/${calendarId}`);
  const channel = channelFromRefName(`Calendars/${calendarId}`);
  yield takeEvery(channel, (action: { value: CalendarNode }) =>
    listenCalInfoChannelChannel(calendarId, action)
  );
  yield take(Actions.AUTH.LOGOUT_REQUEST);
  channel.close();
}
function* listenEventsOfCalendarSaga(action: Action<{ calendarId: string }>) {
  const { calendarId } = action.payload;

  let finish = false;
  while (!finish) {
    const state: AppState = yield select();
    const channel = channelListener((onChange) => {
      return Services.Events.onCalendarEventsChanged(
        calendarId,
        state.ui.syncDate.clone().add(-1, "years"),
        state.ui.syncDate.clone().add(1, "years"),
        onChange
      );
    });
    const recurringChannel = channelFromRefName(
      `Calendar_Event/${calendarId}/recurring`
    );
    /*
     yield call(
      rsf.database.channel,
      firebase
        .database()
        .ref('RecurringEvents')
        .child(calendarId)
    );*/
    yield takeEvery(channel, (action: { value: string[] }) =>
      listenEventsChannel(calendarId, action)
    );
    yield takeEvery(
      recurringChannel,
      (action: { value: { [id: string]: boolean } }) =>
        listenRecurringEventsCal(calendarId, action)
    );
    const action: Action<any> = yield take([
      Actions.AUTH.LOGOUT_REQUEST,
      Actions.CALENDAR.CHANGE_SYNC_DATE,
    ]);
    finish = finish || action.type === Actions.AUTH.LOGOUT_REQUEST;
    channel.close();
    recurringChannel.close();
  }
}
function* listenCategoryChannel(
  calendarId: string,
  action: { value: { [name: string]: CategoryNode } }
) {
  yield put(
    syncCategoriesOfCalendar(
      calendarId,
      action.value
        ? Object.keys(action.value).map((id) =>
            parseCategoryNode(id, action.value[id])
          )
        : []
    )
  );
}

function* listenCalendarCategorySaga(action: Action<{ calendarId: string }>) {
  const { calendarId } = action.payload;

  const state: AppState = yield select();
  const category = getCategoryOfCalendar(action.payload.calendarId, state);

  yield put(syncCategoriesOfCalendar(calendarId, category ? [category] : []));
  /*
  const channel = yield call(
    rsf.database.channel,
    firebase
      .database()
      .ref('Categories')
      .orderByChild(`calendars/${calendarId}`)
      .equalTo(calendarId)
  );

  yield takeEvery(channel, (action: { value: { [name: string]: CategoryNode } }) =>
    listenCategoryChannel(calendarId, action)
  );
  yield take(Actions.AUTH.LOGOUT_REQUEST);
  channel.close(); */
}

function* listenCalendarChannel(action: {
  value: { [name: string]: boolean };
}) {
  const { value } = action;

  const prevCalendars: Calendar[] = yield select(
    (state: AppState) => state.calendars
  );

  const calendarIds: string[] = Object.keys(value);

  const { added, deleted } = diffList(
    prevCalendars,
    calendarIds,
    (prevCal: Calendar, newCalId: string) => prevCal.id === newCalId
  );
  yield all(added.map((calId) => put(calendarAdded(calId))));
  yield all(deleted.map((c) => put(calendarRemoved(c.id))));
}
function* listenUserSaga(action: Action<{ user: User }>) {
  const { user } = action.payload;
  try {
    const channel: EventChannel<Record<string, boolean>> = yield call(
      rsf.database.channel,
      `Admin_Users/${user.id}`
    );
    yield takeEvery(channel, listenCalendarChannel);
    yield take(Actions.AUTH.LOGOUT_REQUEST);
    channel.close();
  } catch (ex) {
    console.error(ex);
  }
}
function* listenReorderCalendar(action: Action<{ newOrder: string[] }>) {
  yield action.payload.newOrder.forEach((calId, order) => {
    Services.Calendar.updateOrder(calId, order + 1);
  });
}
const listeningEvents: { [id: string]: () => any } = {};
function* onNewCalendarEvents(
  action: Action<{ calendarId: string; events: string[] }>
) {
  yield all(
    action.payload.events.map(function* (evId) {
      if (!listeningEvents[evId]) {
        const channel = channelListener((onChange) => {
          return Services.Events.listenEvent(evId, onChange);
        });
        yield takeEvery(channel, function* (ev: Event) {
          if (!ev.exists) {
            return;
          }
          //console.log('adding event',action.payload.calendarId, ev.id);
          yield put(
            createAction(Actions.EVENT.SYNCH_EVENT_INFO, (event: Event) => ({
              event,
            }))(ev)
          );
        });
      }
    })
  );
}
function* calendarRootSaga() {
  //yield fork(syncUserSaga);
  yield all([
    //takeLatest(Actions.AUTH.LOGIN_SUCCESS, listenUserSaga),
    takeEvery(Actions.CALENDAR.CALENDAR_ADDED, listenEventsOfCalendarSaga),
    takeEvery(Actions.CALENDAR.CALENDAR_ADDED, listenCalendarInfoSaga),
    takeEvery(Actions.CALENDAR.CALENDAR_ADDED, listenCalendarCategorySaga),
    takeEvery(Actions.CALENDAR.REORDER, listenReorderCalendar),
    takeEvery(Actions.EVENT.SYNCH, onNewCalendarEvents),
    takeEvery(Actions.EVENT.SYNCH_RECURRING, onNewCalendarEvents),
    //takeEvery(Actions.AUTH.LOGOUT_REQUEST, logoutSaga),
    //takeEvery(Actions.AUTH.RESET_PASSWORD, resetSaga)
  ]);
}

export default [calendarRootSaga];
