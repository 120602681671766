import * as React from 'react';
import AutoSelectInput from './auto.select.input';
import './styles/tag-component.less';
import { FadeList } from '../../utils/fade.list';
interface Props<T, U = any> {
  allItems: T[];
  fileredItems: T[];
  getItemKey: (e: T) => string;
  getItemText: (e: T) => string;
  onChange: (selected: { [key: string]: string }) => any;
  placeHolder: string;
  error?: boolean;
  title: string;
  items: { [key: string]: string };
  showErrorPerDefault?: boolean;
  filterItems: U[];
  getFilterItemKey: (e: U) => string;
  getFilterItemText: (e: U) => string;
  filter: (text: string) => any;
  filterPlaceHolder: string;
  filterText: string;
}
interface State<T> {
  text: string;
  removed: number;
}
const COLORS = ['red', 'blue', '#f2d600', '#c377e0', '#61bd4f'];

export default class TagSelector<T> extends React.Component<Props<T>, State<T>> {
  state = {
    text: '',
    removed: 0
  };
  addItem = () => {
    this.select(this.state.text);
  };
  select = (t: string) => {
    const selected = this.props.allItems.find(i => this.props.getItemText(i) === t);
    if (selected) {
      this.setState({
        text: ''
      });
      if (!this.props.items[this.props.getItemKey(selected)]) {
        this.props.onChange({
          ...this.props.items,
          [this.props.getItemKey(selected)]: COLORS[
            (Object.keys(this.props.items).length + this.state.removed) % COLORS.length
          ]
        });
      }
    }
  };
  removeItem = (itemKey: string) => {
    const newSelectedCalendars = { ...this.props.items };
    delete newSelectedCalendars[itemKey];
    this.props.onChange(newSelectedCalendars);
    this.setState({ removed: this.state.removed + 1 });
  };
  static getColor(idx: number) {
    return COLORS[idx % COLORS.length];
  }
  getItemText = (itemKey: string) => {
    const item = this.props.allItems.find(i => this.props.getItemKey(i) === itemKey);
    if (item) {
      return this.props.getItemText(item);
    }
    return '';
  };
  render() {
    return (
      <div className="tag_component">
        <div className="tag_component__title">
          <AutoSelectInput
            allItems={this.props.filterItems}
            getItemKey={this.props.getFilterItemKey}
            getItemText={this.props.getFilterItemText}
            onChange={text => this.props.filter(text)}
            placeHolder={this.props.filterPlaceHolder}
            value={this.props.filterText}
            onSelect={text => this.props.filter(text)}
          />
          <AutoSelectInput
            allItems={this.props.fileredItems}
            getItemKey={this.props.getItemKey}
            getItemText={this.props.getItemText}
            onChange={text => this.setState({ text })}
            placeHolder={this.props.placeHolder}
            value={this.state.text}
            error={this.props.error}
            showErrorPerDefault={this.props.showErrorPerDefault}
            onSelect={this.select}
          />
          <button className="tag_component__title__plus" onClick={this.addItem}>
            +
          </button>
        </div>
        <div className="event_form_dialog__tags">
          <FadeList
            items={Object.entries(this.props.items)}
            getKey={([k, color]) => k}
            prefix="event_form_dialog__tags"
            renderItem={([itemKey, color]) => {
              return (
                <div className="event_form_dialog__tag" style={{ background: color }}>
                  {this.getItemText(itemKey)}
                  <button className="event_form_dialog__tag__cross" onClick={() => this.removeItem(itemKey)}>
                    x
                  </button>
                </div>
              );
            }}
          />
        </div>
      </div>
    );
  }
}
