import { handleActions, Action } from 'redux-actions';
import { LoaderState } from '../state/state';
import { Actions } from '../state/actions';

export default function(state: LoaderState = { targets: [] }, action: Action<any>): LoaderState {
  if (action.type.indexOf('_REQUEST') > 0) {
    return { targets: [...state.targets, { key: action.type.replace('_REQUEST', ''), start: new Date().getTime() }] };
  } else if (action.type.indexOf('_SUCCESS') > 0 || action.type.indexOf('_ERROR') > 0) {
    const target = action.type.replace('_SUCCESS', '').replace('_ERROR', '');
    return { targets: state.targets.filter(t => t.key !== target) };
  }
  return state;
}
