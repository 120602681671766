import * as React from "react";
import * as ReactDOM from "react-dom";
import { Motion, spring } from "react-motion";
import { getPageXY } from "../../utils/position.utils";
import { between } from "../../utils/range.utils";
interface DraggableProps {
  children: React.ReactElement<any>[];
  onReorder: (newOrder: string[]) => any;
}
interface DraggableState {
  yStart?: number;
  selectedKey?: string;
  currentY?: number;
  currentSort: string[];
  oldIndex: number;
  newIndex: number;
  moving: boolean;
}
export default class DraggableList extends React.Component<
  DraggableProps,
  DraggableState
> {
  handleMove: () => any;
  handleUp: () => any;
  liRefs: { [key: string]: HTMLDivElement } = {};
  constructor(props: DraggableProps) {
    super(props);
    this.state = {
      currentY: 0,
      yStart: 0,
      currentSort: [],
      oldIndex: -1,
      newIndex: -1,
      moving: false
    };
  }

  componentDidMount() {}
  getPositionFromMousePosition(y: number) {
    const correspondingElementKey = Object.keys(this.liRefs).reduce(
      (
        correspondingInfo: {
          key: string;
          minDist: number;
          yRange: { yStart: number; yEnd: number };
        },
        key
      ) => {
        const elem = this.liRefs[key];
        if (!elem) return correspondingInfo;
        const positionElem = getPageXY(elem);
        const minDist = positionElem.yStart - y;

        if (
          y < positionElem.yStart &&
          (!correspondingInfo || minDist < correspondingInfo.minDist)
        ) {
          return { key, yRange: positionElem, minDist };
        } else {
          return correspondingInfo;
        }
      },
      null
    );
    return correspondingElementKey
      ? Math.max(
          this.state.currentSort.indexOf(correspondingElementKey.key) - 1,
          0
        )
      : this.state.currentSort.length - 1;
  }

  handleMouseDown(yStart: number, selectedKey: string) {
    //return;
    if (this.state.moving) return;
    this.setState({ yStart, selectedKey, currentY: yStart, moving: true });
    const move = (e: {
      pageY: number;
      preventDefault: () => any;
      stopPropagation: () => any;
    }) => {
      this.setState({ currentY: e.pageY });
      e.preventDefault();
      e.stopPropagation();
      const resortedArray = [...this.state.currentSort];
      const newIndex = this.getPositionFromMousePosition(e.pageY);
      const oldIndex = resortedArray.indexOf(this.state.selectedKey);

      //resortedArray[newIndex] = this.state.currentSort[oldIndex];
      //resortedArray[oldIndex] = this.state.currentSort[newIndex];
      const yStart = e.pageY;
      const translateFromIdx = oldIndex;
      this.setState({ oldIndex, newIndex }); // yStart: e.pageY
    };
    const up = (e: {
      pageY: number;
      stopPropagation: () => any;
      preventDefault: () => any;
    }) => {
      window.removeEventListener("mousemove", move);
      window.removeEventListener("mouseup", up);
      e.stopPropagation();
      e.preventDefault();
      const resortedArray = [...this.state.currentSort];
      const oldIndex = resortedArray.indexOf(this.state.selectedKey);
      const newIndex = this.getPositionFromMousePosition(e.pageY);
      for (
        let i = Math.min(oldIndex, newIndex);
        i <= Math.max(oldIndex, newIndex);
        i++
      ) {
        if (i !== newIndex) {
          resortedArray[i] = this.state.currentSort[
            i + Math.sign(newIndex - oldIndex)
          ];
        }
      }
      resortedArray[newIndex] = this.state.currentSort[oldIndex];
      setTimeout(() => {
        this.setState({
          currentY: 0,
          yStart: 0,
          selectedKey: null,
          oldIndex: -1,
          newIndex: -1,
          currentSort: resortedArray,
          moving: false
        });
        if (this.props.onReorder) {
          this.props.onReorder(resortedArray);
        }
      }, 250);
    };
    window.addEventListener("mousemove", move);
    window.addEventListener("mouseup", up);
  }
  static getDerivedStateFromProps(
    props: DraggableProps,
    state: DraggableState
  ): Partial<DraggableState> {
    const newKeys = React.Children.map(
      props.children,
      (c: React.ReactElement<any>) => c.key as string
    );
    let changed = false;
    const newSortedList = state.currentSort.filter(
      key => newKeys.indexOf(key) >= 0
    );
    changed = newSortedList.length !== state.currentSort.length;
    newKeys.forEach(k => {
      if (newSortedList.indexOf(k) < 0) {
        newSortedList.push(k);
        changed = true;
      }
    });
    return { currentSort: newSortedList };
  }

  render() {
    const props = this.props;
    const { selectedKey, yStart, currentY, oldIndex, newIndex } = this.state;

    const sortedChildren = this.state.currentSort.map(k =>
      props.children.find((c: any) => c.key === k)
    );

    return (
      <div className="draggable_list">
        {React.Children.map(
          sortedChildren,
          (child: React.ReactElement<any>, idx) =>
            child && (
              <Motion
                defaultStyle={{ y: 0, scl: 1, translateNotSelected: 0 }}
                style={{
                  y:
                    child.key === selectedKey
                      ? spring(currentY - yStart)
                      : between(
                          idx,
                          this.state.oldIndex,
                          this.state.newIndex,
                          this.state.newIndex > this.state.oldIndex,
                          this.state.newIndex < this.state.oldIndex
                        )
                      ? spring(
                          -1 *
                            Math.sign(
                              this.state.newIndex - this.state.oldIndex
                            ) *
                            this.liRefs[
                              this.state.selectedKey
                            ].getBoundingClientRect().height
                        )
                      : this.state.moving
                      ? spring(0)
                      : 0,
                  scl: this.state.selectedKey === child.key ? 1.2 : 1
                }}
              >
                {style => (
                  <div
                    onMouseDown={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.handleMouseDown(e.pageY, child.key as string);
                    }}
                    key={`list_item_${child.key}`}
                    ref={elem => (this.liRefs[child.key] = elem)}
                    style={{
                      transform: `translate(0,${style.y}px) `
                    }}
                  >
                    {React.cloneElement(child)}
                  </div>
                )}
              </Motion>
            )
        )}
      </div>
    );
  }
}
