import * as React from "react";
import "./styles/checkbox.less";

interface CheckboxProps {
  checked: boolean;
  text?: string;
  onChange: (chk: boolean) => any;
  onClick?: () => any;
  radio?: boolean;
  disabled?: boolean;
  containerClass?: string;
  children?: any;
}
interface CheckboxState {}

export default class Checkbox extends React.Component<
  CheckboxProps,
  CheckboxState
> {
  constructor(props: CheckboxProps) {
    super(props);
  }
  render() {
    const props = this.props;
    return (
      <div
        className={`checkbox__container ${this.props.containerClass || ""}`}
        onClick={() => this.onClick()}
      >
        <div
          className={`checkbox__container__checkbox ${
            props.checked ? "checkbox__container__checkbox--checked" : ""
          }
            ${props.radio ? "checkbox__container__checkbox--radio" : ""} ${
            this.props.disabled ? "checkbox__container__checkbox--disabled" : ""
          }`}
        >
          {props.checked ? (
            <div className="checkbox__container__checkbox__tick">
              <div className="checkbox__container__checkbox__tick_content" />
            </div>
          ) : (
            <div />
          )}
        </div>
        <div className="checkbox__container__message">
          {props.text} {props.children}
        </div>
      </div>
    );
  }
  onClick() {
    if (!this.props.disabled) {
      this.props.onChange(!this.props.checked);
    }
    if (this.props.onClick) {
      this.props.onClick();
    }
  }
}
