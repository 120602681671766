import { CategoryApi } from '../services/category.api.service';
import firebase from '../firebase';
import { AsyncResult } from '../services/async.result';
import calendar from '../../components/calendar/calendar';
import CalendarCategory from '../../models/calendar.category';
import { CategoryNode, parseCategoryNode } from '../model/category.node';

export class CategoryApiImpl implements CategoryApi {
  addCategory(organizationId: string, name: string, details: string): AsyncResult<any> {
    const addOfCategory = this.ref().push({
      name,
      details,
      created: new Date().toISOString()
    });
    this.refOrga(organizationId, addOfCategory.key).set(addOfCategory.key);
    return addOfCategory;
  }

  addCalendar(categoryId: string, calendarId: string): PromiseLike<any> {
    return this.ref(categoryId)
      .child('calendars')
      .child(calendarId)
      .set(calendarId);
  }
  updateCategory(categoryId: string, name: string, details: string): Promise<any> {
    return this.ref(categoryId).update({
      name,
      details
    });
  }
  updateOrder(catId: string, order: number) {
    return this.ref(catId).update({
      order
    });
  }

  deleteCategory(orgId: string, categoryId: string): Promise<any> {
    this.refOrga(orgId, categoryId).remove();
    return this.ref(categoryId).remove();
  }
  getCategory(categoryId: string): PromiseLike<CalendarCategory> {
    return new Promise<CalendarCategory>((resolve, reject) => {
      const eventRef = this.ref(categoryId);
      eventRef.once('value', (nodeSnapshot: firebase.database.DataSnapshot) => {
        let cat = nodeSnapshot.val() as CategoryNode;

        cat = Object.assign({ key: nodeSnapshot.key }, cat);

        resolve(parseCategoryNode(categoryId, cat));
      });
    });
  }
  refOrga(orgId: string, catId: string) {
    return firebase.database().ref(`Organizations/${orgId}/categories/${catId}`);
  }
  ref(catId?: string) {
    let ref = firebase.database().ref('Categories');
    if (catId) {
      ref = ref.child(catId);
    }
    return ref;
  }
}
