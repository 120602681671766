import * as React from "react";
import {
  injectStripe,
  CardNumberElement,
  CardCVCElement,
  CardExpiryElement,
  ReactStripeElements
} from "react-stripe-elements";
import { AppState } from "../../../state/state";
import { connect } from "react-redux";
import { withLocalization, LocaleProps } from "../../i18n/localizable";
import Organization from "../../../models/organization";
import InputText from "../../input/text";
import Button from "../../input/button";
import "./checkout.less";
import { isEmpty } from "../../../utils/string.utils";
import OverlayConfirm from "../../overlay/overlay.confirm";
import Checkbox from "../../input/checkbox";
import { Dispatch } from "redux";
import { push } from "connected-react-router";
import { Plan, getOrganizationPlan } from "../../../models/plan";
import { Services } from "../../../api/services";
import AddressInput from "../../input/address.input";
import { EventLocationInfo } from "../../../api/model/event.node";
interface Props {
  orgId: string;
  organization: Organization;
  plan: Plan;
  onPaymentSuccess: () => any;
  onPaymentUpdate: () => any;
}
interface State {
  address: string;
  addressInfo: EventLocationInfo;
  checkAdressError: boolean,
  city: string;
  state: string;
  zip: string;
  country: string;
  validCard: boolean;
  validCvc: boolean;
  validExpiry: boolean;
  emptyCard: boolean;
  emptyCvc: boolean;
  emptyExpiry: boolean;
  clickedSave: boolean;
  errorMessage: string;
  useOrganizationAddress: boolean;
  showAddressError: boolean;
  showCityError: boolean;
  showStateError: boolean;
  showZipError: boolean;
  showCardNumberError: boolean;
  showExpError: boolean;
  showCvcError: boolean;
  nameOnCard: string;
  showNameOnCardError: boolean;
}

const generalStyle: React.CSSProperties = {
  fontFamily: "Roboto",
  fontSize: "16px"
};
declare const FUNCTIONS_BASE: string;
class CheckoutForm extends React.Component<
  Props & ReactStripeElements.InjectedStripeProps & LocaleProps,
  State
> {
  constructor(props: any) {
    super(props);
    this.state = {
      address: "",
      addressInfo: null,
      checkAdressError: false,
      city: "",
      state: "",
      zip: "",
      country: "USA",
      validCard: false,
      validCvc: false,
      validExpiry: false,
      emptyCard: true,
      emptyCvc: true,
      emptyExpiry: true,
      clickedSave: false,
      errorMessage: null,
      useOrganizationAddress: false,
      showAddressError: false,
      showCityError: false,
      showStateError: false,
      showZipError: false,
      showCardNumberError: false,
      showExpError: false,
      showCvcError: false,
      nameOnCard : "",
      showNameOnCardError: false
    };
  }
  isValid = () => {
    return (
      this.state.validCard && this.state.validCvc && this.state.validExpiry
    );
  };
  handleSubmit = (ev: React.FormEvent) => {
    ev.preventDefault();
    if(!isEmpty(this.state.nameOnCard) && !isEmpty(this.state.address) && !isEmpty(this.state.city) && !isEmpty(this.state.state) && !isEmpty(this.state.zip) && !this.state.emptyCard && this.state.validCard && !this.state.emptyExpiry && !this.state.emptyCvc) {
      
      this.setState({ clickedSave: true });
      if (!this.isValid()) {
        this.setState({ errorMessage: "Please fill out the required inputs" });
        return;
      }

      this.props.stripe.createSource({ 
        type: "card", 
        owner: { 
          name: this.state.nameOnCard,
          address: {
            line1: this.state.address,
            city: this.state.city,
            state: this.state.state,
            postal_code: this.state.zip,
            country: this.state.country
          } 
        },
       
       })
        .then(source => {
        if (source.error) {
          this.setState({ errorMessage: "Couldn't validate the credit card" });
          return;
        }
        
        console.log("[source]", source);

        return fetch(
          `${FUNCTIONS_BASE}updatePaymentSource?_=${new Date().valueOf()}`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              orgId: this.props.orgId,
              sourceId: source.source.id,
              billingInfo: {
                address: this.state.address,
                city: this.state.city,
                state: this.state.state,
                zip: this.state.zip,
                country: this.state.country,
                name: this.state.nameOnCard,
                last4Digits: source.source.card
                  ? source.source.card.last4 || ""
                  : ""
              }
            })
          }
        )
          .then(result => {
            if (result.status >= 400 && result.status <= 409) {
              this.setState({
                errorMessage: "There was an error processing your credit card. Please verify the information and try again.",
                clickedSave: false
              });
            } 
            else if (result.status > 409) {
              this.setState({
                errorMessage: "We apologize, but your payment could not be processed at this time. Please try again later.",
                clickedSave: false
              });
            } 
            else {
              this.props.onPaymentSuccess();
            }

            return result.text();
          })
          .catch(error => {
            this.setState({
              errorMessage: "We apologize, but your payment could not be processed at this time. Please try again later.",
              clickedSave: false
            });
          });
      });
    }
    else{

    }
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { organization } = this.props;
    if (
      this.state.useOrganizationAddress &&
      !prevState.useOrganizationAddress
    ) {
      
      this.setState({
        address: organization.address,
        state: organization.state,
        city: organization.city,
        country: organization.country,
        zip: organization.zip,
        addressInfo: {
          address: organization.address,
          state: organization.state,
          city: organization.city,
          country: organization.country,
          zip: organization.zip,
          location: null
        }
      });
    }
    else if(!this.state.useOrganizationAddress && this.state.address !== "" && this.state.addressInfo !== null){
      this.setState({
        address: "",
        state: "",
        city: "",
        country: "",
        zip: "",
        addressInfo: null,
        showAddressError: true
      });
    }
    // else if(!this.state.useOrganizationAddress && this.state.address !== "" ) {
    //   this.setState({
    //     ...this.state,
    //     addressInfo: {
    //       ...this.state.addressInfo,
    //       address: this.state.address
    //     } 
    //   });
    // }
  }

  /*
  addressInfo: {
          address: organization.address,
          state: organization.state,
          city: organization.city,
          country: organization.country,
          zip: organization.zip
        }
  */

  updatePaymentInfo = () => {
      let l4d;
      this.props.stripe.createSource({ type: "card" }).then(source => {
        if (!source.error) {
          l4d = source["source"].card.last4;
          if(l4d.length === 4)
            Services.Organizations.UpdateOrganizationBillingInfo(this.props.orgId, this.state.address, this.state.city, this.state.state, this.state.zip, l4d)
            .then(() => {
              this.props.onPaymentUpdate();
          });
        }
      });
  }

  validateAddress = () => {
    let isValid = this.state.addressInfo !== null;
    if(!isValid && !this.state.showAddressError && this.state.checkAdressError)
      this.setState({ showAddressError: false }); 
    return this.state.showAddressError;
  }

  render() {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const { _, organization,  } = this.props;
    let currentFullDate = new Date();
    const currentDate = (months[currentFullDate.getMonth()]) + " " + currentFullDate.getDate() + ", " + currentFullDate.getFullYear();
    if (!organization) return null;
    return (
      <div className="checkout_form">
        <h1>{ this.props.plan.pricePerMonth === 0 ? _("payments.paymentDetails.title") : _("payments.paymentDetails.paymentInfoUpdateTitle") }</h1> 
        <div className="checkout_form__info_text checkout_form--subtitle_text">{_("payments.paymentDetails.subtitleText1")}<a className="signup__link" target="_blank" href="https://timepeace.io/terms">{_("signup.terms")}</a>{" "}{_("payments.paymentDetails.subtitleText2")}</div>
        <form onSubmit={this.handleSubmit}>
          <Checkbox
            text={``}
            checked={this.state.useOrganizationAddress}
            onChange={useOrganizationAddress =>
              {
                this.setState({ 
                  useOrganizationAddress, 
                  showAddressError: false, 
                  showCityError: isEmpty(this.state.city), 
                  showStateError: isEmpty(this.state.state), 
                  showZipError: isEmpty(this.state.zip) })
              }
              
            }
          >
            <div className="checkout_form__checkbox_text checkout_form__checkbox_text--organization-address">
              Use the organization address as the billing address.
            </div>
            <div className="checkout_form__checkbox_address">
              {organization.address} 
              {!isEmpty(organization.address) ? ", " + organization.city : organization.city } 
              {!isEmpty(organization.city) ? ", " + organization.state : organization.state }
              {!isEmpty(organization.city) ? ", " + organization.zip : organization.zip}
            </div>
          </Checkbox>
          {/* <InputText
            placeholder={_("payments.paymentDetails.address")}
            value={this.state.address}
            error={isEmpty(this.state.address)}
            onChange={address => 
              this.setState({ address, showAddressError: isEmpty(address) })
            } 
            onBlur={()=>{
              this.setState({ showAddressError: isEmpty(this.state.address) })
            }}
            onFocus={()=>{
              this.setState({ showAddressError: false })
            }}
            materialPlaceholder={true}
            showErrorPerDefault={this.state.clickedSave}
            disabled={this.state.useOrganizationAddress}
            
          />
          <div className="text_box__error_desc">{ this.state.showAddressError ? _("payments.paymentDetails.addressError") : null }</div> */}
          <AddressInput
          className="payment_address_input"
          useTextArea={false}
          placeHolder={_("signup.organization.address")}
          text={
            this.state.address ? this.state.address : ""
          }
          onChange={addressInfo => {

            let addressInfoArray: string[] = addressInfo ? addressInfo.addressBoxText.split(',') : [];
            
            if( addressInfo && addressInfoArray[0] !== undefined && addressInfoArray[1] !== undefined)
            {
              if(addressInfoArray[0] === addressInfoArray[0].trim())
              {
                let addressArrayFinal = addressInfoArray.splice(2, addressInfoArray.length);
                addressInfo.addressBoxText = addressInfoArray[0]; 
              }
            }
            this.setState({ 
              address: addressInfo != null ? addressInfo.address : "",
              city: addressInfo != null ? addressInfo.city : "",
              state: addressInfo != null ? addressInfo.state : "",
              zip: addressInfo != null ? addressInfo.zip : "", 
              country: addressInfo != null ? addressInfo.country : "",
              showAddressError: addressInfo == null, 
              addressInfo: null
            });
          }}
          
          top="3rem"
          withIcons={true}
          withMaterial={true}
          showErrorPerDefault={this.state.clickedSave}
          error = { this.validateAddress() } 
          
        />
        <div className="text_box__error_desc">{ this.state.showAddressError ? _("payments.paymentDetails.addressError") : null }</div>
          <div className="checkout_location">
            <InputText
              placeholder={_("payments.paymentDetails.city")}
              value={this.state.city}
              error={isEmpty(this.state.city)}
              onChange={city => this.setState({ city, showCityError: isEmpty(city) })}
              onBlur={()=>{
                this.setState({ showCityError: isEmpty(this.state.city) })
              }}
              onFocus={()=>{
                this.setState({ showCityError: false })
              }}

              materialPlaceholder={true}
              showErrorPerDefault={this.state.clickedSave}
              disabled={this.state.useOrganizationAddress}
            />
            
            <InputText
              value={this.state.state}
              error={isEmpty(this.state.state)}
              onBlur={()=>{
                this.setState({ showStateError: isEmpty(this.state.state) })
              }}
              onFocus={()=>{
                this.setState({ showStateError: false })
              }}
              placeholder={_("payments.paymentDetails.state")}
              onChange={state => this.setState({ state, showCityError: isEmpty(state) })}
              materialPlaceholder={true}
              showErrorPerDefault={this.state.clickedSave}
              disabled={this.state.useOrganizationAddress}
            />
            <InputText
              value={this.state.zip}
              
              error={isEmpty(this.state.zip)}
              onBlur={()=>{
                this.setState({ showZipError: isEmpty(this.state.zip) })
              }}
              onFocus={()=>{
                this.setState({ showZipError: false })
              }}
              placeholder={_("payments.paymentDetails.zip")}
              onChange={zip => this.setState({ zip, showCityError: isEmpty(zip) })}
              materialPlaceholder={true}
              showErrorPerDefault={this.state.clickedSave}
              disabled={this.state.useOrganizationAddress}
              
            />
          </div>
          <div className="text_box__error_desc">{ (this.state.showCityError || this.state.showStateError || this.state.showZipError) && !this.state.useOrganizationAddress ? _("payments.paymentDetails.cityStateZipError") : null }</div>
          
          
          
          <div>
          <InputText
              placeholder={_("payments.paymentDetails.nameOnCard")}
              value={this.state.nameOnCard}
              error={isEmpty(this.state.nameOnCard)}
              onChange={nameOnCard => this.setState({ nameOnCard, showNameOnCardError: isEmpty(nameOnCard) })}
               onBlur={()=>{
                 this.setState({ showNameOnCardError: isEmpty(this.state.nameOnCard) })
               }}
               onFocus={()=>{
                 this.setState({ showNameOnCardError: false })
               }}

              materialPlaceholder={true}
              showErrorPerDefault={this.state.clickedSave}
              //disabled={this.state.useOrganizationAddress}
            />
            <div className="text_box__error_desc">{  this.state.showNameOnCardError ? _("payments.paymentDetails.cardNumberError") : null }</div>
            <InputText
              value={this.state.emptyCard ? "" : "a"}
              onChange={() => {}}
              placeholder={_("payments.paymentDetails.cardNumber")}
              materialPlaceholder={true}
              error={!this.state.validCard}
              additionalClassName={this.state.showCardNumberError ? "text_box__input--error":null}
              onBlur={()=>{
                this.setState({ showCardNumberError: this.state.emptyCard || !this.state.validCard  })
              }}
              onFocus={()=>{
                this.setState({ showCardNumberError: false })
              }}
              showErrorPerDefault={this.state.clickedSave}
              getInput={props => {
                return (
                  <CardNumberElement
                    className=""
                    placeholder={props.placeholder}
                    onChange={evt => {
                      this.setState({
                        validCard: !evt.error,
                        emptyCard: evt.empty
                      });
                    }}
                    
                    disabled={props.disabled}
                    onFocus={props.onFocus}
                    onBlur={props.onBlur}
                    style={{
                      base: generalStyle as any
                    }}
                  />
                );
              }}
            />
            <div className="text_box__error_desc">{  this.state.showCardNumberError ? _("payments.paymentDetails.cardNumberError") : null }</div>
            <div className="checkout_card_details">
              <InputText
                value={this.state.emptyExpiry ? "" : "a"}
                onChange={() => {}}
                placeholder={_("payments.paymentDetails.expiration")}
                materialPlaceholder={true}
                error={!this.state.validExpiry}
                additionalClassName={this.state.showExpError ? "text_box__input--error":null}
                onBlur={()=>{
                  this.setState({ showExpError: this.state.emptyExpiry || !this.state.validExpiry  })
                }}
                onFocus={()=>{
                  this.setState({ showExpError: false })
                }}
                showErrorPerDefault={this.state.clickedSave}
                getInput={props => {
                  return (
                    <CardExpiryElement
                    
                      className=""
                      placeholder={props.placeholder}
                      onChange={evt => {
                        this.setState({
                          validExpiry: !evt.error,
                          emptyExpiry: evt.empty
                        });
                      }}
                      disabled={props.disabled}
                      onFocus={props.onFocus}
                      onBlur={props.onBlur}
                      style={{
                        base: generalStyle as any
                      }}
                    />
                  );
                }}
              />
              <InputText
                value={this.state.emptyCvc ? "" : "a"}
                onChange={() => {}}
                placeholder={_("payments.paymentDetails.cvc")}
                materialPlaceholder={true}
                error={!this.state.validCvc}
                additionalClassName={this.state.showCvcError ? "text_box__input--error":null}
                onBlur={()=>{
                  this.setState({ showCvcError: this.state.emptyCvc || !this.state.validCvc  })
                }}
                onFocus={()=>{
                  this.setState({ showCvcError: false })
                }}
                showErrorPerDefault={this.state.clickedSave}
                getInput={props => {
                  return (
                    <CardCVCElement
                      className=""
                      placeholder={props.placeholder}
                      onChange={evt => {
                        this.setState({
                          validCvc: !evt.error,
                          emptyCvc: evt.empty
                        });
                      }}
                      disabled={props.disabled}
                      onFocus={props.onFocus}
                      onBlur={props.onBlur}
                      style={{
                        base: generalStyle as any
                      }}
                    />
                  );
                }}
              />
            </div>
            <div className="text_box__error_desc">{  this.state.showExpError || this.state.showCvcError ? _("payments.paymentDetails.cardExpCvcError") : null }</div>
          </div>
          <OverlayConfirm
            alert={true}
            show={!isEmpty(this.state.errorMessage)}
            onYes={() => {
              this.setState({ errorMessage: "" });
            }}
            message={this.state.errorMessage}
          />

          {this.props.plan.pricePerMonth === 0 ? <div className="checkout_form__info_text checkout_form--footer_text">{_("payments.paymentDetails.footerText", {amount: "10.00", chargeDate: currentDate})}</div> : <br/> }
          
          {this.props.plan.pricePerMonth === 0 ? 
          (<Button
            disabled={isEmpty(this.state.nameOnCard) || isEmpty(this.state.address) || isEmpty(this.state.city) || isEmpty(this.state.state) || isEmpty(this.state.zip) || this.state.emptyCard || !this.state.validCard || this.state.emptyExpiry || this.state.emptyCvc || this.state.clickedSave }
            additionClassName="checkout_form__button checkout_form__button_checkout"
            text={ this.state.clickedSave ? _("payments.paymentDetails.payBtnProcessing") : _("payments.paymentDetails.payBtn", { amount: "10.00" })}
          />)
          : null
          }
        </form>
        {this.props.plan.pricePerMonth === 0 ? 
          null
          :
          (<Button
            onClick={ this.updatePaymentInfo } 
            disabled={ isEmpty(this.state.nameOnCard) || isEmpty(this.state.address) || isEmpty(this.state.city) || isEmpty(this.state.state) || isEmpty(this.state.zip) || this.state.emptyCard || this.state.emptyExpiry || !this.state.validCard  || this.state.emptyCvc}
            additionClassName="checkout_form__button"
            text={_("payments.paymentDetails.updateBillingInfo")}
          />)
          }
      </div>
    );
  }
}

export default injectStripe(
  connect(
    (state: AppState) => ({
      orgId: state.organization.selectedOrganization
        ? state.organization.selectedOrganization.id
        : null,
      organization: state.organization.selectedOrganization,
      plan: getOrganizationPlan(state.organization.selectedOrganization)
    }),
    (dispatch: Dispatch) => ({
      onPaymentSuccess: () => {
        dispatch(push("/succesful-payment"));
      },
      onPaymentUpdate: () => {
        dispatch(push("/account/plan"));
      }
    })
  )(withLocalization()(CheckoutForm as any) as any)
);
