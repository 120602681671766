import * as React from 'react';

import './accordion.less';

export interface AccordionViewProps {
  children?: any;
  onOpenChange?: (opened: boolean) => any;
  onOptionClick?: () => any;
  title: string;
  withEdit?: boolean;
  onEditClick?: () => any;
  open?: boolean;
}

export interface AccordionViewState {
  open: boolean;
}
export default class AccordionView extends React.Component<AccordionViewProps, AccordionViewState> {
  constructor(props: AccordionViewProps) {
    super(props);
    this.state = { open: props.open || false };
  }
  render() {
    const props = this.props;
    return (
      <div className={`accordion ${this.state.open ? 'accordion--opened' : 'accordion--closed'}`}>
        <div
          className={`accordion__title ${this.state.open ? 'accordion__title--opened' : 'accordion__title--closed'}`}
          onMouseDown={e => {
            const target = e.target as HTMLDivElement;
            if (!target.className || target.className.indexOf('accordion__title__option') < 0) {
              e.preventDefault();
              e.stopPropagation();
              this.setState({ open: !this.state.open });
            }
          }}
        >
          {this.props.onOptionClick && <div className="accordion__title__option" />}
          <div
            className="accordion__title__text"
            onMouseDown={e => {
              e.stopPropagation();
              this.props.onOptionClick();
            }}
          >
            {props.title}
          </div>
          {this.props.withEdit && (
            <div onClick={this.props.onEditClick} className="accordion__title__edit">
              Edit
            </div>
          )}
          <div
            className={`accordion__title__open ${
              this.state.open ? 'accordion__title__open--opened' : 'accordion__title__open--closed'
            }`}
          />
        </div>
        <div
          className={`accordion__content ${
            this.state.open ? 'accordion__content--opened' : 'accordion__content--closed'
          }`}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}
