import { handleActions, Action } from 'redux-actions';
import { Actions } from '../state/actions';
import Calendar from '../models/calendar';
import { CalendarNode, parseCalendarNode } from '../api/model/calendar.node';
import Event from '../models/event';
import Category from '../models/calendar.category';
import CalendarCategory from '../models/calendar.category';
import { mapObj } from '../utils/object.utils';

export default handleActions<{ [id: string]: Calendar }, any>(
  {
    [Actions.CALENDAR.CALENDAR_ADDED]: (state, action: Action<{ calendarId: string; category: CalendarCategory }>) => {
      const newCal = new Calendar();
      newCal.id = action.payload.calendarId;
      newCal.categories = [action.payload.category];
      if (state[newCal.id]) {
        return state;
      }
      return { ...state, [newCal.id]: newCal };
    },
    [Actions.CALENDAR.CALENDAR_REMOVED]: (state, action: Action<{ calendarId: string }>) => {
      const newState = { ...state };
      delete newState[action.payload.calendarId];
      return newState;
      //return state.filter(c => c.id !== action.payload.calendarId);
    },
    [Actions.CALENDAR.CALENDAR_INFO_UPDATED]: (state, action: Action<{ calendarInfo: CalendarNode }>) => {
      const newCalendar = parseCalendarNode(action.payload.calendarInfo);
      const newState = { ...state };
      if (newState[newCalendar.id]) {
        const c = newState[newCalendar.id];
        newCalendar.events = c.events;
        newCalendar.categories = c.categories || [];
        newCalendar.recurringEvents = c.recurringEvents || [];
      }
      newState[newCalendar.id] = newCalendar;
      return newState;
    },
    [Actions.EVENT.SYNCH]: (state, action: Action<{ calendarId: string; events: string[] }>) => {
      const { calendarId, events } = action.payload;
      const c = state[calendarId];
      const newCalendar = c.clone();
      const oldEvents = c.indexAllEvents();
      newCalendar.events = (events || []).map(eId => {
        if (oldEvents[eId]) {
          return oldEvents[eId];
        } else {
          const e = new Event();
          e.id = eId;
          return e;
        }
      });

      return { ...state, [calendarId]: newCalendar };
    },
    [Actions.EVENT.SYNCH_RECURRING]: (state, action: Action<{ calendarId: string; events: string[] }>) => {
      const { calendarId, events } = action.payload;
      const c = state[calendarId];
      const oldEvents = c.indexAllEvents();
      const newCalendar = c.clone();

      newCalendar.recurringEvents = (events || []).map(eId => {
        if (oldEvents[eId]) {
          return oldEvents[eId];
        } else {
          const e = new Event();
          e.id = eId;
          return e;
        }
      });
      return { ...state, [calendarId]: newCalendar };
    },

    [Actions.CATEGORIES.SYNCH_CALENDAR]: (state, action: Action<{ calendarId: string; categories: Category[] }>) => {
      const { calendarId, categories } = action.payload;
      const c = state[calendarId];
      const newCalendar = c.clone();
      newCalendar.categories = categories;
      return { ...state, [calendarId]: newCalendar };
    },
    [Actions.CALENDAR.REORDER]: (state, action: Action<{ newOrder: string[] }>) => {
      const { newOrder } = action.payload;
      const newState = { ...state };
      newOrder.forEach((calId, idx) => {
        const c = state[calId];
        const newCalendar = c.clone();
        newCalendar.order = idx + 1;
        newState[calId] = newCalendar;
      });
      return newState;
    },
    [Actions.ORGANIZATION.SELECT]: (state, action: Action<{ organizationId: string }>) => {
      return {};
    },
    [Actions.AUTH.LOGOUT_SUCCESS]: (state, action: Action<{ organizationId: string }>) => {
      return {};
    }
  },
  {}
);
