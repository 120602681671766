import * as React from 'react';
import { localizable, LocaleProps, MessageFunction } from '../i18n/localizable';
import RoundedCheckbox from './rounded.checkbox';
import './styles/week-day-selector.less';
const getDayLetter = (key: string, _: MessageFunction): string => {
  return _(key)[0].toUpperCase();
};

function WeekSelector(
  props: { selected: number[]; startOfWeek?: number; onChange: (selected: number[]) => any } & LocaleProps
) {
  const weekDayLetter: string[] = [];
  const { selected, _ } = props;
  const startOfWeek = props.startOfWeek || 0;
  //START IS SUNDAY

  weekDayLetter.push(getDayLetter('sunday', _));
  weekDayLetter.push(getDayLetter('monday', _));
  weekDayLetter.push(getDayLetter('tuesday', _));
  weekDayLetter.push(getDayLetter('wednesday', _));
  weekDayLetter.push(getDayLetter('thursday', _));
  weekDayLetter.push(getDayLetter('friday', _));
  weekDayLetter.push(getDayLetter('saturday', _));

  const weekDaysSelectors: any[] = [];
  for (let i = 0; i < 7; i++) {
    const dayOfCheckbox = (i + startOfWeek) % weekDayLetter.length;
    weekDaysSelectors.push(
      <RoundedCheckbox
        square={true}
        text={weekDayLetter[dayOfCheckbox]}
        checked={props.selected.some(weekDay => weekDay === dayOfCheckbox)}
        onChange={checked => {
          if (!checked) {
            props.onChange(props.selected.filter(weekDay => weekDay !== dayOfCheckbox));
          } else {
            props.onChange([...props.selected, dayOfCheckbox]);
          }
        }}
      />
    );
  }
  return <div className="week_day_selector">{weekDaysSelectors}</div>;
}

export default localizable(WeekSelector, 'days');
