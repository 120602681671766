import assert from 'assert';
import * as moment from 'moment-timezone';
//import { assertItems, assertStrings } from '../utils/assertUtils';

//import * as userUtils from '../utils/userUtils';
//import { ISubscriptions, Subscriptions } from './Subscription';
//import { ISettings, SettingsTypes, SettingsFirebaseChildren } from './Settings';
//import { IEmail } from './Emails';
//import { ProviderType } from '../config/enums';

export interface IEmail {
  email: string;
  subscribed: boolean;
  provider: number;
}
export interface UserInfo {
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
  passConfirmation?: string;
}

export interface CalendarSubscription {
  id: string;
  orgId: string;
  categoryId: string;
  calendarId?: string;
}
export interface UserSettings {
  hiddenCalendars: { [id: string]: boolean | moment.Moment };
  hiddenCalendarsHistory: { [id: string]: { start: moment.Moment; end: moment.Moment }[] };
  externalSyncInactive: { start: moment.Moment; end?: moment.Moment }[];
  defaultDaysView: string;
  defaultOrganizationId: string;
}
export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  createdAt: string;
  lastLogin: string;
  emails?: IEmail[];
  subscriptions: CalendarSubscription[];
  admin: boolean;
  settings?: UserSettings;
  userEmail?:string;
  mainEmail?:string;
  // settings: ISettings;
  /*
  readonly appleCalendars: { [id: string]: userUtils.LinkedAppleCalendar };
  readonly subscriptions: ISubscriptions;
  readonly emails: IEmail[];
  readonly subscribedEmails: IEmail[];
  tutorialComplete: boolean;
  subscribeToCalendar(calendarID: string, categoryID: string, organizationID: string): Promise<void>;
  unsubscribeFromCalendar(
    calendarID: string,
    categoryID: string,
    organizationID: string,
    organizationStore: IOrganizationStore
  ): Promise<void>;
  subscribeToCalendarInCategory(calendarID: string, categoryID: string, organizationID: string): Promise<void>;
  unsubscribeFromCalendarInCategory(
    calendarID: string,
    categoryID: string,
    organizationID: string,
    organizationStore: IOrganizationStore
  ): Promise<void>;
  subscribeToCategory(categoryID: string, organizationID: string): Promise<void>;
  unsubscribeFromCategory(categoryID: string, organizationID: string): Promise<void>;
  checkCategorySubscription(categoryID: string, organizationID: string): boolean;
  checkCalendarSubscription(calendarID: string, categoryID: string, organizationID: string): boolean;
  addNewCalendarEmail(): Promise<void>;
  deleteCalendarEmail(email: IEmail): void;
  updateEmailSubscription(subscribe: boolean, emailID: string): void;
  addAppleCalendar(calendarID: string, name: string): Promise<void>;
  deleteAppleCalendar(calendarID: string): Promise<void>;
  updateAppleCalendarSubscriptionState(calendarID: string, isSubscribed: boolean): Promise<void>;
  updateUserSettings(settings: SettingsTypes, child: string): void;
  hideCalendar(calendarID: string): void;
  showCalendar(calendarID: string): void;
  hideEvent(eventID: string): void;
  toggleNotifications(value: boolean): void;
  completeTutorial(): void;
  updateLinkedAccount(account: IEmail | userUtils.LinkedAppleCalendar): Promise<void>;
  */
}

const className = 'User';

export class User implements IUser {
  private _tutorialComplete: boolean;
  readonly id: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly createdAt: string;
  readonly lastLogin: string;
  emails?: IEmail[];
  subscriptions: CalendarSubscription[] = [];
  admin = false;
  settings?: UserSettings;
  userEmail?: string;
  mainEmail?: string;

  constructor(
    id: string,
    tutorialComplete: boolean,
    firstName: string,
    lastName: string,
    //emails: IEmail[],
    createdAt: string,
    lastLogin: string,
    mainEmail: string,
    settings: any
    // appleCalendars: { [id: string]: userUtils.LinkedAppleCalendar },
    //settings: ISettings
    //subscriptions: ISubscriptions
  ) {
    /*
    assertItems(
      { id, tutorialComplete, firstName, lastName, createdAt, lastLogin }, //appleCalendars
      className,
      'constructor'
    );

    assertStrings({ id, firstName, lastName, createdAt, lastLogin }, className, 'constructor');
*/
    this.id = id;
    this._tutorialComplete = tutorialComplete;
    this.firstName = firstName;
    this.lastName = lastName;
    this.createdAt = createdAt || moment().toISOString();
    this.lastLogin = lastLogin;
    this.userEmail = this.emails != undefined ? this.emails.length > 0 ? this.emails[0].email : "" : "";
    this.mainEmail = mainEmail;
    this.settings = settings;
    //this._subscriptions = subscriptions;
    //this._appleCalendars = new ObservableMap(appleCalendars);

    //this._emailProvider = this.createEmailProvider(emails);
  }
  isCalendarHidden(calendarId: string) {
    // console.log('hiddem',calendarId);
    return this.settings && this.settings.hiddenCalendars && this.settings.hiddenCalendars[calendarId];
  }

  static alphabeticSort(u1: User, u2: User) {
    const lastNameLowerU1 = (u1.lastName || '').toLowerCase();
    const lastNameLowerU2 = (u2.lastName || '').toLowerCase();
    if (!lastNameLowerU1 && !lastNameLowerU2) {
      return 0;
    } else if (!lastNameLowerU1) {
      return 1;
    } else if (!lastNameLowerU2) {
      return -1;
    } else {
      return lastNameLowerU1 > lastNameLowerU2 ? 1 : lastNameLowerU1 == lastNameLowerU2 ? 0 : -1;
    }
  }
}
