import * as React from 'react';
import './styles/rounded-checkbox.less';

export default function RoundedCheckBox(props: {
  checked: boolean;
  onChange: (checked: boolean) => any;
  text: string;
  square?: boolean;
}) {
  const { checked, onChange, text, square } = props;
  return (
    <div
      className={`rounded_checkbox rounded_checkbox--${checked ? 'checked' : 'unchecked'} ${
        square ? 'rounded_checkbox--square' : ''
      }`}
      onClick={e => {
        e.preventDefault();
        onChange(!checked);
      }}
    >
      <div className="rounded_checkbox__text">{text}</div>
    </div>
  );
}
