import { compose, withState, lifecycle, setDisplayName } from 'recompose';

export function withLoad<T>(name: string, load: (props?: any) => Promise<T>, defaultVal: T = null) {
  const camelCaseName = `${name[0].toUpperCase()}${name.substr(1)}`;
  const setName = `set${camelCaseName}`;
  const setLoadingName = `setLoading${camelCaseName}`;

  return compose(
    setDisplayName('withLoad'),
    withState(name, setName, (props: any) => props[`initial${camelCaseName}`] || defaultVal),
    withState(`loading${camelCaseName}`, setLoadingName, true),
    lifecycle({
      componentDidMount() {
        const props: any = this.props;
        const setFunc = props[setName] as (v: T) => any;
        const setLoading = props[setLoadingName] as (v: boolean) => any;
        load(this.props)
          .then(val => {
            setFunc(val);
            setLoading(false);
          })
          .catch(err => {
            setFunc(null);
            setLoading(false);
          });
      }
    })
  );
}
