import Calendar from "./calendar";

import * as moment from "moment-timezone";

import RecurringEvaluator from "./recurring.evaluator";
import {
  EventRecursionInfo,
  EventRecursionInfoJson
} from "../api/model/recursion.info";
import { isSameDay } from "../utils/date.utils";

export default class Event {
  id: string;
  calendarIds: string[];
  address: string;
  address2: string;
  location?: string;
  apartmentSuite: string;
  city: string;
  createsUpdateNotification: boolean;
  details: string;
  date: moment.Moment;
  startDate: moment.Moment;
  endDate: moment.Moment;
  isAllDay: boolean;
  name: string;
  state: string;
  zip: string;
  lat?: number;
  lng?: number;
  addressBoxText?: string;
  country: string;
  timezone: string;
  exists?: boolean;
  isRecurring: boolean;
  isOnline: boolean;
  recursionInfo: EventRecursionInfo;
  recurringMoment: RecurringEvaluator;
  constructor() {}
  createRecurringMoment() {
    this.recurringMoment = new RecurringEvaluator(
      moment(this.startDate),
      this.recursionInfo
    );
  }
  getRecursionInfoJson(): EventRecursionInfoJson {
    const info = this.recursionInfo;
    return {
      afterType: info.afterType,
      endsAfter: info.endsAfter,
      endsOn: info.endsOn ? info.endsOn.toISOString() : null,
      everyDuration: info.everyDuration,
      removeDays: info.removeDays
        ? info.removeDays.map(v => v.toISOString())
        : null,
      repeatsEvery: info.repeatsEvery,
      repeatsOn: info.repeatsOn
    };
  }
  isBetweenDates(start: moment.Moment, end: moment.Moment) {
    if (this.isRecurring) {
      const match =
        this.recurringMoment.getMatchingDaysBetween(start, end, true).length >
        0;
      return match;
    }
    return (
      start.isSameOrBefore(this.startDate) && end.isSameOrAfter(this.startDate)
    );
  }
  clone(upd?: (e: Event) => any) {
    const cloned = new Event();
    Object.assign(cloned, this);
    if (upd) {
      upd(cloned);
    }
    return cloned;
  }
  getRecurringStartDate() {
    return this.isRecurring
      ? this.recurringMoment.getStartRepeatDate()
      : this.startDate;
  }

  static cronologicalSort(e1: Event, e2: Event) {
    const nameSort = e1.name > e2.name ? 1 : -1;
    const d1 = e1.date || e1.startDate;
    const d2 = e2.date || e2.startDate;
    if (!isSameDay(d1, d2)) {
      return d1.isAfter(d2) ? 1 : -1;
    } else if (e1.isAllDay && e2.isAllDay) {
      return nameSort;
    } else if (e1.isAllDay || e2.isAllDay) {
      return e1.isAllDay ? -1 : 1;
    } else {
      return d1.diff(d2, "minutes") || nameSort;
    }
  }
}
