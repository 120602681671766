import * as React from "react";
import { LocaleProps, localizable } from "../../i18n/localizable";
import Checkbox from "../../input/checkbox";
import { connect } from "react-redux";
import { AppState } from "../../../state/state";
const Tick = (props: { checked: boolean }) => {
  return props.checked ? (
    <span className="getting_started__check" />
  ) : (
    <span className="getting_started__not_done" />
  );
};

export default connect((state: AppState) => ({
  categoriesRegistered: state.allCategories && state.allCategories.length > 0,
  subCategoriesRegistered: Object.values(state.calendars).some(c => !!c),
  hasEvents: Object.keys(state.events || {}).length > 0,
  clickedSave:
    state.organization.selectedOrganization &&
    state.organization.selectedOrganization.clickedSave,
  isLive:
    state.organization.selectedOrganization &&
    !state.organization.selectedOrganization.needsToFinishRegistration
}))(
  localizable(
    (
      props: LocaleProps & {
        categoriesRegistered: boolean;
        hasEvents: boolean;
        clickedSave: boolean;
        subCategoriesRegistered: boolean;
        isLive: boolean;
      }
    ) => {
      const { _ } = props;
      return (
        <div className="getting_started">
          <div className="getting_started__title">{_("tips.title")}</div>
          <div className="getting_started__subtitle">{_("tips.subtitle")}</div>
          <div className="getting_started__list">
            <ol>
              <li>
                <Tick
                  checked={
                    props.categoriesRegistered && props.subCategoriesRegistered
                  }
                />{" "}
                {_("tips.list.1")}
              </li>
              <li>
                <Tick checked={props.hasEvents} /> {_("tips.list.2")}
              </li>
              <li>
                <Tick checked={props.clickedSave} /> {_("tips.list.3")}
              </li>
              <li>
                <Tick checked={props.isLive} />
                {_("tips.list.4")}
              </li>
              <li>{_("tips.list.5")}</li>
            </ol>
          </div>
          <div className="getting_started__footer">
            <a href="http://www.timepeace.io/tips">{_("tips.footer")}</a>
          </div>
        </div>
      );
    }
  )
);
