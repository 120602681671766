import { IUser } from "../models/User";
import { RouterState } from "connected-react-router";
import Calendar from "../models/calendar";
import CalendarCategory from "../models/calendar.category";
import Organization from "../models/organization";
import Event from "../models/event";
import { Moment } from "moment";
import OrganizationRequest from "../models/organization.request";

export interface AppState {
  currentUser: CurrentUserState;
  router: RouterState;
  loading: LoaderState;
  calendars: { [id: string]: Calendar };
  allCategories: CalendarCategory[];
  organization: OrganizationState;
  ui: UIState;
  events: EventState;
}

export type EventState = { [id: string]: Event };

export enum LoginErrorState {
  GENERAL = "GENERAL",
  USER_NOT_EXISTS = "USER_NOT_EXISTS",
  INVALID_PASSWORD = "INVALID_PASSWORD"
}
export interface LoadingTarget {
  key: string;
  start: number;
}
export interface LoaderState {
  targets: LoadingTarget[];
}
export interface CurrentUserState {
  user: IUser;
  pendingAuthRetrival: boolean;
  loginError?: LoginErrorState;
  resetPasswordStatus?: {
    pending: boolean;
    email: string;
    error?: LoginErrorState;
  };
}
export interface OrganizationState {
  organizations: Organization[];
  selectedOrganization: Organization;
  requests: OrganizationRequest[];
  organizationLoaded: boolean;
}

export enum CalendarDatesView {
  Monthly = "monthly",
  Weekly = "weekly",
  Schedule = "schedule",
  monthly = "monthly",
  weekly = "weekly",
  schedule = "schedule"
}
export interface UIState {
  calendarDatesView: CalendarDatesView;
  syncDate: Moment;
  currentDate: Moment;
  timeZones: TimeZone[];
  selectedCalendars: { [name: string]: boolean };
  selectedCategories: { [name: string]: boolean };
  smallView: boolean;
  screenSize: number;
  showMenu: boolean;
}
export interface TimeZone {
  name: string;
  offsetHours: number;
  offsetMinutes: number;
}
