import * as React from 'react';
import { Component } from 'react';
import { AppState } from "../../../state/state";
import { connect } from "react-redux";
import Organization from "../../../models/organization";
import "./plugin.export.less";
import createMemoryHistory from 'react-router';
import Button from '../../input/button';
import Loader from '../../loader/loader';
import { NEG_ONE } from 'long';
import { push } from "connected-react-router";



export interface PluginExportProps {
    organization: Organization;
    goToPlans?: () => any;
}

const urlPath = window.location.protocol + "//" + window.location.host;

class PluginExport extends React.Component<PluginExportProps> {
    area:HTMLTextAreaElement

    state = {
        loading: true,
        loaded: false
    }

    setAreaHeight = () => {
        this.setState({
            loading: false
        })
        this.area.style.height = "5px";
        this.area.style.height = (this.area.scrollHeight)+"px";
        
    }

    copy = () => {
        this.area.focus();
        this.area.select();
        const successful = document.execCommand('copy');
        if (successful) {
          alert('Text copied to clipboard');
        }
    };

    componentDidMount() {
        setTimeout(()=> {
            this.setState({
                loaded: true
            });
            this.setAreaHeight()
        } , 1000)
    }

    render() { 
        
        return this.props.organization &&  ( 
            
            <div className="plugin_menu_cont">
                <h2 className="plugin_menu_title">Website Calendar</h2>
                <div className="plugin_menu_label">Copy the code below to make your TimePeace events also available on your website. Check out the Preview link to see your updated web calendar included with your paid subscription.</div>
                <div className="plugin_menu_label">Not a paid subscriber? Use the code on your website to direct your users to your TimePeace app calendar. Click 
                <Button blueStyle={ true } text={"Upgrade Plan"} link={true} onClick={ this.props.goToPlans } />
                to learn more about Time Peace premium plans.</div>
                <div className="plugin_menu_code_cont"> 
                { this.state.loading && !this.state.loaded ? (
                    <Loader show={this.state.loading} inline={true} />) :
                
                    <textarea ref={area => { (this.area = area)}} className="plugin_menu_code_area" readOnly value={  
                        "<script id='timepeace_plugin' src='" + urlPath + (urlPath.includes("timepeace.io") ? "" : "/dist") + "/calendarWidget.js' data-host='" + urlPath + "'  data-id='" + this.props.organization.id + "'></script>"
                    } />}

                </div>
                    {
                        !this.state.loading ? 
                            <div>
                                <Button additionClassName="plugin_menu_button_copy" text={'Copy to clipboard'} onClick={this.copy} />  
                                <Button blueStyle={true} additionClassName="plugin_menu_button_preview" link={true} text={'Preview'} onClick={ ()=> window.open(urlPath + '/plugin.html#' + this.props.organization.id)} />  
                            </div>
                         : null
                    }
                    
                
            </div>
         );
    }
}
 
export default connect((state: AppState) => (  {
    organization: state.organization.selectedOrganization,
    user: state.currentUser.user,
}),
dispatch => ({
    goToPlans() {
      dispatch(push("/plans"));
    }
  })) (PluginExport);