import * as moment from "moment-timezone";
import * as React from "react";
import "react-progress-2/main.css";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import Spinner from "react-spinner-material";
import DefaultMessages from "../i18n/messages";
import { IUser } from "../models/User";
import { AppState } from "../state/state";
import "../vendor/bootstrap-grid.min.css";
import LanguageContext from "./i18n/language.context";
import { getMessage } from "./i18n/provider.message";
import MainPageComponent from "./layout/general.page.layout";
import LoginPage from "./pages/login/index";
import PaymentInfo from "./pages/payments/payment.info";
import Plans from "./pages/payments/plans";
import SuccesfulPayment from "./pages/payments/succesful.payment";
import SuccessDowngrade from "./pages/payments/success.downgrade";
import "./styles/general.less";
import "./styles/reset.less";

const Progress = require("react-progress-2").default;

moment.tz.setDefault(moment.tz.guess(true));

const PrivateRoute = connect((state: AppState) => ({
  user: state.currentUser.user,
  pending: state.currentUser.pendingAuthRetrival
}))(
  ({
    component,
    user,
    pending,
    ...rest
  }: {
    component: React.ComponentType<any>;
    user: IUser;
    exact?: boolean;
    path: string;
    pending: boolean;
  }) => (
    <Route
      {...rest}
      render={props => {
        const Component = component;
        return pending ? (
          <div className="page_loader_container">
            <Spinner
              size={120}
              spinnerColor={"#497AF2"}
              spinnerWidth={2}
              visible={true}
            />
          </div>
        ) : user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        );
      }}
    />
  )
);

export default function() {
  return (
    <div>
      <LanguageContext.Provider
        value={{
          _: getMessage(DefaultMessages),
          messageReady: true
        }}
      >
        <Progress.Component />
        <Switch>
          <Route path="/login" key="login" component={LoginPage} />
          <PrivateRoute path="/plans" key="" component={Plans} />
          <PrivateRoute path="/payment" component={PaymentInfo} />
          <PrivateRoute
            path="/succesful-payment"
            component={SuccesfulPayment}
          />
          <PrivateRoute
            path="/succesful-downgrade"
            component={SuccessDowngrade}
          />

          <PrivateRoute path="/" key="index" component={MainPageComponent} />
        </Switch>
      </LanguageContext.Provider>
    </div>
  );
}
