import { CategoryNode } from "./category.node";
import Organization from "../../models/organization";

export interface PersonalContactNode {
  email: string;
  name: string;
  title: string;
  phone: string;
}
export interface OrganizationNode {
  id: string;
  name: string;

  address: string;
  address2: string;
  apartmentSuite: string;
  categories: { [name: string]: string };
  city: string;
  contacts: { [name: string]: string };
  clickedSave?: boolean;
  description: string;
  icon: string;
  links: { [name: string]: string };
  notes: string;
  personalContacts: {
    personalContacts: PersonalContactNode[];
  };
  photo: string;
  state: string;
  type: string;

  zip: string;
  createdBy: string;
  needsToFinishRegistration?: boolean;
}

export function parseOrganizationNode(id: string, node: OrganizationNode) {
  if (!node) {
    return null;
  }
  const organization = new Organization();
  Object.assign(organization, node);

  organization.id = id;
  organization.personalContacts = node.personalContacts
    ? node.personalContacts.personalContacts
    : null;
  organization.clickedSave = !!node.clickedSave;
  return organization;
}
export function toOrganizationNode(organization: Organization) {
  const organizationNode: Partial<OrganizationNode> = {};
  Object.assign(organizationNode, organization);
  if (organization.personalContacts) {
    organizationNode.personalContacts = {
      personalContacts: organization.personalContacts
    };
  } else {
    organizationNode.personalContacts = { personalContacts: [] };
  }
  return organizationNode;
}
