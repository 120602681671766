import * as React from "react";
import { compose, withState } from "recompose";
import { LocaleProps, withLocalization } from "../../i18n/localizable";
import PaymentsLayout from "./payments.layout";
import { connect } from "react-redux";
import { AppState } from "../../../state/state";
import { getOrganizationPlan, Plan, getAllPlans } from "../../../models/plan";
import PlanCard from "../../cards/plan-card";
import Organization from "../../../models/organization";
import { push } from "connected-react-router";
import "./plans.less";
import { Dispatch } from "redux";
import Downgrade from "./downgrade";
export default compose<
  {
    currentPlan: Plan;
    organization: Organization;
    continueWithPlan: () => any;
    choosePlan: (p: Plan) => any;
    showDowngradeDialog: boolean;
    setShowDowngradeDialog: (v: boolean) => any;
    successDowngrade: () => any;
  } & LocaleProps,
  any
>(
  withState("showDowngradeDialog", "setShowDowngradeDialog", false),
  connect(
    (state: AppState) => ({
      currentPlan: getOrganizationPlan(state.organization.selectedOrganization),
      allPlans: [] as Plan[],
      organization: state.organization.selectedOrganization
    }),
    (
      dispatch: Dispatch,
      otherProps: { setShowDowngradeDialog: (v: boolean) => any }
    ) => ({
      continueWithPlan() {
        dispatch(push("/account/plan"));
      },
      choosePlan(p: Plan) {
        if (p.pricePerMonth > 0) {
          dispatch(push("/payment"));
        } else {
          otherProps.setShowDowngradeDialog(true);
        }
      },
      successDowngrade() {
        dispatch(push("/succesful-downgrade"));
      }
    })
  ),

  withLocalization()
)(props => {
  const { _, currentPlan, organization, continueWithPlan, choosePlan } = props;
  const isCurrentPlanPaid = currentPlan.pricePerMonth > 0;
  if (!organization) {
    return null;
  }
  return (
    <PaymentsLayout>
      <h1>
        {isCurrentPlanPaid
          ? _("payments.plan.paid.title")
          : _("payments.plan.free.title")}
      </h1>
      <div className="payment_plans__subtitle">
        {isCurrentPlanPaid
          ? _("payments.plan.paid.subtitle")
          : _("payments.plan.free.subtitle")}
      </div>
      <div className="payment_plans__plans">
        {getAllPlans().map(p => (
          <PlanCard
            plan={p}
            selectedPlan={currentPlan}
            choosePlan={() => {
              choosePlan(p);
            }}
            continueWithPlan={continueWithPlan}
          />
        ))}
      </div>
      <Downgrade
        show={props.showDowngradeDialog}
        back={() => props.setShowDowngradeDialog(false)}
        onSuccess={props.successDowngrade}
      />
    </PaymentsLayout>
  );
});
