import * as React from "react";
const Spinner = require("react-spinkit");
import "./loader.less";

export default function(props: {
  show: boolean;
  inline?: boolean;
  children?: any;
  fullPage?: boolean;
}) {
  const { show, inline, children } = props;
  if (!inline) {
    return (
      <div
        className={`loader loader--${show ? "visible" : "hidden"} ${
          props.fullPage ? "loader--full-page" : ""
        }`}
      >
        <Spinner name="circle" fadeIn={"none"} className="loader__circle" />
        {children}
      </div>
    );
  }
  return (
    <div
      className={`inline-loader inline-loader--${show ? "visible" : "hidden"}`}
    >
      {children}
      <Spinner
        name="three-bounce"
        fadeIn={"none"}
        className="inline-loader__bounce"
      />
    </div>
  );
}
