import { AppState, EventState } from './state';
import Calendar from '../models/calendar';

import * as moment from 'moment-timezone';
import { isBetweenDays, beforeOrSameDay } from '../utils/date.utils';
import Event from '../models/event';
export function getSelectedOrganization(state: AppState) {
  return state.organization.selectedOrganization;
}
export function getOrganizationId(state: AppState) {
  return getSelectedOrganization(state) ? getSelectedOrganization(state).id : null;
}

export function getCategoryOfCalendar(calendarId: string, state: AppState) {
  return state.allCategories.find(cat => cat.calendars && !!cat.calendars[calendarId]);
}

export function computeDiff<T>(
  listA: T[],
  listB: T[],
  equal: (a: T, b: T) => boolean,
  changedPredicate: (a: T, b: T) => boolean
): {
  added: T[];
  removed: T[];
  changed: { old: T; newElem: T }[];
} {
  const added: T[] = [];
  const removed: T[] = [];
  const changed: { old: T; newElem: T }[] = [];

  listA.forEach(a => {
    let found = false;
    listB.forEach(b => {
      if (equal(a, b)) {
        found = true;
        if (changedPredicate(a, b)) {
          changed.push({ old: a, newElem: b });
        }
      }
    });
    if (!found) {
      removed.push(a);
    }
  });
  listB.forEach(b => {
    if (!listA.find(a => equal(a, b))) {
      added.push(b);
    }
  });
  return {
    added,
    changed,
    removed
  };
}
interface EventsInDatesResult {
  [calId: string]: { [dateKey: string]: Event[] };
}

function addEventToDictionary(e: Event, eventDate: moment.Moment, calId: string, result: EventsInDatesResult) {
  const key = dateKey(eventDate);
  if (!result[calId]) {
    result[calId] = {};
  }
  if (!result[calId][key]) {
    result[calId][key] = [];
  }
  result[calId][key].push(e.clone(c => (c.date = eventDate)));
}
function addSimpleEventsToResult(
  calendar: Calendar,
  events: EventState,
  startOfCalendar: moment.Moment,
  endOfCalendar: moment.Moment,
  result: EventsInDatesResult
) {
  calendar.events.forEach(calendarEvent => {
    const e = events[calendarEvent.id];
    if (!e) {
      return;
    }
    const start = e.startDate.clone();
    const end = e.endDate.clone();
    if (!intervalWillBeDisplayedInCalendar(start, end, startOfCalendar, endOfCalendar)) {
      return;
    }
    let eventDate = start.clone();
    while (beforeOrSameDay(eventDate, end)) {
      addEventToDictionary(e, eventDate, calendar.id, result);
      eventDate = eventDate.clone().add(1, 'day');
    }
  });
}
function addRecurringEventsOfCalendar(
  c: Calendar,
  events: EventState,
  startOfCalendar: moment.Moment,
  endOfCalendar: moment.Moment,
  result: EventsInDatesResult
) {
  c.recurringEvents.forEach(ev => {
    const e: Event = events[ev.id];
    if (!e || !e.recurringMoment) {
      return;
    }

    const dates = e.recurringMoment.getMatchingDaysBetween(moment(startOfCalendar), endOfCalendar);

    dates.forEach(d => {
      addEventToDictionary(e, d, c.id, result);
    });
  });
}
function intervalWillBeDisplayedInCalendar(
  start: moment.Moment,
  end: moment.Moment,
  startOfCalendar: moment.Moment,
  endOfCalendar: moment.Moment
) {
  return (
    isBetweenDays(start, startOfCalendar, endOfCalendar) ||
    isBetweenDays(
      end,
      startOfCalendar,
      endOfCalendar
    ) /* ||
    (start.isBefore(startOfCalendar) && end.isAfter(endOfCalendar))*/
  );
}
export type EventCalendarMapperSelector = (
  calendars: Calendar[],
  events: EventState,
  startOfCalendar: moment.Moment,
  endOfCalendar: moment.Moment
) => EventsInDatesResult;

function dateKey(m: moment.Moment) {
  return m.format('YYYY-MM-DD');
}
export function getCalendars(state: AppState): Calendar[] {
  return Object.values(state.calendars);
}
export function getSortedCalendars(state: AppState): Calendar[] {
  return Object.values(state.calendars).sort(Calendar.defaultSort);
}
export function sameEvents(ev1: Event[], ev2: Event[], events: EventState) {
  return ev1 === ev2;
  //return ev1.filter(e => events[e.id]).length === ev2.filter(e => events[e.id]).length;
}
export function eventsInDatesSelector() {
  let lastCalendars: Calendar[] = [];

  let lastDates: { start?: moment.Moment; end?: moment.Moment } = { start: null, end: null };
  let result: EventsInDatesResult = {};
  return (calendars: Calendar[], events: EventState, startOfCalendar: moment.Moment, endOfCalendar: moment.Moment) => {
    let { added, changed, removed } = computeDiff(
      lastCalendars,
      calendars,
      (a, b) => a.id === b.id,
      (a, b) => !sameEvents(a.events, b.events, events) || !sameEvents(a.recurringEvents, b.recurringEvents, events)
    );
    if (
      true ||
      !lastDates.start ||
      !lastDates.start.isSame(startOfCalendar) ||
      !lastDates.end ||
      !lastDates.end.isSame(endOfCalendar)
    ) {
      // A diff date computation can be done but the effect will be marginal.
      result = {};
      added = calendars;
      changed = [];
      removed = [];
      //console.log('a');
    }
    let start = performance.now();

    removed.forEach(c => {
      if (result[c.id]) {
        delete result[c.id];
      }
    });
    changed.forEach(change => {
      if (result[change.old.id]) {
        delete result[change.old.id];
      }
      addSimpleEventsToResult(change.newElem, events, startOfCalendar, endOfCalendar, result);
      addRecurringEventsOfCalendar(change.newElem, events, startOfCalendar, endOfCalendar, result);
    });

    added.forEach(c => {
      addSimpleEventsToResult(c, events, startOfCalendar, endOfCalendar, result);
      addRecurringEventsOfCalendar(c, events, startOfCalendar, endOfCalendar, result);
    });
    //console.log('took',lastCalendars.length,calendars.length,changed.length, added.length,removed.length, performance.now() - start);
    lastCalendars = calendars;
    lastDates = { start: startOfCalendar.clone(), end: endOfCalendar.clone() };

    return result;
  };
}

function getEvent(state: AppState): (id: string) => Event {
  return (id: string) => state.events[id];
}
