import * as React from 'react';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import { AppState } from '../../state/state';
export type RestrictedFunc = (
  elem: React.ReactElement<any>,
  fallBack?: React.ReactElement<any>
) => React.ReactElement<any>;

export const withRestriction = () =>
  compose(
    connect((state: AppState) => ({
      superAdmin: state.currentUser && state.currentUser.user && state.currentUser.user.admin
    })),
    withProps((props: { superAdmin: boolean; loggedIn: boolean }) => ({
      restricted(elem: React.ReactElement<any>, fallBack?: React.ReactElement<any>) {
        return props.superAdmin ? elem : fallBack;
      }
    }))
  );
