import * as React from "react";
import { connect } from "react-redux";
import { compose, withProps } from "recompose";
import { AppState } from "../../state/state";
import OverlayConfirm from "../overlay/overlay.confirm";

export interface ConfirmConfig<Props> {
  name: string;
  onYes: (props?: Props) => any;
  message?: (props?: Props) => string;
  title?: (props?: Props) => string;
  alert?: boolean;
  onNo?: (props?: Props) => any;
}
export function withConfirm<Props>(configs: ConfirmConfig<Props>[]) {
  return (Comp: React.ComponentType<Props>) => {
    return class ConfirmComponent<Props> extends React.Component<
      Props & { Comp: React.ComponentType<Props> },
      { overlayVisible: { [id: string]: boolean } }
    > {
      showConfirms: { [name: string]: () => any } = {};
      constructor(props: Props & { Comp: React.ComponentType<Props> }) {
        super(props);
        this.state = { overlayVisible: {} };
        configs.forEach(c => {
          this.state.overlayVisible[c.name] = false;
          this.showConfirms[
            `show${c.name[0].toUpperCase()}${c.name.substr(1)}Overlay`
          ] = () => {
            console.log("a");
            this.setState({
              overlayVisible: { ...this.state.overlayVisible, [c.name]: true }
            });
          };
        });
      }
      render() {
        return (
          <>
            <Comp {...this.props as any} {...this.showConfirms} />
            {configs.map(conf => (
              <OverlayConfirm
                onYes={() => {
                  this.setState({
                    overlayVisible: {
                      ...this.state.overlayVisible,
                      [conf.name]: false
                    }
                  });
                  conf.onYes(this.props as any);
                }}
                message={conf.message ? conf.message(this.props as any) : null}
                title={conf.title ? conf.title(this.props as any) : null}
                onNo={() => {
                  this.setState({
                    overlayVisible: {
                      ...this.state.overlayVisible,
                      [conf.name]: false
                    }
                  });
                  conf.onNo && conf.onNo(this.props as any);
                }}
                alert={conf.alert}
                show={this.state.overlayVisible[conf.name]}
              />
            ))}
          </>
        );
      }
    };
  };
}
