import * as React from "react";

import Modal from "react-modal";
import Button from "../../input/button";
import { LocaleProps, localizable } from "../../i18n/localizable";
import { AppState } from "../../../state/state";
import { connect } from "react-redux";
import OrganizationRequest from "../../../models/organization.request";
import ProfileEditDialog from "../profile-edit/profile.edit.dialog";
import { NewUser } from "../user-admin/new.user";
import { goBack, push } from "connected-react-router";
import Organization from "../../../models/organization";
import { User, UserInfo } from "../../../models/User";
import { Services } from "../../../api/services";
import TextField from "../../input/text";

import "./request.less";
import OverlayConfirm from "../../overlay/overlay.confirm";
import { isEmpty } from "../../../utils/string.utils";
import TextBoxWithLabel from "../../input/input.label";
import AddressInput from "../../input/address.input";
import { EventLocationInfo } from "../../../api/model/event.node";
import InputText from "../../input/text";
import Checkbox from "../../input/checkbox";
import { goToAppStore } from "../../../utils/gotomobile.link";
import OrgRequestMultiSignupDialog from './org.request.multi.signup.dialog';
import { Link } from "react-router-dom";
const pathLogo = require("../../layout/images/logo.png");

declare const FUNCTIONS_BASE: string;
declare const STAGING: boolean;
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

interface Props {
  close: () => any;
  success: (e: string) => any;
  multiSuccess: (name: string) => any;
}
interface State {
  organizationName: string;
  addressInfo: EventLocationInfo;
  user: UserInfo;
  validationError: string;
  loading: boolean;
  clickedSave: boolean;
  emailConfirmation: string;
  acceptedTerms: boolean;
  invalidEmail: boolean;
  showAddressError: boolean;
  checkAdressError: boolean;
  modalMessageHtml: any;
  showMultiSignupDialog: boolean;
  hasPortalAccount: boolean;
}

class OrganizationRequestPageComponent extends React.Component<
  Props & LocaleProps,
  State
> {
  constructor(props: Props & LocaleProps) {
    super(props);
    this.state = {
      organizationName: "",
      user: {
        email: "",
        password: "",
        passConfirmation: "",
        firstName: "",
        lastName: "",
      },
      validationError: null,
      addressInfo: null,
      loading: false,
      clickedSave: false,
      acceptedTerms: false,
      emailConfirmation: "",
      invalidEmail: false,
      showAddressError: false,
      checkAdressError: false,
      modalMessageHtml: null,
      showMultiSignupDialog: false,
      hasPortalAccount: false
    };
    // this.state = {
    //   organizationName: "TestOrg_" + this.GernerateRandom(10),
    //   user: {
    //     email: "manuel@1950labs.com",
    //     password: "Manuel.12345",
    //     passConfirmation: "Manuel.12345",
    //     firstName: "FN_" + this.GernerateRandom(10),
    //     lastName: "LN_" + this.GernerateRandom(10),
    //   },
    //   validationError: null,
    //   addressInfo: null,
    //   loading: false,
    //   clickedSave: false,
    //   acceptedTerms: true,
    //   emailConfirmation: "manuel@1950labs.com",
    //   invalidEmail: false,
    //   showAddressError: false,
    //   checkAdressError: false,
    //   modalMessageHtml: null,
    //   showMultiSignupDialog: false
    // };
  }
// //TODO: Manuel - DELETE, just for quick testing (enable validations again after removing this)
//   GernerateRandom =(long:number)=>
//   {
//     var text = "";
//     var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
//     for (var i = 0; i < long; i++)
//       text += possible.charAt(Math.floor(Math.random() * possible.length));
//     return text;
//   }

  ProceedSave = () => {
    if (this.state.loading) {
      return;
    }
    const newRequest = new OrganizationRequest();
    //newRequest.organization = this.state.organization;
    this.setState({ clickedSave: true });
    if (!this.validationError()) {
      this.setState({ loading: true });
      fetch(`${FUNCTIONS_BASE}signupOrganization`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          name: this.state.organizationName,
          address: this.state.addressInfo,
          email: this.state.user.email,
          password: this.state.user.password,
          userFirstName: this.state.user.firstName,
          userLastName: this.state.user.lastName
        })
      })
        .then(result => {
          this.setState({ loading: false, clickedSave: false });
          this.props.success(this.state.user.email);
        })
        .catch(() => {
          this.setState({ loading: false });
          alert("There was an error while trying to approve the request");
        });

      //this.props.close();
    } else {
      this.setState({ validationError: this.validationError(), checkAdressError: true, showAddressError: this.state.addressInfo == null, loading: false });
    }
  };
  validationError = () => { 
    this.validateAddress()
    if (isEmpty(this.state.organizationName)) {
      return "signup.error.organization";
    } else if (this.state.addressInfo == null) {
      return "signup.error.address";
    } else if (isEmpty(this.state.user.firstName)) {
      return "signup.error.firstname";
    } else if (isEmpty(this.state.user.lastName)) {
      return "signup.error.lastname";
    } else if (isEmpty(this.state.user.email)) {
      return "signup.error.email";
    } else if (this.state.user.email !== this.state.emailConfirmation) {
      return "signup.error.emailsDontMatch";
    } else if (isEmpty(this.state.user.password)) {
      return "signup.error.password";
    } else if (!this.isPasswordValid(this.state.user.password)) {
      return "signup.error.password.not.match.requirements";
    } else if (this.state.user.passConfirmation != this.state.user.password) {
      return "signup.error.confirmation";
    }

    return null;
  };

  isMultipleSignup = () => {
    this.setState({ loading: true });
    const addressInfo = this.state.addressInfo, emailAddress = this.state.user.email, name = this.state.organizationName;
    let alreadyUsed = false;
    let errorMessage:string;
    let modalMessageHtml:any;
    Services.Organizations.IsOrganizationNameZipTaken(this.state.organizationName)
    .then((res) => {
      res.forEach(function (childSnapshot:any) {
        var org = childSnapshot.val();
        if(org.name === name && org.zip == addressInfo.zip) {
          errorMessage = "signup.existingOrgNameZip";
          modalMessageHtml = <div>An organization with that name and location already exists, please update organization name. Perhaps include a street name or other distinguishing name. If you believe that someone who is unauthorized to represent your organization has set up an account, please email: <a className="login_signup_almost_reasons_link" href='mailto:hello@timepeace.io'>hello@timepeace.io</a>. Be very specific so that we can better help you.</div>;
        }
        else if(org.name === name && org.address == addressInfo.address) {
          errorMessage = "signup.existingOrgNameAddress";
          modalMessageHtml = <div>An organization with that name and location already exists. If you believe that someone who is unauthorized to represent your organization has set up an account, please email: <a className="login_signup_almost_reasons_link" href='mailto:hello@timepeace.io'>hello@timepeace.io</a>. Be very specific so that we can better help you.</div>;
        }
      });

      if(!isEmpty(errorMessage))
      {
        this.setState({ ...this.state, validationError: modalMessageHtml, modalMessageHtml });
      }
      else {
        Services.Users.IsUserEmailTaken(emailAddress)
        .then((res) => {
          // res.length is 0, then it is a new email
          if(res.length === 0) {
            this.setState({ loading: false });
            this.ProceedSave();
          }
            
          else {
            let hasPortalAccount = false;
            if(res.length > 0) {
              // res.length is 1 or more, then it is an existing account.
              if(res.indexOf("password") > -1) {
                //User has a Portal account 
                hasPortalAccount = true;
              }
            }
            // this.setState({ ...this.state, validationError: "signup.existingEmail"})
            this.setState({ ...this.state, showMultiSignupDialog:true, hasPortalAccount})
          }
        });
      }
    })
    .catch(() => {
      alert("There was an error while validating the organization data");
      alreadyUsed = true;
    })
  }

  isPasswordValid = (pass: string) => {
    return (
      pass.length >= 10 &&
      /[A-Z]/.test(pass) &&
      /[a-z]/.test(pass) &&
      /[0-9]/.test(pass) &&
      /[#@\\^!\\$()\\*\\+_,\\.\\{\\}\\?\\-]/.test(pass)
    );
  };

  validateAddress = () => {
    let isValid = this.state.addressInfo !== null;
    if(!isValid && !this.state.showAddressError && this.state.checkAdressError)
      this.setState({ showAddressError: false }); 
    return this.state.showAddressError;
  }

  hideMultiSignUpDialog = () => {
    this.setState({
      ...this.state,
      showMultiSignupDialog: false,
      loading:false
    })
  }

  signUpOrganizationForExistingAdmin = (userId: string) => {
    fetch(`${FUNCTIONS_BASE}signupOrganizationForExisitingAdmin`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        name: this.state.organizationName,
        address: this.state.addressInfo,
        email: this.state.user.email,
        password: this.state.user.password,
        userFirstName: this.state.user.firstName,
        userLastName: this.state.user.lastName,
        userId
      })
    })
      .then(result => {
          fetch(`${FUNCTIONS_BASE}sendWelcomeEmail`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              email: this.state.user.email,
              orgName: this.state.organizationName,
              staging: STAGING
            })
          }).then(r => r.text()).then(()=> {
            this.props.multiSuccess(this.state.organizationName);
          });
      })
      .catch((error) => {
        alert("There was an error while trying create the organization");
      });
  }

  render() {
    const { _ } = this.props;
    return (
      <div className="signin_page">
      <Link to={'/login'} >
            <img className="payments_layout_logo_img" src={pathLogo} />
          </Link>
          <div className="login_page__login__signup">
          Already have an account?
          <Button
            text="Sign In"
            onClick={() => this.props.close()}
            grayStyle={true}
          />
        </div>
        <h1 className="login_page__left__get_started">{_("signup.title")}</h1>
        <h3 className="login_page__left__sign_in_below">
          {_("signup.subtitle")}
        </h3>
        
        <TextField
          placeholder={_("signup.organization.name")}
          value={this.state.organizationName}
          error={isEmpty(this.state.organizationName)}
          showErrorPerDefault={this.state.clickedSave}
          errorText={_("signup.error.organization")}
          showErrorAfter={true}
          onChange={organizationName => {
            this.setState({ organizationName });
          }}
          materialPlaceholder={true}
        />
        {false && (
          <div className="signin_page__footnote">
            {_("signup.cannot.be.changed")}
          </div>
        )}
        <AddressInput
          useTextArea={true}
          placeHolder={_("signup.organization.address")}
          text={
            this.state.addressInfo ? this.state.addressInfo.addressBoxText : ""
          }
          onChange={addressInfo => {

            let addressInfoArray: string[] = addressInfo ? addressInfo.addressBoxText.split(',') : [];
            
            if( addressInfo && addressInfoArray[0] !== undefined && addressInfoArray[1] !== undefined)
            {
              if(addressInfoArray[0] === addressInfoArray[0].trim())
              {
                let addressArrayFinal = addressInfoArray.splice(2, addressInfoArray.length)
                addressInfo.addressBoxText = addressInfoArray[0] + "," + addressArrayFinal.join(","); 
              }
            }
            this.setState({ addressInfo, showAddressError: addressInfo == null });

          }}
          
          top="3rem"
          withIcons={true}
          withMaterial={true}
          showErrorPerDefault={this.state.clickedSave}
          error = { this.validateAddress() } 
          
        />
        {this.state.showAddressError && <div className="text_box__error_desc">{ _("signup.error.address") }</div>}
        <div className="orga_requests__page__admin_name">
          <InputText
            placeholder={_("signup.admin.name")}
            value={this.state.user.firstName}
            onChange={firstName => {
              this.setState({
                user: Object.assign({}, this.state.user, { firstName })
              });
            }}
            materialPlaceholder={true}
            error={ isEmpty(this.state.user.firstName) }
            showErrorPerDefault={this.state.clickedSave}
            //errorText={_("signup.email.invalid")}
            showErrorAfter={false}
          />
          <InputText
            placeholder={_("signup.admin.last.name")}
            value={this.state.user.lastName}
            onChange={lastName => {
              this.setState({
                user: Object.assign({}, this.state.user, { lastName })
              });
            }}
            error={ isEmpty(this.state.user.lastName) }
            materialPlaceholder={true}
            showErrorPerDefault={this.state.clickedSave}
            //errorText={_("signup.email.invalid")}
            showErrorAfter={false}
          />
        </div>
        <TextField
        type="email"
        // onFocus={ ()=>  this.setState({ ...this.state, invalidEmail: !EMAIL_REGEX.test(this.state.user.email)})}
        onBlur={ ()=>  this.setState({ ...this.state, invalidEmail: !EMAIL_REGEX.test(this.state.user.email)})}
          placeholder={_("signup.email")}
          error={isEmpty(this.state.user.email)}
          showErrorPerDefault={this.state.clickedSave}
          errorText={_("signup.email.invalid")}
          showErrorAfter={true}
          value={this.state.user.email}
          onChange={email => {
            this.setState({
              user: Object.assign({}, this.state.user, { email }),
              invalidEmail: !EMAIL_REGEX.test(email)
            });
          }}
          materialPlaceholder={true}
        />
        {/* <div className="text_box__error_desc">{ this.state.invalidEmail ? _("signup.email.invalid") : ""}</div> */}
        <TextField
        
        type="email"
          placeholder={_("signup.email.confirmation")}
          error={
            isEmpty(this.state.emailConfirmation) ||
            this.state.user.email !== this.state.emailConfirmation
          }
          errorText={_("signup.error.emailsDontMatch")}
          showErrorAfter={true}
          showErrorPerDefault={this.state.clickedSave}
          value={this.state.emailConfirmation}
          onChange={emailConfirmation => {
            this.setState({
              emailConfirmation
            });
          }}
          materialPlaceholder={true}
        />
        
        <TextField
          placeholder={_("signup.password")}
          type={"password"}
          value={this.state.user.password}
          onChange={password => {
            this.setState({
              user: Object.assign({}, this.state.user, { password })
            });
          }}
          showErrorPerDefault={this.state.clickedSave}
          error={!this.isPasswordValid(this.state.user.password)}
          errorText={_("signup.error.password.not.match.requirements")}
          showErrorAfter={true}
          materialPlaceholder={true}
        />
        <TextField
          placeholder={_("signup.confirmation")}
          type={"password"}
          value={this.state.user.passConfirmation}
          onChange={passConfirmation => {
            this.setState({
              user: Object.assign({}, this.state.user, { passConfirmation })
            });
          }}
          showErrorPerDefault={this.state.clickedSave}
          errorText={_("signup.error.confirmation")}
          error={ isEmpty(this.state.user.passConfirmation) || this.state.user.password != this.state.user.passConfirmation }
          showErrorAfter={true}
          materialPlaceholder={true}
        />
        <div>
          <Checkbox
            checked={this.state.acceptedTerms}
            onChange={acceptedTerms => this.setState({ acceptedTerms })}
            containerClass="signin_page__accept_terms"
          >
            {_("signup.agree.with.terms")}{" "}
            <a className="signup__link" target="_blank" href="https://timepeace.io/terms">{_("signup.terms")}</a>{" "}
            {_("signup.and")}{" "}
            <a className="signup__link" target="_blank" href="https://timepeace.io/privacy">{_("signup.privacy")}</a>
          </Checkbox>
        </div>

        <div className="signup_occasionally_emails_text">{!(isEmpty(this.state.organizationName) || this.state.addressInfo == null || isEmpty(this.state.user.firstName) || isEmpty(this.state.user.lastName) || 
              isEmpty(this.state.user.email) || this.state.user.email !== this.state.emailConfirmation || isEmpty(this.state.user.password) || 
              !this.isPasswordValid(this.state.user.password) || this.state.user.passConfirmation != this.state.user.password || !this.state.acceptedTerms) ? _(`signup.continueMessage`) : null}</div>

        <div className="signup_buttons">
          <Button
            link={true}
            additionClassName="profile_edit_dialog__buttons__cancel signup__button"
            onClick={() => {
              this.props.close();
            }}
            text={_(`cancel`)}
          />
          <Button
            onClick={this.isMultipleSignup}
            text={_(`signup.save`)}
            additionClassName="signup__button"
            disabled={isEmpty(this.state.organizationName) || this.state.addressInfo == null || isEmpty(this.state.user.firstName) || isEmpty(this.state.user.lastName) || 
              isEmpty(this.state.user.email) || this.state.user.email !== this.state.emailConfirmation || isEmpty(this.state.user.password) || 
              !this.isPasswordValid(this.state.user.password) || this.state.user.passConfirmation != this.state.user.password || !this.state.acceptedTerms || this.state.loading }
          />
        </div>
        
        <div className="signup_terms_and_conditions">
          <br/>
          <div>Get the TimePeace app to see what's happening near you.</div>
          <div>Download in the <a className="signup__link--underline" target="_blank" href="https://itunes.apple.com/us/app/timepeace-calendar/id1278750105?mt=8">Apple App Store</a> or <a className="signup__link--underline" target="_blank" href="https://play.google.com/store/apps/details?id=com.smartcal&hl=en">Google Play Store</a>.</div>
        </div>
        { <OverlayConfirm
          fixed={true}
          show={!!this.state.validationError}
          message={_(this.state.validationError)}
          alert={true}
          yesText={_("close")}
          onYes={() => {
            this.setState({
              loading: false,
              validationError: null
            });
          }}
          children={this.state.modalMessageHtml}
        /> }

        <OrgRequestMultiSignupDialog
          fixed={true}
          show={this.state.showMultiSignupDialog}
          user={this.state.user}
          alert={true}
          yesText={_('continue')}
          onNo={this.hideMultiSignUpDialog}
          onYes={(userId) => { 
            //debugger;
            this.signUpOrganizationForExistingAdmin(userId);
          }}
          hasPortalAccount={this.state.hasPortalAccount}
        />
      </div>
    );
  }
}

const OrganizationRequestPage = connect(
  (state: AppState) => ({}),
  dispatch => ({
    close() {
      dispatch(goBack());
    },
    success(email: string) {
      dispatch(push(`/login/almost-there/${email}`));
    },
    multiSuccess(name: string) {
      dispatch(push(`/login/signup/existing/${name}`));
    }
  })
)(localizable(OrganizationRequestPageComponent));
export default OrganizationRequestPage;
