import { compose } from "recompose";
import * as React from "react";
import Button from "../../input/button";
import {
  LocaleProps,
  localizable,
  withLocalization
} from "../../i18n/localizable";
import { AppState } from "../../../state/state";
import { connect } from "react-redux";
import TextField from "../../input/text";
import { goBack } from "connected-react-router";
import { Services } from "../../../api/services";
import { AsyncResult } from "../../../api/services/async.result";
import { IUser, IEmail } from "../../../models/User";
import "./profile-dialog.less";
import Organization from "../../../models/organization";
import ContactInfo from "../../../models/contact.info";
import TextBoxWithLabel from "../../input/input.label";
import firebase from "../../../api/firebase";
import {
  updateOrganization,
  createOrganization,
  deleteOrganization
} from "../../../actions/organization.actions";
import Loader from "../../loader/loader";
import { isEmpty } from "../../../utils/string.utils";
import { getSelectedOrganization } from "../../../state/state_getters";
import AddressInput from "../../input/address.input";
import OverlayConfirm from "../../overlay/overlay.confirm";
import { withRestriction, RestrictedFunc } from "../../util/with.restriction";
import { withConfirm } from "../../util/with.confirmation";
import ReactCrop, { type Crop } from 'react-image-crop'
import Modal from "react-modal";
import 'react-image-crop/dist/ReactCrop.css'
import {createRef} from "react";

const whiteIcon = require("./images/white-icon.png");
const whiteBanner = require("./images/white-banner.png");
const _URL = window.URL; //|| (window as any).webkitURL;

interface SimpleEditionDialogDialogProps {
  close?: () => any;
  updKey?: string;
  referalId?: string;
  user: IUser;
  organization: Organization;
  update: (organization: Organization) => any;
  saveNew: (organization: Organization) => any;
  isNew?: boolean;
  isRequest?: boolean;
  onOrganizationChange?: (organization: Organization) => any;
  restricted: RestrictedFunc;
  showDeleteOverlay: () => any;
}

interface SimpleEditionDialogDialogState {
  loading: boolean;
  error: string;
  addressSearchText: string;
  organization: Organization;
  addressMoreInfoOpen: boolean;
  clickedOnSave: boolean;
  overlayError?: string;
  isIconLoaded: boolean;
  isBannerLoaded: boolean;
  cropIcon: Crop;
  cropBanner: Crop;
  iconRef: any;
  bannerRef: any;
}
class Dialog extends React.Component<
  SimpleEditionDialogDialogProps & LocaleProps,
  SimpleEditionDialogDialogState
> {
  page: HTMLDivElement;
  nameTxt: HTMLInputElement;
  descTxt: HTMLInputElement;
  bannerFileInp: HTMLInputElement;
  iconFileInp: HTMLInputElement;
  constructor(props: SimpleEditionDialogDialogProps & LocaleProps) {
    super(props);
    const org = props.organization;
    this.state = {
      loading: false,
      organization: !props.isNew ? props.organization : new Organization(),
      error: "",
      addressMoreInfoOpen: false,
      clickedOnSave: false,
      addressSearchText: props.isNew ? "" : Dialog.getAddressText(org),
      overlayError: "",
      isIconLoaded: false,
      isBannerLoaded: false,
      cropIcon: {
        unit: "px",
        x: 25,
        y: 25,
        width: 512,
        height: 512,
      },
      cropBanner:  {
        unit: "px",
        x: 25,
        y: 25,
        width: 1650,
        height: 675,
      },
      iconRef: createRef(),
      bannerRef: createRef(),
    }

    if (props.onOrganizationChange) {
      props.onOrganizationChange(this.state.organization);
    }
  }

  componentDidMount() {
    if (!this.bannerFileInp) {
      return;
    }
    this.bannerFileInp.addEventListener("change", evt => {
      const files = this.bannerFileInp.files;
      const failedMsg = this.props._("upload.failed");
      if (FileReader && files && files.length) {
        this.onCloseCropBanner()
        var fr = new FileReader();
        fr.onload = () => {
          this.changeOrganization({ photo: fr.result as string });
        };
        const img = new Image();
        img.src = _URL.createObjectURL(files[0]);
        img.onload = () => {
          // if (img.width < 1650 || img.height < 675) {
          //   this.setState({ overlayError: failedMsg });
          //   setTimeout(() => {
          //     this.changeOrganization({ photo: "" });
          //   }, 500);
          // }
        };
        img.onerror = () => {
          this.setState({ overlayError: failedMsg });
          this.changeOrganization({ photo: "" });
        };
        if (files[0].name.endsWith("jpg") || files[0].name.endsWith("png")) {
          fr.readAsDataURL(files[0]);
          this.setState({ isBannerLoaded: true });
        } else {
          this.setState({ overlayError: failedMsg });
          this.changeOrganization({ photo: "" });
        }
      }
    });
    this.iconFileInp.addEventListener("change", evt => {
      const files = this.iconFileInp.files;
      const failedMsg = this.props._("upload.failed");
      if (FileReader && files && files.length) {
        this.onCloseCropIcon()
        // opened popup
        // crop
        var fr = new FileReader();
        fr.onload = () => {
          this.changeOrganization({ icon: fr.result as string });
        };
        const img = new Image();
        img.src = _URL.createObjectURL(files[0]);
        img.onload = () => {
          // if (img.width < 512 || img.height < 512) {
          //   this.setState({ overlayError: failedMsg });
          //   setTimeout(() => {
          //     this.changeOrganization({ icon: "" });
          //   }, 500);
          // }
        };
        img.onerror = () => {
          this.setState({ overlayError: failedMsg });
          this.changeOrganization({ icon: "" });
        };
        if (files[0].name.endsWith("jpg") || files[0].name.endsWith("png")) {
          fr.readAsDataURL(files[0]);
          this.setState({ isIconLoaded: true });
        } else {
          this.setState({ overlayError: failedMsg });
          this.changeOrganization({ icon: "" });
        }
      }
    });
  }

  static getAddressText(org: Organization) {
    let orgAAddressDisplay = org.address || "";
    orgAAddressDisplay += orgAAddressDisplay.length > 0 ? ", " : "";
    orgAAddressDisplay += org.city || "";
    orgAAddressDisplay += orgAAddressDisplay.length > 0 ? ", " : "";
    orgAAddressDisplay += org.state || "";
    orgAAddressDisplay += orgAAddressDisplay.length > 0 ? ", " : "";
    orgAAddressDisplay += org.zip || "";
    let finalOrgAddressDisplay = orgAAddressDisplay
      .replace(", ,", ", ")
      .replace(",  ", "")
      .replace("  ,", "")
      .trim();
    return finalOrgAddressDisplay.startsWith(",")
      ? finalOrgAddressDisplay.substring(1)
      : finalOrgAddressDisplay;
  }
  changeOrganization(newData: Partial<Organization>) {
    const newOrga = this.state.organization.clone();
    Object.assign(newOrga, newData);
    this.setState({ organization: newOrga }, () => {
      if (this.props.onOrganizationChange) {
        this.props.onOrganizationChange(this.state.organization);
      }
    });
    return newOrga;
  }
  updateContact(newInfo: Partial<ContactInfo>, idx: number) {
    this.changeOrganization({
      personalContacts: (this.state.organization.personalContacts || []).map(
        (p, pIdx) => {
          if (pIdx !== idx) {
            return p;
          } else {
            return Object.assign({}, p, newInfo);
          }
        }
      )
    });
  }

  validate = () => {
    return !isEmpty(this.state.organization.name);
  };
  clickSave = () => {
    const { _, isNew, saveNew, update } = this.props;
    this.setState({ clickedOnSave: true });
    if (!this.validate()) {
      return;
    }
    this.setState({ loading: true, error: "" });
    const newOrganization = this.state.organization;
    const oldOrganization = this.props.organization;
    const promises: Promise<string>[] = [];
    if (
      newOrganization.photo &&
      (isNew || newOrganization.photo !== oldOrganization.photo)
    ) {
      promises.push(
        this.uploadFile(
          "banners",
          newOrganization.photo,
          photo => (newOrganization.photo = photo)
        )
      );
    }
    if (
      newOrganization.icon &&
      (isNew || newOrganization.icon !== oldOrganization.icon)
    ) {
      promises.push(
        this.uploadFile(
          "icons",
          newOrganization.icon,
          icon => (newOrganization.icon = icon)
        )
      );
    }
    newOrganization.personalContacts = (
      newOrganization.personalContacts || []
    ).filter(
      c =>
        !isEmpty(c.name) ||
        !isEmpty(c.email) ||
        !isEmpty(c.phone) ||
        !isEmpty(c.title)
    );

    Promise.all(promises)
      .then(() => {
        this.setState({ loading: false });
        if (this.props.isNew) {
          saveNew(newOrganization);
        } else {
          update(newOrganization);
        }

        this.props.close();
      })
      .catch(err => {
        this.setState({ loading: false, error: _("upload.error") });
      });
  };
  static getDerivedStateFromProps(
    props: SimpleEditionDialogDialogProps & LocaleProps,
    state: SimpleEditionDialogDialogState
  ) {
    if (props.organization && !state.organization && !props.isNew) {
      return {
        organization: props.organization.clone(),
        addressSearchText: Dialog.getAddressText(props.organization)
      } as Partial<SimpleEditionDialogDialogState>;
    }
    return null;
  }

  blobToBase64 = (blob: Blob) => {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  createCropPreview = async (image: HTMLImageElement, crop: Crop, fileName: string) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    canvas.width = Math.ceil(crop.width);
    canvas.height = Math.ceil(crop.height);

    // Get the canvas context
    const ctx = canvas.getContext("2d");

    // If there's no context, throw an error
    if (!ctx) {
      throw new Error('No 2d context available');
    }

    // Calculate the real crop dimensions
    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;
    const cropWidth = crop.width * scaleX;
    const cropHeight = crop.height * scaleY;

    // Clear the canvas (Useful if this function is called multiple times)
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.drawImage(
        image,
        cropX,
        cropY,
        cropWidth,
        cropHeight, // Source coordinates
        0,
        0,
        canvas.width,
        canvas.height // Destination coordinates
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(async (blob: any) => {
        if (blob) {
          const img = await this.blobToBase64(blob)
          resolve(img);
        }
        reject(new Error("Canvas is empty"));
      }, "image/jpeg");
    });
  };

  onCropChangeIcon = (crop: Crop, percentageCrop?: any) => {
    this.setState({ cropIcon: crop })
  };

  onCropChangeBanner = (crop: Crop, percentageCrop?: any) => {
    this.setState({ cropBanner: crop })
  };

  onCloseCropIcon = () => {
    this.setState({ cropIcon: { unit: "px", x: 25, y: 25, width: 512, height: 512 } })
    this.setState({iconRef: createRef()})
  }

  onCloseCropBanner = () => {
    this.setState({ cropBanner: { unit: "px", x: 25, y: 25, width: 1650, height: 675 } })
    this.setState({bannerRef: createRef()})
  }

  onAcceptCropIcon = async () => {
    const img = await this.createCropPreview(this.state.iconRef.current, this.state.cropIcon, "cropIconFile.jpeg")
    this.changeOrganization({ icon: img as string });
    this.setState({ isIconLoaded: false })
  };

  onAcceptCropBanner = async () => {
    const img = await this.createCropPreview(this.state.bannerRef.current, this.state.cropBanner, "cropBannerFile.jpeg")
    this.changeOrganization({ photo: img as string });
    this.setState({ isBannerLoaded: false })
  };

  render() {
    const { _, user, isNew, isRequest } = this.props;
    const { organization } = this.state;
    const conditionalHideRowClass = `profile_edit__row  ${
      isRequest ? "profile_edit__row--hiden" : ""
    }`;
    let enableFields = isRequest || (user && user.admin); //|| (user && !isNew && user.id === organization.createdBy);
    // enableFields = false;
    if (!organization) {
      return null;
    }
    return (
      <div ref={page => (this.page = page)} className="profile_edit_page">
        <h2 className="profile_edit_dialog__title">
          {isRequest
            ? _("request.title")
            : !isNew
            ? _("title")
            : _("new.title")}
        </h2>
        <div className="profile_edit__row" >
          <TextBoxWithLabel
            enabled={enableFields}
            value={organization.name}
            onChange={name => this.changeOrganization({ name })}
            label={_("name")}
            error={isEmpty(this.state.organization.name)}
            errorText={"Please enter a valid name"}
            showErrorPerDefault={this.state.clickedOnSave}
          />
          <TextBoxWithLabel
            enabled={enableFields}
            label={_("type")}
            onChange={type => this.changeOrganization({ type })}
            value={organization.type}
          />
        </div>
        <div className="profile_edit__row" >
          <div>
            {enableFields && (
              <TextBoxWithLabel
                label={_("search.addres")}
                value={this.state.addressSearchText}
                additionalClass="profile_edit__row__address_text"
                renderInput={addressSearchText => (
                  <AddressInput
                    enabled={enableFields}
                    onChange={info => {
                      this.changeOrganization({
                        address: info.address,
                        city: info.city,
                        state: info.state,
                        country: info.country,
                        zip: info.zip || this.state.organization.zip,
                        lat: info.lat,
                        lng: info.lng
                      });
                      this.setState({ addressSearchText: info.addressBoxText });
                    }}
                    text={addressSearchText}
                  />
                )}
              >
                {/* <div className={`address_more_info_btn__container`}>
                  {enableFields && (
                    <div
                      className={`address_more_info_btn__open address_more_info_btn__open--${
                        this.state.addressMoreInfoOpen ? "close" : "open"
                      }`}
                      onClick={() =>
                        this.setState({
                          addressMoreInfoOpen: !this.state.addressMoreInfoOpen
                        })
                      }
                    />
                  )}
                </div> */}
              </TextBoxWithLabel>
            )}
            {!enableFields && (
              <TextBoxWithLabel
                label={_("search.addres")}
                value={this.state.addressSearchText}
                additionalClass="profile_edit__row__address_text profile_edit__row__address_text--not-editable"
                renderInput={value => (
                  <div className="profile_edit__row__address_text__not_editable_text">
                    {value}
                  </div>
                )}
              />
            )}
            <div
              className={`profile_edit__address_info profile_edit__address_info--${
                this.state.addressMoreInfoOpen ? "open" : "closed"
              }`}
              style={{ display: "none" }}
            >
              <TextBoxWithLabel
                enabled={enableFields}
                value={organization.address}
                onChange={address => this.changeOrganization({ address })}
                label={_("address")}
              />

              <TextBoxWithLabel
                additionalClass=""
                enabled={enableFields}
                value={organization.address2}
                onChange={address2 => this.changeOrganization({ address2 })}
                label={_("address2")}
              />

              <TextBoxWithLabel
                enabled={enableFields}
                value={organization.zip}
                onChange={zip => this.changeOrganization({ zip })}
                label={_("zip")}
              />
              <TextBoxWithLabel
                enabled={enableFields}
                value={organization.city}
                onChange={city => this.changeOrganization({ city })}
                label={_("city")}
              />

              <TextBoxWithLabel
                enabled={enableFields}
                value={organization.state}
                onChange={state => this.changeOrganization({ state })}
                label={_("state")}
              />
              <TextBoxWithLabel
                enabled={enableFields}
                value={organization.country}
                onChange={country => this.changeOrganization({ country })}
                label={_("country")}
              />
            </div>
          </div>
          {user && !user.admin && (
            <div className="profile__no_editable_row">
              {_("no.edit.msg")}{" "}
              <a href={`mailto:${_("mail.edit.contact")}`}>
                {_("mail.edit.contact")}
              </a>
            </div>
          )}
        </div>

        <div className="profile_edit__row">
          <TextBoxWithLabel
            value={organization.description}
            onChange={description => this.changeOrganization({ description })}
            label={_("field.description")}
            area={true}
            rows={7}
            additionalClassName="profile_edit__row__area"
            placeholder={_("description")}
          />
        </div>
        <div className="profile_edit__row">
          <TextBoxWithLabel
            value={organization.contacts.Phone}
            onChange={Phone =>
              this.changeOrganization({
                contacts: Object.assign({}, organization.contacts, { Phone })
              })
            }
            label={_("phone")}
          />
          <TextBoxWithLabel
            value={organization.contacts.Fax}
            onChange={Fax =>
              this.changeOrganization({
                contacts: Object.assign({}, organization.contacts, { Fax })
              })
            }
            label={_("fax")}
          />
        </div>
        <div className="profile_edit__row">
          <TextBoxWithLabel
            value={organization.links.Email}
            onChange={Email =>
              this.changeOrganization({
                links: Object.assign({}, organization.links, { Email })
              })
            }
            label={_("email")}
          />
          <TextBoxWithLabel
            value={organization.links.Website}
            onChange={Website =>
              this.changeOrganization({
                links: Object.assign({}, organization.links, { Website })
              })
            }
            label={_("website")}
          />
        </div>
        <div
          className="profile_edit__personal_contacts"
          style={{ display: this.props.isRequest ? "none" : "" }}
        >
          {(organization.personalContacts || []).map((c, idx) => [
            <div className="profile_edit__row__contact_title">
              Contact {idx + 1}
              <Button
                link={true}
                onClick={() => {
                  this.changeOrganization({
                    personalContacts: organization.personalContacts.filter(
                      (c, i) => i !== idx
                    )
                  });
                }}
                text={_("remove.contact")}
              />
            </div>,
            <div className="profile_edit__row profile_edit__row profile_edit__row--small-margin">
              <TextBoxWithLabel
                value={c.name}
                onChange={name => this.updateContact({ name }, idx)}
                label={_("contact.name", { name: "" })}
              />
              <TextBoxWithLabel
                value={c.email}
                onChange={email => this.updateContact({ email }, idx)}
                label={_("contact.email", { name: "" })}
              />
            </div>,
            <div className="profile_edit__row profile_edit__row--small-margin">
              <TextBoxWithLabel
                value={c.title}
                onChange={title => this.updateContact({ title }, idx)}
                label={_("contact.title", { name: "" })}
              />
              <TextBoxWithLabel
                value={c.phone}
                onChange={phone => this.updateContact({ phone }, idx)}
                label={_("contact.phone", { name: "" })}
              />
            </div>
          ])}
          <div className="profile_edit__row">
            <div className="profile_edit__row__add_container">
              <Button
                text={_("add.contact")}
                additionClassName="profile_edit__row__addContact"
                onClick={() => {
                  this.changeOrganization({
                    personalContacts: [
                      ...(this.state.organization.personalContacts || []),
                      {
                        email: "",
                        name: "",
                        title: "",
                        phone: ""
                      }
                    ]
                  });
                }}
              />
            </div>
          </div>
        </div>
        {!isRequest && (
          <div className="profile_edit__row">
            <div className="profile_edit__image_cell">
              <input
                type="file"
                ref={iconFileInp => (this.iconFileInp = iconFileInp)}
                style={{ display: "none" }}
              />
              <div className="profile_edit__image_cell__container">
                <div className="profile_edit__image_cell__message">
                  <label className="profile_edit__image_cell__label">
                    {_("icon")}
                  </label>
                  <div
                    className={`profile_edit__image_cell__icon ${
                      !organization.icon
                        ? "profile_edit__image_cell__icon--hidden"
                        : ""
                    }`}
                  >
                    <img src={organization.icon || whiteIcon} />
                  </div>
                </div>
                <div className="profile_edit__image_cell__message">
                  <div className="">{_("upload.notes.1")}</div>
                  <div className="">{_("upload.notes.2")}</div>
                </div>
              </div>
              <div className="profile_edit__image_cell__upload_container">
                {!isEmpty(this.state.organization.icon) && (
                  <Button
                    link={true}
                    deleteButton={true}
                    onClick={() => {
                      this.changeOrganization({ icon: "" });
                    }}
                    text={_("delete")}
                  />
                )}
                <Button
                  additionClassName="profile_edit__image_cell__upload_btn"
                  text={_("upload.icon")}
                  onClick={() => this.iconFileInp.click()}
                />
              </div>
            </div>
            <div className="profile_edit__image_cell">
              <label className="profile_edit__image_cell__label">
                {_("banner")}
              </label>
              <input
                type="file"
                ref={bannerFileInp => (this.bannerFileInp = bannerFileInp)}
                style={{ display: "none" }}
              />
              <div
                className={`profile_edit__image_cell__banner ${
                  !organization.photo
                    ? "profile_edit__image_cell__banner--hidden"
                    : ""
                }`}
              >
                <img src={organization.photo || whiteBanner} />
              </div>
              <div className="profile_edit__image_cell__upload_container">
                {!isEmpty(this.state.organization.photo) && (
                  <Button
                    link={true}
                    deleteButton={true}
                    onClick={() => {
                      this.changeOrganization({ photo: "" });
                    }}
                    text={_("delete")}
                  />
                )}
                <Button
                  additionClassName="profile_edit__image_cell__upload_btn"
                  text={_("upload.banner")}
                  onClick={() => this.bannerFileInp.click()}
                />
              </div>
            </div>
          </div>
        )}

        <div className={conditionalHideRowClass}>
          <TextBoxWithLabel
            value={organization.links.Facebook}
            onChange={Facebook =>
              this.changeOrganization({
                links: Object.assign({}, organization.links, { Facebook })
              })
            }
            label={_("facebook")}
          />
          <TextBoxWithLabel
            value={organization.links.Twitter}
            onChange={Twitter =>
              this.changeOrganization({
                links: Object.assign({}, organization.links, { Twitter })
              })
            }
            label={_("twitter")}
          />
        </div>
        <div className={conditionalHideRowClass}>
          <TextBoxWithLabel
            value={organization.links.YouTube}
            onChange={YouTube =>
              this.changeOrganization({
                links: Object.assign({}, organization.links, { YouTube })
              })
            }
            label={_("youtube")}
          />

          <TextBoxWithLabel
            value={organization.links.Instagram}
            onChange={Instagram =>
              this.changeOrganization({
                links: Object.assign({}, organization.links, { Instagram })
              })
            }
            label={_("instagram")}
          />
        </div>
        <div className={conditionalHideRowClass}>
          <TextBoxWithLabel
            value={organization.links.Pinterest}
            onChange={Pinterest =>
              this.changeOrganization({
                links: Object.assign({}, organization.links, { Pinterest })
              })
            }
            label={_("pinterests")}
          />

          <TextBoxWithLabel
            value={organization.links.LinkedIn}
            onChange={LinkedIn =>
              this.changeOrganization({
                links: Object.assign({}, organization.links, { LinkedIn })
              })
            }
            label={_("linkedin")}
          />
        </div>
        <div className={conditionalHideRowClass}>
          <TextBoxWithLabel
            label={_("field.info")}
            value={organization.notes}
            additionalClassName="profile_edit__row__area"
            onChange={notes => this.changeOrganization({ notes })}
            placeholder={_("additionalInformation")}
            area={true}
            rows={7}
          />
        </div>

        {!this.props.isRequest && (
          <div className="profile_edit_dialog__buttons">
            <Loader show={this.state.loading} />
            {!isEmpty(this.state.error) && (
              <div className="profile_edit__error">{this.state.error}</div>
            )}
            {this.props.restricted(
              <Button
                link={true}
                deleteButton={true}
                additionClassName="profile_edit_dialog__buttons__delete"
                onClick={this.props.showDeleteOverlay}
                text={_(`delete`)}
              />
            )}
            <Button
              link={true}
              additionClassName="profile_edit_dialog__buttons__cancel"
              onClick={() => {
                this.props.close();
              }}
              text={_(`cancel`)}
            />
            <Button onClick={this.clickSave} text={_(`save`)} />
          </div>
        )}
        {!this.props.isNew && (
          <div className="profile_edit_dialog__buttons">
            <Button
              onClick={() => {
                this.changeOrganization({
                  needsToFinishRegistration: !organization.needsToFinishRegistration
                });
                Services.Organizations.changeLiveStatus(
                  organization.id,
                  !organization.needsToFinishRegistration
                );
              }}
              text={
                organization.needsToFinishRegistration
                  ? "Make Live on the TimePeace app"
                  : "Remove from the TimePeace app"
              }
            />
          </div>
        )}
        <OverlayConfirm
          alert={true}
          show={!isEmpty(this.state.overlayError)}
          message={this.state.overlayError}
          onYes={() => {
            this.setState({ overlayError: "" });
          }}
        />
        <Modal isOpen={this.state.isIconLoaded} testId="crop-image">
          <h1>
            <div
                className="event_dialog__close_btn"
                onClick={e => {
                  e.preventDefault();
                  this.setState({ isIconLoaded: false });
                }}
            />

            Crop your Icon </h1>
          <div className="profile_edit__image_cell__crop_image">
            <Button
                link={false}
                onClick={async () => {
                  await this.onAcceptCropIcon();
                }}
                text="Accept Crop"
            />
          </div>
          <div className="profile_edit__image_crop">
            <ReactCrop crop={this.state.cropIcon} onChange={(crop, percentageCrop) => this.onCropChangeIcon(crop, percentageCrop)} locked>
              <img ref={this.state.iconRef} src={this.state.organization.icon} alt=".."/>
            </ReactCrop>
          </div>
        </Modal>

        <Modal isOpen={this.state.isBannerLoaded} testId="crop-image">
          <h1>
            <div
                className="event_dialog__close_btn"
                onClick={e => {
                  e.preventDefault();
                  //this.props.close();
                  this.setState({ isBannerLoaded: false });
                }}
            />

            Crop your Banner </h1>

          <div className="profile_edit__image_cell__crop_image">
            <Button
                link={false}
                onClick={async () => {
                  await this.onAcceptCropBanner();
                }}
                text="Accept Crop"
            />
          </div>
          <div className="profile_edit__image_crop">
            <ReactCrop crop={this.state.cropBanner} onChange={(crop, percentageCrop) => this.onCropChangeBanner(crop, percentageCrop)} locked>
              <img ref={this.state.bannerRef} src={this.state.organization.photo}  alt=".."/>
            </ReactCrop>
          </div>
        </Modal>
      </div>
    );
  }
  uploadFile(
    path: string,
    fileContent: string,
    onSuccess: (url: string) => any
  ) {
    return firebase
      .storage()
      .ref(`/${path}/${this.state.organization.id}${new Date().getTime()}`)
      .putString(fileContent, "data_url")
      .then(result => {
        return result.ref.getDownloadURL().then(result => {
          onSuccess(result);
          //console.log('success', result);
          return result;
        });
      });
  }
}
export default compose<any, { isNew?: boolean }>(
  connect(
    (state: AppState) => ({
      user: state.currentUser.user,
      organization: getSelectedOrganization(state)
    }),
    dispatch => ({
      close() {
        dispatch(goBack());
      },
      update(orga: Organization) {
        dispatch(updateOrganization(orga));
      },
      saveNew(orga: Organization) {
        dispatch(createOrganization(orga));
      },
      delete(orga: Organization) {
        dispatch(deleteOrganization(orga));
      }
    })
  ),
  withLocalization("dialog.profile"),
  withRestriction(),
  withConfirm<
    LocaleProps & {
      delete: (orga: Organization) => any;
      organization: Organization;
    }
  >([
    {
      name: "delete",
      message: props =>
        props._("confirm.delete", {
          orgName: props.organization ? props.organization.name : ""
        }),
      onYes: props => {
        props.delete(props.organization);
      }
    }
  ])
)(Dialog);
