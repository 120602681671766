import * as React from "react";
import { compose } from "recompose";
import { LocaleProps, withLocalization } from "../../i18n/localizable";
import { Link } from "react-router-dom";
const pathLogo = require("../../layout/images/logo.png");
import "./layout.less";
export default compose<LocaleProps, any>(withLocalization())(props => {
  const { _ } = props;
  return (
    <div className="payments_layout">
      <div className="payments_layout__left">
        <div className="payments_layout__left__logo">
        <Link to={'/'} >
          <img className="payments_layout__left__logo__img" src={pathLogo} />
          </Link>
        </div>
        <h3>{_("payments.page.sub.header")}</h3>
      </div>
      <div className="payments_layout__right">
        <div className="payments_layout__right__content">{props.children}</div>
      </div>
    </div>
  );
});
