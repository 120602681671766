import { EventsApi, NotificationInfo } from "../services/event.api.services";
import {
  EventCreationInfo,
  EventNode,
  parseEventNode,
  EventDeleteOptions
} from "../model/event.node";
import firebase from "../firebase";
import Event from "../../models/event";
import { CalendarApi } from "../services/calendar.api.services";
import { CategoryApi } from "../services/category.api.service";
import { AsyncResult } from "../services/async.result";
import * as moment from "moment-timezone";
import {
  RepeatDuration,
  EventRecursionInfo,
  EventRecursionInfoJson,
  AfterType
} from "../model/recursion.info";
import {
  diffList,
  indexByText,
  indexByTextWithMap,
  listChanged
} from "../../utils/list.utils";

export class EventsApiImpl implements EventsApi {
  calendarApi: CalendarApi;
  categoryApi: CategoryApi;
  constructor(calendarApi: CalendarApi, categoryApi: CategoryApi) {
    this.calendarApi = calendarApi;
    this.categoryApi = categoryApi;
  }
  formatCalendarEventDate(eventStart?: moment.Moment) {
    return eventStart.format("YYYYMM");
  }
  private calendarEventRefPath(
    calendarId: string,
    eventStart?: moment.Moment,
    eventKey?: string,
    recurring: boolean = false,
    prefix?: string
  ) {
    let path = `${prefix || "Calendar_Event"}/${calendarId}`;
    if (eventStart) {
      if (recurring) {
        path = `${path}/recurring`;
      } else {
        path = `${path}/${this.formatCalendarEventDate(eventStart)}`;
      }

      if (eventKey) {
        path = `${path}/${eventKey}`;
      }
    }
    return path;
  }

  calendarEventRef(
    calendarId: string,
    eventStart?: moment.Moment,
    eventKey?: string,
    recurring: boolean = false
  ) {
    return firebase
      .database()
      .ref(
        this.calendarEventRefPath(calendarId, eventStart, eventKey, recurring)
      );
  }

  addEvent(
    info: EventCreationInfo,
    notification?: NotificationInfo
  ): Promise<any> {
    const eventsRef = this.ref();
    const node = this.createEventNodeFromInfo(info);

    const eventPromise = eventsRef.push(node);
    const eventKey = eventPromise.key;

    const addPromises = this.setAllCalendarsRef(eventKey, info);

    return eventPromise
      .then(() => Promise.all(addPromises))
      .then((val: any) => {
        if (node.createsUpdateNotification) {
          fetch(
            `/api/events/event-created/${info.organizationId}/${eventKey}`,
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
              },
              body: JSON.stringify({ customNotification: notification || null })
            }
          );
        }

        return val;
      }) as Promise<any>;
  }
  ref(eventId?: string) {
    let ref = firebase.database().ref("Events");
    if (eventId) {
      ref = ref.child(eventId);
    }
    return ref;
  }

  listenEvent(key: string, callback: (ev: Event) => any) {
    const eventRef = this.ref(key);
    const cb = (nodeSnapshot: firebase.database.DataSnapshot) => {
      let event = nodeSnapshot.val() as EventNode;
      event = Object.assign({ key: nodeSnapshot.key }, event);
      event.exists = nodeSnapshot.exists();
      const ev = parseEventNode(key, event);
      callback(ev);
    };
    eventRef.on("value", cb);
    return () => {
      eventRef.off("value", cb);
    };
  }

  getEvent(key: string, deleted?: boolean): Promise<Event> {
    const eventRef = deleted ? this.deletedRef(key) : this.ref(key);
    return eventRef
      .once("value")
      .then((nodeSnapshot: firebase.database.DataSnapshot) => {
        let event = nodeSnapshot.val() as EventNode;
        event = Object.assign({ key: nodeSnapshot.key }, event);
        event.exists = nodeSnapshot.exists();
        const parsed = parseEventNode(key, event);

        return parsed;
      })
      .catch(err => {
        console.log("error", err);
        return null;
      });
  }
  createEventNodeFromInfo(info: EventCreationInfo) {
    const node: EventNode = {
      address: info.locationInfo ? info.locationInfo.address || "" : "",
      address2: info.locationInfo ?
        typeof info.locationDescription !== "undefined"
          ? info.locationDescription
          : info.locationInfo.address2 || "" : "",
      apartmentSuite: info.locationInfo ? info.locationInfo.apartmentSuite || "" : "",
      location: info.locationInfo ? info.locationInfo.location || "" : "",
      city: info.locationInfo ? info.locationInfo.city || "" : "",
      state: info.locationInfo ? info.locationInfo.state || "" : "",
      zip: info.locationInfo ? info.locationInfo.zip || "" : "",
      country: info.locationInfo ? info.locationInfo.country || "" : "",
      details: info.description || "",
      name: info.name || "",
      isAllDay: info.isAllDay,
      startDate: info.startDate.toISOString(),
      endDate: info.endDate.toISOString(),
      createsUpdateNotification: info.createPushNotification,
      //locationDescription: info.locationDescription,
      isRecurring: info.recurring && true,
      lat: info.locationInfo ? info.locationInfo.lat : 0,
      lng: info.locationInfo ? info.locationInfo.lng : 0,
      addressBoxText: info.locationInfo ? info.locationInfo.addressBoxText : "",
      isOnline: info.isOnline,
      calendarIds: indexByTextWithMap(info.calendarIds, c => c, () => true)
    };
    if (info.timeZone) {
      node.timezone = info.timeZone;
    }
    let recurringInfo: any = null;
    if (info.recurring) {
      recurringInfo = Object.assign({}, info.recurring);
      if (info.recurring.endsOn) {
        recurringInfo.endsOn = moment(info.recurring.endsOn).toISOString();
      }
      if (info.recurring.everyDuration === RepeatDuration.YEAR) {
        recurringInfo.repeatsOn = info.recurring.repeatsOn.map(r =>
          moment(r).toISOString()
        );
      }
      node.recursionInfo = recurringInfo;
    }

    return node;
  }

  removeAllCalendarRefs(originalEvent: Event) {
    const deletePromises = originalEvent.calendarIds.reduce(
      (proms: Promise<any>[], calId) => {
        proms.push(
          this.calendarEventRef(
            calId,
            originalEvent.startDate,
            originalEvent.id,
            originalEvent.isRecurring
          ).remove()
        );

        return proms;
      },
      []
    );
    return deletePromises;
  }
  setAllCalendarsRef(key: string, eventInfo: EventCreationInfo) {
    return eventInfo.calendarIds.reduce((proms: Promise<any>[], calId) => {
      proms.push(
        this.calendarEventRef(
          calId,
          eventInfo.startDate,
          key,
          !!eventInfo.recurring
        ).set(true)
      );

      return proms;
    }, []);
  }
  getCalendarEventsRefs(ev: Event, parentNode?: string) {
    return ev.calendarIds.map(calendarId => {
      return this.calendarEventRefPath(
        calendarId,
        ev.startDate,
        ev.id,
        ev.isRecurring,
        parentNode
      );
    });
  }
  updateEvent(
    key: string,
    eventInfo: EventCreationInfo,
    organizationId: string,
    changedFields?: string[],
    originalEvent?: Event,
    notification?: NotificationInfo
  ): Promise<any> {
    const node = this.createEventNodeFromInfo(eventInfo);
    console.log("node", node);
    const oldRefs = this.getCalendarEventsRefs(originalEvent);
    const newRefs = this.getCalendarEventsRefs(parseEventNode(key, node));
    const shouldIUpdateExternalReferences = listChanged(
      oldRefs,
      newRefs,
      (a, b) => a === b
    );
    const deletePromises = shouldIUpdateExternalReferences
      ? this.removeAllCalendarRefs(originalEvent)
      : [];
    const addPromises = shouldIUpdateExternalReferences
      ? this.setAllCalendarsRef(key, eventInfo)
      : [];
    if (node.recursionInfo && node.recursionInfo.removeDays) {
      node.recursionInfo.removeDays = node.recursionInfo.removeDays.map(((
        d: moment.Moment
      ) => d.toISOString()) as any);
      console.log("v", node);
    }
    return this.ref(key)
      .update(node)
      .then(() => Promise.all([...deletePromises, ...addPromises]))
      .then(() => {
        if (node.createsUpdateNotification) {
          fetch(`/api/events/event-updated/${organizationId}/${key}`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              changedField:
                changedFields && changedFields.length === 1
                  ? changedFields[0]
                  : null,
              originalValue:
                changedFields &&
                changedFields.length === 1 &&
                (changedFields[0] === "start-date" ||
                  changedFields[0] === "start-time")
                  ? originalEvent.startDate.toISOString()
                  : null,
              customNotification: notification || null
            })
          });
        }
        return true;
      });
  }

  deleteEvent(
    key: string,
    organizationId: string,
    deleteOption: EventDeleteOptions = EventDeleteOptions.All,
    date: moment.Moment = moment(),
    notification?: NotificationInfo
  ) {
    return this.getEvent(key).then(event => {
      let notificationPromise: Promise<any> = Promise.resolve(0);
      if (notification) {
        notificationPromise = fetch(
          `/api/events/event-deleted/${organizationId}/${key}/${date.valueOf()}`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            },
            body: JSON.stringify({ customNotification: notification || null })
          }
        );
      }

      if (deleteOption === EventDeleteOptions.All || !event.isRecurring) {
        return notificationPromise
          .then(() => this.removeAllCalendarRefs(event))
          .then(() => this.ref(key).once("value"))
          .then((data: firebase.database.DataSnapshot) =>
            Promise.all([
              this.ref(key).remove(),
              this.deletedRef(key).set(data.val()),
              ...this.getCalendarEventsRefs(
                event,
                "Deleted_Calendar_Event"
              ).map(ref =>
                firebase
                  .database()
                  .ref(ref)
                  .set(true)
              )
            ])
          );
      } else {

        // EventDeleteOptions.ThisOcurrence
        event.recursionInfo.removeDays = event.recursionInfo.removeDays
        ? [...event.recursionInfo.removeDays]
        : [];
        event.recursionInfo.removeDays.push(moment(date).startOf("day"));

        if (deleteOption === EventDeleteOptions.Future) {
          event.recursionInfo.endsOn = moment(date)
            .startOf("day")
            .toDate();
          event.recursionInfo.afterType = AfterType.ON;
        }
        const newRecursionInfo: EventRecursionInfoJson = event.getRecursionInfoJson();

        this.ref(key).update({ recursionInfo: newRecursionInfo });
      }
    });
  }
  deletedRef(key: string) {
    return firebase.database().ref(`Deleted_Events/${key}`);
  }
  onCalendarEventsChanged(
    calendarId: string,
    startDate: moment.Moment,
    endDate: moment.Moment,
    onChange: (e: { value: string[] }) => any
  ) {
    const eventRef = this.eventsOfCalendarsBetweenDates(
      calendarId,
      startDate,
      endDate
    );

    eventRef.on("value", (nodeSnapshot: firebase.database.DataSnapshot) => {
      let events = nodeSnapshot.val() as {
        [id: string]: { [id: string]: boolean };
      };
      if (events) {
        Promise.all(
          Object.keys(events).reduce((ids: string[], dateKey) => {
            ids.push(...Object.keys(events[dateKey]));
            return ids;
          }, [])
        ).then(eventIds => {
          onChange({ value: eventIds });
        });
      } else {
        onChange({ value: [] });
      }
    });
    return () => {
      eventRef.off("value");
    };
  }
  eventsOfCalendarsBetweenDates(
    calendarId: string,
    startDate: moment.Moment,
    endDate: moment.Moment,
    deleted?: boolean
  ) {
    return (deleted
      ? firebase.database().ref(`Deleted_Calendar_Event/${calendarId}`)
      : this.calendarEventRef(calendarId)
    )
      .orderByKey()
      .startAt(this.formatCalendarEventDate(startDate))
      .endAt(this.formatCalendarEventDate(endDate) + "\uf8ff");
  }

  getEventsOfCalendar(
    calendarId: string,
    startDate: moment.Moment,
    endDate: moment.Moment,
    deleted?: boolean
  ): Promise<Event[]> {
    return Promise.all([
      this.eventsOfCalendarsBetweenDates(
        calendarId,
        startDate,
        endDate,
        deleted
      )
        .once("value")
        .then((nodeSnapshot: firebase.database.DataSnapshot) => {
          let events = nodeSnapshot.val() as {
            [id: string]: { [id: string]: boolean };
          };
          const eventIds: string[] = [];
          if (events) {
            Object.keys(events).forEach(dateK => {
              Object.keys(events[dateK]).forEach(eventId =>
                eventIds.push(eventId)
              );
            });
          }

          return eventIds;
        }),
      (deleted
        ? firebase
            .database()
            .ref(`/Deleted_Calendar_Event/${calendarId}/recurring`)
        : this.calendarEventRef(calendarId).child("recurring")
      )
        .once("value")
        .then((nodeSnapshot: firebase.database.DataSnapshot) => {
          let eventIds = nodeSnapshot.val() as { [id: string]: boolean };
          return eventIds ? Object.keys(eventIds) : [];
        })
    ])
      .then(([singleEventIds, recurringIds]) => {
        const allIds = [].concat(singleEventIds).concat(recurringIds);
        //console.log("all", allIds);
        return Promise.all(allIds.map(evId => this.getEvent(evId, deleted)));
      })
      .then(allEvents => {
        return allEvents.filter(
          ev => ev.exists && ev.isBetweenDates(startDate, endDate)
        );
      });
    /*
    return new Promise<Event[]>((resolve, reject) => {
      
      const eventRef = firebase
        .database()
        .ref('Events')
        .orderByChild('calendarID_endDate')
        .startAt(`${calendarId}_${startDate.toISOString()}`)
        .endAt(`${calendarId}_${endDate.toISOString()}`);
      eventRef.once('value', (nodeSnapshot: firebase.database.DataSnapshot) => {
        let events = nodeSnapshot.val() as { [id: string]: EventNode };

        const result = events ? Object.keys(events).map(eventId => parseEventNode(eventId, events[eventId])) : [];

        resolve(result);
      });
    }); */
  }
}
