import rsf from "./firebase";
import {
  put,
  call,
  take,
  fork,
  takeEvery,
  all,
  select,
  takeLatest,
  Effect,
  PutEffect,
} from "redux-saga/effects";
import {
  loginSuccess,
  loginError,
  logoutSuccess,
  logoutError,
  resetPasswordSuccess,
  resetPasswordError,
} from "../actions/auth.actions";
import { Actions } from "../state/actions";
import { Action, createAction } from "redux-actions";
import { User } from "../models/User";
import {
  synchOrganizations,
  synchOrganizationInfo,
  selectOrganization,
  updateRequests,
} from "../actions/organization.actions";
import { syncCategoriesOfOrganization } from "../actions/category.actions";
import {
  OrganizationNode,
  parseOrganizationNode,
  toOrganizationNode,
} from "../api/model/organization.node";
import Organization from "../models/organization";
import { AppState } from "../state/state";
import {
  OrganizationRequestNode,
  parseOrgRequestNode,
} from "../api/model/organization.request";
import { push } from "connected-react-router";
import { EventChannel } from "redux-saga";

function* listenOrgaChannel(
  orgaId: string,
  action: { value: OrganizationNode }
) {
  const state: AppState = yield select();
  if (action.value) {
    const orga = parseOrganizationNode(orgaId, action.value);
    yield put(synchOrganizationInfo(orga));
    const appState: AppState = yield select();
    const adminNeedsToFinishRegistration =
      action.value &&
      action.value.needsToFinishRegistration &&
      state.currentUser &&
      state.currentUser.user &&
      action.value.createdBy === state.currentUser.user.id;

    if (
      !appState.organization.selectedOrganization ||
      adminNeedsToFinishRegistration
    ) {
      yield put(selectOrganization(orga.id));
      /*if (adminNeedsToFinishRegistration) {
        const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
        yield delay(500);
        yield put(push("/organization/edit"));
      }*/
    } else if (
      appState.organization.selectedOrganization &&
      appState.organization.selectedOrganization.id === orga.id
    ) {
      yield put(
        syncCategoriesOfOrganization(orga, Object.keys(orga.categories))
      );
    }
    //yield put(syncCategoriesOfOrganization(orga, Object.keys(orga.categories)));
  } else {
    yield put(synchOrganizationInfo(null));
    yield put(selectOrganization(null));
  }
}
let organizationChannels: { close: () => any }[] = [];
function* listenSpecificOrganization(organizationId: string) {
  const channel: EventChannel<OrganizationNode> = yield call(
    rsf.database.channel,
    `Organizations/${organizationId}`
  );

  organizationChannels.push(channel);
  yield takeEvery(
    channel,
    (action: { value: OrganizationNode }) =>
      listenOrgaChannel(organizationId, action)
    //  listenCategoryChannel(calendarId, action)
  );
}

function* listenOrganizationsSaga(action: Action<{ ids: string[] }>) {
  const { ids } = action.payload;
  const organizationId = ids[0];
  yield all(ids.map((id) => call(listenSpecificOrganization, id)));

  yield take(Actions.AUTH.LOGOUT_REQUEST);
  organizationChannels.forEach((c) => c.close());
  organizationChannels = [];
}

function* listenAdminChannel(action: { value: { [name: string]: boolean } }) {
  const { value } = action;

  const organizationIds: string[] = Object.keys(value);

  yield put(synchOrganizations(organizationIds));
}

function* listenSuperAdminChannel(action: {
  value: { [name: string]: OrganizationNode };
}) {
  let first = true;
  const appState: AppState = yield select();

  yield all(
    Object.keys(action.value).map((orgId) => {
      const orga = parseOrganizationNode(orgId, action.value[orgId]);
      const actions: PutEffect<any>[] = [put(synchOrganizationInfo(orga))];
      if (first && !appState.organization.selectedOrganization) {
        actions.push(put(selectOrganization(orga.id)));
      } else if (
        appState.organization.selectedOrganization &&
        appState.organization.selectedOrganization.id === orgId
      ) {
        actions.push(
          put(syncCategoriesOfOrganization(orga, Object.keys(orga.categories)))
        );
      }
      first = false;
      return all(actions);
    })
  );
}
function* listenSelectOrga(action: Action<{ organizationId: string }>) {
  const state: AppState = yield select();
  const selected = state.organization.selectedOrganization;
  if (selected) {
    yield put(
      syncCategoriesOfOrganization(selected, Object.keys(selected.categories))
    );
  }
}
function* listenUserSaga(action: Action<{ user: User }>) {
  const { user } = action.payload;
  try {
    if (user.admin) {
      const channel: EventChannel<Record<string, OrganizationNode>> =
        yield call(rsf.database.channel, `Organizations/`);
      yield takeEvery(channel, listenSuperAdminChannel);
      yield take(Actions.AUTH.LOGOUT_REQUEST);
      channel.close();
    } else {
      const channel: EventChannel<Record<string, boolean>> = yield call(
        rsf.database.channel,
        `Admin_Users/${user.id}`
      );
      yield takeEvery(channel, listenAdminChannel);
      yield take(Actions.AUTH.LOGOUT_REQUEST);
      channel.close();
    }
  } catch (ex) {
    console.error(ex);
  }
}
function* listenOrgaRequestChannel(action: {
  value: { [name: string]: OrganizationRequestNode };
}) {
  const parsedRequests = Object.keys(action.value || {}).map((key) => {
    const request = parseOrgRequestNode(action.value[key]);
    request.id = key;
    return request;
  });
  yield put(updateRequests(parsedRequests));
}
function* listenOrgaRequests(action: Action<{ user: User }>) {
  const { user } = action.payload;
  try {
    if (user.admin) {
      const channel: EventChannel<Record<string, OrganizationRequestNode>> =
        yield call(rsf.database.channel, `Organization_Requests/`);
      yield takeEvery(channel, listenOrgaRequestChannel);
      yield take(Actions.AUTH.LOGOUT_REQUEST);
      channel.close();
    }
  } catch (ex) {
    console.error(ex);
  }
}
function* updateOrga(action: Action<{ organization: Organization }>) {
  const { organization } = action.payload;
  const organizationNode: Partial<OrganizationNode> = {};
  Object.assign(organizationNode, organization);
  if (organization.personalContacts) {
    organizationNode.personalContacts = {
      personalContacts: organization.personalContacts,
    };
  } else {
    organizationNode.personalContacts = { personalContacts: [] };
  }

  delete organizationNode.categories;
  organizationNode.clickedSave = true;
  yield rsf.database.patch(
    `Organizations/${organization.id}`,
    organizationNode
  );
}
function* createOrga(action: Action<{ organization: Organization }>) {
  const { organization } = action.payload;

  yield rsf.database.create(`Organizations`, toOrganizationNode(organization));
}
function* deleteOrga(action: Action<{ organization: Organization }>) {
  yield call(
    rsf.database.delete,
    `Organizations/${action.payload.organization.id}`
  );
  const state: AppState = yield select();
  const allOrganizations = state.organization.organizations;
  if (allOrganizations && allOrganizations.length) {
    yield put(selectOrganization(allOrganizations[0].id));
  } else {
    yield put(selectOrganization(null));
  }
  yield put(push("/"));
}

function* organizationRootSaga() {
  yield all([
    takeLatest(Actions.AUTH.LOGIN_SUCCESS, listenUserSaga),
    takeLatest(Actions.AUTH.LOGIN_SUCCESS, listenOrgaRequests),
    takeEvery(Actions.ORGANIZATION.LOADED, listenOrganizationsSaga),
    takeEvery(Actions.ORGANIZATION.SELECT, listenSelectOrga),
    takeEvery(Actions.ORGANIZATION.UPDATE, updateOrga),
    takeEvery(Actions.ORGANIZATION.CREATE, createOrga),
    takeEvery(Actions.ORGANIZATION.DELETE, deleteOrga),
  ]);
}

export default [organizationRootSaga];
