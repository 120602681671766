import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "connected-react-router";
import { createLogger } from "redux-logger";
const logger = createLogger({
  duration: true,
  titleFormatter: (action: Action<any>, time: string, took: number) =>
    took > 1 ? `err ${action.type} ${took} ms` : "",
  collapsed: true
});
interface IWindow extends Window {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: any;
}
const composeEnhancers =
  (window as IWindow).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
import createSagaMiddleware from "redux-saga";
import reducers from "../reducers/index";
import sagas from "../sagas/index";

import { Action } from "redux-actions";
import { history } from "./history";

const routerMiddle = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();
// mount it on the Store
export const store = createStore(
  reducers,
  composeEnhancers(
    applyMiddleware(sagaMiddleware),
    applyMiddleware(routerMiddle)
  ) //, applyMiddleware(logger))
);
sagas.forEach(s => sagaMiddleware.run(s));
