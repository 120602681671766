import Organization from "./organization";

export interface Plan {
  id: string;
  name: string;
  pricePerMonth: number;
  pricePerYear: number;
  changeOption: ChangePlanOption;
  features: PlanFeature[];
}

export enum ChangePlanOption {
  Upgrade = "upgrade",
  Downgrade = "downgrade",
  Change = "change"
}

export interface PlanFeature {
  id: string;
}

export enum PlanIds {
  Free = "free",
  Full = "full"
}
const PlansById: { [id: string]: Plan } = {
  [PlanIds.Free]: {
    id: PlanIds.Free,
    changeOption: ChangePlanOption.Upgrade,
    name: "plans.free",
    pricePerMonth: 0,
    pricePerYear: 0,
    features: [
      { id: "1" },
      { id: "2" },
      { id: "3" },
      { id: "4" },
      { id: "5" },
      { id: "6" }
    ]
  },
  [PlanIds.Full]: {
    id: PlanIds.Full,
    changeOption: ChangePlanOption.Downgrade,
    name: "plans.premium",
    pricePerMonth: 10,
    pricePerYear: 12 * 10,
    features: [
      { id: "7" },
      { id: "8" },
      { id: "9" },
      { id: "10" },
      { id: "11" }
    ]
  }
};

export interface Feature {
  id: string;
  text: string;
}

export const AllFeatures: Feature[] = [
  { id: "1", text: "plans.features.1" },
  { id: "2", text: "plans.features.2" },
  { id: "3", text: "plans.features.3" },
  { id: "4", text: "plans.features.4" },
  { id: "5", text: "plans.features.5" },
  { id: "6", text: "plans.features.6" },
  { id: "7", text: "plans.features.7" },
  { id: "8", text: "plans.features.8" },
  { id: "9", text: "plans.features.9" },
  { id: "10", text: "plans.features.10" },
  { id: "11", text: "plans.features.11" }
];
export function getPlanById(planId: string) {
  return PlansById[planId] || PlansById[PlanIds.Free];
}

export function getOrganizationPlan(org: Organization) {
  if (!org) {
    return getPlanById(null);
  }
  if (
    org.subscriptionStatus &&
    org.subscriptionStatus.subscriptionStatus === "active" &&
    !org.subscriptionStatus.cancel_at_period_end
  ) {
    return getPlanById(PlanIds.Full);
  } else {
    return getPlanById(PlanIds.Free);
  }
  //return getPlanById(org ? org.planId : null);
}

export function getAllPlans() {
  return Object.values(PlansById);
}
