import * as React from 'react';

import Category from '../../../models/calendar.category';
import Calendar from '../../../models/calendar';
import Button from '../../input/button';
import Accordion from '../../accordion/accordion';
import { LocaleProps, localizable } from '../../i18n/localizable';
import { AppState } from '../../../state/state';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';
import { Services } from '../../../api/services';
import { AsyncResult } from '../../../api/services/async.result';

import { match } from 'react-router';
import SimpleEditionDialog from '../../simple-edition-dialog/simple.edition.dialog';
import { getOrganizationId, getCalendars } from '../../../state/state_getters';

interface CalendarDialogProps {
  add(categoryId: string, name: string, desc: string): AsyncResult<any>;
  update(id: string, name: string, desc: string): Promise<any>;
  delete(categoryId: string, id: string, orgId: string): Promise<any>;
  read(id: string): Promise<{ name: string; description: string }>;
  close: () => any;
  match?: match<{ key: string; categoryId: string }>;
  categoryId?: string;
  organizationId: string;
}

function Dialog(props: CalendarDialogProps) {
  const id = props.match.params.key;
  /*        props.allCalendars.some(
  c => c.id === id && ((c.events && c.events.length > 0) || (c.recurringEvents && c.recurringEvents.length > 0))
) */

  return (
    <SimpleEditionDialog
      baseKey="dialog.calendar"
      {...props}
      updKey={id}
      referalId={props.match.params.categoryId}
      nameErrorText={'Please provide a calendar name'}
      confirmDeleteMessage="dialog.calendar.confirm.delete"
      shouldConfirm={elem => true}
      delete={(catId, id) => props.delete(catId, id, props.organizationId)}
    />
  );
}

export default connect(
  (state: AppState) => ({
    organizationId: getOrganizationId(state)
  }),
  dispatch => ({
    add(categoryId: string, name: string, desc: string) {
      const calendarAdd = Services.Calendar.addCalendar(name, desc);
      Services.Category.addCalendar(categoryId, calendarAdd.key);
      return calendarAdd;
    },
    update(id: string, name: string, desc: string) {
      return Services.Calendar.updateCalendar(id, name, desc);
    },
    delete(id: string, categoryId: string, orgId: string) {
      return Services.Calendar.deleteCalendar(id, categoryId, orgId);
    },
    close() {
      dispatch(goBack());
    },
    read(id: string) {
      return Services.Calendar.getCalendar(id).then(calNode => ({
        name: calNode.name,
        description: calNode.description
      }));
    }
  })
)(Dialog);
