import * as React from "react";
import Organization from "../../../models/organization";
import { Services } from "../../../api/services";
import organizationSaga from "../../../sagas/organization.saga";
interface Props {
  onSelect: (o: Organization) => any;
  selected: Organization;
  renderBox?: (o: Organization) => any;
  updatedOrganizations?: { [id: string]: Organization };
}
interface State {
  organizations: Organization[];
}
export default class OrganizationList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { organizations: [] };
  }
  componentDidMount() {
    Services.Organizations.findAll()
      .then(organizations => {
        this.setState({ organizations });
      })
      .catch(err => {});
  }

  render() {
    const { selected } = this.props;
    const { organizations } = this.state;
    return (
      <ul className="organization_list">
        {organizations.map(oh => {
          const o =
            (oh && oh.id && this.props.updatedOrganizations
              ? this.props.updatedOrganizations[oh.id]
              : oh) || oh;
          //console.log(o.id, this.props.updatedOrganizations[oh.id]);
          return (
            o.name && (
              <li
                className={`organization_list__item ${
                  selected && selected.id === o.id
                    ? "organization_list__item--selected"
                    : ""
                }`}
                onClick={() => {
                  this.props.onSelect(o);
                }}
                key={`Organization_${o.id}`}
              >
                <div className="org_list_item_div">{o.name}</div>
                {this.props.renderBox && (
                  <div className="org_list__aditional__item">
                    {this.props.renderBox && this.props.renderBox(o)}
                  </div>
                )}
              </li>
            )
          );
        })}
      </ul>
    );
  }
}
