import { handleActions } from 'redux-actions';
import { CurrentUserState } from '../state/state';
import { Actions } from '../state/actions';
export default handleActions<CurrentUserState, any>(
  {
    [Actions.AUTH.LOGIN_SUCCESS]: (state: CurrentUserState, action) => {
      return { ...state, user: action.payload.user, pendingAuthRetrival: false };
    },
    [Actions.AUTH.SYNC_USER_NO_DATA]: (state: CurrentUserState, action) => {
      return { ...state, pendingAuthRetrival: false };
    },
    [Actions.AUTH.LOGIN_ERROR]: (state: CurrentUserState, action) => {
      return { ...state, user: null, loginError: action.payload.errorType };
    },
    [Actions.AUTH.LOGOUT_SUCCESS]: (state: CurrentUserState, action) => {
      return { ...state, user: null };
    },
    [Actions.AUTH.RESET_PASSWORD]: (state: CurrentUserState, action) => {
      return { ...state, resetPasswordStatus: { pending: true, email: action.payload.email } };
    },
    [Actions.AUTH.RESET_PASSWORD_SUCCESS]: (state: CurrentUserState, action) => {
      return { ...state, resetPasswordStatus: { pending: false, email: state.resetPasswordStatus.email } };
    },
    [Actions.AUTH.RESET_PASSWORD_ERROR]: (state: CurrentUserState, action) => {
      return {
        ...state,
        resetPasswordStatus: { pending: false, email: state.resetPasswordStatus.email, error: action.payload.errorType }
      };
    }
  },
  { user: null, pendingAuthRetrival: true }
);
