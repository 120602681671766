import * as React from 'react';
import AutoSelect from './auto.select.input';
import * as moment from 'moment-timezone';

import './styles/time-picker.less';

interface TimePickerProps {
  onChange: (d: moment.Duration) => any;
  show?: boolean;
  value?: moment.Duration;
}
interface TimePickerState {
  value: string;
  focus: boolean;
}

const timeItems: moment.Duration[] = [];
let startDuration = moment.duration(0);
while (startDuration.asHours() < 24) {
  timeItems.push(startDuration.clone());
  startDuration = startDuration.add(15, 'minutes');
}
const TIME_REGEX = /^([0-9]{1,2})(:([0-9]{1,2}))?( )*(am|pm)?$/;
const MINUTE_REGEX = /^([0-9]{1,2}):([0-9]{1,2})$/;
const HOUR_REGEX = /^[0-9]{1,2}$/;

export default class TimePicker extends React.Component<TimePickerProps, TimePickerState> {
  menuDiv: HTMLDivElement;
  constructor(props: TimePickerProps) {
    super(props);
    this.state = { value: TimePicker.formatDuration(props.value), focus: false };
  }

  static getDerivedStateFromProps(props: TimePickerProps, state: TimePickerState): Partial<TimePickerState> {
    if (!state.focus) {
      return { value: TimePicker.formatDuration(props.value) };
    } else {
      return null;
    }
  }
  locateEnteredValue(val?: string) {
    const duration = this.paseDuration(val || this.state.value);
    if (duration) {
      const itemIdx = timeItems.reduce((minIdx: number, val, idx) => {
        if (
          minIdx === -1 ||
          Math.abs(val.asMinutes() - duration.asMinutes()) <
            Math.abs(timeItems[minIdx].asMinutes() - duration.asMinutes())
        ) {
          return idx;
        } else {
          return minIdx;
        }
      }, -1);
      let scrollIdx = itemIdx;
      if (itemIdx > 0) {
        scrollIdx = Math.max(itemIdx - 2, 0);
      }
      //console.log('idx', scrollIdx, duration.humanize(), timeItems[scrollIdx].humanize());
      this.menuDiv.querySelector('.auto_select__menu').scrollTop = scrollIdx * 40;
    }
  }
  valueChanged(val: string) {
    let newValue = val;
    const duration = this.paseDuration(val);
    if (duration) {
      newValue = TimePicker.formatDuration(duration);
    }
    this.locateEnteredValue(val);
    this.setState({ value: val });
  }
  static formatNumber(num: number, isMinutes:boolean) {
    if(isMinutes)
     if (num < 10) 
       return `0${num}`;
    return `${num}`;
  }
  blur = () => {
    const duration = this.paseDuration(this.state.value);
    let newValue = this.props.value ? TimePicker.formatDuration(this.props.value) : '';
    if (duration) {
      newValue = TimePicker.formatDuration(duration);
    }
    this.props.onChange(duration);
    this.setState({ value: newValue, focus: false });
  };
  static formatDuration(duration: moment.Duration) {
    if (!duration) return '';
    if (duration.asHours() < 12) {
      return `${this.formatNumber(duration.hours() || 12, false)}:${this.formatNumber(duration.minutes(), true)} am`;
    } else {
      return `${this.formatNumber(duration.hours() - 12 || 12, false)}:${this.formatNumber(duration.minutes(), true)} pm`;
    }
  }
  paseDuration(val: string): moment.Duration {
    const matches = val.match(TIME_REGEX);
    if (!matches) {
      return null;
    }
    const { hour, minutes, ampm } = this.interpretValueText(val);
    let aproxDuration = null;
    if (hour) {
      const hourNum = parseInt(hour);
      aproxDuration = moment.duration(hourNum, 'hour');
      if (ampm === 'pm' && hourNum !== 12) {
        aproxDuration.add(12, 'hours');
      } else if (ampm == 'am' && hourNum === 12) {
        aproxDuration = moment.duration(0, 'hour');
      } else if (!ampm && hourNum < 8) {
        aproxDuration.add(12, 'hours');
      }
      if (minutes) {
        aproxDuration.add(parseInt(minutes), 'minutes');
      }
    }
    return aproxDuration;
  }

  interpretValueText(val: string): { hour?: string; minutes?: string; ampm?: string } {
    const value = val || this.state.value;

    if (TIME_REGEX.test(value)) {
      const splittedByColon = value.split(':');
      let ampm = null;
      let minutes = null;
      let hour = null;
      if (splittedByColon.length > 1) {
        const splittedAmPm = splittedByColon[1].split(' ');
        hour = splittedByColon[0];
        minutes = splittedAmPm[0];
        ampm = splittedAmPm[1];
      } else {
        const splittedAmPm = splittedByColon[0].split(' ');
        hour = splittedAmPm[0];
        if (splittedAmPm.length > 1) {
          ampm = splittedAmPm[1];
        }
      }
      return { hour, minutes, ampm };
    } else if (MINUTE_REGEX.test(value)) {
      const splittedByColon = value.split(':');
      return { hour: splittedByColon[0], minutes: splittedByColon[1] };
    } else if (HOUR_REGEX.test(value)) {
      return { hour: value, minutes: null, ampm: null };
    }
    return {};
  }
  filterMatchingDurations(item: moment.Duration) {
    return true;
  }
  render() {
    //this.formatDuration(this.props.value)
    return (
      <AutoSelect
        items={timeItems.filter(item => this.filterMatchingDurations(item))}
        allItems={timeItems}
        show={this.props.show}
        value={this.state.value}
        getItemKey={(t: moment.Duration) => '' + t.asMilliseconds()}
        getItemText={(t: moment.Duration) => TimePicker.formatDuration(t)}
        onChange={val => this.valueChanged(val)}
        onBlur={this.blur}
        onFocus={() => {
          this.setState({ focus: true });
          setTimeout(() => {
            this.locateEnteredValue();
          });
        }}
        className="time-picker"
        placeHolder="Time"
        inputClass="time-picker__input"
        hideExpandButton={true}
        onRef={menuDiv => (this.menuDiv = menuDiv)}
      />
    );
    /*
    return (
      <Autocomplete
        wrapperProps={{
          className: 'time-picker'
        }}
        value={this.state.value}
        items={timeItems}
        onChange={e => this.valueChanged(e.target.value)}
        onSelect={val => this.valueChanged(val)}
        getItemValue={(item: moment.Duration) => this.formatDuration(item)}
        renderItem={(item: moment.Duration) => <div className="">{this.formatDuration(item)}</div>}
        inputProps={{
          className: 'time-picker__input'
        }}
      />
    );*/
  }
}
