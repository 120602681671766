import { combineReducers } from "redux";
import { AppState } from "../state/state";
import currentUser from "./current.user.reducer";
import calendars from "./calendar.reducer";
import loader from "./loader.reducer";
import organization from "./organization.reducer";
import allCategories from "./categories.reducer";
import { connectRouter } from "connected-react-router";
import uiReducer from "./ui.reducer";
import eventsReducer from "./events.reducer";
import { history } from "../state/history";

export default combineReducers<AppState>({
  currentUser,
  router: connectRouter(history),
  loading: loader,
  calendars,
  organization,
  allCategories,
  ui: uiReducer,
  events: eventsReducer
});
