import * as React from "react";
import OrganizationList from "./organization.list";
import Organization from "../../../models/organization";
import { User } from "../../../models/User";
import UserList from "./user.list";
import { Services } from "../../../api/services";
import "./admin.less";
import Button from "../../input/button";
import TextBoxWithLabel from "../../input/input.label";
import { isEmpty } from "../../../utils/string.utils";
import { LocaleProps, localizable } from "../../i18n/localizable";
import { NewUser } from "./new.user";
import { connect } from "react-redux";
import { AppState } from "../../../state/state";

import { getSelectedOrganization } from "../../../state/state_getters";

type Props = {
  user: User;
  organization: Organization;
} & LocaleProps;

interface State {
  organization: Organization;
  users: User[];
  loading: boolean;
}

class UserAdmin extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      organization: props.organization,
      users: [],
      loading: false
    };
    if (props.organization) {
      this.onSelectOrganization(props.organization);
    }
  }
  onSelectOrganization = (organization: Organization) => {
    this.setState({ users: [], organization, loading: true });
    Services.Users.administratorsOfOrganization(organization.id).then(users =>
      this.setState({ users:(users||[]).filter(u=>!!u), loading: false })
    );
  };
  onUserChange = (checked: boolean, user: User) => {
    if (!this.state.organization) {
      return;
    }
    Services.Users.setAdministratorOfOrganization(
      checked,
      user,
      this.state.organization.id
    ).then(() => {
      if (checked) {
        this.setState({ users: [...this.state.users, user] });
      } else {
        this.setState({
          users: this.state.users.filter(u => u.id !== user.id)
        });
      }
    });
  };

  render() {
    const { _, user, organization } = this.props;
    if (!user.admin && (!organization || organization.createdBy !== user.id)) {
      return null;
    }
    return (
      <div className="admins_page">
        <h2 className="admins_page_title">{_("user.admin.title")}</h2>
        <div
          className={`admins_page__content ${
            user.admin ? "admins_page__content--with-org-selector" : ""
          }`}
        >
          {user.admin && (
            <OrganizationList
              onSelect={this.onSelectOrganization}
              selected={this.state.organization}
            />
          )}
          
        </div>

        <UserList
            users={this.state.users}
            onChange={this.onUserChange}
            disabled={this.state.loading || !this.state.organization}
            onlyByEmail={!this.props.user.admin}
            withSearch={false}
            notAbleToDelete={
              this.state.organization ? [this.state.organization.createdBy] : []
            }
          />

        <NewUser
          organizationId={
            !user.admin ? (organization ? organization.id : null) : null
          }
          showOnlyOnClick={true}
        />
        <h2>Search Users</h2>
        <UserList
          users={[]}
          onChange={this.onUserChange}
          disabled={this.state.loading || !this.state.organization}
          onlyByEmail={!this.props.user.admin}
          withSearch={true}
          notAbleToAdd={(this.state.users || []).map(u => (u ? u.id : ""))}
        />
      </div>
    );
  }
}
export default connect((state: AppState) => ({
  organization: getSelectedOrganization(state),
  user: state.currentUser.user
}))(localizable(UserAdmin));
