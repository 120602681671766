import { handleActions, Action } from 'redux-actions';
import { CurrentUserState, OrganizationState } from '../state/state';
import { Actions } from '../state/actions';
import Organization from '../models/organization';
import CalendarCategory from '../models/calendar.category';
import Calendar from '../models/calendar';

export default handleActions<CalendarCategory[], any>(
  {
    [Actions.CATEGORIES.SYNCH]: (
      state: CalendarCategory[],
      action: Action<{ organization: Organization; ids: string[] }>
    ) => {
      return action.payload.ids.map(id => {
        const cat = new CalendarCategory();
        const oldCategory = state.find(c => c.id == id);

        cat.id = id;
        cat.parent = action.payload.organization;
        if (oldCategory) {
          cat.calendars = oldCategory.calendars;
          cat.description = oldCategory.description;
          cat.name = oldCategory.name;
        }
        return cat;
      });
    },
    [Actions.CATEGORIES.INFO_LOADED]: (state: CalendarCategory[], action: Action<{ category: CalendarCategory }>) => {
      const { category } = action.payload;
      return state
        .map(c => {
          if (c.id === category.id) {
            const newCategory = new CalendarCategory();
            Object.assign(newCategory, category);
            if (c.calendars && !newCategory.calendars) {
              newCategory.calendars = c.calendars;
            }

            newCategory.parent = c.parent;
            return newCategory;
          } else {
            return c;
          }
        })
        .sort(CalendarCategory.defaultSort);
    },
    [Actions.CATEGORIES.REORDER]: (state: CalendarCategory[], action: Action<{ newOrder: string[] }>) => {
      const { newOrder } = action.payload;
      return state
        .map(c => {
          const newCategory = new CalendarCategory();
          Object.assign(newCategory, c);
          newCategory.order = newOrder.indexOf(c.id);
          if (newCategory.order >= 0) {
            newCategory.order += 1;
          }
          return newCategory;
        })
        .sort(CalendarCategory.defaultSort);
    },
    [Actions.ORGANIZATION.SELECT]: (state: CalendarCategory[], action: Action<{ organizationId: string }>) => {
      return [];
    },
    [Actions.AUTH.LOGOUT_SUCCESS]: (state: CalendarCategory[], action: Action<{ organizationId: string }>) => {
      return [];
    }
  },
  []
);
