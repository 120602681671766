import firebase from '../firebase';
import { CalendarApi } from '../services/calendar.api.services';
import { AsyncResult } from '../services/async.result';
import { CalendarNode, parseCalendarNode } from '../model/calendar.node';
import Calendar from '../../models/calendar';

import { EventsApi } from '../services/event.api.services';

export class CalendarApiImpl implements CalendarApi {
  eventService: EventsApi;
  setEventService(serv: EventsApi) {
    this.eventService = serv;
  }
  addCalendar(name: string, details: string): AsyncResult<any> {
    return this.ref().push({
      name,
      details
    });
  }

  updateCalendar(calendarId: string, name: string, details: string): Promise<any> {
    return this.ref(calendarId).update({ name, details });
  }
  updateOrder(calendarId: string, order: number) {
    return this.ref(calendarId).update({ order });
  }
  deleteCalendar(calendarId: string, categoryId: string,orgId:string): Promise<any> {
    firebase
      .database()
      .ref('Events')
      .orderByChild('calendarID')
      .equalTo(calendarId)
      .once('value', e => {
        if (e) {
          const value = e.val() as { [name: string]: any };
          const eventIds = Object.keys(value || {});
          eventIds.forEach(eId => {
            this.eventService.deleteEvent(eId,orgId);
          });
        }
      });

    firebase
      .database()
      .ref(`Categories/${categoryId}/calendars/${calendarId}`)
      .remove();

    return this.ref(calendarId).remove();
  }

  getCalendar(id: string): Promise<Calendar> {
    return new Promise<Calendar>((resolve, reject) => {
      const calRef = this.ref(id);
      calRef.once('value', (nodeSnapshot: firebase.database.DataSnapshot) => {
        let cal = nodeSnapshot.val() as CalendarNode;

        cal = Object.assign({ key: nodeSnapshot.key }, cal);

        resolve(parseCalendarNode(cal));
      });
    });
  }

  ref(calId?: string) {
    let ref = firebase.database().ref('Calendars');
    if (calId) {
      ref = ref.child(calId);
    }
    return ref;
  }
}
