import * as React from 'react';

import Button from '../../input/button';
import { LocaleProps, localizable } from '../../i18n/localizable';
import { AppState } from '../../../state/state';
import { connect } from 'react-redux';
import OrganizationRequest from '../../../models/organization.request';
import Organization from '../../../models/organization';
import { isEmpty } from '../../../utils/string.utils';
import Loader from '../../loader/loader';
declare const FUNCTIONS_BASE: string;
interface Props {
  requests: OrganizationRequest[];
}
interface State {
  selectedRequest?: OrganizationRequest;
  loading: boolean;
}
class OrganizationRequestsPage extends React.Component<Props & LocaleProps, State> {
  constructor(props: Props & LocaleProps) {
    super(props);
    this.state = {
      loading: false
    };
  }
  getDetail(
    label: string,
    condition: (organization: Organization) => boolean,
    value: (organization: Organization) => string
  ) {
    const { _ } = this.props;
    if (condition(this.state.selectedRequest.organization)) {
      return (
        <div className="orga_requests__page__details__line">
          <label>{_(`org.requests.${label}`)}:</label>
          {value(this.state.selectedRequest.organization)}
        </div>
      );
    }
    return null;
  }
  clickReject = () => {
    this.setState({ loading: true });
    return fetch(`${FUNCTIONS_BASE}rejectOrganization`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: this.state.selectedRequest.id })
    })
      .then(result => {
        this.setState({ selectedRequest: null, loading: false });
      })
      .catch(() => {
        alert('There was an error while trying to approve the request');
      });
  };
  clickApprove = () => {
    this.setState({ loading: true });
    return fetch(`${FUNCTIONS_BASE}approveOrganization`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: this.state.selectedRequest.id })
    })
      .then(result => {
        this.setState({ selectedRequest: null, loading: false });
      })
      .catch(() => {
        alert('There was an error while trying to approve the request');
      });
  };
  getOrganizationDetails(): JSX.Element[] {
    return [
      this.getDetail('name', () => true, org => org.name),
      this.getDetail('type', org => !isEmpty(org.type), org => org.type),
      this.getDetail('description', org => !isEmpty(org.description), org => org.description),
      this.getDetail('address', org => !isEmpty(org.address), org => org.address),
      this.getDetail('city', org => !isEmpty(org.city), org => org.city),
      this.getDetail('state', org => !isEmpty(org.state), org => org.state),
      this.getDetail('zip', org => !isEmpty(org.zip), org => org.zip)
    ].filter(e => !!e);
  }
  getOrganizationPage(): JSX.Element {
    const { _ } = this.props;
    return (
      <div className="orga_requests__page__details__content">
        <h2>Request details</h2>
        <div className="orga_requests__page__details__details">{this.getOrganizationDetails()}</div>
        <div className="orga_requests__page__details__buttons">
          <Loader show={this.state.loading} inline={true} />
          <Button
            link={true}
            additionClassName="simple_edition_dialog__buttons__delete"
            onClick={this.clickReject}
            text={_(`org.requests.reject`)}
          />

          <Button onClick={this.clickApprove} text={_(`org.requests.approve`)} />
        </div>
      </div>
    );
  }
  render() {
    const { _, requests } = this.props;
    console.log(requests);
    if (!requests || !requests.length) {
      return <h2> No requests</h2>;
    }
    return (
      <div className="orga_requests__page">
        <div>
          <h2>Requests</h2>
          <ul className="orga_requests__page__request_list">
            {requests.map(r => (
              <li
                className="orga_requests__page__request_list__item"
                onClick={() => this.setState({ selectedRequest: r })}
              >
                <div>
                  {r.organization.name} ({r.user.email})
                </div>
                <div>
                  {r.organization.address},{r.organization.city},{r.organization.state}, {r.organization.zip}
                </div>
                <div>{r.organization.notes}</div>
              </li>
            ))}
          </ul>
        </div>
        <div className="orga_requests__page__details">
          {this.state.selectedRequest ? (
            this.getOrganizationPage()
          ) : (
            <div className="orga_requests__page__details__select">Please select an organization</div>
          )}
        </div>
      </div>
    );
  }
}

export default connect((state: AppState) => ({
  requests: state.organization.requests
}))(localizable(OrganizationRequestsPage));
