import { handleActions, Action } from 'redux-actions';
import { CurrentUserState, OrganizationState, EventState } from '../state/state';
import { Actions } from '../state/actions';
import Organization from '../models/organization';
import CalendarCategory from '../models/calendar.category';
import Calendar from '../models/calendar';
import Event from '../models/event';

export default handleActions<EventState, any>(
  {
    [Actions.EVENT.SYNCH]: (state, action: Action<{ calendarId: string; events: string[] }>) => {
      const newState = { ...state };

      return newState;
    },
    [Actions.EVENT.SYNCH_EVENT_INFO]: (state, action: Action<{ event: Event }>) => {
      const event = action.payload.event;

      return { ...state, [event.id]: event };
    }
  },
  {}
);
