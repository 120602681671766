import * as React from 'react';
import { LocaleProps, localizable } from '../../i18n/localizable';
import Button from '../../input/button';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
export default connect(
  () => ({}),
  dispatch => ({
    goToLogin() {
      dispatch(push('/login'));
    }
  })
)(
  localizable((props: { goToLogin: () => any } & LocaleProps) => {
    const { _ } = props;
    return (
      <div>
        <h1 className="login_page__left__get_started">{_('verify.title')}</h1>
        <div className={`login_page__status login_page__status--visible`}>{_('verify.description')}</div>
        <div className="login_page__left__buttons">
          <Button text={_('to.login')} onClick={() => props.goToLogin()} />
        </div>
      </div>
    );
  }, 'login.page')
);
