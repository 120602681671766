import * as React from 'react';
import TextField from '../../input/text';
import Button from '../../input/button';
import { LocaleProps, localizable } from '../../i18n/localizable';
import { LoginErrorState, AppState } from '../../../state/state';
import { connect } from 'react-redux';
import { login, resetPassword } from '../../../actions/auth.actions';
import { push } from 'connected-react-router';
import Loader from '../../loader/connected.loader';
import { LoaderTarget } from '../../../state/loader.target';
import { validateEmail } from '../../../utils/validation';
interface LoginViewState {
  hideErrorView: boolean;
}
interface LoginViewProps {
  userError?: LoginErrorState;
  resetPassword: (email: string) => any;
  goToLogin: () => any;
  success: boolean;
  email: string;
  requestedEmail: string;
  updateEmail: (e: string) => any;
  className?: string;
  classNames?: string;
}
class LoginParamsView extends React.PureComponent<LoginViewProps & LocaleProps, LoginViewState> {
  constructor(props: LoginViewProps & LocaleProps) {
    super(props);
    this.state = { hideErrorView: true };
  }

  render() {
    const { _, className } = this.props;
    return (
      <div className={className}>
        <h1 className="login_page__left__get_started">{_('forgot.title')}</h1>
        <h3 className="login_page__left__sign_in_below">{_('forgot.subtitle')}</h3>
        <TextField
          type="email"
          placeholder={_('email.placeholder')}
          value={this.props.email}
          onEnterPressed={() => this.reset()}
          onChange={email => {
            this.props.updateEmail(email);
            if (!this.state.hideErrorView) {
              this.setState({ hideErrorView: true });
            }
          }}
        />
        <div
          className={`login_page__status ${
            (this.props.userError || this.props.success) && !this.state.hideErrorView
              ? 'login_page__status--visible'
              : ''
          } ${this.props.userError ? 'login_page__status--error' : ''}`}
        >
          {this.props.success && _('forgot.success', { email: this.props.requestedEmail })}
          {this.props.userError === LoginErrorState.USER_NOT_EXISTS && _('forgot.user.not.found')}
          {this.props.userError === LoginErrorState.GENERAL && _('forgot.error')}
        </div>
        <div className="login_page__left__buttons">
          <Button text={_('forgot.reset')} onClick={() => this.reset()} />
          <Button text={_('to.login')} onClick={() => this.props.goToLogin()} />
        </div>
        <Loader inline={true} targets={[LoaderTarget.RESET_PASSWORD]}>
          {_('forgot.progress')}
        </Loader>
      </div>
    );
  }
  reset() {
    if (validateEmail(this.props.email)) {
      this.setState({ hideErrorView: false });
      this.props.resetPassword(this.props.email);
    }
  }
}

export default connect(
  (state: AppState) => ({
    userError:
      state.currentUser.resetPasswordStatus && !state.currentUser.resetPasswordStatus.pending
        ? state.currentUser.resetPasswordStatus.error
        : null,
    success:
      state.currentUser.resetPasswordStatus &&
      !state.currentUser.resetPasswordStatus.pending &&
      !state.currentUser.resetPasswordStatus.error,
    requestedEmail: state.currentUser.resetPasswordStatus ? state.currentUser.resetPasswordStatus.email : ''
  }),
  dispatch => ({
    resetPassword(email: string) {
      dispatch(resetPassword(email));
    },
    goToLogin() {
      dispatch(push('/login'));
    }
  })
)(localizable(LoginParamsView, 'login.page'));
