import * as React from "react";

import LanguageContext from "./language.context";
export type MessageFunction = (msg: string, replacements?: any) => string;
export interface LocaleProps {
  _: MessageFunction;
  messagesReady: boolean;
}
export function localizable<Props>(
  Comp: React.ComponentType<Props & LocaleProps>,
  baseKey: string = ""
): React.ComponentType<Props> {
  return class LocalizationComponentConsumer<Props> extends React.PureComponent<
    Props & { Comp: React.ComponentType<Props> }
  > {
    render() {
      return (
        <LanguageContext.Consumer>
          {value => (
            <Comp
              {...this.props as any}
              _={
                baseKey
                  ? (msg: string, replacements?: any) =>
                      value._(`${baseKey}.${msg}`, replacements)
                  : value._
              }
              messagesReady={value.messageReady}
            />
          )}
        </LanguageContext.Consumer>
      );
    }
  } as any;
}

export function withLocalization<Props>(baseKey: string = "") {
  return (Comp: React.ComponentType<Props & LocaleProps>) => {
    return class LocalizationComponentConsumer<
      Props
    > extends React.PureComponent<
      Props & { Comp: React.ComponentType<Props> }
    > {
      render() {
        return (
          <LanguageContext.Consumer>
            {value => (
              <Comp
                {...this.props as any}
                _={
                  baseKey
                    ? (msg: string, replacements?: any) =>
                        value._(`${baseKey}.${msg}`, replacements)
                    : value._
                }
                messagesReady={value.messageReady}
              />
            )}
          </LanguageContext.Consumer>
        );
      }
    };
  };
}
