import Event from "../../models/event";
import { EventRecursionInfo, EventRecursionInfoJson } from "./recursion.info";
import * as moment from "moment-timezone";
import RecurringEvaluator from "../../models/recurring.evaluator";
export interface EventNode {
  address: string;
  address2: string;
  location?: string;
  apartmentSuite: string;
  lat?: number;
  lng?: number;
  addressBoxText?: string;
  city: string;
  createsUpdateNotification: boolean;
  details: string;
  startDate: string; //ISO string
  endDate: string; //ISO string
  country?: string;
  isAllDay: boolean;
  name: string;
  state: string;
  zip: string;
  isRecurring?: boolean;
  recursionInfo?: EventRecursionInfoJson;
  timezone?: string;
  calendarIds: { [id: string]: boolean };
  exists?: boolean;
  isOnline: boolean;
}

export function parseEventNode(id: string, node: EventNode): Event {
  const event = new Event();
  Object.assign(event, node);
  event.id = id;
  event.startDate = moment(node.startDate);
  event.endDate = moment(node.endDate);
  event.calendarIds = Object.keys(node.calendarIds || {});
  if (event.recursionInfo) {
    if (event.recursionInfo.removeDays) {
      event.recursionInfo.removeDays = event.recursionInfo.removeDays.map(r =>
        moment(r)
      );
    }
    if (event.recursionInfo.endsOn) {
      event.recursionInfo.endsOn = moment(event.recursionInfo.endsOn).toDate();
    }
    event.recurringMoment = new RecurringEvaluator(
      event.startDate,
      event.recursionInfo
    );
  }

  return event;
}
export interface EventLocationInfo {
  address: string;
  address2?: string;
  apartmentSuite?: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  lat?: number;
  lng?: number;
  addressBoxText?: string;
  location: string;
}
export interface EventCreationInfo {
  startDate: moment.Moment;
  endDate: moment.Moment;
  isAllDay: boolean;
  calendarIds: string[];
  // categoryId: string;
  name: string;
  description: string;
  createPushNotification: boolean;
  locationInfo: EventLocationInfo;
  recurring?: EventRecursionInfo;
  locationDescription: string;
  organizationId?: string;
  isOnline: boolean;
  timeZone?: string;
}

export enum EventDeleteOptions {
  All = "ALL",
  Future = "Future",
  ThisOcurrence = "ThisOccurrence"
}
