import { Moment } from 'moment';

export enum RepeatDuration {
  YEAR = 'YEAR',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  DAY = 'DAY'
}

export enum AfterType {
  ON = 'ON',
  AFTER = 'AFTER',
  NEVER = 'NEVER'
}

export interface EventRecursionInfo {
  repeatsEvery: number;
  everyDuration: RepeatDuration;
  /* Depends on every duration:
      date if duration=Year
      dayOfMonth if duration=Month
      weekDay if duration = Week
      hour+minutes if duration= Day 
    */
  repeatsOn: number[];
  endsOn?: Date;
  endsAfter?: number;
  removeDays?: Moment[];
  afterType: AfterType;
}

export interface EventRecursionInfoJson {
  repeatsEvery: number;
  everyDuration: RepeatDuration;
  repeatsOn: number[];
  endsOn?: string;
  endsAfter?: number;
  removeDays: string[];
  afterType: AfterType;
}
