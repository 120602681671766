import * as React from "react";
import OrganizationList from "../user-admin/organization.list";
import Organization from "../../../models/organization";
import Checkbox from "../../input/checkbox";
import { Services } from "../../../api/services";
import Spinner from "react-spinner-material";

import "./free-users.less";
import Loader from "../../loader/loader";
import { Dispatch } from "redux";
import {
  updateOrganization,
  synchOrganizationInfo
} from "../../../actions/organization.actions";
import { connect } from "react-redux";

export const FreeUsers = connect()((props: { dispatch: Dispatch<any> }) => {
  const [org, setSelectedOrg] = React.useState(null as Organization);
  const [updatedOrgs, setUpdatedOrgs] = React.useState({} as {
    [id: string]: Organization;
  });
  const [loading, setLoading] = React.useState(false);
  return (
    <div className="free-users ">
      <h2 className="free-users-title">Free users</h2>
      <div className="">
        <OrganizationList
          onSelect={org => {
            //setSelectedOrg(org);
          }}
          selected={org}
          updatedOrganizations={updatedOrgs}
          renderBox={o => (
            <Checkbox
              checked={!!o.freeUser}
              text={"Is Free?"}
              radio={true}
              onChange={free => {
                setLoading(true);
                Services.Organizations.setFreeOrganizaiton(o.id, free)
                  .then(() => {
                    setLoading(false);
                    setUpdatedOrgs({
                      ...updatedOrgs,
                      [o.id]: o.clone(f => (f.freeUser = free))
                    });
                    props.dispatch(
                      synchOrganizationInfo(o.clone(f => (f.freeUser = free)))
                    );
                  })
                  .catch(() => {
                    setLoading(false);
                  });
              }}
            />
          )}
        />
      </div>

      <Loader show={loading} fullPage={true} />
    </div>
  );
});
