import { handleActions, Action } from "redux-actions";
import { CalendarDatesView, UIState, TimeZone } from "../state/state";
import { Actions } from "../state/actions";
import * as moment from "moment";
const time = require("moment-timezone");

let timeZones: { [name: string]: TimeZone } = {};

(time.tz.names() as string[]).forEach(name => {
  const utc = time.tz(name).utcOffset() as number;
  timeZones[name] = {
    name,
    offsetHours: Math.floor(utc / 60),
    offsetMinutes: Math.abs(utc) % 60
  };
});
const initialState: UIState = {
  calendarDatesView: CalendarDatesView.Monthly,
  syncDate: moment(),
  currentDate: moment(),
  timeZones:
    Object.values(timeZones).sort((a, b) => {
      let diff =
        (a.offsetHours - b.offsetHours) * 60 +
        (a.offsetMinutes - b.offsetMinutes);
      if (diff != 0) {
        return diff;
      } else {
        return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
      }
    }) || [],
  selectedCalendars: null,
  selectedCategories: null,
  smallView: false,
  screenSize: window.innerWidth,
  showMenu: false
};
export default handleActions<UIState, any>(
  {
    [Actions.UI.SWITCH_DATES_VIEW]: (
      state: UIState,
      action: Action<{ view: CalendarDatesView }>
    ) => {
      return {
        ...state,
        calendarDatesView: action.payload.view
      };
    },
    [Actions.CALENDAR.CHANGE_SYNC_DATE]: (
      state: UIState,
      action: Action<{ newDate: moment.Moment }>
    ) => {
      return {
        ...state,
        syncDate: action.payload.newDate
      };
    },
    [Actions.CALENDAR.CHANGE_CURRENT_DATE]: (
      state: UIState,
      action: Action<{ currentDate: moment.Moment }>
    ) => {
      return {
        ...state,
        currentDate: action.payload.currentDate
      };
    },
    [Actions.UI.UPDATE_SELECTED_CALENDARS]: (
      state: UIState,
      action: Action<{ selectedCalendars: { [name: string]: boolean } }>
    ) => {
      return {
        ...state,
        selectedCalendars: action.payload.selectedCalendars
      };
    },
    [Actions.UI.UPDATE_SELECTED_CATEGORIES]: (
      state: UIState,
      action: Action<{ selectedCategories: { [name: string]: boolean } }>
    ) => {
      return {
        ...state,
        selectedCategories: action.payload.selectedCategories
      };
    },
    [Actions.UI.UPD_WIDTH]: (
      state: UIState,
      action: Action<{ width: number }>
    ) => {
      return {
        ...state,
        smallView: action.payload.width <= 750,
        screenSize: action.payload.width
      };
    },
    [Actions.AUTH.LOGOUT_SUCCESS]: (
      state: UIState,
      action: Action<{ width: number }>
    ) => {
      return initialState;
    },
    [Actions.UI.TOGGLE_MENU]: (
      state: UIState,
      action: Action<{ showMenu:boolean  }>
    ) => {
      return {
        ...state,
        showMenu: action.payload.showMenu
      };
    }
  },
  initialState
);
