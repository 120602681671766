import './new-category-dialog.less';
import * as React from 'react';

import Category from '../../../models/calendar.category';
import Calendar from '../../../models/calendar';
import Button from '../../input/button';
import Accordion from '../../accordion/accordion';
import { LocaleProps, localizable } from '../../i18n/localizable';
import { AppState } from '../../../state/state';
import { connect } from 'react-redux';
import './new-category-dialog.less';
import { goBack } from 'connected-react-router';
import { Services } from '../../../api/services';
import { AsyncResult } from '../../../api/services/async.result';

import { match } from 'react-router';
import SimpleEditionDialog from '../../simple-edition-dialog/simple.edition.dialog';
import { getOrganizationId } from '../../../state/state_getters';

interface NewCategoryDialogProps {
  add(oId: string, name: string, desc: string): AsyncResult<any>;
  update(id: string, name: string, desc: string): Promise<any>;
  delete(oId: string, id: string): Promise<any>;
  read(id: string): Promise<{ name: string; description: string }>;
  close: () => any;
  match?: match<{ key: string }>;
  organizationId?: string;
}
interface NewCategoryDialogState {
  name: string;
  description: string;
  loading: boolean;
}
function Dialog(props: NewCategoryDialogProps) {
  const id = props.match.params.key;
  //props.allCalendars.some(c => c.categories && c.categories.some(cat => cat.id === id))
  return (
    <SimpleEditionDialog
      baseKey="dialog.category"
      {...props}
      updKey={id}
      referalId={props.organizationId}
      nameErrorText={'Please provide a category name'}
      confirmDeleteMessage="dialog.category.confirm.delete"
      shouldConfirm={() => true}
    />
  );
}

export default connect(
  (state: AppState) => ({
    organizationId: getOrganizationId(state)
  }),
  dispatch => ({
    add(orgId: string, name: string, desc: string) {
      return Services.Category.addCategory(orgId, name, desc);
    },
    update(id: string, name: string, desc: string) {
      return Services.Category.updateCategory(id, name, desc);
    },
    delete(id: string, oId: string) {
      return Services.Category.deleteCategory(oId, id);
    },
    close() {
      dispatch(goBack());
    },
    read(id: string) {
      return Services.Category.getCategory(id).then(cNode => ({
        name: cNode.name,
        description: cNode.description
      }));
    }
  })
)(Dialog);
