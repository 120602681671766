import * as React from 'react';
import { LocaleProps, localizable } from '../i18n/localizable';
import Button from '../input/button';
import './overlay.less';

function OverlayConfirm(
  props: {
    show: boolean;
    message?: string;
    onYes: () => any;
    onNo?: () => any;
    yesText?: string;
    noText?: string;
    children?: any;
    className?: string;
    alert?: boolean;
    title?: string;
    fixed?: boolean;
    isHtml?: boolean;
  } & LocaleProps
) {
  const { _, show, message, onNo, onYes, yesText, noText, className, alert, fixed, isHtml } = props;

  return show ? (
    <div className="component_overlay" style={fixed ? { position: 'fixed' } : {}}>
      <div className={`component_overlay__content ${className || ''}`}>
        {props.title && <h2>{props.title}</h2>}
        {message || ''}
        {props.children}
        <div className="component_overlay__confirm_text" />
        <div className="component_overlay__confirm_buttons">
          {!alert && <Button link={true} text={noText || _('no')} onClick={onNo} />}
          <Button text={yesText ? yesText : alert ? _('ok') : _('yes')} onClick={onYes} />
        </div>
      </div>
    </div>
  ) : null;
}

export default localizable(OverlayConfirm);
