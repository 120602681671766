import Event from './event';
import CalendarCategory from './calendar.category';
import { indexByText } from '../utils/list.utils';

export default class Calendar {
  events: Event[] = [];
  recurringEvents: Event[] = [];
  name: string;
  description: string;
  id: string;
  categories: CalendarCategory[];
  order?: number;

  clone(full = false) {
    const calendar = new Calendar();
    Object.assign(calendar, this);
    if (full) {
      calendar.events = [...this.events];
      calendar.categories = [...this.categories];
      calendar.recurringEvents = [...this.recurringEvents];
    }
    return calendar;
  }
  indexAllEvents() {
    const allEvents: { [id: string]: Event } = indexByText(this.events, ev => ev.id);
    (this.recurringEvents || []).forEach(ev => (allEvents[ev.id] = ev));
    return allEvents;
  }
  static defaultSort(c1: Calendar, c2: Calendar) {
    if (c1.order && c2.order) {
      return c1.order - c2.order;
    } else if (c1.order) {
      return -1;
    } else if (c2.order) {
      return 1;
    } else {
      return c1.id > c2.id ? 1 : -1;
    }
  }
}
