import { Moment } from 'moment';
import * as moment from 'moment-timezone';

export function isSameDay(a: Moment, b: Moment) {
  if (a && b) {
    return a.year() === b.year() && a.month() === b.month() && a.date() === b.date();
  } else {
    return !a && !b;
  }
}

export function isTodaysDate(a: Moment) {
  return isSameDay(a, moment.utc());
}

export function isBetweenDays(a: Moment, start: Moment, end: Moment) {
  return beforeOrSameDay(a, end) && afterOrSameDay(a, start);
}

export function beforeOrSameDay(a: Moment, b: Moment) {
  return a.isBefore(b) || isSameDay(a, b);
}
export function afterOrSameDay(a: Moment, b: Moment) {
  return a.isAfter(b) || isSameDay(a, b);
}
function formatNumber(num: number) {
  if (num < 10) {
    return `0${num}`;
  } else {
    return `${num}`;
  }
}
export function formatDuration(duration: moment.Duration) {
  if (!duration) return '';
  if (duration.asHours() < 12) {
    return `${formatNumber(duration.hours() || 12)}:${formatNumber(duration.minutes())} am`;
  } else {
    return `${formatNumber(duration.hours() - 12 || 12)}:${formatNumber(duration.minutes())} pm`;
  }
}
