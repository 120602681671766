import { OrganizationNode, parseOrganizationNode } from './organization.node';
import { UserDbNode, parseUserNode } from './user.node';
import OrganizationRequest from '../../models/organization.request';
import { UserInfo } from '../../models/User';

export interface OrganizationRequestNode {
  organization: OrganizationNode;
  user: UserInfo;
}

export function parseOrgRequestNode(node: OrganizationRequestNode) {
  const request = new OrganizationRequest();
  request.organization = parseOrganizationNode(null, node.organization);
  request.user = node.user;

  return request;
}
