import * as React from "react";
//import Autocomplete from 'react-autocomplete';
import { EventLocationInfo } from "../../api/model/event.node";
import AutoCompleteInput from "./auto.select.input";
import { isEmpty } from "../../utils/string.utils";
import "./styles/address.less";
interface AutoCompleteService {
  new(): AutoCompleteService;
  getPredictions(
    info: { input: string },
    onSuccess: (predictions: MapPrediction[]) => any
  ): any;
}
interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}
interface DirectionResponse {
  address_components: AddressComponent[];
  formatted_address: string;
  place_id: string;
  types: string[];
  geometry: {
    location: {
      lat: () => number;
      lng: () => number;
    };
  };
}
interface GeoCodeResponsePart extends DirectionResponse { }
interface GeoCodeResponse { }
interface Geocoder {
  new(): Geocoder;
  geocode: (
    request: { placeId?: string; address?: string },
    onSuccess: (data: GeoCodeResponsePart[]) => any
  ) => any;
}
interface PlaceDetailsResult extends DirectionResponse {
  name: string;
}
interface PlaceService {
  new(doc: any): PlaceService;
  getDetails: (
    data: {
      placeId: string;
    },
    callback: (result: PlaceDetailsResult | null, status: string) => any
  ) => any;
}
declare var google: {
  maps: {
    places: {
      AutocompleteService: AutoCompleteService;
      PlacesService: PlaceService;
    };
    Geocoder: Geocoder;
  };
};
interface MapPrediction {
  description: string;
  place_id: string;
  types: string[];
  structured_formatting: {
    main_text: string;
  };
}
interface AddressInputProps {
  onChange: (info: EventLocationInfo) => any;
  placeHolder?: string;
  show?: boolean;
  text?: string;
  error?: boolean;
  showErrorPerDefault?: boolean;
  errorText?: string;
  enabled?: boolean;
  top?: string;
  withIcons?: boolean;
  withMaterial?: boolean;
  className?: string;
  useTextArea?: boolean;
  isEventEdit?: boolean;
  onBlur?: boolean;
  isNewEvent?: boolean;
}
interface AddressInputState {
  suggestions: MapPrediction[];
  focused: boolean;
  value: string;
  showAutocomplete: boolean;
  valueUpdated: boolean;
}

export default class AddressInput extends React.Component<
  AddressInputProps,
  AddressInputState
> {
  addressAreaCont: HTMLDivElement;
  addressArea: HTMLTextAreaElement;
  delayTimeout: any;

  service: AutoCompleteService;
  geoCoder: Geocoder;
  placeService: PlaceService;
  constructor(props: AddressInputProps) {
    super(props);
    this.state = { showAutocomplete: true, suggestions: [], focused: false, value: props.text || "", valueUpdated: false };
  }
  static getDerivedStateFromProps(
    props: AddressInputProps,
    state: AddressInputState
  ): Partial<AddressInputState> {
    if (!state.focused && props.text !== state.value) {
      return { value: props.text, valueUpdated: true };
    } else {
      return null;
    }
  }
  addressInput: any
  autoCompleteWrapper: any

  componentDidMount() {
    // if (this.props.isNewEvent) {
    //   this.onBlur(this.state.value, true)
    // }
    if (this.props.isNewEvent || this.props.isEventEdit) {
      this.onBlur(this.state.value, true)
    }
  }

  clearAddressInput = () => {
    // this.props.onChange(null);
    // setTimeout(() => {
    //   this.setState({
    //     showAutocomplete: true
    //   });
    //   (document.querySelector("[data-name='autompleteWrapper']") as HTMLElement).style.height = "3rem";
    //   setTimeout(() => {
    //     this.addressInput.refs.input.focus();
    //   }, 200);
    // }, 100)
    this.props.onChange(null);
   
   this.setState({
        showAutocomplete: true
      });
   setTimeout(() => {
        this.addressInput.refs.input.focus();
      }, 200);
  }

  render() {
    if ((this.props.isNewEvent || this.props.isEventEdit) && this.state.valueUpdated && this.state.value.length > 50) {
      this.onBlur(this.state.value, true)
      this.setState({
        valueUpdated: false
      })
    } 
    return (
      this.state.showAutocomplete ? (<div className={"address_container"} data-name='autompleteWrapper' ref={addressAreaCont => this.autoCompleteWrapper = addressAreaCont}>
        <AutoCompleteInput
          onAddressInputRef={ref => this.addressInput = ref}
          className={this.props.className}
          enabled={this.props.enabled}
          items={this.state.suggestions ? this.state.suggestions : []}
          getItemKey={(s: MapPrediction) => s.place_id}
          onChange={value => this.updateValue(value)}
          onSelect={val => this.onBlur(val)}
          getItemText={(s: MapPrediction) => s.description}
          value={this.state.value}
          placeHolder={this.props.placeHolder}
          onFocus={() => this.setState({ focused: true })}
          onBlur={() => {
            this.setState({ focused: false });
            this.onBlur();
          }}
          show={this.props.show}
          hideExpandButton={true}
          error={this.props.error}
          showErrorPerDefault={this.props.showErrorPerDefault}
          errorText={this.props.errorText}
          top={this.props.top}
          iconClass={this.props.withIcons ? "address_input_icon" : null}
          withMaterial={this.props.withMaterial}
        />

        { (this.props.isNewEvent || this.props.isEventEdit) && this.state.value.length > 0 && <div className={"address_clear_btn_cont"}
          onClick={() => this.clearAddressInput()}>
          <div className={"address_clear_btn"} />
        </div>}

      </div>)
        :
        (
          //isEventEdit
          <div ref={addressAreaCont => this.addressAreaCont = addressAreaCont} className="text_box text_box--visible text_box--with-material" data-name={"address"}>
            { !this.props.isEventEdit ? (<div className="text_box__placeholder text_box__placeholder--top">{this.props.placeHolder}</div>) : null}

            <textarea value={this.state.value} ref={area => this.addressArea = area} className="text_box__input address_area_value"
              onFocus={() => {
                this.setState({ ...this.state, showAutocomplete: true });
                setTimeout(() => { (document.querySelector("[role=combobox][data-name='address']") as HTMLElement).focus(); }, 100);
              }} >

            </textarea>
            { (this.props.isNewEvent || this.props.isEventEdit) && this.state.value.length > 0 && <div className={"address_clear_btn_cont"} onClick={() => {
              this.clearAddressInput();
            }}>
              <div className={"address_clear_btn"} />
            </div>}
          </div>

        )
    );
  }
  updateValue(value: string) {
    this.setState({ value });

    if (!this.service) {
      this.service = new google.maps.places.AutocompleteService();
      this.geoCoder = new google.maps.Geocoder();
      this.placeService = new google.maps.places.PlacesService(
        document.createElement("div")
      );
    }
    if (isEmpty(value)) {
      this.setState({ suggestions: [] });
      return;
    }

    this.delayTimeout != null && clearTimeout(this.delayTimeout);
    this.delayTimeout = setTimeout(()=> {
      this.service.getPredictions({ input: value || "" }, suggestions =>
      this.onPredictionSuccess(suggestions)
    );
    }, 1000)
  }
  onPredictionSuccess(suggestions: MapPrediction[]) {
    //console.log(suggestions);
    this.setState({ suggestions });
  }

  getAddressComponent(response: GeoCodeResponsePart, ...args: string[]) {
    const component = args
      .map(typeName =>
        response.address_components.find(component =>
          component.types.some(t => t === typeName)
        )
      )
      .find(component => component && true);
    if (!component) {
      return "";
    }
    return component.long_name;
  }
  getAddressFromResponse(response: GeoCodeResponsePart) {



    return `${this.getAddressComponent(
      response,
      "street_number"
    )} ${this.getAddressComponent(response, "", "route")}`;
  }
  parseDirectionResponse(response: GeoCodeResponsePart | PlaceDetailsResult) {
    if (!response) {
      this.props.onChange(null);
      return;
    }

    const hasLocation = response.types.indexOf("street_address") < 0;
    const locationName = (response as PlaceDetailsResult).name || "";
    const info: EventLocationInfo = {
      location: hasLocation ? locationName : "",
      address: this.getAddressFromResponse(response),
      city: this.getAddressComponent(response, "locality", "political"),
      state: this.getAddressComponent(response, "administrative_area_level_1"),
      zip: this.getAddressComponent(response, "postal_code"),
      country: this.getAddressComponent(response, "country"),
      lat:
        response.geometry && response.geometry.location
          ? response.geometry.location.lat()
          : null,
      lng:
        response.geometry && response.geometry.location
          ? response.geometry.location.lng()
          : null,
      addressBoxText: hasLocation
        ? `${locationName}, ${response.formatted_address}`
        : `${response.formatted_address}`
    };

    this.refreshTextArea();
    this.props.onChange(info);
  }
  findPlaceInfo(placeId: string, val?: string) {
    const value = val || this.state.value;
    this.placeService.getDetails({ placeId }, result => {
      if (result) {
        this.parseDirectionResponse(result);
      } else {
        this.geoCoder.geocode({ address: value }, data => {
          if (data && data.length) {
            this.parseDirectionResponse(data && data.length ? data[0] : null);
          }
        });
      }
    });
  }

  refreshTextArea = (info?:any) => {
    if (this.props.useTextArea) {
      this.setState({
        ...this.state,
        showAutocomplete: false
      })

      const isEvent = this.props.isEventEdit || this.props.isNewEvent;
      this.addressAreaCont.style.justifyContent = "flex-start";
      this.addressArea.style.overflow = "hidden";   
      this.addressArea.style.minHeight = isEvent ? "3rem" : "4rem";
      const addressTextLarge = info ? info.addressBoxText.length : this.state.value.length > 50;

      if(isEvent) {
        if(addressTextLarge) {
          this.addressArea.style.height = (this.addressArea.scrollHeight + 10)+"px";
          this.addressAreaCont.style.height = (this.addressArea.scrollHeight + 15) + "px";
        }
      } else {
          this.addressArea.style.height = (this.addressArea.scrollHeight + 10)+"px";
          this.addressAreaCont.style.height = (this.addressArea.scrollHeight + 5) + "px";
      }
     
      if (!this.props.isEventEdit) {
        this.addressAreaCont.style.paddingTop = "0.5rem";
        this.addressArea.style.paddingTop = `1.8rem !important`;
        if(addressTextLarge) {
          this.addressArea.classList.remove("address_area_value");
          this.addressArea.classList.add("address_area_value_min");
        }
      } else {
        this.addressArea.classList.remove("address_area_value");
        this.addressArea.classList.add("address_area_value_edit");
        this.addressAreaCont.style.marginTop = "0";
      }
      this.forceUpdate()
    }
  }

  onBlur(val?: string, useStateAddress?: boolean) {
    if (!this.service && !useStateAddress) {
      this.service = new google.maps.places.AutocompleteService();
      this.geoCoder = new google.maps.Geocoder();
      this.placeService = new google.maps.places.PlacesService(
        document.createElement("div")
      );
    }
    const value = val || this.state.value;
    if (isEmpty(value)) {
      this.props.onChange(null);
      return;
    }

    if(!useStateAddress) {
       this.service.getPredictions({ input: value || "" }, suggestions => {
      if (suggestions && suggestions.length >= 1) {
        this.findPlaceInfo(suggestions[0].place_id, val);
      } else {
        this.geoCoder.geocode({ address: value }, data => {
          if (data && data.length) {
            this.findPlaceInfo(data[0].place_id, val);
          }
        });
      }
    });
    } else {
      setTimeout(()=> {
        this.refreshTextArea();
      }, 500) 
    }
  }
}
