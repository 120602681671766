import { createAction } from 'redux-actions';
import { Actions } from '../state/actions';
import Organization from '../models/organization';
import OrganizationRequest from '../models/organization.request';

export const synchOrganizations = createAction(Actions.ORGANIZATION.LOADED, (ids: string[]) => ({ ids }));

export const synchOrganizationInfo = createAction(
  Actions.ORGANIZATION.INFORMATION_SET,
  (organization: Organization) => ({ organization })
);
export const selectOrganization = createAction(Actions.ORGANIZATION.SELECT, (organizationId: string) => ({
  organizationId
}));

export const updateOrganization = createAction(Actions.ORGANIZATION.UPDATE, (organization: Organization) => ({
  organization
}));

export const createOrganization = createAction(Actions.ORGANIZATION.CREATE, (organization: Organization) => ({
  organization
}));

export const updateRequests = createAction(Actions.ORGANIZATION.UPDATE_REQUESTS, (requests: OrganizationRequest[]) => ({
  requests
}));
export const deleteOrganization = createAction(Actions.ORGANIZATION.DELETE, (organization: Organization) => ({
  organization
}));
