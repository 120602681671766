import * as React from 'react';

import Button from '../input/button';
import { LocaleProps, localizable } from '../i18n/localizable';
import { AppState } from '../../state/state';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import './simple-edition-dialog.less';
import TextField from '../input/text';
import { goBack } from 'connected-react-router';
import { Services } from '../../api/services';
import { AsyncResult } from '../../api/services/async.result';
import { match } from 'react-router';
import { isEmpty } from '../../utils/string.utils';
import OverlayConfirm from '../overlay/overlay.confirm';

interface EditableElement {
  name: string;
  description: string;
}
interface SimpleEditionDialogDialogProps<T extends EditableElement> {
  add(oId: string, name: string, desc: string): AsyncResult<any>;
  update(id: string, name: string, desc: string): Promise<any>;
  delete(id: string, oId: string): Promise<any>;
  read(id: string): Promise<T>;
  close: () => any;
  updKey?: string;
  referalId?: string;
  baseKey: string;
  nameErrorText: string;
  shouldConfirm: (elem: T) => boolean;
  confirmDeleteMessage?: string;
}
interface SimpleEditionDialogDialogState<T extends EditableElement> {
  name: string;
  description: string;
  loading: boolean;
  clickedOnSave: boolean;
  readData?: T;
  confirmDelete?: boolean;
  wordCountNameTxt?: number;
  wordCountDescriptionTxt?: number;
}
class Dialog<T extends EditableElement> extends React.Component<
  SimpleEditionDialogDialogProps<T> & LocaleProps,
  SimpleEditionDialogDialogState<T>
> {
  nameTxt: HTMLInputElement;
  descTxt: HTMLInputElement;
  maxNameWords = 40;
  maxDescWords = 75;
  constructor(props: SimpleEditionDialogDialogProps<T> & LocaleProps) {
    super(props);
    this.state = { name: '', description: '', loading: false, clickedOnSave: false, wordCountNameTxt: 0, wordCountDescriptionTxt: 0 };
  }
  componentDidMount() {
    if (this.isUpdate()) {
      this.setState({ loading: true });
      this.props
        .read(this.props.updKey)
        .then(data => {
          this.setState(Object.assign({ loading: false, readData: data }, data));
        })
        .catch(e => {
          this.setState({ loading: false });
        });
    }
  }

  isUpdate() {
    return this.props.updKey && true;
  }
  clickDelete = () => {
    if (this.props.shouldConfirm(this.state.readData)) {
      this.setState({ confirmDelete: true });
    } else {
      this.performDelete();
    }
  };
  performDelete = () => {
    this.props.delete(this.props.updKey, this.props.referalId).then(() => {
      this.props.close();
    });
  };

  handleInputNameChange = (name : string) => {
    const words = name;
    const wordCount = this.state.name.length;
    this.setState({ wordCountNameTxt: this.maxNameWords - wordCount })

    if (words.length <= this.maxNameWords) {
        this.setState({ name });
      }
  };

  handleInputDescriptionChange = (description : string) => {
    const words = description;
    const wordCount = this.state.description.length;
    this.setState({ wordCountDescriptionTxt: this.maxDescWords - wordCount })

    if (words.length <= this.maxDescWords) {
      this.setState({ description });
    }
  };
  render() {
    const { _, baseKey, referalId } = this.props;
      return (
      <Modal isOpen={true} className="simple_edition_dialog">
        <div
          className="simple_edition_dialog__close_btn"
          onClick={e => {
            e.preventDefault();
            this.props.close();
          }}
        />
        <h2 className="simple_edition_dialog__title">
          {_(this.isUpdate() ? `${baseKey}.title.update` : `${baseKey}.title.new`)}
        </h2>
        <div>
          <label className="simple_edition_dialog__label">{_(`${baseKey}.name`)}</label>
          <TextField
            additionalClassName="simple_edition_dialog__input"
            value={this.state.name}
            onChange={(name) => this.handleInputNameChange(name)}
            error={!this.state.name || !this.state.name.length}
            showErrorPerDefault={this.state.clickedOnSave}
            errorText={this.props.nameErrorText}
          />
          <label className={`simple_edition_dialog__label ${this.state.wordCountNameTxt === 0 ? 'simple_edition_dialog_word_count_error' : 'simple_edition_dialog_word_count_success'}`}>{this.state.wordCountNameTxt} words remaining.</label>
        </div>
        <div>
          <label className="simple_edition_dialog__label">{_(`${baseKey}.description`)}</label>
          <TextField
            additionalClassName="simple_edition_dialog__input"
            value={this.state.description}
            onChange={description => this.handleInputDescriptionChange(description)}
          />
          <label className={`simple_edition_dialog__label ${this.state.wordCountDescriptionTxt === 0 ? 'simple_edition_dialog_word_count_error' : 'simple_edition_dialog_word_count_success'}`}>{this.state.wordCountDescriptionTxt} words remaining.</label>
        </div>
        <div className="simple_edition_dialog__buttons">
          {!this.isUpdate() && (
            <Button
              link={true}
              additionClassName="simple_edition_dialog__buttons__cancel"
              onClick={() => {
                this.props.close();
              }}
              text={_(`${baseKey}.cancel`)}
            />
          )}
          {this.isUpdate() && (
            <Button
              link={true}
              additionClassName="simple_edition_dialog__buttons__delete"
              onClick={this.clickDelete}
              text={_(`${baseKey}.delete`)}
            />
          )}
          <Button
            disabled={isEmpty(this.state.name)}
            onClick={() => {
              this.setState({ loading: true, clickedOnSave: true });
              if (isEmpty(this.state.name)) {
                return;
              }
              if (this.isUpdate()) {
                this.props.update(this.props.updKey, this.state.name, this.state.description).then(() => {
                  this.props.close();
                });
              } else {
                this.props.add(referalId, this.state.name, this.state.description).then(() => {
                  this.props.close();
                });
              }
            }}
            text={this.isUpdate() ? _(`${baseKey}.update`) : _(`${baseKey}.save`)}
          />
        </div>
        <OverlayConfirm
          show={this.state.confirmDelete}
          onNo={() => this.setState({ confirmDelete: false })}
          onYes={this.performDelete}
          message={_(this.props.confirmDeleteMessage, { name: this.state.readData ? this.state.readData.name : '' })}
        />
      </Modal>
    );
  }
}
export default localizable(
  Dialog
); /*  connect(
  (state: AppState) => ({
    organizationId:
      state.organization.organizations && state.organization.organizations[0]
        ? state.organization.organizations[0].id
        : null
  }),
  dispatch => ({
    add(orgId: string, name: string, desc: string) {
      return Services.Category.addCategory(orgId, name, desc);
    },
    update(id: string, name: string, desc: string) {
      return Services.Category.updateCategory(id, name, desc);
    },
    delete(oId: string, id: string) {
      return Services.Category.deleteCategory(oId, id);
    },
    close() {
      dispatch(goBack());
    }
  })
)(localizable(Dialog, 'dialog.category'));*/
