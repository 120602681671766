export const Actions = {
  AUTH: {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGOUT_REQUEST: 'LOGOUT_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_ERROR: 'LOGOUT_ERROR',
    SYNC_USER_SUCCESS: 'SYNC_USER_SUCCESS',
    SYNC_USER_ERROR: 'SYNC_USER_ERROR',
    RESET_PASSWORD: 'RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',
    SYNC_USER_NO_DATA: 'SYNC_USER_NO_DATA'
  },
  CALENDAR: {
    CALENDAR_ADDED: 'CALENDAR_ADDED',
    CALENDAR_REMOVED: 'CALENDAR_REMOVED',
    CALENDAR_INFO_UPDATED: 'CALENDAR_INFO_UPDATED',
    CHANGE_SYNC_DATE: 'CHANGE_SYNC_DATE',
    REORDER: 'CALENDAR_REORDER',
    CHANGE_CURRENT_DATE: 'CHANGE_CURRENT_DATE'
  },
  EVENT: {
    SYNCH: 'CALENDAR_EVENTS_SYNC',
    SYNCH_RECURRING: 'CALENDAR_EVENTS_SYNC_RECURRING',
    SYNCH_EVENT_INFO: 'SYNCH_EVENT_INFO'
  },
  CATEGORIES: {
    SYNCH: 'CALENDAR_CATEGORIES_SYNCH',
    SYNCH_CALENDAR: 'CALENDAR_CATEGORIES_SYNCH_CALENDAR',
    INFO_LOADED: 'CATEGORY_INFO_LOADED',
    NEW_REQUEST: 'NEW_CATEGORY_REQUEST',
    NEW_SUCCESS: 'NEW_CATEGORY_SUCCESS',
    NEW_ERROR: 'NEW_CATEGORY_ERROR',
    REORDER: 'CATEGORY_REORDER'
  },
  ORGANIZATION: {
    LOAD_REQUEST: 'ORGANIZATION_LOAD_REQUEST',
    LOADED: 'ORGANIZATION_LOAD_SUCCESS',
    LOAD_ERROR: 'ORGANIZATION_LOAD_ERROR',
    INFORMATION_SET: 'INFORMATION_SET',
    UPDATE: 'UPDATE_ORGA',
    CREATE: 'CREATE_ORGA',
    SELECT: 'SELECT_ORGA',
    UPDATE_REQUESTS: 'UPDATE_REQUESTS',
    DELETE: 'DELETE'
  },
  UI: {
    SWITCH_DATES_VIEW: 'SWITCH_DATES_VIEW',
    UPDATE_SELECTED_CATEGORIES: 'UPDATE_SELECTED_CATEGORIES',
    UPDATE_SELECTED_CALENDARS: 'UPDATE_SELECTED_CALENDARS',
    UPD_WIDTH: 'UPD_WIDTH',
    TOGGLE_MENU: 'TOGGLE_MENU'
  }
};
/*


{
type: 'SELECT_ORGA',
payload:{
organizationId:'-L0l1MQPzS8k1uvmqQE9'
}
}
*/
