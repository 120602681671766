import { compose } from "recompose";
import * as React from "react";
import { LocaleProps, withLocalization } from "../i18n/localizable";
import Button from "../input/button";
import { Plan, getOrganizationPlan } from "../../models/plan";
import numeral from "numeral";
import { connect } from "react-redux";
import { AppState } from "../../state/state";
import { push } from "connected-react-router";
import PlanPrice from "./plan.price";
import { Dispatch } from "redux";
import "./plan.less";
export default compose<{ plan: Plan; changePlan: () => any } & LocaleProps, {}>(
  withLocalization(),
  connect(
    (state: AppState) => ({
      plan: getOrganizationPlan(state.organization.selectedOrganization)
    }),
    (dispatch: Dispatch) => ({
      changePlan() {
        dispatch(push("/plans"));
      }
    })
  )
)(props => {
  const { _, plan } = props;
  const isFree = plan.pricePerMonth === 0;
  return (
    <div>
      <div className="your_plan__title">{_("account.plan.your.plan")}</div>
      <div>
        <div className="your_plan__plan_container">
          <div className="your_plan__plan_container__plan_info">
            <div className="your_plan__plan_container__name">
              {_(plan.name)}
            </div>
            {!isFree && <PlanPrice plan={plan} />}
            {isFree && (
              <div className="your_plan__plan_container__msg">
                Engage your audiences even better with our premium options.
              </div>
            )}
          </div>
          <div className="your_plan__plan_container__plan_info your_plan__plan_container__button_plan_info">
            <Button
              text={_(`plans.change.${plan.changeOption}`)}
              onClick={props.changePlan}
              grayStyle={!isFree}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
