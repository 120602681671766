import firebase from "../api/firebase";
import { eventChannel } from "redux-saga";

export function promiseFromRef<T>(
  ref: firebase.database.Reference
): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    ref.once(
      "value",
      (nodeSnapshot: firebase.database.DataSnapshot) => {
        const val = nodeSnapshot.val() as T;
        if (val) {
          let databaseValue = Object.assign(
            { key: nodeSnapshot.key },
            val
          ) as T;
          resolve(databaseValue);
        } else {
          resolve(null);
        }
      },
      () => reject()
    );
  });
}

export function channelFromRefName(refName: string) {
  return eventChannel(emitter => {
    firebase
      .database()
      .ref(refName)
      .on("value", s => {
        setTimeout(() => {
          emitter({ value: s.val() });
        });
      });

    return () => {
      firebase
        .database()
        .ref(refName)
        .off("value");
    };
  });
}
