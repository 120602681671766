import * as React from 'react';
import { User } from '../../../models/User';
import { localizable, LocaleProps } from '../../i18n/localizable';
import Button from '../../input/button';
import { Services } from '../../../api/services';
import Checkbox from '../../input/checkbox';
import InputText from '../../input/text';
interface Props {
  users: User[];
  onChange: (checked: boolean, u: User) => any;
  disabled?: boolean;
  onlyByEmail: boolean;
  withSearch: boolean;
  notAbleToDelete?: string[];
  notAbleToAdd?: string[];
}
interface State {
  foundUsers: User[];
  searchText: string;
  loading: boolean;
  currentPage: number;
  searchDone?: boolean;
}
const MAX_USERS_PER_PAGE = 5;
function UserItem(
  props: {
    user: User;
    admin: boolean;
    disabled: boolean;
    onChange: (checked: boolean, u: User) => any;
    cancel: (u: User) => any;
    notAbleToDelete?: string[];
    notAbleToAdd?: string[];
  } & LocaleProps
) {
  const { user, _ } = props;
  const buttons = [];
  if (props.admin) {
    buttons.push(
      <Button
        text={!props.admin ? _('user.admin.button.add') : _('user.admin.button.remove')}
        link={true}
        additionClassName="user_list__item__btn__link"
        onClick={() => props.onChange(!props.admin, user)}
        disabled={props.admin && props.notAbleToDelete && props.notAbleToDelete.indexOf(props.user.id) >= 0}
      />
    );
  } else {
    buttons.push(
      <Button
        text={_('cancel')}
        link={true}
        additionClassName="user_list__item__btn__link user_list__item__btn__cancel"
        onClick={() => props.cancel(user)}
      />,
      <Button
        text={_('user.admin.button.add')}
        link={true}
        additionClassName="user_list__item__btn__link"
        onClick={() => props.onChange(!props.admin, user)}
        disabled={props.notAbleToAdd && props.notAbleToAdd.indexOf(props.user.id) >= 0}
      />
    );
  }
  return (
    <li className="user_list__item" data-user-id={user.id}>
    {user.admin ? 
          (<div className="user_list__item__row">
          <div className="user_list__item__row__name_and_email">
          
            <form>
              <fieldset className="user_list_admin_box">
                <legend className="user_list_admin_box_title">Admin</legend>
                <div className="user_list__item__name">
                  {user.firstName || ''} {user.lastName || ''}
                </div>
                <div className="user_list__item__email">{user.emails ? user.emails.map(e => e.email).join(',') : ''}</div>
              </fieldset>
            </form>
  
            
  
  
          </div>
          {user.admin ? null : <div className="user_list__item__btn">{buttons}</div> }
          
        </div>) 
          : 
          (
            <div className="user_list__item__row user_list_non_admin">
        <div className="user_list__item__row__name_and_email">
              <div className="user_list__item__name">
                {user.firstName || ''} {user.lastName || ''}
              </div>
              <div className="user_list__item__email">{user.emails ? user.emails.map(e => e.email).join(',') : ''}</div>
        </div>
        {user.admin ? null : <div className="user_list__item__btn">{buttons}</div> }
        
      </div>
          ) }
      
    </li>
  );
}
class UserAdmin extends React.Component<Props & LocaleProps, State> {
  constructor(props: Props & LocaleProps) {
    super(props);
    this.state = { foundUsers: [], searchText: '', searchDone: false, loading: false, currentPage: 0 };
  }
  searchUsers = () => {
    Services.Users.findUsers(this.state.searchText, this.props.onlyByEmail).then(foundUsers => {
      this.setState({ searchDone: true, foundUsers, currentPage: 0 });
    });
  };

  render() {
    const { users, _ } = this.props;
    const { foundUsers } = this.state;
    const allUsers = [
      ...(users || []).sort(User.alphabeticSort),
      ...foundUsers.filter(u => !users.find(u2 => u2.id === u.id))
    ];
    const pageCount = Math.ceil(allUsers.length / MAX_USERS_PER_PAGE);
    const usersStart = this.state.currentPage * MAX_USERS_PER_PAGE;
    const usersEnd = Math.min(allUsers.length, (this.state.currentPage + 1) * MAX_USERS_PER_PAGE);
    const usersOfPage = allUsers.slice(usersStart, usersEnd);
    const pageNumbers = [];
    for (let i = 0; i < pageCount; i++) {
      pageNumbers.push(i + 1);
    }
    return (
      <div className={`user_list ${this.props.disabled ? 'user_list--disabled' : ''}`}>
        {this.props.withSearch && (
          <div className="user_list__search_box">
            <InputText
              disabled={this.props.disabled}
              value={this.state.searchText}
              onChange={searchText => this.setState({ searchText })}
            />
            <Button disabled={this.props.disabled} text={_('user.admin.button.search')} onClick={this.searchUsers} />
          </div>
        )}
        {(!usersOfPage || !usersOfPage.length) && this.state.searchDone && <div>{_('user.admin.user.not.found')}</div>}
        <br/>
        <ul className="user_list__list">
          {usersOfPage.sort((a, b) => (a.admin === b.admin)? 0 : a? -1 : 1).map(u => (
            <UserItem
              _={_}
              messagesReady={this.props.messagesReady}
              key={u.id}
              user={u}
              admin={!!users.find(u2 => u2.id === u.id)}
              disabled={this.props.disabled}
              onChange={(a, b) => {
                this.props.onChange(a, b);
                this.setState({ foundUsers: [], searchDone: false });
              }}
              cancel={(us: User) => this.setState({ foundUsers: [] })}
              notAbleToDelete={this.props.notAbleToDelete}
              notAbleToAdd={this.props.notAbleToAdd}
            />
          ))}
        </ul>
        {pageCount > 1 && (
          <ul className="page_selector">
            {pageNumbers.map(num => (
              <li
                className={`page_selector__item ${
                  num - 1 === this.state.currentPage ? 'page_selector__item--selected' : ''
                }`}
              >
                <Button
                  link={true}
                  text={`${num}`}
                  onClick={() => {
                    this.setState({ currentPage: num - 1 });
                  }}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}
export default localizable(UserAdmin);
