import * as React from "react";
import { compose } from "recompose";
import { LocaleProps, withLocalization } from "../../i18n/localizable";
import PaymentsLayout from "./payments.layout";
import { connect } from "react-redux";
import { AppState } from "../../../state/state";
import { getOrganizationPlan, Plan, getAllPlans } from "../../../models/plan";
import PlanCard from "../../cards/plan-card";
import Organization from "../../../models/organization";
import { push } from "connected-react-router";
import "./success.less";
import { Dispatch } from "redux";
import Button from "../../input/button";
export default compose<
  {
    currentPlan: Plan;
    organization: Organization;
    goToAccount: () => any;
  } & LocaleProps,
  any
>(
  connect(
    (state: AppState) => ({
      currentPlan: getOrganizationPlan(state.organization.selectedOrganization),
      allPlans: [] as Plan[],
      organization: state.organization.selectedOrganization
    }),
    (dispatch: Dispatch) => ({
      goToAccount() {
        dispatch(push("/account/plan"));
      },
      choosePlan(p: Plan) {}
    })
  ),
  withLocalization()
)(props => {
  const { _, currentPlan, organization, goToAccount } = props;
  const isCurrentPlanPaid = currentPlan.pricePerMonth > 0;
  if (!organization) {
    return null;
  }
  return (
    <PaymentsLayout>
      <div className="success_tick_icon">
        <div className="success_tick_icon__img" />
      </div>
      <div className="success_payment__payment_recieved">
        Your Plan has been downgraded successfully
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          text={"Return to my account"}
          onClick={goToAccount}
          additionClassName="success_payment__button"
        />
      </div>
    </PaymentsLayout>
  );
});
