import * as React from 'react';
import { indexByTextWithMap } from './list.utils';
interface Props<T> {
  items: T[];
  renderItem: (elem: T) => React.ReactElement<any>;
  getKey: (elem: T) => string;
  prefix: string;
}
interface State<T> {
  currentItems: T[];
  removeTimeouts: { [id: string]: any };
  toRemove: { [id: string]: boolean };
}
interface ItemProps<T> {
  item: T;
  renderItem: (elem: T) => React.ReactElement<any>;
  getKey: (elem: T) => string;
  prefix: string;
  hiding: boolean;
}
interface ItemState<T> {
  mounted: boolean;
}
class FadeListItem<T, U> extends React.Component<ItemProps<T>, ItemState<U>> {
  state = { mounted: false };
  componentDidMount() {
    setTimeout(() => {
      this.setState({ mounted: true });
    });
  }
  render() {
    const { getKey, item, prefix } = this.props;
    return (
      <div
        className={`${prefix}__item ${prefix}__item--${
          this.props.hiding ? 'hide' : this.state.mounted ? 'show' : 'start'
        }`}
        key={`fade_item_${getKey(item)}`}
      >
        {this.props.renderItem(this.props.item)}
      </div>
    );
  }
}
export class FadeList<T, U> extends React.Component<Props<T>, State<T>> {
  state = {
    currentItems: [] as T[],
    removeTimeouts: {} as { [id: string]: any },
    toRemove: {} as { [id: string]: boolean }
  };

  static getDerivedStateFromProps<T>(props: Props<T>, state: State<T>) {
    const previousItems = indexByTextWithMap(state.currentItems, e => props.getKey(e), e => false);
    const toAdd: T[] = [];

    props.items.forEach(i => {
      const k = props.getKey(i);
      if (typeof previousItems[k] !== 'undefined') {
        previousItems[k] = true;
      } else {
        toAdd.push(i);
      }
    });
    let changed = toAdd.length > 0;
    const toRemove = { ...state.toRemove };
    Object.entries(previousItems).forEach(([key, present]) => {
      if (!present) {
        toRemove[key] = true;
      }
    });
    const newList = [...state.currentItems, ...toAdd];

    return {
      currentItems: newList,
      toRemove
    };
  }
  render() {
    return this.state.currentItems.map((elem: T) => (
      <FadeListItem
        item={elem}
        renderItem={this.props.renderItem}
        getKey={this.props.getKey}
        prefix={this.props.prefix}
        hiding={this.state.toRemove[this.props.getKey(elem)]}
      />
    ));
  }
  componentDidUpdate() {
    const activateTimeouts = Object.keys(this.state.toRemove).filter(k => !this.state.removeTimeouts[k]);
    if (activateTimeouts.length > 0) {
      const removeTimeouts = { ...this.state.removeTimeouts };
      activateTimeouts.forEach(key => {
        removeTimeouts[key] = setTimeout(() => {
          this.setState((state: State<T>) => {
            const toRemove = { ...state.toRemove };
            const removeTimeouts = { ...state.removeTimeouts };
            delete toRemove[key];
            delete removeTimeouts[key];
            return {
              currentItems: state.currentItems.filter(i => this.props.getKey(i) != key),
              removeTimeouts,
              toRemove
            };
          });
        }, 500);
      });
      this.setState({ removeTimeouts });
    }
  }
}
