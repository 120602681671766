import * as React from "react";
import Autocomplete from "react-autocomplete";
import TextInput from "./text";
import "./styles/autocomplete.less";
import { isThisWeek } from "date-fns";
import { isEmpty } from "../../utils/string.utils";

interface AutocompleteInputProps<T> {
  allItems?: T[];
  items?: T[];
  enabled?: boolean;
  getItemText: (item: T) => string;
  getItemKey: (item: T) => string;
  onChange: (val: string) => any;
  value: string;
  placeHolder: string;
  onBlur?: () => any;
  show?: boolean;
  onSelect?: (val: string) => any;
  inputClass?: string;
  hideExpandButton?: boolean;
  className?: string;
  onFocus?: () => any;
  error?: boolean;
  showErrorPerDefault?: boolean;
  errorText?: string;
  onRef?: (elem: HTMLDivElement) => any;
  top?: string;
  iconClass?: string;
  withMaterial?: boolean;
  onAddressInputRef?: (elem: any) => any;
}
interface AddressInputState {
  error: boolean;
  focused: boolean;
  open: boolean;
  first: boolean;
  wasFocused: boolean;
}

export default class AutocompleteInput<T> extends React.Component<
  AutocompleteInputProps<T>,
  AddressInputState
> {
  input: Autocomplete;
  constructor(props: AutocompleteInputProps<T>) {
    super(props);
    this.state = {
      error: false,
      focused: false,
      open: false,
      first: true,
      wasFocused: false
    };
  }
  getItems() {
    const props = this.props;
    const items = this.state.first
      ? props.allItems
      : props.items ||
        props.allItems.filter(
          i =>
            (props.getItemText(i) || "")
              .toLowerCase()
              .indexOf((props.value || "").toLowerCase()) >= 0
        );
    return items || [];
  }
  render() {
    const props = this.props;
    const error =
      (this.props.error &&
        (this.state.wasFocused || this.props.showErrorPerDefault)) ||
      this.state.error;
    const enabled = this.props.enabled;
    return (
      <div
        className={`auto_select ${props.className || ""} auto_select--${
          props.show === false ? "invisible" : "visible"
        } ${error && !this.state.focused ? "auto_select--error" : ""} ${
          this.props.withMaterial
            ? "auto_select--with-material-placeholder"
            : ""
        }`}
        ref={r => {
          this.props.onRef && this.props.onRef(r);
        }}
      >
        <Autocomplete
          getItemValue={(item: T) => props.getItemText(item)}
          items={this.getItems()}
          renderItem={(item: T, isHighlighted) => (
            <div
              key={props.getItemKey(item)}
              className={`auto_select__item ${
                isHighlighted ? "auto_select__item--highlight" : ""
              }`}
            >
              {props.iconClass && <div className={props.iconClass} />}
              {props.getItemText(item)}
            </div>
          )}
          wrapperProps={{
            className: "auto_select__container"
          }}
          open={this.state.open}
          value={
            error && this.props.errorText && !this.state.focused
              ? this.props.errorText
              : this.props.value
          }
          onChange={e => {
            this.updateValue(e.target.value);
          }}
          onSelect={val => {
            this.updateValue(val);
            this.setState({ open: false });
            if (this.props.onSelect) {
              this.props.onSelect(val);
            }
          }}
          menuStyle={{
            top: this.props.top || "3em",
            left: 0,
            maxHeight: '50%'
          }}
          inputProps={{
            className:
              props.inputClass ||
              `text_box__input ${
                error && !this.state.focused ? "text_box__input--error" : ""
              } ${this.state.focused ? "text_box__input--focused" : ""}`,
            placeholder: !this.props.withMaterial
              ? props.placeHolder || ""
              : "",

            onFocus: () => {
              this.setState({ focused: true, first: true });
              if (this.props.onFocus) {
                this.props.onFocus();
              }
            },
            onBlur: () => {
              this.setState({ focused: false, first: false, wasFocused: true });
              if (this.props.onBlur) {
                this.props.onBlur();
              }
            },
            disabled: typeof enabled !== "undefined" && !enabled
          }}
          renderMenu={(items, value, style) => {
            return (
              <div
                className="auto_select__menu"
                style={{
                  top: this.props.top || "3em",
                  left: 0
                }}
                children={items}
              />
            );
          }}
          ref={el => {this.input = el; this.props.onAddressInputRef && this.props.onAddressInputRef(el)}}
          onMenuVisibilityChange={visible => this.setState({ open: visible })}
        />
        <div
          className={`auto_select__container__button auto_select__container__button--${
            this.state.open ? "close" : "open"
          }`}
          style={{ display: props.hideExpandButton ? "none" : "block" }}
          onMouseDown={e => {
            e.preventDefault();

            this.setState({
              open: !this.state.open,
              first: !this.state.open ? true : this.state.first
            });
          }}
        >
          <div className={`auto_select__container__button__arrow`} />
        </div>
        {this.props.placeHolder && this.props.withMaterial && (
          <div
            className={`auto_select__placeholder auto_select__placeholder--${
              this.state.focused
                ? "focused"
                : !isEmpty(this.props.value)
                ? "top"
                : "normal"
            }`}
          >
            {this.props.placeHolder}
          </div>
        )}
      </div>
    );
  }
  updateValue(value: string) {
    //this.setState({ value });
    this.setState({ first: false });
    this.props.onChange(value);
  }
}
