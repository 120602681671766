import * as React from "react";
import InputText from "../../input/text";
import Organization from "../../../models/organization";
import {
  compose,
  withState,
  withPropsOnChange,
  withStateHandlers,
  withProps
} from "recompose";
import { connect } from "react-redux";
import { AppState } from "../../../state/state";
import { updateOrganization } from "../../../actions/organization.actions";
import AddressInput from "../../input/address.input";
import { getOrganizationAddressText } from "../../util/organization.utils";
import { localizable, LocaleProps } from "../../i18n/localizable";
import Button from "../../input/button";
import { sameEvents } from "../../../state/state_getters";
import { IUser } from "../../../models/User";
import { isEmpty } from '../../../utils/string.utils';
import PasswordChangeDialog from '../account/account.password.change';
import { Services } from '../../../api/services';

export default compose<any, any>(
  connect((state: AppState) => ({
    organization: state.organization.selectedOrganization,
    user: state.currentUser.user,
    currentState: state
  }), dispatch => ({})),
  localizable,
  withState("clickedOnSave", "setClickedSave", false), 
  withState("showChangePassword", "setShowChangePassword", false),
  withStateHandlers(
    { editOrganization: null, editing: false, editUser: null },
    {
      setEditModeOn(state, props: { organization: Organization; user: IUser }) {
        return () => ({
          editing: true,
          editOrganization: props.organization.clone(),
          editUser: { ...props.user }
        });
      },
      setEditModeOff() {
        return () => ({
          editing: false,
          editOrganization: null,
          editUser: null
        });
      },
      setEditOrganization() {
        return (editOrganization: Organization) =>
          editOrganization
            ? {
                editOrganization: editOrganization.clone()
              }
            : null;
      },
      updateUser(state) {
        return (data: Partial<IUser>) => ({
          editUser: { ...state.editUser, ...data }
        });
      },
      updateOrganization(state) {
        return (data: Partial<Organization>) => ({
          editOrganization: state.editOrganization.clone((o: Organization) =>
            Object.assign(o, data)
          )
        });
      }
    }
  ),
  withProps((props: { setEditModeOff: () => any;setClickedSave:(clicked:boolean)=>any; setShowChangePassword:(clicked:boolean)=>any; 
    organization: Organization; user: IUser, state:any, editOrganization:any, editUser: any, currentPass: any, currentState: any }) => ({
    cancel() {
      props.setEditModeOff();
    },
    save() {
      
      
      props.setClickedSave(true)
      const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let validForm = true;
      if(!EMAIL_REGEX.test(props.editUser.mainEmail) 
          || isEmpty(props.editUser.firstName.trim()) 
          || isEmpty(props.editUser.lastName.trim()) 
          || isEmpty(props.editUser.lastName.trim())
          || isEmpty(props.editOrganization.name.trim())
          || isEmpty(getOrganizationAddressText(props.editOrganization))) //This will contain the rest of the info?
        validForm = false;
      
      if(validForm)
      {
        props.setEditModeOff();
        Services.Organizations.UpdateOrganizationInfo(props.editOrganization, props.editUser)
        Object.assign(props.user, props.editUser);
        Object.assign(props.currentState.currentUser.user, props.editUser);
        
      }        
    },
    showPasswordDialog(){
      props.setShowChangePassword(true);
    },
    hidePasswordDialog(){
      props.setShowChangePassword(false);
    }
  }))
  /*
  withPropsOnChange(
    ["organization"],
    (props: {
      organization: Organization;
      setEditOrganization: (o: Organization) => any;
      updateOrganization: (data: Partial<Organization>) => any;
    }) => {
      props.setEditOrganization(props.organization);
    }
  )*/
)(
  (
    props: {
      organization: Organization;
      editOrganization: Organization;
      updateOrganization: (data: Partial<Organization>) => any;
      updateUser: (data: Partial<IUser>) => any;
      editing: boolean;
      user: IUser;
      editUser: IUser;
      setEditModeOn: any;
      cancel: () => any;
      save: () => any;
      clickedOnSave:boolean;
      showChangePassword: boolean;
      showPasswordDialog: () => any;
      hidePasswordDialog: () => any;
    } & LocaleProps
  ) => {
    let organization = props.organization;
    let user = props.user;
    const {
      
      editOrganization,
      updateOrganization,
      _,
      editUser,
      updateUser,
      editing,
      showChangePassword
    } = props;

    if (props.editing) {
      organization = editOrganization;
      user = editUser;
    }
    if (!organization) {
      return null;
    }

    return (
      <div className='account__profile__page'>
        <InputText
          disabled={!editing}
          value={organization.name}
          placeholder={_("dialog.profile.name")}
          onChange={name => {
            
            updateOrganization({ name });
          }}
          materialPlaceholder={true}
          error={isEmpty(organization.name)&& props.editing}
          showErrorPerDefault={props.clickedOnSave}
          errorText={_('account.organization.profile.edit.name.error')}
          showErrorAfter={true}
        />
        <AddressInput
        className="text_box"
          text={getOrganizationAddressText(organization)}
          enabled={editing}
          placeHolder={_("dialog.profile.address")}
          withMaterial={true}
          onChange={info => {
            props.updateOrganization({
              address: info.address,
              city: info.city,
              state: info.state,
              country: info.country,
              zip: info.zip || organization.zip,
              lat: info.lat,
              lng: info.lng
            });
            //this.setState({ addressSearchText: info.addressBoxText });
          }}

        />
        <div className="container50">
        <div className="">
          <InputText
              error={isEmpty(user.firstName)&& props.editing}
              showErrorPerDefault={props.clickedOnSave}
              errorText={_('account.profile.edit.first.error')}
              showErrorAfter={true}
              disabled={!editing}
              value={user.firstName}
              placeholder={_("account.profile.first.name")}
              onChange={firstName => {
                updateUser({ firstName });
              }}
              materialPlaceholder={true}
            />
        </div>
          
          <div className="">
            <InputText
              error={isEmpty(user.lastName) && props.editing}
              showErrorPerDefault={props.clickedOnSave}
              errorText={_('account.profile.edit.last.error')}
              showErrorAfter={true}
              disabled={!editing}
              value={user.lastName}
              placeholder={_("account.profile.last.name")}
              onChange={lastName => {
                updateUser({ lastName });
              }}
              materialPlaceholder={true}
            />
          </div>
          
        </div>
        <InputText
        type="email"
          disabled={!editing}
          error={ isEmpty(user.mainEmail) && props.editing}
          showErrorPerDefault={props.clickedOnSave}
          errorText={_('account.profile.edit.email.error')}
          showErrorAfter={true}
          // value={getDefaultEmailAddress(user.emails)}
          value={user.mainEmail}
          placeholder={_("account.profile.email")}
          onChange={mainEmail => {
            updateUser({ mainEmail });
          }}
          materialPlaceholder={true}
        />
        <div className="float-left footer-control">
          <Button blueStyle={ true } text={_("account.profile.change.password")} link={true} onClick={ props.showPasswordDialog } />
        </div>
        <div className="float-right footer-control">
          {!props.editing && (
            <Button className="time_peace_button--link time_peace_button--gray-border-style"
              text={_("account.profile.edit.profile")}
              link={true}
              onClick={props.setEditModeOn}
            />
          )}
          {props.editing && (
            <Button text={_("cancel")} onClick={props.cancel} link={true} />
          )}

          {props.editing && (
            <Button additionClassName="account__profile__edit--save" onClick={props.save} text={_("save.changes")}  />
          )}
        </div>

        <PasswordChangeDialog
          fixed={true}
          show={props.showChangePassword}
          user={props.user}
          alert={true}
          yesText={_('continue')}
          onNo={props.hidePasswordDialog}
          onYes={() => { 
          }}
        />
      </div>
    );
  }
);
