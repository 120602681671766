import * as React from "react";

import Category from "../../../models/calendar.category";
import Calendar from "../../../models/calendar";
import Button from "../../input/button";
import Accordion from "../../accordion/accordion";
import { LocaleProps, localizable } from "../../i18n/localizable";
import { AppState } from "../../../state/state";
import { connect } from "react-redux";
import { indexByText } from "../../../utils/list.utils";
import NewCategoryDialog from "../new-category-dialog/index";
import "./manage.less";
import CalendarCategory from "../../../models/calendar.category";
import { push } from "connected-react-router";
import DraggableList from "../../draggable-list/draggable.list";
import { Services } from "../../../api/services";
import { handleCategoryReorder } from "../../../actions/category.actions";
import { handleCalendarReorder } from "../../../actions/calendar.actions";
import { isEmpty } from "../../../utils/string.utils";
import { IUser } from "../../../models/User";
import { getSortedCalendars } from "../../../state/state_getters";
import moment from "moment";
import Tips from "./tips";

interface ManagePageProps {
  categories: Category[];
  calendars: Calendar[];
  openNewCategoryDialog: () => any;
  openNewCalendarDialog: (cat: CalendarCategory) => any;
  openCategoryEditionDialog: (cat: CalendarCategory) => any;
  openCalendarEditionDialog: (cat: CalendarCategory, cal: Calendar) => any;
  handleCatReOrder: (sort: string[]) => any;
  handleCalReOrder: (sort: string[]) => any;
}
interface ManagePageState {}
function sortByOrder<T extends { order?: number; name: string; id: string }>(
  list: T[]
): T[] {
  const sortedCategories = [...list];
  sortedCategories.sort((a, b) => {
    if (a.order && b.order && a.order > 0 && b.order > 0) {
      return a.order - b.order;
    } else if (a.order && a.order > 0) {
      return -1;
    } else if (b.order && b.order > 0) {
      return 1;
    } else if (a.id > b.id) {
      return 1;
    } else if (a.id < b.id) {
      return -1;
    } else {
      return 0;
    }
  });
  return sortedCategories;
}
class Manage extends React.Component<
  ManagePageProps & LocaleProps,
  ManagePageState
> {
  constructor(props: ManagePageProps & LocaleProps) {
    super(props);
    this.state = {};
  }
  render() {
    const props = this.props;
    const { _ } = props;
    const indexedCalendars = indexByText(props.calendars, cal => cal.id);

    return (
      <div className="manage_page">
        <div className="manage_page__title">
          <h2 className="manage_page__title__title">{_("title")}</h2>

          <Button
            text={_("create.new")}
            additionClassName="manage_page__title__button"
            onClick={() => this.props.openNewCategoryDialog()}
          />
        </div>
        <div className="manage_page__content">
          {props.categories && !!props.categories.length && (
            <DraggableList onReorder={newOrder => this.handleReorder(newOrder)}>
              {sortByOrder(props.categories)
                .filter(cat => !isEmpty(cat.name))
                .map(cat => (
                  <Accordion
                    title={cat.name}
                    onOptionClick={() => {
                      this.props.openCategoryEditionDialog(cat);
                    }}
                    withEdit={true}
                    onEditClick={() => {
                      this.props.openCategoryEditionDialog(cat);
                    }}
                    key={cat.id}
                    open={
                      cat.created && moment().diff(cat.created, "minutes") < 10
                    }
                  >
                    <DraggableList
                      onReorder={newOrder => {
                        this.handleCalendarReorder(newOrder);
                      }}
                    >
                      {sortByOrder(
                        Object.keys(cat.calendars || {})
                          .map(calId => indexedCalendars[calId])
                          .filter(cal => cal != null)
                      )
                        .filter(cal => !isEmpty(cal.name))
                        .map(cal => (
                          <div
                            key={cal.id}
                            className="manage__calendar__category__list__item"
                          >
                            <div className="manage__calendar__category__list__item__handle" />
                            {cal.name}

                            <Button
                              link={true}
                              additionClassName="manage__calendar__category__list__item__button"
                              text={_("edit.link")}
                              onClick={() => {
                                this.props.openCalendarEditionDialog(cat, cal);
                              }}
                            />
                          </div>
                        ))}
                    </DraggableList>
                    <div className="manage__calendar__category__list__item">
                      <Button
                        link={true}
                        additionClassName="manage__calendar__category__list__item__button"
                        text={_("add.link")}
                        onClick={() => {
                          this.props.openNewCalendarDialog(cat);
                        }}
                      />
                    </div>
                  </Accordion>
                ))}
            </DraggableList>
          )}
          <Tips />
        </div>
      </div>
    );
  }
  handleReorder(newOrder: string[]) {
    this.props.handleCatReOrder(newOrder);
  }
  handleCalendarReorder(newOrder: string[]) {
    this.props.handleCalReOrder(newOrder);
  }
}

export default connect(
  (state: AppState) => ({
    categories: state.allCategories,
    calendars: getSortedCalendars(state)
  }),
  dispatch => ({
    openCategoryEditionDialog: (cat: CalendarCategory) => {
      dispatch(push(`/categories/edit/${cat.id}`, { modal: true }));
    },
    openNewCategoryDialog: (cat: CalendarCategory) => {
      dispatch(push("/categories/new", { modal: true }));
    },
    openCalendarEditionDialog: (cat: CalendarCategory, cal: Calendar) => {
      dispatch(push(`/calendar/edit/${cat.id}/${cal.id}`, { modal: true }));
    },
    openNewCalendarDialog: (cat: CalendarCategory) => {
      dispatch(push(`/calendar/${cat.id}/new`, { modal: true }));
    },
    handleCatReOrder: (newOrder: string[]) => {
      dispatch(handleCategoryReorder(newOrder));
    },
    handleCalReOrder: (newOrder: string[]) => {
      dispatch(handleCalendarReorder(newOrder));
    }
  })
)(localizable(Manage, "manage"));
