import { createAction } from 'redux-actions';
import { Actions } from '../state/actions';
import Event from '../models/event';

export const syncEventsOfCalendar = createAction(Actions.EVENT.SYNCH, (calendarId: string, events: string[]) => ({
  calendarId,
  events
}));

export const syncRecurringEventsOfCalendar = createAction(
  Actions.EVENT.SYNCH_RECURRING,
  (calendarId: string, events: string[]) => ({
    calendarId,
    events
  })
);
