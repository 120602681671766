import { User, IEmail, UserSettings } from "../../models/User";
import * as moment from "moment-timezone";
import { mapObj } from "../../utils/object.utils";

export interface UserDbNode {
  key: string;
  createdAt: string;
  lastLogin: string;
  firstName: string;
  lastName: string;
  tutorialComplete: boolean;
  emails?: { [key: string]: IEmail };
  superUser?: boolean;
  selfSigned?: boolean;
  mainEmail?: string;
}
interface UserSubscriptionNode {
  [calendarId: string]: boolean;
}
export interface UserSubscriptionsNode {
  categories: {
    [orgId: string]: UserSubscriptionNode;
  };
  calendars: {
    [orgId: string]: { [categoryId: string]: UserSubscriptionNode };
  };
}
export interface UserNodeSettings {
  hiddenCalendars: { [id: string]: boolean | string };
  hiddenCalendarsHistory: { [id: string]: { start: string; end: string }[] };
  externalSyncInactive: { start: string; end?: string }[];
  defaultDaysView: string;
  defaultOrganizationId: string;
}
export function parseSettings(data: UserNodeSettings): UserSettings {
  if (!data) {
    return {
      externalSyncInactive: [],
      hiddenCalendars: {},
      hiddenCalendarsHistory: {},
      defaultDaysView: "",
      defaultOrganizationId: "",
    };
  }

  return {
    defaultDaysView: data.defaultDaysView,
    defaultOrganizationId: data.defaultOrganizationId,
    hiddenCalendars: mapObj(
      data.hiddenCalendars,
      (h) => (typeof h !== "boolean" ? moment(h) : h),
      {}
    ),
    hiddenCalendarsHistory: mapObj(
      data.hiddenCalendarsHistory,
      (h) =>
        h.map((elem) => ({ start: moment(elem.start), end: moment(elem.end) })),
      {}
    ),
    externalSyncInactive: data.externalSyncInactive
      ? data.externalSyncInactive.map((s) => ({
          start: moment(s.start),
          end: s.end ? moment(s.end) : null,
        }))
      : [],
  };
}

export function parseUserNode(
  node: UserDbNode,
  settings?: UserNodeSettings,
  subscriptions?: UserSubscriptionsNode
) {
  const user = new User(
    node.key,
    node.tutorialComplete,
    node.firstName,
    node.lastName,
    node.createdAt,
    node.lastLogin,
    node.mainEmail,
    settings
  );
  user.settings = parseSettings(settings);
  user.admin = node.superUser || false;
  if (subscriptions && subscriptions.categories) {
    Object.keys(subscriptions.categories).forEach((organizationId) => {
      const calendarSubscriptions = subscriptions.categories[organizationId];
      if (calendarSubscriptions) {
        Object.keys(calendarSubscriptions).forEach((categoryId) => {
          if (calendarSubscriptions[categoryId]) {
            user.subscriptions.push({
              id: categoryId,
              categoryId,
              orgId: organizationId,
            });
          }
        });
      }
    });
  }
  if (subscriptions && subscriptions.calendars) {
    Object.keys(subscriptions.calendars).forEach((organizationId) => {
      const calendarSubscriptions = subscriptions.calendars[organizationId];
      if (calendarSubscriptions) {
        Object.keys(calendarSubscriptions).forEach((categoryId) => {
          if (calendarSubscriptions[categoryId]) {
            Object.keys(calendarSubscriptions[categoryId]).forEach(
              (calendarId) => {
                console.log(calendarSubscriptions[categoryId][calendarId]);
                user.subscriptions.push({
                  id: calendarId,
                  categoryId,
                  calendarId,
                  orgId: organizationId,
                });
              }
            );
          }
        });
      }
    });
  }

  user.emails = node.emails
    ? Object.keys(node.emails).map((k) => node.emails[k])
    : [];
  user.emails = user.emails || [];
  if (node.mainEmail && !user.emails.find((e) => e.email === node.mainEmail)) {
    user.emails.push({
      email: node.mainEmail,
      subscribed: false,
      provider: -1,
    });
  }
  return user;
}

export interface NewUserParams {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}
