import * as React from "react";
import { localizable, LocaleProps } from "../../i18n/localizable";

import "./login.less";

import { Link, Redirect, Route, Switch } from "react-router-dom";

import { connect } from "react-redux";
import { login } from "../../../actions/auth.actions";
import { LoginErrorState, AppState } from "../../../state/state";
import { NewUserParams } from "../../../api/model/user.node";
import ForgotPasswordView from "./forgot.password";
import LoginView from "./login";
import ResetSuccess from "./reset.success";
import ProfileEditDialog from "../profile-edit/profile.edit.dialog";
import OrganizationRequestPage from "../org-requests/org.request.edit.page";
import { Location } from "history";
import SignupSuccess from "../org-requests/signup.success";
import VerifyAccount from "./verify.account";
import Button from "../../input/button";
import AlmostThere from "../org-requests/almost.there";
import OrgRequestMultiSignup from "../org-requests/org.request.multi.signup";
import { goToAppStore } from "../../../utils/gotomobile.link";
import AuthenticationPage from "../org-requests/authentication";
import { push } from "connected-react-router";
const pathLogo = require("../../layout/images/logo.png");

interface LoginPageProps {
  currentLocation: Location;
  login: (mail: string, pass: string, toPage: string | null) => any;
  createUser: (params: NewUserParams) => any;
  userError?: LoginErrorState;
  goToLogin?: () => any;
}
class LoginPage extends React.Component<
  LoginPageProps & LocaleProps,
  {
    email: string;
  }
> {
  constructor(props: LoginPageProps & LocaleProps) {
    super(props);
    this.state = { email: "" };
  }

  render() {
    const { _, currentLocation } = this.props;

    return (
      // <div
      //   className={`login_page ${
      //     currentLocation.pathname.indexOf("signup") >= 0
      //       ? "login_page--bigger-left"
      //       : ""
      //   } `}
      // >
      <div
        className={`login_page`}
      >
        <div className="login_page__left">
          <Switch>
          <Route exact path="/login/authentication">
              <AuthenticationPage location={ this.props.currentLocation } />
            </Route>
            <Route exact path="/login/forgot">
              <ForgotPasswordView
                email={this.state.email}
                updateEmail={email => this.setState({ email })}
              />
            </Route>
            <Route exact path="/login/reset/success">
              <ResetSuccess />
            </Route>
            <Route exact path="/login/signup">
              <OrganizationRequestPage />
            </Route>
            <Route exact path="/login/signup-success">
              <SignupSuccess />
            </Route>
            <Route
              exact
              path="/login/almost-there/:email"
              component={AlmostThere}
            />
            <Route
              exact
              path="/login/signup/existing/:name"
              component={OrgRequestMultiSignup}
            />
            <Route exact path="/login/verify">
              <VerifyAccount />
            </Route>
            <Route exact path="/login">
              <LoginView
                email={this.state.email}
                updateEmail={email => this.setState({ email })}
              />
            </Route>
            
          </Switch>
        </div>
        <div className="login_page__image">
          <div className="login_layout__left__logo">
          <Link to={'/login'} >
            <img className="payments_layout__left__logo__img" src={pathLogo} />
          </Link>
            
          </div>
          <h3>{_("where.you.want.to.be")}</h3>
          <div className="login_page__grow" />

          <div className="login_page__looking_for">{_("looking.for")}</div>
          {/* <Button
            text={_("download.app")}
            className="timepeace_button_green"
            onClick={goToAppStore}
          /> */}
          <div className="mobile-icons-container">
              <a
                href="https://itunes.apple.com/us/app/timepeace-calendar/id1278750105?mt=8"
                target="_blank"
              >
                <div className="download-app-store" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.smartcal&hl=en"
                target="_blank"
              >
                <div className="download-app-play" />
              </a>
            </div>
        </div>
      </div>
    );
  }
}
export default connect(
  (state: AppState) => ({
    userError: state.currentUser.loginError,
    currentLocation: state.router.location
  }),
  dispatch => ({
    login(email: string, pass: string, toPage: string | null) {
      dispatch(login(email, pass, toPage || null));
    },
    createUser(params: NewUserParams) {}
  })
)(localizable(LoginPage, "login.page"));
