import * as React from "react";
import { compose } from "recompose";
import { LocaleProps, withLocalization } from "../i18n/localizable";
import { connect } from "react-redux";
import { AppState } from "../../state/state";
import { BillingInformation } from "../../models/billing.information";
import Organization from "../../models/organization";
import Button from "../input/button";
import { getOrganizationPlan } from "../../models/plan";
import { Dispatch } from "redux";
import { push } from "connected-react-router";
import "./billing-info.less";
export default compose<
  {
    billingInfo: BillingInformation;
    organization: Organization;
    goToCheckOut: () => any;
  } & LocaleProps,
  {}
>(
  withLocalization(),
  connect(
    (state: AppState) => ({
      billingInfo: state.organization.selectedOrganization.billingInfo,
      organization: state.organization.selectedOrganization
    }),
    (dispatch: Dispatch) => ({
      goToCheckOut() {
        dispatch(push("/payment"));
      }
    })
  )
)(props => {
  const { _, billingInfo, organization } = props;
  if (!billingInfo || getOrganizationPlan(organization).pricePerMonth === 0) {
    return null;
  }
  return (
    <div className="billing_info">
      <div className="your_plan__title">{_("account.plan.billing.info")}</div>

      <div className="your_plan__plan_container">
        <div className="your_plan__plan_container__plan_info">
          <div className="your_plan_container_item" >{organization.name}</div>
          <div className="your_plan_container_item">{billingInfo.address}</div>
          <div className="your_plan_container_item">
            {billingInfo.city}, {billingInfo.state}, {billingInfo.zip}
          </div>
          <div className="your_plan_container_item">
            {billingInfo.country}
          </div>
        </div>
        <div className="your_plan__plan_container__plan_info your_plan__plan_container__button_plan_info">
          <Button
            text={_("account.plan.update.billing.info")}
            grayStyle={true}
            onClick={() => props.goToCheckOut()}
          />
        </div>
      </div>
    </div>
  );
});
