import Organization from "../../models/organization";
import firebase from "../firebase";
import { promiseFromRef } from "../../utils/firebase.utils";
import {
  OrganizationNode,
  parseOrganizationNode,
  toOrganizationNode
} from "../model/organization.node";
import OrganizationRequest from "../../models/organization.request";
import { OrganizationRequestNode } from "../model/organization.request";
import { User } from "../../models/User";
import { RecieptNode } from "../model/reciept.node";
declare const FUNCTIONS_BASE: string;
declare const STAGING: boolean;
export default class OrganizationApiImpl {
  findAll(): Promise<Organization[]> {
    return promiseFromRef<{ [name: string]: OrganizationNode }>(
      firebase.database().ref("Organizations")
    ).then(organizations => {
      return Object.keys(organizations).map(id => {
        const node = organizations[id];
        return parseOrganizationNode(id, node);
      });
    });
  }
  cancelPayment(orgId: string) {
    return fetch(`${FUNCTIONS_BASE}cancelPayment`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        orgId
      })
    }).then(r => r.text());
  }

  getOrganizationReciepts(
    orgId: string,
    limit?: number
  ): Promise<{ data: RecieptNode[]; hasMore: boolean }> {
    return fetch(`${FUNCTIONS_BASE}getCustomerInvoices`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        orgId,
        limit
      })
    })
      .then(r => r.json())
      .then((r: any) => ({
        data: r.data as RecieptNode[],
        hasMore: r.has_more as boolean
      }));
  }
  findAdmins(id: string): Promise<User[]> {
    return firebase
      .database()
      .ref("Admin_Users")
      .orderByKey()
      .equalTo(id)
      .once("value", snapshot => snapshot.val()) as any;
  }
  saveRequest(request: OrganizationRequest): PromiseLike<void> {
    return firebase
      .database()
      .ref("Organization_Requests")
      .push({
        organization: toOrganizationNode(request.organization),
        user: request.user
      } as OrganizationRequestNode) as any;
  }

  UpdateOrganizationInfo(org: Organization, user: User): PromiseLike<void> {
    //In production for some reason address2 is undefined in the DB (it does not exist). We need to ensure the node exists before caling the update
    let result = firebase
      .database()
      .ref(`Organizations/${org.id}`).child('address2').set(org.address2 || "")

    result = firebase
      .database()
      .ref(`Organizations/${org.id}`).child('country').set(org.country || "")

    result = firebase
      .database()
      .ref(`Organizations/${org.id}`)
      .update({
        name: org.name,
        address: org.address,
        address2: org.address2 || "",
        zip: org.zip,
        city: org.city,
        state: org.state,
        country: org.country || ""
      });

    result = firebase
      .database()
      .ref(`Users/${user.id}/information`)
      .update({
        firstName: user.firstName,
        lastName: user.lastName,
        mainEmail: user.mainEmail
      });

    var currentUser = firebase.auth().currentUser;
    currentUser
      .updateEmail(user.mainEmail)
      .then(() => {})
      .catch(error => {
        console.log(error);
      });

    return result;
  }
  changeLiveStatus(orgId: string, newLiveStatus: boolean) {
    firebase
      .database()
      .ref(`Organizations/${orgId}`)
      .update({
        needsToFinishRegistration: newLiveStatus
      } as Partial<OrganizationNode>);
  }
  sendWelcomeEmail(email: string, orgName: string = "") {
    return fetch(`${FUNCTIONS_BASE}sendWelcomeEmail`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email,
        orgName: orgName || "",
        staging: STAGING
      })
    }).then(r => r.text());
  }

  setFreeOrganizaiton(orgId: string, free: boolean) {
    return firebase
      .database()
      .ref(`Organizations/${orgId}`)
      .update({ freeUser: free || false });
  }
  UpdateOrganizationIcon(orgId: string, newIconUrl: string): PromiseLike<void> {
    let result = firebase
      .database()
      .ref(`Organizations/${orgId}`)
      .update({ icon: newIconUrl });
    return result;
  }

  IsOrganizationNameZipTaken(orgName: string): Promise<any> {
    return firebase
      .database()
      .ref(`Organizations`)
      .orderByChild("name")
      .equalTo(orgName)
      .once("value");
  }

  UpdateOrganizationBillingInfo(
    organizationId: string,
    address: string,
    city: string,
    state: string,
    zip: string,
    last4Digits: string
  ): PromiseLike<void> {
    let result = firebase
      .database()
      .ref(`Organizations/${organizationId}/billingInfo`)
      .update({
        address: address,
        city: city,
        last4Digits: last4Digits,
        state: state,
        zip: zip
      });
    return result;
  }

  ReauthenticateUser(email: string, currentPassword: string): Promise<any> {
    let user: any;
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, currentPassword)
        .then(res => {
          if (res) {
            user = res.user;
            firebase
              .auth()
              .signOut()
              .then(() => {
                resolve(user);
              });
          } else {
            resolve(null);
          }
        })
        .catch(error => {
          resolve(null);
        });
    });
  }
}
