import * as React from "react";
import { Link } from "react-router-dom";

import { MessageFunction } from "../i18n/localizable";
import "./vertical-nav.less";
import { User } from "../../models/User";
import Organization from "../../models/organization";
interface NavBarItem {
  message: string;
  paths: string[];
  redirect?: string;
  onClick?: () => any;
  iconName: string;
  visible?: boolean;
  small?: boolean;
  big?: boolean;
  superUser?: boolean;
  creator?: boolean;
}
export default ({
  items,
  currentLocation,
  _,
  children,
  onRedirect,
  user,
  selectedOrganization
}: {
  items: NavBarItem[];
  currentLocation: string;
  _: MessageFunction;
  children?: any;
  onRedirect: () => any;
  user: User;
  selectedOrganization: Organization;
}) => {
  return (
    <nav className="vertical_nav_bar">
      {items.map(i => {
        const conditions = [
          i.superUser && user.admin,
          //user.admin,
          i.creator &&
            (selectedOrganization &&
              user.id === selectedOrganization.createdBy),
          !i.creator && !i.superUser
        ];
        if (
          conditions.every(c => !c) ||
          (typeof i.visible === "boolean" && !i.visible)
        ) {
          return null;
        }
        const selected = i.paths.some(p => p === currentLocation);
        const itemClassName = `vertical_nav_bar__item ${
          selected ? "vertical_nav_bar__item--selected" : ""
        } ${i.small ? "vertical_nav_bar__item--small-view" : ""} ${
          i.big ? "vertical_nav_bar__item--big-view" : ""
        }`;
        if (typeof i.visible !== "undefined" && !i.visible && !user.admin) {
          return null;
        }
        const iconClass = `vertical_nav_bar__item__icon__container__icon vertical_nav_bar__item__icon__container__icon--${
          i.iconName
        } ${
          selected
            ? "vertical_nav_bar__item__icon__container__icon--" +
              i.iconName +
              "-active"
            : ""
        }`;
        if (i.redirect) {
          return (
            <Link
              key={`${i.message}`}
              className={itemClassName}
              to={i.redirect}
              onClick={() => onRedirect()}
            >
              <div className="vertical_nav_bar__item__icon__container">
                <div className={iconClass} />
              </div>
              <div className={`vertical_nav_bar__item__link__text`}>
                {_(i.message)}
              </div>
            </Link>
          );
        } else {
          return (
            <a
              className={itemClassName}
              href="#"
              key={`${i.message}`}
              onClick={e => {
                e.preventDefault();
                i.onClick();
              }}
            >
              <div className="vertical_nav_bar__item__icon__container">
                <div className={iconClass} />
              </div>
              <div className={`vertical_nav_bar__item__link__text`}>
                {_(i.message)}
              </div>
            </a>
          );
        }
      })}
      {children}
    </nav>
  );
};
