import firebase from "../firebase";
import { IUser, User, UserSettings } from "../../models/User";
import {
  UserDbNode,
  parseUserNode,
  UserSubscriptionsNode,
  UserNodeSettings,
} from "../model/user.node";
declare const FUNCTIONS_BASE: string;
export class UsersApi {
  updateUser(user: IUser) {
    return this.ref(user.id)
      .child("information")
      .update({
        firstName: user.firstName,
        lastName: user.lastName,
        emails: user.emails || null,
      });
  }
  getUserInfo(id: string): Promise<User> {
    return new Promise<User>((resolve, reject) => {
      const eventRef = this.ref(id);
      eventRef.once("value", (nodeSnapshot: firebase.database.DataSnapshot) => {
        const val = nodeSnapshot.val() as {
          information: UserDbNode;
          settings?: UserNodeSettings;
          subscriptions: UserSubscriptionsNode;
        };
        if (val) {
          let user = val ? val.information : null;

          user = Object.assign({ key: nodeSnapshot.key }, user);

          resolve(parseUserNode(user, val.settings, val.subscriptions));
        } else {
          resolve(null);
        }
      });
    });
  }
  userFindQuery(
    field: string,
    value: string
  ): Promise<{
    val: () => {
      [name: string]: {
        information: UserDbNode;
        subscription: UserSubscriptionsNode;
      };
    };
  }> {
    if (field === "email") {
      return firebase
        .database()
        .ref("Users")
        .orderByChild(`information/mainEmail`)
        .equalTo(value)
        .once("value");
    }
    return firebase
      .database()
      .ref("Users")
      .orderByChild(`information/${field}`)
      .startAt(value.toLowerCase())
      .endAt(value.toLowerCase() + "\uf8ff")
      .once("value");
  }
  findUsers(value: string, onlyByEmail = false): Promise<User[]> {
    const userPromises: Promise<{
      val: () => {
        [key: string]: {
          information: UserDbNode;
          settings?: UserNodeSettings;
          subscription: UserSubscriptionsNode;
        };
      };
    }>[] = onlyByEmail
      ? [this.userFindQuery("email", value)]
      : [
          this.userFindQuery("firstNameLower", value),
          this.userFindQuery("lastNameLower", value),
          this.userFindQuery("email", value),
        ];
    return Promise.all(userPromises)
      .then((allSnapshots) => {
        const users: { [key: string]: User } = {};
        allSnapshots.forEach((snapshot) => {
          const userNodes = snapshot.val();
          if (userNodes) {
            Object.keys(userNodes).forEach((nodeKey) => {
              users[nodeKey] = parseUserNode(
                Object.assign({ key: nodeKey }, userNodes[nodeKey].information),
                userNodes[nodeKey].settings,
                userNodes[nodeKey].subscription
              );
            });
          }
        });
        return Object.values(users);
      })
      .then((users) => users.sort(User.alphabeticSort));
  }
  createUser(params: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    organizationId?: string;
  }): Promise<string> {
    return fetch(`${FUNCTIONS_BASE}createUser`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    }).then((result) => {
      return result.text();
    });
  }
  administratorsOfOrganization(orgId: string): Promise<User[]> {
    return firebase
      .database()
      .ref("Admin_Users")
      .orderByChild(orgId)
      .equalTo(true)
      .once("value")
      .then((snapshot: { val: () => { [userKey: string]: any } }) => {
        if (!snapshot.val()) {
          return [];
        }
        return Promise.all(
          Object.keys(snapshot.val()).map((id) => this.getUserInfo(id))
        );
      });
  }
  ref(id: string) {
    return firebase.database().ref(`Users/${id}`);
  }
  verifyEmail(actionCode: string) {
    return firebase.auth().applyActionCode(actionCode);
  }

  setAdministratorOfOrganization(add: boolean, user: User, orgId: string) {
    if (!add) {
      return firebase
        .database()
        .ref(`Admin_Users/${user.id}/${orgId}`)
        .remove();
    }
    return firebase.database().ref(`Admin_Users/${user.id}/${orgId}`).set(true);
  }

  updateUserPassword(
    user: User,
    currentPassword: string,
    newPassword: string
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.reauthenticate(currentPassword)
        .then(() => {
          var user = firebase.auth().currentUser;
          user
            .updatePassword(newPassword)
            .then(() => {
              resolve("");
            })
            .catch((error) => {
              let errorInfo = {
                invalidPassword: false,
                invalidLength: true,
                message: error.message,
              };
              resolve(errorInfo);
            });
        })
        .catch((error) => {
          let errorInfo = {
            invalidPassword: true,
            invalidLength: false,
            message: error.message,
          };
          resolve(errorInfo);
        });
    });
  }

  reauthenticate = (currentPassword: string) => {
    var user = firebase.auth().currentUser;
    var cred = firebase.auth.EmailAuthProvider.credential(
      user.email,
      currentPassword
    );
    return user.reauthenticateWithCredential(cred);
  };

  updateUserSettings(user: User, setting: any) {
    const settingName = setting.name;
    const settingValue = setting.value;
    let result = firebase
      .database()
      .ref(`Users/${user.id}/settings`)
      .update({
        [settingName]: settingValue,
      });
  }

  IsUserEmailTaken(userEmail: string): Promise<any> {
    return firebase.auth().fetchSignInMethodsForEmail(userEmail);
    //return firebase.auth().fetchProvidersForEmail(userEmail);
    // return firebase
    //   .database()
    //   .ref(`Users/information`)
    //   .orderByChild('mainEmail')
    //   .equalTo(userEmail)
    //   .once("value");
  }
}
