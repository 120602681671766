import CalendarCategory from '../../models/calendar.category';
import moment from 'moment';
export interface CategoryNode {
  id: string;
  name: string;
  details: string;
  calendars: { [id: string]: string };
  order?: number;
  created?: string;
}

export function parseCategoryNode(id: string, node: CategoryNode) {
  const category = new CalendarCategory();
  Object.assign(category, node);
  category.description = node.details;
  category.id = id;
  category.created = node.created ? moment(node.created) : null;

  return category;
}
