import * as React from "react";
import "./styles/text.less";
import { validateEmail, validateNumber } from "../../utils/validation";
import { isMobile, isEmpty } from "../../utils/string.utils";
interface InputTextProps {
  placeholder?: string;
  onChange: (value: string) => any;
  value: string;
  type?: string;
  validate?: (text: string) => boolean;
  error?: boolean;
  showErrorPerDefault?: boolean;
  onEnterPressed?: () => any;
  show?: boolean;
  additionalClassName?: string;
  area?: boolean;
  descriptor?: string;
  descriptorFirst?: boolean;
  max?: number;
  errorText?: string;
  onFocus?: () => any;
  onBlur?: () => any;
  rows?: number;
  disabled?: boolean;
  maxLength?: number;
  materialPlaceholder?: boolean;
  showErrorAfter?: boolean;
  overrideInternalStateError?: boolean;
  getInput?: (
    props: {
      onFocus: () => any;
      onBlur: () => any;
      onChange: (e: string) => any;
      disabled: boolean;
      className: string;
      placeholder: string;
      value: string;
      onKeyDown: (e: any) => any;
    }
  ) => React.ReactElement<any, any>;
  autoFocus?: boolean;
}
interface InputTextState {
  focused: boolean;
  wasFocused: boolean;
  error: boolean;
}
type Validator = (text: string) => boolean;
const DEFAULT_VALIDATORS: { [name: string]: Validator } = {
  email: validateEmail,
  number: validateNumber
};
export default class InputText extends React.Component<
  InputTextProps,
  InputTextState
> {
  constructor(props: InputTextProps) {
    super(props);
    this.state = { focused: false, error: false, wasFocused: false };
  }
  onFocus = () => {
    this.setState({ focused: true, wasFocused: true });
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  };
  onBlur = () => {
    this.setState({ focused: false });
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };

  render() {
    const {
      placeholder,
      onChange,
      value,
      type,
      show,
      additionalClassName,
      area
    } = this.props;
    const showMaterialPlaceHolder = this.props.materialPlaceholder;
    const error =
      (this.props.error &&
        (this.state.wasFocused || this.props.showErrorPerDefault)) ||
      this.state.error;
    if (area) {
      return (
        <div
          className={`text_box text_box--area text_box--${
            show === false ? "invisible" : "visible"
          } ${additionalClassName}`}
        >
          <textarea
            placeholder={this.props.error && this.props.overrideInternalStateError && this.state.wasFocused ? this.props.errorText : placeholder}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            onChange={e => this.onChange(e.target.value)}
            onKeyDown={e => this.onKeyDown(e)}
            className= {`text_box__area ${this.props.rows >= 5 ? isMobile() ? " text_box__area_big_mobile " : " text_box__area_big " : " " }  ${
              (this.state.error || this.props.error && this.props.overrideInternalStateError) && !this.state.focused
                ? "text_box__area--error"
                : ""
            } ${this.state.focused ? "text_box__area--focused" : ""}`}
            disabled={this.props.disabled}
            value={value}
            rows={this.props.rows || 3}
          />
        </div>
      );
    }
    const focusState =
      error && !this.state.focused
        ? "error"
        : this.state.focused
        ? "focused"
        : "";
    const inputClassName = `text_box__input ${
      error && !this.state.focused ? "text_box__input--error" : ""
    } ${this.state.focused ? "text_box__input--focused" : ""}`;
    const inputVal =
      error &&
      this.props.errorText &&
      !this.state.focused &&
      !this.props.showErrorAfter
        ? this.props.errorText
        : value;
    const input =
      this.props.getInput &&
      this.props.getInput({
        onBlur: this.onBlur,
        onFocus: this.onFocus,
        disabled: this.props.disabled,
        className: inputClassName,
        placeholder: showMaterialPlaceHolder ? "" : placeholder,
        value: inputVal,
        onKeyDown: this.onKeyDown,
        onChange: this.onChange
      });

    return (
      <>
        <div
          className={`text_box text_box--${
            show === false ? "invisible" : "visible"
          } ${showMaterialPlaceHolder ? "text_box--with-material" : ""} ${
            focusState ? "text_box--" + focusState : ""
          } ${input ? "text_box--with-input" : ""} ${additionalClassName} `}
        >
          {this.props.descriptor && this.props.descriptorFirst && (
            <div className="text_box__description">{this.props.descriptor}</div>
          )}
          {input || (
            <input
              type={type || "text"}
              className={inputClassName}
              disabled={this.props.disabled}
              placeholder={showMaterialPlaceHolder ? "" : placeholder}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              value={inputVal}
              onChange={e => this.onChange(e.target.value)}
              onKeyDown={e => this.onKeyDown(e)}
              max={this.props.max}
              autoFocus={this.props.autoFocus}
            />
          )}
          {this.props.materialPlaceholder && this.props.placeholder && (
            <div
              className={`text_box__placeholder text_box__placeholder--${
                this.state.focused
                  ? "focused"
                  : !isEmpty(value)
                  ? "top"
                  : "normal"
              }`}
            >
              {this.props.placeholder}
            </div>
          )}
          {this.props.descriptor && !this.props.descriptorFirst && (
            <div className="text_box__description">{this.props.descriptor}</div>
          )}
        </div>
        {this.props.maxLength && (
          <div className="text_box__counter">
            <div className="text_box__counter__value">
              {value.length} / {this.props.maxLength}{" "}
            </div>
          </div>
        )}
        {this.props.showErrorAfter &&
          (this.state.wasFocused || this.props.showErrorPerDefault) &&
          !this.state.focused &&
          this.props.error && (
            <div className="text_box__error_desc">{this.props.errorText}</div>
          )}
      </>
    );
  }
  onKeyDown(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (e.key === "Enter" && this.props.onEnterPressed) {
      e.preventDefault();
      this.props.onEnterPressed();
    }
  }
  onChange(text: string) {
    let error = false;
    if (this.props.maxLength && text.length > this.props.maxLength) {
      text = text.substr(0, this.props.maxLength);
    }
    const validator =
      this.props.validate || DEFAULT_VALIDATORS[this.props.type || "text"];
    if (validator && !validator(text)) {
      error = true;
    }
    if (this.state.error !== error) {
      this.setState({ error });
    }
    this.props.onChange(text || "");
  }
}
