import * as React from "react";
import { compose, withProps } from "recompose";
import Modal from "react-modal";
import { LocaleProps, withLocalization } from "../../i18n/localizable";
import Button from "../../input/button";
import { connect } from "react-redux";
import { Services } from "../../../api/services";
import { AppState } from "../../../state/state";
import { Dispatch } from "redux";
import { goBack } from "connected-react-router";
import Organization from "../../../models/organization";
import * as moment from "moment-timezone";
import { getPlanById, PlanIds } from "../../../models/plan";
import "./downgrade.less";
export default compose<
  {
    show: boolean;
    cancelPayment: () => any;
    goBack: () => any;
    organization: Organization;
    onSuccess: () => any;
    back: () => any;
  } & LocaleProps,
  { show: boolean; onSuccess: () => any; back: () => any }
>(
  withLocalization(),
  connect(
    (state: AppState) => ({
      orgId: state.organization.selectedOrganization.id,
      organization: state.organization.selectedOrganization
    }),
    (dispatch: Dispatch) => ({})
  ),
  withProps((props: { orgId: string; onSuccess: () => any }) => ({
    cancelPayment() {
      Services.Organizations.cancelPayment(props.orgId)
        .then(() => props.onSuccess())
        .catch(() => {
          alert("There was an error while downgrading your payment");
        });
    }
  }))
)(props => {
  const { _, show, organization } = props;
  let daysRemaining = 0;
  let priceRenew = null;
  if (organization.subscriptionStatus && organization.subscriptionStatus.periodEnd) {
    let date = moment(organization.subscriptionStatus.periodEnd * 1000);
    daysRemaining = moment(organization.subscriptionStatus.periodEnd * 1000).diff(
      moment(),
      "days"
    );
    priceRenew = moment(organization.subscriptionStatus.periodEnd * 1000);
    daysRemaining = Math.max(0, daysRemaining);
  }

  return show ? (
    <div className="component_overlay" style={{ position: "fixed" }}>
      <div className={`component_overlay__content  downgrade`}>
        <h1>Your Premium Plan Subscription Status</h1>
        <div className="downgrade__message">
          {/* Your plan will be renewed in {daysRemaining} days.
          <br /> */}
          {/* If you decide to cancel your current Plan, you will access to your Premium
          Plan for {daysRemaining} days. After that, you will continue with Free Plan. */}
          If you decide to cancel your current Plan, you will have access to your Premium Plan and its features until your renewal date. Upon that date, you will continue with the Free Plan.
        </div>
        <div className="downgrade__data__title">
          Current Plan Subscription Details
        </div>
        <div className="downgrade__data">
          <div className="downgrade__data__label">Next Renew Date</div>
          <div className="downgrade__data__value">
            {priceRenew ? priceRenew.add(1, "months").format("MMM DD, YYYY") : "-"}
          </div>
          <div className="downgrade__data__label">Price</div>
          <div className="downgrade__data__value">
            $ {getPlanById(PlanIds.Full).pricePerMonth}/ month
          </div>
          <div className="downgrade__data__label">Payment Method</div>
          <div className="downgrade__data__value">
            **** **** **** {props.organization.billingInfo &&
            props.organization.billingInfo.last4Digits
              ? props.organization.billingInfo.last4Digits
              : "****"}
          </div>
        </div>
        {/* <div className="downgrade_buttons_action_title">You have chosen to cancel your current plan.</div> */}
        <div className="downgrade_buttons_action_title">Please confirm your choice below.</div>
        <div className="downgrade__buttons_row">
        <Button
          grayStyle={true}
          text="Downgrade Premium Plan to Free Plan"
          onClick={props.cancelPayment}
        />
          <Button
            
            text="Continue Current Paid Plan"
            onClick={props.back}
          />
          
        </div>
      </div>
    </div>
  ) : null;
});
