import { Moment } from "moment";
import * as moment from "moment-timezone";
import {
  EventRecursionInfo,
  RepeatDuration,
  AfterType
} from "../api/model/recursion.info";
import { isSameDay } from "../utils/date.utils";

export default class RecurringEvaluator {
  start: Moment;
  params: EventRecursionInfo;
  expectedEnd?: Moment;

  constructor(start: Moment, params: EventRecursionInfo) {
    this.start = start;
    this.params = params;
    if (this.params.afterType === AfterType.ON) {
      this.expectedEnd = moment(this.params.endsOn);
    } else if (this.params.afterType === AfterType.AFTER) {
      let count = 0;
      const currentMatchDay = this.start.clone();
      let endDay = currentMatchDay.clone();

      while (count < this.params.endsAfter) {
        if (this.params.everyDuration === RepeatDuration.WEEK) {
          this.params.repeatsOn
            .sort((a, b) => a - b)
            .forEach(dayOfWeek => {
              if (count >= this.params.endsAfter) return;
              const day = currentMatchDay.clone().day(dayOfWeek);
              if (day.isSameOrAfter(start)) {
                endDay = endDay.isAfter(day) ? endDay : day.clone();
                count++;
                //console.log(day.format('DD-MM'));
              }
            });
        } else {
          if (
            currentMatchDay.isSameOrAfter(start) &&
            currentMatchDay.isSameOrAfter(this.start)
          ) {
            count++;
            endDay = endDay.isAfter(currentMatchDay)
              ? endDay
              : currentMatchDay.clone();
          }
        }
        if (count < this.params.endsAfter)
          currentMatchDay.add(this.params.repeatsEvery, this._getDuration());
      }
      this.expectedEnd = endDay;
    }
  }

  getStartRepeatDate() {
    let addDuration: moment.unitOfTime.DurationConstructor = "year";
    let startRepeat = this.start.clone();

    switch (this.params.everyDuration) {
      case RepeatDuration.YEAR:
        const repeatDate = moment(this.params.repeatsOn[0]);

        startRepeat.date(repeatDate.date()).month(repeatDate.month());
        break;
      case RepeatDuration.MONTH:
        const dayOfMonthRepeat = this.params.repeatsOn[0] - 1;
        startRepeat = startRepeat.date(dayOfMonthRepeat);
        if (startRepeat.isBefore(this.start)) {
          startRepeat.add(1, "month");
        }
        break;
      case RepeatDuration.WEEK:
        let minWeekDay = 1000;
        if(this.params.repeatsOn) {
          this.params.repeatsOn.forEach(dayOfWeek => {
            minWeekDay = Math.min(minWeekDay, dayOfWeek);
          });
        } 
        startRepeat.day(minWeekDay);
        if (startRepeat.isBefore(this.start)) {
          startRepeat.add(1, "week");
        }
        break;
      case RepeatDuration.DAY:
        break;
      default:
        throw "Error";
    }
    //if (startRepeat.isBefore(this.start)) {
    //  startRepeat.add(this.params.repeatsEvery, addDuration);
    //}
    return startRepeat;
  }

  match(d: Moment) {
    let startRepeatDate = this.getStartRepeatDate();
    let diffInUnit = 0;
    let isExpectedDate = false;

    if (this.params.everyDuration == RepeatDuration.YEAR) {
      const repeatDate = moment(this.params.repeatsOn[0]);

      diffInUnit = d.diff(startRepeatDate, "years");
      isExpectedDate =
        d.date() === startRepeatDate.date() &&
        d.month() === startRepeatDate.month();
    } else if (this.params.everyDuration == RepeatDuration.MONTH) {
      const dayOfMonthRepeat = this.params.repeatsOn[0] - 1;
      let lastDay = false;
      if (dayOfMonthRepeat === 31) {
        lastDay = true;
      }

      isExpectedDate =
        d.date() === dayOfMonthRepeat ||
        (lastDay &&
          d.date() ===
            d
              .clone()
              .endOf("month")
              .date());
      diffInUnit = d.diff(startRepeatDate, "month");
    } else if (this.params.everyDuration == RepeatDuration.WEEK) {
      isExpectedDate = this.params.repeatsOn.some(day => d.day() === day);
      diffInUnit = d.diff(startRepeatDate, "weeks");
    } else {
      diffInUnit = d.diff(startRepeatDate, "day");
    }
    const isActive =
      this.start.isSameOrBefore(d) &&
      (!this.params.endsOn || moment(this.params.endsOn).isSameOrAfter(d)) &&
      (!this.params.endsAfter && this.params.endsAfter > diffInUnit);
    return (
      isExpectedDate &&
      diffInUnit >= 0 &&
      diffInUnit % this.params.repeatsEvery === 0 &&
      isActive
    );
  }
  _getDuration(): moment.unitOfTime.DurationConstructor {
    switch (this.params.everyDuration) {
      case RepeatDuration.YEAR:
        return "year";
      case RepeatDuration.MONTH:
        return "month";
      case RepeatDuration.WEEK:
        return "week";
      case RepeatDuration.DAY:
        return "days";
      default:
        return "days";
    }
  }
  ended(date: Moment) {
    return moment(this.expectedEnd.clone().endOf("day")).isSameOrBefore(date);
  }
  getMatchingDaysBetween(start: Moment, end: Moment, first = false) {
    const startRepeat = this.getStartRepeatDate();
    let currentMatchDay = start
      .clone()
      .startOf("day")
      .isAfter(startRepeat.clone().startOf("day"))
      ? startRepeat.add(
          this.params.repeatsEvery *
            Math.floor(
              start.diff(startRepeat, this._getDuration()) /
                this.params.repeatsEvery
            ),
          this._getDuration()
        )
      : startRepeat.clone();
    const result: moment.Moment[] = [];
    while (
      currentMatchDay.isSameOrBefore(end) &&
      !this.ended(currentMatchDay)
    ) {
      if (this.params.everyDuration === RepeatDuration.WEEK) {
        this.params.repeatsOn.forEach(dayOfWeek => {
          const day = currentMatchDay.clone().day(dayOfWeek);
          if (
            day.isSameOrAfter(start) &&
            currentMatchDay.isSameOrAfter(this.start) &&
            !this.ended(day)
          ) {
            result.push(day);
          }
        });
      } else {
        if (
          currentMatchDay.isSameOrAfter(start) &&
          currentMatchDay.isSameOrAfter(this.start)
        ) {
          result.push(
            currentMatchDay
              .clone()
              .hours(this.start.hours())
              .minutes(this.start.minutes())
          );
        }
      }
      if (first && result.length) {
        return result;
      }
      currentMatchDay.add(this.params.repeatsEvery, this._getDuration());
    }
    if (this.params.removeDays && this.params.removeDays.length) {
      return result.filter(
        d => !this.params.removeDays.find(remove => isSameDay(d, remove))
      );
    }
    return result;
  }
  getNearestFutureDate(moment: Moment) {
    const start = moment.clone();
    const end = moment
      .clone()
      .add(this.params.repeatsEvery * 2, this._getDuration());
    const dates = this.getMatchingDaysBetween(start, end);
    if (dates && dates.length) {
      return dates[0];
    } else {
      return null;
    }
  }
}
