import * as React from "react";
import "./styles/button.less";

interface ButtonProps {
  text?: string;
  onClick?: (option?: string) => any;
  className?: string;
  link?: boolean;
  additionClassName?: string;
  deleteButton?: boolean;
  disabled?: boolean;
  options?: ({ key: string; text: string })[];
  defaultOption?: string;
  active?: boolean;
  grayStyle?: boolean;
  blueStyle?: boolean;
  buttonGray?: boolean;
  boldLinkText?: boolean;
}
interface ButtonState {
  optionsOpen: boolean;
}
export default class Button extends React.Component<ButtonProps, ButtonState> {
  constructor(props: ButtonProps) {
    super(props);
    this.state = { optionsOpen: false };
  }

  handleMouseDown = () => {
    this.setState({ optionsOpen: false });
    window.removeEventListener("mousedown", this.handleMouseDown);
  };
  render() {
    const props = this.props;
    const btn = (
      <button
        className={`${props.className ||
          (props.deleteButton
            ? "time_peace_button--delete"
            : props.link
            ? "time_peace_button--link"
            : "time_peace_button")} ${props.additionClassName || ""} ${
          props.disabled ? "time_peace_button--disabled" : ""
        } ${
          props.link && props.active ? "time_peace_button--link-active" : ""
        } ${props.grayStyle ? "time_peace_button--gray-style" : props.blueStyle ? props.boldLinkText ? "time_peace_button--blue-style-bold" : "time_peace_button--blue-style" : ""} `}
        onMouseDown={e => {
          //e.preventDefault();
          e.stopPropagation();
          if (this.props.options && !this.props.defaultOption) {
            if (!this.state.optionsOpen) {
              window.addEventListener("mousedown", this.handleMouseDown);
            }
            this.setState({ optionsOpen: !this.state.optionsOpen });
          } else {
            if (props.onClick && !this.props.disabled) {
              props.onClick(props.defaultOption);
            }
          }
        }}
      >
        {props.text}
        {props.options && (
          <div
            className={`time_peace_button__arrow time_peace_button__arrow--${
              this.state.optionsOpen ? "open" : "close"
            }`}
            onMouseDown={e => {
              e.preventDefault();
              e.stopPropagation();
              this.setState({ optionsOpen: !this.state.optionsOpen });
              window.addEventListener("mousedown", this.handleMouseDown);
            }}
          />
        )}
      </button>
    );
    return props.options ? (
      <div className="time_peace_button__container">
        {btn}
        <ul
          className={`time_peace_button__options time_peace_button__options--${
            this.state.optionsOpen ? "open" : "close"
          }`}
        >
          {props.options
            ? props.options.map(opt => (
                <li
                  key={opt.key}
                  className="time_peace_button__option"
                  onClick={e => {
                    e.preventDefault();
                    if (!this.props.disabled) {
                      props.onClick(opt.key);
                    }
                  }}
                >
                  {opt.text}
                </li>
              ))
            : null}
        </ul>
      </div>
    ) : (
      btn
    );
  }
}
