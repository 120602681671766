import { createAction } from 'redux-actions';
import { Actions } from '../state/actions';
import { CalendarDatesView } from '../state/state';
import { Moment } from 'moment';

export const changeCalendarDatesView = createAction(Actions.UI.SWITCH_DATES_VIEW, (view: CalendarDatesView) => ({
  view
}));

export const changeSyncDate = createAction(Actions.CALENDAR.CHANGE_SYNC_DATE, (newDate: Moment) => ({
  newDate
}));

export const changeCalendarSelectedDate = createAction(Actions.CALENDAR.CHANGE_CURRENT_DATE, (currentDate: Moment) => ({
  currentDate
}));
export const updateWidth = createAction(Actions.UI.UPD_WIDTH, (width: number) => ({
  width
}));
export const toggleMenu = createAction(Actions.UI.TOGGLE_MENU, (showMenu:boolean) => ({
  showMenu
}));
