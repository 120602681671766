import * as React from "react";
import TextField from "../../input/text";
import Button from "../../input/button";
import { LocaleProps, localizable } from "../../i18n/localizable";
import { LoginErrorState, AppState } from "../../../state/state";
import { connect } from "react-redux";
import { login } from "../../../actions/auth.actions";
import { push } from "connected-react-router";
import { Location } from "history";
import queryString from "querystring";
import { Link } from "react-router-dom";
const pathLogo = require("../../layout/images/logo.png");

interface LoginViewState {
  password: string;
  hideLoginErrorView: boolean;
  isPasswordReset: boolean;
}
interface LoginViewProps {
  userError?: LoginErrorState;
  login: (email: string, pass: string, toPage?: string) => any;
  goToForgotPasswordPage: () => any;
  email: string;
  updateEmail: (e: string) => any;
  goToSignUpPage: () => any;
  currentLocation: Location;
}
class LoginParamsView extends React.PureComponent<
  LoginViewProps & LocaleProps,
  LoginViewState
> {
  constructor(props: LoginViewProps & LocaleProps) {
    super(props);
    this.state = {
      password: "",
      hideLoginErrorView: false,
      isPasswordReset:
        queryString.parse(this.props.currentLocation.search)[
          "?passwordReset"
        ] !== undefined
          ? true
          : false
    };
  }

  render() {
    const { _, currentLocation } = this.props;
    return (
      <div className="login_page__login">
        <Link to={'/login'} >
            <img className="payments_layout_logo_img" src={pathLogo} />
          </Link>
        <div className="login_page__login__signup" >
        
          {_("dont.have.account")}{" "}
          <Button
            text={_("sign.up")}
            onClick={() => this.props.goToSignUpPage()}
            grayStyle={true}
          />
        </div>
        
        <h1 className="login_page__left__get_started">{_("get.started")}</h1>
        <h3 className="login_page__left__sign_in_below">
          {_("sign.in.below")}
        </h3>
        
        <TextField
          placeholder={_("email.placeholder")}
          value={this.props.email}
          type={"email"}
          onChange={email => {
            this.props.updateEmail(email);
            if (!this.state.hideLoginErrorView) {
              this.setState({ hideLoginErrorView: true });
            }
          }}
          onEnterPressed={() => this.logIn()}
        />
        <TextField
          placeholder={_("password.placeholder")}
          value={this.state.password}
          type={"password"}
          onChange={password =>
            this.setState({ password, hideLoginErrorView: true })
          }
          onEnterPressed={() => this.logIn()}
        />
        <div style={{ textAlign: "right" }}>
          <Button
            text={_("forgot.password")}
            onClick={() => this.props.goToForgotPasswordPage()}
            link={true}
          />
        </div>
        <div className="login_page__left__buttons">
          <Button text={_("log.in.button")} onClick={() => this.logIn()} />
        </div>

        <div
          className={`login_page__status ${
            this.props.userError && !this.state.hideLoginErrorView
              ? "login_page__status--visible"
              : ""
          }`}
        >
          {this.props.userError === LoginErrorState.USER_NOT_EXISTS &&
            _("invalid.email")}
          {this.props.userError === LoginErrorState.INVALID_PASSWORD &&
            _("invalid.password")}
          {this.props.userError === LoginErrorState.GENERAL && _("error")}
        </div>
        {this.state.isPasswordReset ? (
          <div className="login_page__login_passwordReset">
            <br /> {_("password.reset.ok")}{" "}
          </div>
        ) : null}

        <div className="login_page__login__separator" />
      </div>
    );
  }
  logIn() {
    let toPage = null;
    if (
      this.props.currentLocation &&
      (this.props.currentLocation as any).state
    ) {
      toPage = (this.props.currentLocation as any).state.from
        .pathname as string;
    }

    this.setState({ hideLoginErrorView: false, isPasswordReset: false });
    this.props.login(this.props.email, this.state.password, toPage);
  }
}

export default connect(
  (state: AppState) => ({
    userError: state.currentUser.loginError,
    currentLocation: state.router.location
  }),
  dispatch => ({
    login(email: string, pass: string, toPage?: string) {
      dispatch(login(email, pass, toPage));
    },
    goToForgotPasswordPage() {
      dispatch(push("/login/forgot"));
    },
    goToSignUpPage() {
      dispatch(push("/login/signup"));
    }
  })
)(localizable(LoginParamsView, "login.page"));
