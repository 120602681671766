import * as React from "react";
import {
  LocaleProps,
  localizable,
  withLocalization
} from "../../i18n/localizable";
import Button from "../../input/button";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { compose, withState } from "recompose";
import { AppState } from "../../../state/state";
import { match } from "react-router";

export default compose<any, any>(
  connect(
    (state: AppState) => ({
      loggedEmail:
        state.currentUser && state.currentUser.user
          ? state.currentUser.user.mainEmail || ""
          : ""
    }),
    dispatch => ({
      goToLogin() {
        dispatch(push("/login"));
      }
    })
  ),
  withState("reasonsVisible", "setReasonsVisible", false),
  withLocalization("signup.almostThere")
)(
  (
    props: {
      goToLogin: () => any;
      setReasonsVisible: (val: boolean) => any;
      reasonsVisible: boolean;
      match?: match<{ email: string }>;
      loggedEmail: string;
    } & LocaleProps
  ) => {
    const { _ } = props;
    console.log(props.match);
    return (
      <div>
        <h1 className="login_page__left__get_started">{_("title")}</h1>
        <br />
        <div className={`login_page__success__description`}>
          {_("description", {
            address: props.match ? props.match.params.email : props.loggedEmail
          })}
        </div>
        <div className="login_page__success__reasons__title">
          {_("if.havent.recievied")}{" "}
          <a
            // link={true}
            //text={_("check.reasons")}
            className="login_signup_almost_reasons_link"
            onClick={() => { props.setReasonsVisible(!props.reasonsVisible); return false }}
          >{_("check.reasons")}</a>
        </div>
        <div
          className={`login_page__success__reasons login_page__success__reasons--${
            props.reasonsVisible ? "visible" : "hidden"
          }`}
        >
          <div className="">{_("reasons.description")}</div>
          <ul className="login_page__success__reasons__list">
            <li className="signup_failure_reason_item" >{_("reason.1")}</li>
            <li className="signup_failure_reason_item" >{_("reason.2")}</li>
            <li className="signup_failure_reason_item" >{_("reason.3")}</li>
            <li className="signup_failure_reason_item" >{_("reason.4")}</li>
          </ul>
          <br></br>
          If you still have trouble, <a className="login_signup_almost_reasons_link" href='mailto:hello@timepeace.io'>email </a> us. 
        </div>
      </div>
    );
  }
);
