import * as React from "react";
import { compose, withProps, withState } from "recompose";
import { Services } from "../../api/services";
import {
  localizable,
  withLocalization,
  LocaleProps,
} from "../i18n/localizable";
import { RecieptNode } from "../../api/model/reciept.node";
import * as moment from "moment-timezone";
import { withLoad } from "../hocs/with.load";
import { connect } from "react-redux";
import { AppState } from "../../state/state";
import numeral from "numeral";
import Button from "../input/button";
import "./reciepts.less";
export default compose<
  {
    organizationReciepts: RecieptNode[];
    loadMore: () => any;
    currentLimit: number;
    setCurrentLimit(limit: number): any;
    showMore: boolean;
  } & LocaleProps,
  {}
>(
  localizable,
  withLocalization(),
  connect((state: AppState) => ({
    orgId: state.organization.selectedOrganization.id,
  })),
  withState("showMore", "setShowMore", false),
  withState("currentLimit", "setCurrentLimit", 5),
  withLoad(
    "organizationReciepts",
    (props: {
      orgId: string;
      currentLimit: number;
      setShowMore: (v: boolean) => any;
      setCurrentLimit: (limit: number) => any;
    }) =>
      Services.Organizations.getOrganizationReciepts(
        props.orgId,
        props.currentLimit
      ).then((result) => {
        props.setShowMore(result.hasMore);
        props.setCurrentLimit(props.currentLimit + 20);
        return result.data;
      }),
    []
  ),
  withProps(
    (props: {
      orgId: string;
      currentLimit: number;
      setShowMore: (v: boolean) => any;
      setCurrentLimit: (limit: number) => any;
      setOrganizationReciepts: (reciepts: any[]) => any;
    }) => ({
      loadMore() {
        Services.Organizations.getOrganizationReciepts(
          props.orgId,
          props.currentLimit
        ).then((reciepts) => {
          props.setOrganizationReciepts(reciepts.data);
          props.setShowMore(reciepts.hasMore);
          props.setCurrentLimit(props.currentLimit + 20);
        });
      },
    })
  )
)((props) => {
  const { _ } = props;

  if (!props.organizationReciepts || !props.organizationReciepts.length) {
    return null;
  }
  return (
    <div className="your_plan__invoices">
      <div className="your_plan__title">
        {_("account.plan.invoice.history")}
      </div>
      <table className="your_plan__invoices__table">
        <tbody>
          {props.organizationReciepts.map((r) => {
            return (
              <tr>
                <td>
                  {moment((r.date as any) * 1000).format("MMMM DD, YYYY")}
                </td>
                <td>{numeral(r.amount_paid / 100).format("0.00")}</td>
                <td>
                  <Button
                    link={true}
                    onClick={() => window.open(r.invoice_pdf, "_blank")}
                    text={_("general.pdf")}
                    active={true}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {props.showMore && (
        <Button
          link={true}
          active={true}
          text={_("account.plan.show.invoices")}
          onClick={() => props.loadMore()}
        />
      )}
    </div>
  );
});
