import * as moment from 'moment-timezone';
import messages from '../i18n/messages';

const getBodyOfUpdNotification = (type: string, changed: string[]): string => {
  const modifiedField = changed.length === 1 ? changed[0] : '';
  let result = messages['notifications.generalUpdate'];
  if (modifiedField && modifiedField.length) {
    switch (modifiedField) {
      case 'details':
        result = messages['notifications.updatedDetails'];
        break;

      case 'recursion-repeat':
      case 'recursion-after':
      case 'recursion-on':
      case 'every-duration':
        result = messages['notifications.updatedRecurrence'];
        break;
      case 'location':
        result = messages['notifications.locationChanged'];
        break;
      case 'start-time':
        result = messages['notifications.updatedStartTime'];
        break;
      case 'start-date':
        result = messages['notifications.updatedStartDate'];
        break;
      default:
        break;
    }
  } else if (!changed || changed.length === 0) {
    result = messages['notifications.reminderText'];
  }
  return result;
};
const formatNotificationDate = (date: moment.Moment): string => {
  if (
    date &&
    date
      .clone()
      .utc()
      .startOf('day')
      .isSame(moment.utc().startOf('day'))
  ) {
    return 'Today';
  }
  return date.format('ddd,MMM D');
};

const commonNotificationReplacements = (text: string, data: any): string => {
  return Object.keys(data).reduce((result, key) => {
    return result.replace(`{${key}}`, data[key]);
  }, text);
};
export const getBodyOfNotification = (type: string, changed: string[], data: any): string => {
  let result = '';
  switch (type) {
    case 'event_added':
      result = messages['notifications.added'];
      break;
    case 'event_changed':
      result = getBodyOfUpdNotification(type, changed);

      break;
    case 'event_cancelled':
      result = messages['notifications.deleted']; //.replace(/{date}/gi, formatNotificationDate(moment.utc(data.date)));
      break;
    default:
      break;
  }

  return commonNotificationReplacements(result, data);
};
export const getTitleOfNotification = (
  type: 'event_added' | 'event_changed' | 'event_cancelled',
  changed: string[],
  data: any
): string => {
  let result = '';
  switch (type) {
    case 'event_added':
      result = messages['notifications.addTitle'];
      break;
    case 'event_changed':
      if (changed && changed.length) {
        result = messages['notifications.updateTitle'];
      } else {
        result = messages['notifications.reminderTitle'];
      }
      break;
    case 'event_cancelled':
      result = messages['notifications.deleteTitle'];
      break;
    default:
      break;
  }

  return commonNotificationReplacements(result, data);
};
