import { createAction } from 'redux-actions';
import { Actions } from '../state/actions';
import { LoginErrorState } from '../state/state';
import { User } from '../models/User';

export const login = createAction(
  Actions.AUTH.LOGIN_REQUEST,
  (email: string, password: string, toPage: string | null) => ({
    email,
    password,
    toPage
  })
);
export const logOut = createAction(Actions.AUTH.LOGOUT_REQUEST);

export const loginSuccess = createAction(Actions.AUTH.LOGIN_SUCCESS, (user: User) => ({ user }));
export const loginError = createAction(Actions.AUTH.LOGIN_ERROR, (error: LoginErrorState) => ({
  errorType: error
}));
export const logoutSuccess = createAction(Actions.AUTH.LOGOUT_SUCCESS);
export const logoutError = createAction(Actions.AUTH.LOGOUT_ERROR);

export const resetPassword = createAction(Actions.AUTH.RESET_PASSWORD, (email: string) => ({
  email
}));
export const resetPasswordSuccess = createAction(Actions.AUTH.RESET_PASSWORD_SUCCESS, (email: string) => ({
  email
}));
export const resetPasswordError = createAction(Actions.AUTH.RESET_PASSWORD_ERROR, (error: LoginErrorState) => ({
  errorType: error
}));

export const syncWithNoPrevUser = createAction(Actions.AUTH.SYNC_USER_NO_DATA);
