import * as React from "react";
import { compose } from "recompose";
import { LocaleProps, withLocalization } from "../../i18n/localizable";
import { Plan, AllFeatures } from "../../../models/plan";
import Button from "../../input/button";
import "./card.less";
import PlanPrice from "../../your-plan/plan.price";
export default compose<
  {
    plan: Plan;
    selectedPlan: Plan;
    continueWithPlan: () => any;
    choosePlan: () => any;
  } & LocaleProps,
  {
    plan: Plan;
    selectedPlan: Plan;
    continueWithPlan: () => any;
    choosePlan: () => any;
  }
>(withLocalization())(props => {
  const { plan, _, selectedPlan } = props;
  return (
    <div
      className={`plan_card plan_card--${
        plan.pricePerMonth === 0 ? "free" : "paid"
      }`}
    >
      {selectedPlan && selectedPlan.id === plan.id && (
        <div className="plan_card__selected_marker" />
      )}
      <div className={`plan_card__name_price_container`}>
        <div
          className={`plan_card__name ${
            plan.pricePerMonth === 0 ? "plan_card__name--free" : ""
          }`}
        >
          {_(plan.name)}
        </div>
        <PlanPrice plan={plan} />
      </div>
      <ul className="plan_card__features">
        {plan.features.map(feature => {
          const f = AllFeatures.find(f => f.id === feature.id);
          const isSelected = true;

          return (
            <li className="plan_card__features__feature">
              <div
                className={
                  isSelected
                    ? "plan_card__features__feature__tick"
                    : "plan_card__features__feature__cross"
                }
              />
              <div
                className={`plan_card__features__feature__text  ${
                  isSelected
                    ? "plan_card__features__feature__text--selected"
                    : ""
                }`}
              >
                {_(f.text)}
              </div>
            </li>
          );
        })}
      </ul>
      <div className="plan_card__button_container">
        {selectedPlan && selectedPlan.id === plan.id ? (
          <Button
            text={_("payments.plan.continue", { plan: _(plan.name) })}
            onClick={props.continueWithPlan}
            grayStyle={plan.pricePerMonth === 0}
          />
        ) : (
          <Button
            text={_("payments.plan.choosePlanBtn", { plan: _(plan.name) })}
            onClick={props.choosePlan}
            grayStyle={plan.pricePerMonth === 0}
          />
        )}
      </div>
    </div>
  );
});
