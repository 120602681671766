import * as React from 'react';
import { LocaleProps, localizable } from '../../i18n/localizable';
import Button from '../../input/button';
import InputText from "../../input/text";
import { isEmpty } from '../../../utils/string.utils';  
import { Services } from '../../../api/services';
import { push } from "connected-react-router";
import { connect } from "react-redux";

  export interface Props {
        show: boolean;
        message?: string;
        onYes: (userId:string) => any;
        onNo?: () => any;
        yesText?: string;
        noText?: string;
        children?: any;
        className?: string;
        alert?: boolean;
        title?: string;
        fixed?: boolean;
        password?: string;
        user: any;
        nameInput?:string;
        goToLogin?: () => any;
        hasPortalAccount?: boolean;
  }
  
  export interface State {
      
  }
 
class OrgRequestMultiSignupDialog extends React.Component<Props & LocaleProps, State> {
    state = { 
        password: " ",
        passwordError: "",
        disableInputs: false
     }
     
    handleClear = () => {
        if(!this.state.disableInputs)
        {
            this.setState({
                password: " ",
                passwordError: "",
                disableInputs: false
            });
            this.props.onNo();
        }
    };
     
    validateUser = () => {
        if(!isEmpty(this.state.password.trim()) && this.state.password.length >= 6)
        {      
            this.setState({ ...this.state, disableInputs: true }) 
            Services.Organizations.ReauthenticateUser(this.props.user.email, this.state.password).then((user) => {
                if(user)
                {
                    this.props.onYes(user.uid);
                }
                else {
                    this.setState({ ...this.state, passwordError: true, password: "", disableInputs: false })
                }
            })
         }
         return "";
     }

     ClearMessages(name: string, e?:any){
        this.setState({
            ...this.state,
            password: name === "password" ? this.state.password.trim(): this.state.password,
            passwordError: "",
            disableInputs: false
        });
     }

    render() { 
        const { hasPortalAccount, show, alert, _ } = this.props;
        return ( show ? (
        <div style={ this.props.show ? { display: "block"} : { display: "none"}} >
            <div className="component_overlay" style={{ position: 'fixed'} }>
                <div className={`component_overlay__content`}>
                    <h3 className="title_password_dialog">{_("signup.multi.popup.title")}</h3>
                    <div className={"event_dialog__close_btn"} style={{margin: 15}} onClick={()=> {hasPortalAccount ? this.props.goToLogin() : this.handleClear(); }} />
                    <br />
                     {hasPortalAccount ? _("signup.multi.popup.descriptionPortal1") : _("signup.multi.popup.descriptionMobile1")}
                    {hasPortalAccount && <Button
                        link={true}
                        additionClassName="signup_multi--link"
                        onClick={() => {
                        this.props.goToLogin();
                        }}
                        text={"Login"}
                    />}{hasPortalAccount && _("signup.multi.popup.descriptionPortal2")}<br /><br />
                    {hasPortalAccount ? _("signup.multi.popup.descriptionPortal3") : _("signup.multi.popup.descriptionMobile2")}
                   <br />
                    {/* <InputText
                        autoFocus={false}
                        type={ this.state.password.trim().length === 0 ? "text" : "password" }
                        onFocus={ ()=> this.ClearMessages("password") }
                        disabled={this.state.disableInputs} 
                        value={ this.state.password }
                        error={false}
                        showErrorPerDefault={ true }
                        errorText={_("signup.multi.popup.passwordLabel")}
                        showErrorAfter={true}
                        placeholder={_("signup.multi.popup.passwordPh")}
                        onChange={ (pass) => this.setState({
                            ...this.state,
                            password: pass.trim()
                        }) }
                        materialPlaceholder={true} 
                    />
                    { this.state.passwordError ? (<div className="text_box__error_desc">{_("signup.multi.popup.error.description1")}
                    <Button
                        link={true}
                        additionClassName="signup_multi--link"
                        onClick={() => {
                        this.props.goToLogin();
                        }}
                        text={"Login"}
                    />{_("signup.multi.popup.error.description2")}</div>) : null} */}
                    
                    <div  className="component_overlay__confirm_buttons">
                        {/* <Button disabled={this.state.disableInputs} link={true} text={_("signup.multi.popup.cancel")} onClick={this.handleClear} /> */}
                        {/* <Button disabled={this.state.password.length < 6 || this.state.disableInputs} text={this.props.yesText ? _("signup.multi.popup.continue") : alert ? _("signup.multi.popup.ok") : _("signup.multi.popup.yes")} 
                        onClick={this.validateUser} data-state={this.state */}
                        <Button text={_("signup.multi.popup.continue")} 
                        onClick={this.handleClear} data-state={this.state} />
                    </div>
                </div>
            </div>
        </div>) : null
        )
    }
}

export default connect(
    null,
    dispatch => ({
        goToLogin() {
        dispatch(push("/login"));
      }
    })
  )(localizable(OrgRequestMultiSignupDialog));