import * as React from 'react';
import { MessageFunction } from '../../i18n/localizable';
import * as moment from 'moment-timezone';
import './new-event.less';
import DatePicker from '../../input/date.picker';
import Checkbox from '../../input/checkbox';
import AutoSelectInput from '../../input/auto.select.input';
import TextInput from '../../input/text';
import 'react-datepicker/dist/react-datepicker.css';
import { RepeatDuration, AfterType, EventRecursionInfo } from '../../../api/model/recursion.info';
import WeekSelector from '../../input/week.selector';

function AfterOptionView(props: {
  option: AfterType;
  selectedOption: AfterType;
  selectOption: (option: AfterType) => any;
  updateValue: (value: string | moment.Moment) => any;
  value: string | Date;
  _: MessageFunction;
}) {
  const { selectedOption, option, updateValue, selectOption, value } = props;
  return (
    <div className="event_form_dialog__row__recurring__options__row__after_row">
      <div className="event_form_dialog__row__recurring__options__row__after_row__chk_container">
        <Checkbox
          checked={selectedOption === option}
          text={option}
          onChange={checked => checked && selectOption(option)}
        />
      </div>
      <div className="event_form_dialog__row__recurring__options__row__after_row__value_container">
        {option === AfterType.AFTER && (
          <TextInput
            additionalClassName="event_form_dialog__after_box"
            value={(value as string) || '0'}
            onChange={endsAfter => updateValue(endsAfter)}
            type="number"
            descriptor={props._('occurrences')}
            maxLength={5}
          />
        )}

        {option === AfterType.ON && (
          <DatePicker
            additionalClassName="event_form_dialog__afer_on"
            selected={value ? moment(value) : null}
            onChange={endsOn => updateValue(endsOn)}
          />
        )}
      </div>
    </div>
  );
}

function updateRecursionInfo(
  original: EventRecursionInfo,
  newInformation: Partial<EventRecursionInfo>
): EventRecursionInfo {
  return Object.assign({}, original, newInformation);
}
export default function RecurringOptions(props: {
  info: EventRecursionInfo;
  updateValue: (info: EventRecursionInfo) => any;
  visible: boolean;
  startDate: moment.Moment;
  _: MessageFunction;
}) {
  const { _, info, visible, updateValue } = props;
  const update = (val: Partial<EventRecursionInfo>) => updateValue(updateRecursionInfo(info, val));
  return (
    <div
      className={`event_form_dialog__row__recurring__options event_form_dialog__row__recurring__options--${
        visible ? 'visible' : 'invisible'
      }`}
    >
      <div className="event_form_dialog__row__recurring__options__row">
        <div className="event_form_dialog__row__recurring__left event_form_dialog__row__recurring__every">
          <div className="event_form_dialog__row__recurring__options__row__message event_form_dialog__row__recurring__options__every_text">
            {_('dialog.events.recurring.repeats.every')}
          </div>
          <TextInput
            type="number"
            additionalClassName="event_form_dialog__row__recurring__options__row__every_text"
            value={info.repeatsEvery + ''}
            onChange={val => update({ repeatsEvery: parseInt(val) })}
          />
          <AutoSelectInput
            getItemKey={(e: string) => e}
            getItemText={(e: string) => e}
            onChange={text => {
              let repeatsOn = [0];
              const everyDuration = text.toUpperCase() as RepeatDuration;
              switch (everyDuration) {
                case RepeatDuration.MONTH:
                  repeatsOn = [(props.startDate || moment()).date()];
                  break;
                case RepeatDuration.WEEK:
                  repeatsOn = [(props.startDate.clone() || moment()).day()];
                  break;
                default:
                  repeatsOn = null;
                  break;
              }
              update({ everyDuration, repeatsOn });
            }}
            value={info.everyDuration.toLowerCase()}
            className="event_form_dialog__row__recurring__options__row__every_select"
            allItems={[RepeatDuration.DAY, RepeatDuration.WEEK, RepeatDuration.MONTH, RepeatDuration.YEAR].map(d =>
              d.toLowerCase()
            )}
            placeHolder=""
          />
        </div>
      </div>
      <div className="event_form_dialog__row__recurring__options__row event_form_dialog__row__recurring__options__row--repeat">
        {(info.everyDuration == RepeatDuration.MONTH || info.everyDuration == RepeatDuration.WEEK) && (
          <div className="event_form_dialog__row__recurring__options__row__message recurring__options__repeats_on_label">
            {_('dialog.events.recurring.repeats.on')}
          </div>
        )}
        {info.everyDuration == RepeatDuration.MONTH && (
          <TextInput
            additionalClassName="event_form_dialog__row__recurring__month_day"
            type="number"
            value={info.repeatsOn[0] + ''}
            onChange={val => update({ repeatsOn: [parseInt(val)] })}
            descriptor={props._('dialog.events.on.day')}
            descriptorFirst={true}
            max={31}
          />
        )}
        {info.everyDuration == RepeatDuration.WEEK && (
          <WeekSelector selected={info.repeatsOn} onChange={newSelected => update({ repeatsOn: newSelected })} />
        )}
      </div>
      <div className="event_form_dialog__row__recurring__options__row event_form_dialog__row__recurring__options__row--end">
        <div className="event_form_dialog__row__recurring__options__row__message">
          {_('dialog.events.recurring.ends')}
        </div>
      </div>
      {[AfterType.ON, AfterType.AFTER].map(opt => (
        <AfterOptionView
          option={opt}
          selectedOption={info.afterType}
          selectOption={val =>
            update({
              afterType: val
            })
          }
          _={_}
          updateValue={val => {
            if (opt === AfterType.ON) {
              
              let endsOn = val as moment.Moment;
              update({ endsOn: endsOn.toDate(), afterType: AfterType.ON });
            } else if (opt === AfterType.AFTER) {
              const endsAfter = parseInt(val as string);
              update({ endsAfter });
            }
          }}
          value={opt === AfterType.AFTER ? info.endsAfter + '' : opt === AfterType.ON ? info.endsOn : null}
        />
      ))}
    </div>
  );
}
