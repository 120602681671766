import * as React from "react";
import OrganizationReciepts from "../../organization-reciepts";
import YourPlan from "../../your-plan";
import BillingInformation from "../../billing-information";
import Organization from "../../../models/organization";
import { connect } from "react-redux";
import { AppState } from "../../../state/state";

export default connect((state:AppState)=>({
organization:state.organization.selectedOrganization
}))( (props:{organization:Organization}) =>{
  const {organization}=props;
  return (
    <div className="your_plan">
      <YourPlan />
      <BillingInformation />
      {organization && <OrganizationReciepts key={organization.id} />}
    </div>
  );
})
