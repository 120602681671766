import * as React from "react";
import CalendarView from "../../calendar/calendar";
import Tabs from "../../tab-component/tabs";
import { Switch, Route } from "react-router";
import AccountProfile from "./account.profile";
import AccountPlan from "./account.plan";
import "./account-layout.less";
import { LocaleProps, localizable } from "../../i18n/localizable";
import Organization from "../../../models/organization";
import { connect } from "react-redux";
import { AppState } from "../../../state/state";
import { IUser } from "../../../models/User";
//import OrgIconChangeDialog from "./account.image.dialog";
import {
  compose,
  withState,
  withPropsOnChange,
  withStateHandlers,
  withProps
} from "recompose";

export default compose<any, any>(
  localizable,
  withState("showImageDialog", "setShowImageDialog", false),
   withProps((props: { setEditModeOff: () => any;setClickedSave:(clicked:boolean)=>any; setShowImageDialog:(clicked:boolean)=>any; 
    organization: Organization; user: IUser, state:any, editOrganization:any, editUser: any, currentPass: any }) => ({
      showImageChange(){
        props.setShowImageDialog(true);
      },
      hideImageChange(){
        props.setShowImageDialog(false);
      }

    })),
  connect((state: AppState) => (  {
    
    organization: state.organization.selectedOrganization,
    user: state.currentUser.user,
  }))
)(
  (
    props: { 
      organization: Organization; 
      user: IUser; 
      showImageDialog:boolean; 
      showImageChange: () => any; 
      hideImageChange: () => any; 
    } 
    & LocaleProps) => {

  const { organization, user } = props;
  if (!props.organization) {
    return null;
  }
  return (
    <div className="account_page">
      <h2 className="account_page__title">{props._("account.title")}</h2>
      <div className="account_page__content_container">

        {/* <div className="account_page__general_info">
          <div className="account_page__general_info__logo">
            <div className="account_page__general_info__logo_cont">
            <img
              className="account_page__general_info__logo__image"
              src={organization.icon}
              onClick={ props.showImageChange } 
            />
            </div>
            <div className="account_page__general_info__logo_edit_cont">
            <img
              className="account_page__general_info__logo__image_edit"
              src={require('../../../components/images/imageEdit.png')}
              onClick={ props.showImageChange } 
            />
            </div>
            
            
          </div>
          <div className="account_page__general_info__org_name">
            {props.organization.name}
          </div>
          <div className="account_page__general_info__user_name">
            {props.user.firstName} {props.user.lastName}
          </div>
          <div className="account_page__general_info__email">
            {props.user.mainEmail}
          </div>
        </div> */}


        <div className="account_page__content">
          <Tabs
            options={[
              {
                msg: "account.profile.tab",
                route: "/account/profile"
              },
              {
                msg: "account.plan.tab",
                route: "/account/plan"
              }
            ]}
          />

          <Switch>
            <Route path="/account/profile">
              <AccountProfile />
            </Route>           
            <Route path="/account/plan">
              <AccountPlan />
            </Route>
          </Switch>
        </div>

        {/* <OrgIconChangeDialog 
          fixed={true}
          show={props.showImageDialog}
          organization={props.organization}
          alert={true}
          yesText={props._('continue')}
          onNo={props.hideImageChange}
          onYes={() => {
          }}
        /> */}
      </div>
    </div>
  );
});
