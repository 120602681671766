import * as React from "react";
import {
  LocaleProps,
  localizable,
  withLocalization
} from "../../i18n/localizable";
import Button from "../../input/button";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { compose, withState } from "recompose";
import { AppState } from "../../../state/state";
import { match } from "react-router";

export default compose<any, any>(
  connect(
    (state: AppState) => ({
      organizationName:
        state.currentUser && state.currentUser.user
          ? state.currentUser.user.mainEmail || ""
          : ""
    }),
    dispatch => ({
      goToLogin() {
        dispatch(push("/login"));
      }
    })
  ),
  withState("reasonsVisible", "setReasonsVisible", false),
  withLocalization("signup.multi")
)(
  (
    props: {
      goToLogin: () => any;
      setReasonsVisible: (val: boolean) => any;
      reasonsVisible: boolean;
      match?: match<{ name: string }>;
      organizationName: string;
    } & LocaleProps
  ) => {
    const { _, match } = props;
    return (
      <div>
        <h1 className="login_page__left__get_started">{_("success.title",{orgName: match.params.name})}</h1>
        <br />
        <div className={`login_page__success__description`}>{_("success.description1")}</div>
        <div className={`login_page__success__description`}>
          {_("success.description2")}<Button
                        link={true}
                        additionClassName="signup_multi--link"
                        onClick={() => {
                        props.goToLogin();
                        }}
                        text={"log in"}
                    />
                    {_("success.description3")}
        </div>
      </div>
    );
  }
);
