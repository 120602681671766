import * as React from 'react';
import { LocaleProps, localizable } from '../../i18n/localizable';
import Button from '../../input/button';
import './account.password.change.less';
import InputText from "../../input/text";
import { isEmpty } from '../../../utils/string.utils';  
import { Services } from '../../../api/services';

  export interface Props {
        show: boolean;
        message?: string;
        onYes: () => any;
        onNo?: () => any;
        yesText?: string;
        noText?: string;
        children?: any;
        className?: string;
        alert?: boolean;
        title?: string;
        fixed?: boolean;
        oldPassword?: string;
        user: any;
        nameInput?:string;
  }
  
  export interface State {
      
  }
 
class PasswordChangeDialog extends React.Component<Props, State> {
  
    state = { 
        oldPassword: " ",
        newPassword: " ",
        newPasswordConfirm: " ",
        oldPasswordError: "",
        newPasswordError: "",
        newPasswordConfirmError: "",
        disableInputs: false
     }

      handleClear = () => { 
        this.setState({
            oldPassword: " ",
            newPassword: " ",
            newPasswordConfirm: " ",
            oldPasswordError: "",
            newPasswordError: "",
            newPasswordConfirmError: "",
            disableInputs: false
        });
        this.props.onNo();
     };

     updatePassword = () => {
         if(!isEmpty(this.state.oldPassword.trim()) || !isEmpty(this.state.newPassword.trim()) || !isEmpty(this.state.newPasswordConfirm.trim()))
         {      
             if(this.state.newPassword === this.state.newPasswordConfirm)
             {
                if(this.state.newPassword.length < 6) 
                {
                    this.setState({
                        ...this.state,
                        newPasswordError: "Password should have at least 6 characters long",
                        newPasswordConfirmError: "Password should have at least 6 characters long"
                    });
                }
                else{
                    this.setState({ ...this.state, disableInputs: true }) 
                    Services.Users.updateUserPassword(this.props.user, this.state.oldPassword, this.state.newPassword).then((result) => {
                        if(result.invalidPassword)
                            this.setState({ ...this.state, oldPasswordError: result.message, disableInputs: false }) 
                        else if(result.invalidLength)
                            this.setState({ ...this.state, newPasswordError: result.message, newPasswordConfirmError: result.message, disableInputs: false }) 
                        else
                            this.handleClear();
                    })
                }
             }
             else{
                this.setState({
                    ...this.state,
                    newPasswordError: "New Passwords do not match",
                    newPasswordConfirmError: "New Passwords do not match"
                });
             }
         }
     }

     ClearMessages(name: string, e?:any){
        this.setState({
            ...this.state,
            oldPassword: name === "oldPassword" ? this.state.oldPassword.trim(): this.state.oldPassword,
            newPassword: name === "newPassword" ? this.state.newPassword.trim(): this.state.newPassword,
            newPasswordConfirm: name === "newPasswordConfirm" ? this.state.newPasswordConfirm.trim(): this.state.newPasswordConfirm,
            oldPasswordError: "",
            newPasswordError: "",
            newPasswordConfirmError: "",
            disableInputs: false
        });
     }

    render() { 
        const { show, alert } = this.props;
        return ( show ? (
        <div style={ this.props.show ? { display: "block"} : { display: "none"}} >
            <div className="component_overlay" style={{ position: 'fixed'} }>
                <div className={`component_overlay__content`}>
                    <h3 className="title_password_dialog">Password Reset</h3>
                    <InputText
                        autoFocus={true}
                        type={ this.state.oldPassword.trim().length === 0 ? "text" : "password" }
                        onFocus={ ()=> this.ClearMessages("oldPassword") }
                        disabled={this.state.disableInputs} 
                        value={ this.state.oldPassword }
                        error={isEmpty(this.state.oldPassword)}
                        showErrorPerDefault={ true }
                        errorText={"Please enter the current password"}
                        showErrorAfter={true}
                        placeholder={"Current Password"}
                        onChange={ (oldPass) => this.setState({
                            ...this.state,
                            oldPassword: oldPass.trim()
                        }) }
                        materialPlaceholder={true} 
                    />
                    <div className="text_box__error_desc">{ this.state.oldPasswordError}</div>
                    <InputText
                        type={ this.state.newPassword.trim().length === 0 ? "text" : "password" }
                        onFocus={()=> this.ClearMessages("newPassword")}
                        disabled={ this.state.disableInputs } 
                        value={ this.state.newPassword }
                        error={isEmpty(this.state.newPassword)}
                        showErrorPerDefault={ true }
                        errorText={"Please enter the new password"}
                        showErrorAfter={true}
                        placeholder={"New Password"}
                        onChange={ (newPass) => 
                            {
                                this.setState({
                                    ...this.state,
                                    newPassword: newPass
                                }) 
                            }
                        }
                        materialPlaceholder={true} 
                    />
                    <div className="text_box__error_desc">{ this.state.newPasswordError}</div>
                    <InputText
                        type={ this.state.newPasswordConfirm.trim().length === 0 ? "text" : "password" }
                        onFocus={ ()=> this.ClearMessages("newPasswordConfirm")}
                        disabled={this.state.disableInputs} 
                        value={ this.state.newPasswordConfirm }
                        error={isEmpty(this.state.newPasswordConfirm)}
                        showErrorPerDefault={ true }
                        errorText={"Please repeat the current Password"}
                        showErrorAfter={true}
                        placeholder={"Re-Type New Password"}
                        onChange={ (newPassConf) => this.setState({
                            ...this.state,
                            newPasswordConfirm: newPassConf
                        }) }
                        materialPlaceholder={true}
                    /> 
                    <div className="text_box__error_desc">{ this.state.newPasswordConfirmError}</div>
                    <div  className="component_overlay__confirm_buttons">
                        <Button disabled={this.state.disableInputs} link={true} text={"Cancel"} onClick={this.handleClear} />
                        <Button disabled={this.state.disableInputs} text={this.props.yesText ? "Save" : alert ? "ok" : "yes"} onClick={this.updatePassword} data-state={this.state} />
                    </div>
                </div>
            </div>
        </div>) : null
        )
    }
}
 
export default PasswordChangeDialog; 