import * as React from "react";
import { LocaleProps, localizable } from "../i18n/localizable";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import { any } from "prop-types";
import { connect } from "react-redux";
import { AppState } from "../../state/state";

export default compose<any, { options: { route: string; msg: string }[] }>(
  localizable,
  connect(
    (state: AppState) => ({
      currentLocation: state.router.location
    }),
    dispatch => ({})
  )
)(
  (
    props: {
      options: { route: string; msg: string }[];
      currentLocation: Location;
    } & LocaleProps
  ) => {
    return (
      <ul className="tabs">
        {props.options.map(o => {
          const itemClassName = `tabs__item  ${
            props.currentLocation.pathname.indexOf(o.route) >= 0
              ? "tabs__item--selected"
              : ""
          }`;
          return (
            <li key={ o.route } className={itemClassName}>
              <Link
                key={`${o.msg}`}
                className={"tabs__item__link"}
                to={o.route}
              >
                {props._(o.msg)}
              </Link>
            </li>
          );
        })}
      </ul>
    );
  }
);
