import { render } from "react-dom";
import * as React from "react";

import MainComponent from "./components/index";
//import { BrowserRouter as Router } from 'react-router-dom';
import { Router } from "react-router";
import { ConnectedRouter, push } from "connected-react-router";

import LanguageContext from "./components/i18n/language.context";
import createBrowserHistory from "history/createBrowserHistory";
import { Provider } from "react-redux";
import { store } from "./state/store";
import { GeneralLoaderTargets, LoaderTarget } from "./state/loader.target";
import { StripeProvider } from "react-stripe-elements";
import { history } from "./state/history";
declare const API_KEY: string;
const Progress = require("react-progress-2").default;

let previousLoadingTargets: LoaderTarget[] = [];

store.subscribe(() => {
  const newLoadingState = store.getState().loading;
  const newGeneralTargets = newLoadingState.targets
    .filter(t => GeneralLoaderTargets.indexOf(t.key as LoaderTarget) >= 0)
    .map(t => t.key as LoaderTarget);
  newGeneralTargets.forEach(t => {
    const isNewTarget = previousLoadingTargets.every(
      prevTarget => prevTarget !== t
    );
    if (isNewTarget) {
      Progress.show();
    }
  });
  previousLoadingTargets.forEach(t => {
    const isDeleted = newGeneralTargets.every(newTarget => newTarget !== t);
    if (isDeleted) {
      Progress.hide();
    }
  });
  previousLoadingTargets = newGeneralTargets;
});

render(
  <StripeProvider apiKey={API_KEY}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <MainComponent />
      </ConnectedRouter>
    </Provider>
  </StripeProvider>,
  document.getElementById("time-peace-app")
);
