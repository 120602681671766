import rsf from "./firebase";
import {
  put,
  call,
  take,
  fork,
  takeEvery,
  all,
  select,
  takeLatest,
} from "redux-saga/effects";
import {
  loginSuccess,
  loginError,
  logoutSuccess,
  logoutError,
  resetPasswordSuccess,
  resetPasswordError,
} from "../actions/auth.actions";
import { Actions } from "../state/actions";
import { Action, createAction } from "redux-actions";
import { LoginErrorState, AppState } from "../state/state";
import { CategoryNode, parseCategoryNode } from "../api/model/category.node";
import {
  syncCategoriesOfOrganization,
  categoryInfoLoaded,
} from "../actions/category.actions";

import { relatedElement } from "../utils/saga.utils";
import { Services } from "../api/services";
import newCategoryDialog from "../components/pages/new-category-dialog";

function* addedCategory(id: string, node: CategoryNode) {
  const newCategory = parseCategoryNode(id, node);
  yield put(categoryInfoLoaded(newCategory));
  if (node.calendars) {
    yield all(
      Object.keys(node.calendars).map((calendarId) => {
        return put(
          createAction(Actions.CALENDAR.CALENDAR_ADDED)({
            calendarId,
            categoryId: id,
            category: newCategory,
          })
        );
      })
    );
  }
}
function* addNewCategorySaga(
  action: Action<{ name: string; description: string }>
) {
  const { name, description } = action.payload;
  const key: string = yield call(rsf.database.create, "Categories", {
    name,
    details: description,
  });
  const state: AppState = yield select();
  const organizationId = state.organization.organizations[0].id;
  yield call(
    rsf.database.update,
    `Organizations/${organizationId}/categories/${key}`,
    key
  );
  yield put(createAction(Actions.CATEGORIES.NEW_SUCCESS)());
}
function* changeCategoriesOrder(action: Action<{ newOrder: string[] }>) {
  yield action.payload.newOrder.forEach((key, idx) => {
    Services.Category.updateOrder(key, idx + 1);
  });
}
function* categoryRootSaga() {
  yield all([
    relatedElement<CategoryNode>({
      synchActionName: Actions.CATEGORIES.SYNCH,
      ref: (id: string) => `Categories/${id}`,
      onChannelUpdated: (id, value) => addedCategory(id, value),
      removeAction: (id) => {
        return createAction("REMOVE_CATEGORY")({ id });
      },
    }),
    takeEvery(Actions.CATEGORIES.NEW_REQUEST, addNewCategorySaga),
    takeEvery(Actions.CATEGORIES.REORDER, changeCategoriesOrder),
  ]);
}

export default [categoryRootSaga];
