// @flow
import * as React from 'react';
import Loader from '../loader/loader';

interface AsyncComponentState {
  error: boolean;
  Component: any;
}

export default function asyncComponent(loader: () => Promise<any>, key?: string) {
  return class AsyncComponent extends React.Component<any, AsyncComponentState> {
    constructor(props: any) {
      super(props);
      this.state = { error: false, Component: null };
    }
    componentDidMount() {
      loader()
        .then(Component => {
          this.setState({ error: false, Component: Component.default });
        })
        .catch(() => {
          loader()
            .then(Component => this.setState({ error: false, Component: Component.default }))
            .catch(() => this.setState({ error: true }));
        });
    }

    render() {
      const { Component, error } = this.state;
      if (Component) {
        return <Component key={key} />;
      } else if (!error) {
        return <Loader show={true} />;
      }
      return <div>There was an error while loading the view</div>;
    }
  };
}
